/* eslint-disable jsx-a11y/anchor-is-valid */
import { Player } from "@lottiefiles/react-lottie-player";
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useMutation, useQuery } from "@tanstack/react-query";
import { AiEditor } from "aieditor";
import "aieditor/dist/style.css";
import dayjs, { Dayjs } from "dayjs";
import "easymde/dist/easymde.min.css";
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import removeMarkdown from 'remove-markdown';
import 'tippy.js/animations/scale.css';
import 'tippy.js/dist/tippy.css';
import newTab from "../../assets/images/new-tab.png";
import AbunButton from "../../components/AbunButton/AbunButton";
import AbunModal from "../../components/AbunModal/AbunModal";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import GenericButton from "../../components/GenericButton/GenericButton";
import Icon from "../../components/Icon/Icon";
import Input from "../../components/Input/Input";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import {
    saveGlossaryMutation,
    scheduleGlossaryMutation,
    editGlossaryTermMutation,
    postGlossaryBlogMutation
} from "../../utils/api";
import { AllIntegrations } from "../Articles/ShowV2Articles";
import ConnectWebsiteLoading from "../ConnectWebsite/ConnectWebsiteLoading";
import WebsiteCompetitors from "../ConnectWebsite/WebsiteCompetitors";
import WebsiteDomain from "../ConnectWebsite/WebsiteDomain";
import WebsiteICP from "../ConnectWebsite/WebsiteICP";
import WebsiteTitle from "../ConnectWebsite/WebsiteTitle";
import { useLoaderData } from "react-router-dom";
import { connectWebsiteQuery } from "../../utils/api";
import AbunLoader from "../../components/AbunLoader/AbunLoader";
import { pageURL } from "../routes";

import { BasePageData } from "../Base/Base";
import "./GlossaryEditor.scss";

interface ConnectWebsiteProps {
    setShowConnectWebsiteModal: React.Dispatch<React.SetStateAction<boolean>>
    successAlertRef: MutableRefObject<any>
    failAlertRef: MutableRefObject<any>
}
interface WebsiteData {
    domain: string
    protocol: string
    blog: string
    title: string
    description: string
    industry: string
    icp: string
    language: string
    competitors: Array<string>
    generate_content_plan: boolean
}

type Link = {
    linkHref: string
    linkTxt: string
    rel?: string
}

interface GlossaryTerm {
    term: string;
    content: string;
    keyword_hash: string;
    internal_link_count: number | null;
    glossary_link: string | null;
    is_processing: boolean;
    is_failed: boolean;
    is_generated: boolean;
    is_posted: boolean;
    is_archived: boolean;
    posted_to: 'wordpress' | 'webflow' | 'wix' | 'shopify';
    posted_on: string | null;
    feedback: 'positive' | 'negative' | 'no_feedback' | null;
    all_integrations_with_unique_id: AllIntegrations[];
    article_scheduled_for_posting: boolean
    article_scheduled_datetime: string
}


interface GlossaryContentData {
    glossary_term: GlossaryTerm;
}


export default function GlossaryEditor() {
    // ------------------- REACT ROUTER -------------------
    const { keywordHash } = useParams();
    const pageData = useLoaderData() as GlossaryContentData;
    const navigate = useNavigate();
    const basePageData = useRouteLoaderData("base") as BasePageData;

    const { active_website_domain } = basePageData;
    const [showConnectWebsiteWarningModal, setShowConnectWebsiteWarningModal] = useState(false);
    const [showConnectWebsiteModal, setShowConnectWebsiteModal] = useState(false);
    const failAlertRef = useRef<any>(null);

    // ------------------- STATES -------------------
    const [articlePosted, setArticlePosted] = useState(false);

    const [integrationDone, setIntegrationDone] = useState(false);

    const [selectedIntegration, setSelectedIntegration] = useState("");

    const [editTitleModalActive, setEditTitleModalActive] = useState(false);

    const [processing, setProcessing] = useState(false);

    const [articleTitle, setArticleTitle] = useState("");

    const [keyword, setKeyword] = useState<string | null>(null);

    const [keywordTraffic, setKeywordTraffic] = useState<number | null>(null);

    const [wordCount, setWordCount] = useState<number | null>(null);

    const [featuredImageURL, setFeaturedImageURL] = useState<string | null>(null);

    const [articleContent, setArticleContent] = useState<string | undefined>(undefined);

    const [externalBacklinksPreference, setExternalBacklinksPreference] = useState<string>("");

    const [saveButtonText, setSaveButtonText] = useState("Save");

    const [disableSave, setDisableSave] = useState(true);

    const [disableUpdatePublish, setDisableUpdatePublish] = useState(true);

    const [changeTitleValue, setChangeTitleValue] = useState("");

    // article post link
    const [articlePostLink, setArticlePostLink] = useState<string>("");

    // article posted to (wordpress, wix, webflow etc.)
    const [articlePostedTo, setArticlePostedTo] = useState<string>("");

    // article posted on (date)
    const [articlePostedOn, setArticlePostedOn] = useState<string>("");

    const [articleScheduledForPosting, setArticleScheduledForPosting] = useState<boolean>(false);

    const [articleScheduledDatetime, setArticleScheduledDatetime] = useState<Dayjs | null>(null);

    const [schedulePublishDateTime, setSchedulePublishDateTime] = useState<Dayjs | null>(dayjs().add(1, "day"));

    // today's date and time in string format as default value
    const [schedulePublishDate, setSchedulePublishDate] = useState<string>(dayjs().format("YYYY-MM-DD"));
    const [schedulePublishTime, setSchedulePublishTime] = useState<string>(dayjs().format("HH:mm"));

    const [articleFeedback, setArticleFeedback] = useState<string>("no_feedback");

    const [selectedIntegrationUniqueID, setSelectedIntegrationUniqueID] = useState("");

    const [integrationWithUniqueID, setIntegrationWithUniqueID] = useState<Array<AllIntegrations>>([]);

    const [articleInternalLinks, setArticleInternalLinks] = useState<Array<Link>>([]);
    const [newLinkHref, setNewLinkHref] = useState<string>("https://");
    const [newLinkPhrase, setNewLinkPhrase] = useState<string>("");

    const [articleInternalLinkEditableIndex, setArticleInternalLinkEditableIndex] = useState<number | null>(null);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);
    const [isOptionSidebarCollapsed, setIsOptionSidebarCollapsed] = useState<boolean>(true);
    const [addNewLinkModalActive, setAddNewLinkModalActive] = useState({ active: false, linkType: "internal" });
    const [modalText, setModalText] = useState("");
    const [requestModalActive, setRequestModalActive] = useState(false);
    const [aiEditorMarkdownContent, setAiEditorMarkdownContent] = useState<string | undefined>(undefined);
    const [aiEditorHtmlContent, setAiEditorHtmlContent] = useState<string | undefined>(undefined);
    const [aiEditorTextContent, setAiEditorTextContent] = useState<string | undefined>(undefined);
    const [publishOption, setPublishOption] = useState('publish');
    const [publishType, setPublishType] = useState('publish');
    const [copyAs, setCopyAs] = useState<string>("markdown");

    //Define Ref
    const articleEditorDivRef = useRef(null);
    const aiEditor = useRef<AiEditor | null>(null);

    // ------------------- CUSTOM EVENTS -------------------
    function GetWordCount(data: string): number {
        var pattern = /[a-zA-Z0-9_\u00A0-\u02AF\u0392-\u03c9\u0410-\u04F9]+|[\u4E00-\u9FFF\u3400-\u4dbf\uf900-\ufaff\u3040-\u309f\uac00-\ud7af]+/g;
        var m = data.match(pattern);
        var count = 0;
        if (m === null) return count;
        for (var i = 0; i < m.length; i++) {
            if (m[i].charCodeAt(0) >= 0x4E00) {
                count += m[i].length;
            } else {
                count += 1;
            }
        }
        return count;
    }

    function setIntegrationAndHideDropDownContent(integrate: string) {
        setSelectedIntegration(integrate);
    }

    function debounce<T extends (...args: any[]) => any>(
        func: T,
        wait: number
    ): (...args: Parameters<T>) => void {
        let timeout: NodeJS.Timeout | undefined;

        return function (this: any, ...args: Parameters<T>): void {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    }

    function useMediaQuery(query: string) {
        const [matches, setMatches] = useState(() => window.matchMedia(query).matches);

        useEffect(() => {
            const mediaQueryList = window.matchMedia(query);
            const documentChangeHandler = () => setMatches(mediaQueryList.matches);

            mediaQueryList.addEventListener('change', documentChangeHandler);

            return () => {
                mediaQueryList.removeEventListener('change', documentChangeHandler);
            };
        }, [query]);

        return matches;
    };

    // ------------------- MUTATIONS -------------------
    const saveGlossary = useMutation(saveGlossaryMutation);
    const postGlossary = useMutation(postGlossaryBlogMutation);
    const editGlossaryTerm = useMutation(editGlossaryTermMutation);
    const scheduleGlossary = useMutation(scheduleGlossaryMutation);

    // ----------------------- REFS -----------------------
    const successAlertRef = useRef<any>(null);
    const errorAlertRef = useRef<any>(null);

    // ----------------------- MEDIA QUERIES -----------------------
    const isMediumScreen = useMediaQuery("(max-width: 1000px)");
    const isSmallScreen = useMediaQuery("(max-width: 600px)");

    // ----------------------- EFFECTS -----------------------
    useEffect(() => {
        if (articlePosted) {
            document.title = "View Article - Published";
        } else {
            document.title = "View Article - Not Published";
        }
    }, [articlePosted]);

    useEffect(() => {
        if (isMediumScreen) {
            setIsSidebarCollapsed(true);
        } else {
            setIsSidebarCollapsed(false);
        }
    }, [isMediumScreen]);


    useEffect(() => {
        if (pageData?.glossary_term) {
            const {
                term,
                content,
                keyword_hash,
                internal_link_count,
                glossary_link,
                is_processing,
                is_failed,
                is_generated,
                is_posted,
                is_archived,
                posted_to,
                posted_on,
                feedback,
                all_integrations_with_unique_id,
                article_scheduled_for_posting,
                article_scheduled_datetime,
            } = pageData.glossary_term;

            setArticleTitle(term);
            setArticleContent(content);
            setIntegrationWithUniqueID(all_integrations_with_unique_id);
            setProcessing(is_processing);
            setChangeTitleValue(term);
            setIntegrationDone(all_integrations_with_unique_id.length > 0);
            setArticleFeedback(feedback ?? "");
            setArticlePostLink(glossary_link ?? "");
            setArticlePosted(is_posted);
            setArticleScheduledForPosting(article_scheduled_for_posting);
            setArticleScheduledDatetime(dayjs(article_scheduled_datetime));


            if (posted_on) {
                setArticlePostedOn(dayjs(posted_on).format("hh:mma DD MMMM YYYY"));
            }

            if (is_posted) {
                // Find the integration matching the posted_to value
                const matchingIntegration = all_integrations_with_unique_id.find((integration) =>
                    integration.integrationName.toLowerCase().includes(posted_to.toLowerCase())
                );

                // Set the selected integration and unique ID
                setSelectedIntegration(
                    matchingIntegration ? matchingIntegration.integrationName : ""
                );
                setSelectedIntegrationUniqueID(
                    matchingIntegration ? matchingIntegration.integrationUniqueID : ""
                );
            } else {
                // Default to the first integration in the list if no specific integration is posted
                const firstIntegration = all_integrations_with_unique_id[0];
                setSelectedIntegration(firstIntegration ? firstIntegration.integrationName : "");
                setSelectedIntegrationUniqueID(firstIntegration ? firstIntegration.integrationUniqueID : "");
            }

            setArticlePostedTo(posted_to);
        }
    }, [pageData]);

    useEffect(() => {
        if (articleContent?.trim().length) {
            analyzeAndUpdateArticleContent(articleContent);
        }
        if (articleEditorDivRef.current && articleContent) {
            aiEditor.current = new AiEditor({
                editable: true,
                element: articleEditorDivRef.current,
                placeholder: "Write your article here...",
                content: articleContent,
                draggable: false,
                lang: "en",
                toolbarKeys: [
                    "heading",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "link",
                    "code",
                    "divider",
                    "divider",
                    "font-color",
                    "align",
                    "bullet-list",
                    "ordered-list",
                    "hr",
                    "divider",
                    "image",
                    "video",
                    "quote",
                    "code-block",
                    "table",
                    "source-code",
                    "divider",
                    // "ai"
                ],
                textSelectionBubbleMenu: {
                    enable: true,
                    items: [
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "subscript",
                        "code",
                        "link",
                        // "ai"
                    ]
                },
                onChange: (editor: AiEditor) => {
                    setDisableSave(false);
                }
            },

            )

            setAiEditorMarkdownContent(aiEditor.current.getMarkdown());
            setAiEditorHtmlContent(aiEditor.current.getHtml());

            const markdown = aiEditor.current.getMarkdown();

            // Use a regular expression to find all image markdown and replace it with the URL followed by a line break
            const imageRegex = /!\[.*?\]\((.*?)\)/g;
            let finalContent = markdown.replace(imageRegex, (match, imageUrl) => {
                return `${imageUrl}\n\n`; // Replace the image markdown with just the URL and a line break
            });

            const plainTextContent = removeMarkdown(finalContent);
            setAiEditorTextContent(plainTextContent);

            const observer = new MutationObserver(() => {
                const popup = document.querySelector(".aie-popover-content");
                if (popup && popup.querySelector("#href")) {
                    const input = popup.querySelector("#href");
                    if (input && input instanceof HTMLInputElement && !input.value) {
                        input.value = "https://"; // Set default value
                    }
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true,
            });

            return () => {
                aiEditor.current?.destroy();
                observer.disconnect();
            }
        }
    }, [articleContent, articlePosted]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const dropdowns = [
                document.querySelector(".copy-txt-dropdown"),
                document.querySelector(".publish-dropdown"),
                document.querySelector(".download-dropdown")
            ];

            dropdowns.forEach(dropdown => {
                if (dropdown && !dropdown.contains(event.target as Node)) {
                    dropdown.classList.remove("show");
                }
            });
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    // =================================================
    // ------------------- MAIN CODE -------------------
    // =================================================

    const updateArticleContent = (content: string) => {
        setArticleContent(content);
        analyzeAndUpdateArticleContent(content);
    };

    // Debounced version of updateArticleContent with a 5sec delay
    const debouncedUpdateContent = debounce(updateArticleContent, 5000);

    const handleContentChange = (e) => {
        const newContent = e.currentTarget.innerHTML;
        const updatedArticleContent = aiEditor.current?.getMarkdown() || newContent;
        debouncedUpdateContent(updatedArticleContent);  // Use debounced update function
    };

    const handleSaveClick = async () => {
        if (keywordHash && articleContent) {
            try {
                saveContent(articleContent, articleFeedback);

                // if (selectedWordpressUrl) {
                //     PostCheckedCategory();
                // }

            } catch (error) {
                console.error('Failed to save content:', error);
            }
        }
    };

    const handleUpdateArticle = async () => {
        if (keywordHash) {
            postToBlogHandler(keywordHash, true);
            setDisableUpdatePublish(true);
        }

    };


    function analyzeAndUpdateArticleContent(articleContent: string) {
        setWordCount(GetWordCount(articleContent));

        // Regular expression to match href attributes within <a> tags
        const hrefRegex = /href=(["'])(.*?)\1/g;
        const links = articleContent.matchAll(hrefRegex);

        // Regular expression to match src attributes within <img> tags
        const imgSrcREgex = /<img\s+(?:[^>]*?\s+)?src=(["'])(.*?)\1/g;
        const imageTags = articleContent.matchAll(imgSrcREgex);
        let imagesNo = 0;
        let externalLinks = 0;
        let internalLinks = 0;

        // Regular expression to match Markdown-style links [visit our site](https://abun.com/)
        const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
        const markdownLinks = articleContent.matchAll(markdownLinkRegex);

        // Regular expression to match Markdown-style image syntax ![]()
        const markdownImgRegex = /!\[.*?\]\((.*?)\)/g;
        const markdownImages = articleContent.matchAll(markdownImgRegex);

        for (const link of links) {

            const href = link[2];

            if (href.includes("unsplash")) continue;//don't count unsplash img credit links

            if (href && !href.includes(active_website_domain as any)) {
                externalLinks++;
            } else if (href && href.includes(active_website_domain as any)) {
                internalLinks++;
            }
        }

        for (const markdownLink of markdownLinks) {
            const href = markdownLink[2];
            if (href && !href.includes(active_website_domain as any)) {
                externalLinks++;
            } else if (href && href.includes(active_website_domain as any)) {
                internalLinks++;
            }
        }

        for (const image of imageTags) {
            const src = image[2];
            if (src) {
                imagesNo++;
            }
        }

        for (const markdownImage of markdownImages) {
            const src = markdownImage[1];
            if (src) {
                imagesNo++;
            }
        }

        const articleExternalLinks: Link[] = [];
        const articleInternalLinks: Link[] = [];

        // Regular expression to match entire <a> tags and Markdown-style links
        const linkRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]+)"(?:\s+rel="([^"]+)")?[^>]*>(.*?)<\/a>|\[([^\]]+)\]\(([^)]+)\)/g;

        for (const match of articleContent.matchAll(linkRegex)) {
            const linkHref = match[1] || match[5]; // Check which group matched for href
            const linkTxt = match[3]?.replaceAll("*", "") || match[4]?.replaceAll("*", ""); // Check which group matched for link text	

            const rel = match[2]; // Check for rel attribute

            if (linkHref.includes("unsplash")) continue; //exclude unsplash img credit links

            const isInternal = linkHref.includes(active_website_domain as string);
            const isExternal = !linkHref.includes(active_website_domain as string);

            const link: Link = { linkHref, linkTxt };

            // Add rel attribute if it exists
            if (rel) {
                link.rel = rel;
            }

            if (isInternal) {
                articleInternalLinks.push(link);
            } else if (isExternal) {
                articleExternalLinks.push(link);
            }
        }
        setArticleInternalLinks(articleInternalLinks);
    }

    function goBack() {
        navigate(-1);
    }

    // optionally accepts updatedArticleContent and updatedArticleMetaDescription
    function saveContent(updatedArticleContent?: string, updatedArticleFeedback?: string) {
        if (articleContent) {
            setDisableSave(true);
            setSaveButtonText("Saving...");
            saveGlossary.mutate({
                keyword_hash: keywordHash as string,
                content: aiEditor.current?.getMarkdown() as string,
                feedback: updatedArticleFeedback ? updatedArticleFeedback : articleFeedback
            }, {
                onSuccess: () => {
                    setSaveButtonText("Save");
                    setDisableSave(true);
                    setDisableUpdatePublish(false)
                    successAlertRef.current?.show("Glossary updated successfully.");
                    setTimeout(() => {
                        successAlertRef.current?.close();
                    }, 3000);
                },
                onError: () => {
                    setSaveButtonText("Save");
                    setDisableSave(false);
                    setDisableUpdatePublish(true)
                    errorAlertRef.current?.show("Failed to save article. Please try again.");
                    setTimeout(() => {
                        errorAlertRef.current?.close();
                    }, 3000);
                }
            });
        }
    }

    function removeLinkHandler(link: Link) {

        const escapedLinkTxt = link.linkTxt.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const aTagRegex = new RegExp(`<a\\s+href="${link.linkHref}"(?:\\s+rel="[^"]*")?>${escapedLinkTxt}<\\/a>`, 'g');
        // Replace the <a> tag with the plain text
        let updatedArticleContent = articleContent?.replace(aTagRegex, link.linkTxt);

        // Regex pattern for markdown link []() types
        const markdownLinkRegex = new RegExp(`(?:!\\[)?\\[\\s*(\\*\\*\\s*)?${escapedLinkTxt}(\\s*\\*\\*)?\\s*\\]\\(${link.linkHref}\\)`, 'g');
        updatedArticleContent = updatedArticleContent?.replace(markdownLinkRegex, link.linkTxt + " ");

        // Regex pattern for <img> tags to replace with their src attribute URLs
        const imgTagRegex = /<img[^>]+src="([^">]+)"[^>]*>/g;
        updatedArticleContent = updatedArticleContent?.replace(imgTagRegex, (match) => {
            return match;
        });

        setArticleContent(updatedArticleContent);
        analyzeAndUpdateArticleContent(updatedArticleContent as string);
        setDisableSave(false);
    }


    // for saving link after editing url
    function saveEditedLinkHandler(linkType: "internal" | "external", linkToBeReplaced: Link, newLinkUrl: string) {
        if (articlePosted) {
            errorAlertRef.current?.show("You cannot edit links in a published article.");
            setTimeout(() => {
                errorAlertRef.current?.close();
            }, 5000);
            return;
        }
        // Check if new url is an HTML tag
        const htmlTagRegex = /<[^>]*>/g;
        if (htmlTagRegex.test(newLinkUrl as string)) {
            errorAlertRef.current?.show(`${linkType} link URL cannot be an HTML tag.`);
            setTimeout(() => {
                errorAlertRef.current?.close();
            }, 5000);
            return;
        }

        // Check if new url is in valid URL format
        const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#]+\.[^\s]*$/i;
        if (!urlPattern.test(newLinkUrl as string)) {
            errorAlertRef.current?.show(`Please enter a valid ${linkType} link URL.`);
            setTimeout(() => {
                errorAlertRef.current?.close();
            }, 5000);
            return;
        }

        //---------------- Replace the link ------------
        // Replace the link url of the <a></a> type link and []() with newLinkUrl 

        let updatedArticleContent: string;

        if (linkToBeReplaced?.rel) {// if it had rel attribute, for external links
            updatedArticleContent = (articleContent as string).replace(
                `<a href="${linkToBeReplaced?.linkHref}" rel="${linkToBeReplaced.rel}">${linkToBeReplaced?.linkTxt}</a>`,
                `<a href="${newLinkUrl}" rel="${linkToBeReplaced.rel}">${linkToBeReplaced?.linkTxt}</a>`)
        } else {
            updatedArticleContent = (articleContent as string).replace(
                `<a href="${linkToBeReplaced?.linkHref}">${linkToBeReplaced?.linkTxt}</a>`,
                `<a href="${newLinkUrl}">${linkToBeReplaced?.linkTxt}</a>`
            ).replace(
                `[${linkToBeReplaced?.linkTxt}](${linkToBeReplaced?.linkHref})`,
                `[${linkToBeReplaced?.linkTxt}](${newLinkUrl})`
            );
        }

        setArticleContent(updatedArticleContent);
    }

    //Connecting Internal Website 
    function ConnectWebsite(props: ConnectWebsiteProps) {
        // -------------------- STATES --------------------
        const [stage, setStage] = useState(1);  // start from first stage
        const [called, setCalled] = useState(false);

        // -------------------- CONSTANTS --------------------
        const navigate = useNavigate();
        const stages = [
            <p>Oops! Something went wrong :(</p>,
            <WebsiteDomain getWebsiteData={getWebsiteData} updateWebsiteData={updateWebsiteData} nextStage={nextStage} getWebsiteTempData={getWebsiteTempData} updateWebsiteTempData={updateWebsiteTempData} />,  // domain & blog
            <WebsiteTitle getWebsiteData={getWebsiteData} updateWebsiteData={updateWebsiteData} nextStage={nextStage} getWebsiteTempData={getWebsiteTempData} updateWebsiteTempData={updateWebsiteTempData} setStage={setStage} />, // title & description
            <WebsiteICP getWebsiteData={getWebsiteData} updateWebsiteData={updateWebsiteData} nextStage={nextStage} getWebsiteTempData={getWebsiteTempData} updateWebsiteTempData={updateWebsiteTempData} setStage={setStage} />,  // icp text & industry
            <WebsiteCompetitors getWebsiteData={getWebsiteData} updateWebsiteData={updateWebsiteData} nextStage={nextStage} setStage={setStage} />  // competitors
        ];

        // -------------------- REFS --------------------
        const connectWebsiteData = useRef<WebsiteData>({
            domain: '',
            protocol: '',
            blog: '',
            title: '',
            description: '',
            industry: '',
            icp: '',
            language: '',
            competitors: [],
            generate_content_plan: false,
        });

        // Temporary Website Data this used when user go back to previous steps
        const websiteTempData = useRef<WebsiteData>({
            domain: '',
            protocol: '',
            blog: '',
            title: '',
            description: '',
            industry: '',
            icp: '',
            language: '',
            competitors: [],
            generate_content_plan: false,
        });

        const {
            data,
            refetch,
            status,
            fetchStatus
        } = useQuery(connectWebsiteQuery(connectWebsiteData.current));

        function getWebsiteData(): WebsiteData {
            return connectWebsiteData.current;
        }

        function updateWebsiteData(key: keyof WebsiteData, value: any) {
            if (key !== 'generate_content_plan') connectWebsiteData.current[key] = value;
        }

        function getWebsiteTempData(): WebsiteData {
            return websiteTempData.current;
        }
        function updateWebsiteTempData(key: keyof WebsiteData, value: any) {
            if (key !== 'generate_content_plan') websiteTempData.current[key] = value;
        }

        /**
         * Use to move to next stage.
         */
        function nextStage() {
            let newStage = stage + 1;
            if (newStage < stages.length) {
                setStage(stage + 1);
            } else {
                // Last stage is done. Time to call connect website api.
                if (connectWebsiteData.current.competitors.length > 0 && !called) {
                    refetch().then();
                    setCalled(true);
                }
            }
        }

        useEffect(() => {
            if (status === 'success' && called) {
                props.setShowConnectWebsiteModal(false);
                setAddNewLinkModalActive({ active: true, linkType: "internal" });
                props.successAlertRef.current?.show("Website connected successfully.");
                setTimeout(() => {
                    props.successAlertRef.current?.close();
                }, 5000);
            }
        }, [called, navigate, props, status]);


        return (
            <>
                <div className={"modal connect-website-modal is-active"}>
                    <div className={"modal-background"}></div>
                    <div className={"modal-content"}>
                        <div className={"modal-card"}>
                            <header className={"modal-card-head is-flex is-align-items-center is-justify-content-space-between"}>
                                <p className={"modal-card-title"} style={{ opacity: 0, width: 0, height: 0, pointerEvents: 'none' }} />
                                <p className={"modal-card-title"}>
                                    Find Competitors
                                </p>
                                { // show only if stage is not 0
                                    stage <= 1 && <button type="button" className={"delete"} aria-label="close" onClick={() => props.setShowConnectWebsiteModal(false)} />
                                }
                            </header>
                            <section className={"modal-card-body"}>
                                <div className="container is-flex is-flex-direction-column is-align-items-center" style={{ maxHeight: "100vh", overflow: "none" }}>
                                    {fetchStatus !== 'idle' && stage !== stages.length - 1 ? <ConnectWebsiteLoading text={"Finishing setup. Just a moment..."} /> : stages[stage]}
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </>
        )
    }


    // for adding new link
    function addNewLinkHandler(linkType: "internal" | "external") {
        // if (articlePosted) {
        // 	errorAlertRef.current?.show("You cannot add links to a published article.");
        // 	setTimeout(() => {
        // 		errorAlertRef.current?.close();
        // 	}, 5000);
        // 	return;
        // }

        if (!newLinkHref.trim().length || !newLinkPhrase.trim().length) {
            return;
        }

        const isInternalLink = newLinkHref.includes(active_website_domain as string);
        if (!isInternalLink) {
            errorAlertRef.current?.show("Please Enter an Internal Link Only.");
            return;
        }

        // ---------------- validations --------------------

        // Check if newLinkPhrase is an HTML tag
        const htmlTagRegex = /<[^>]*>/g;
        if (htmlTagRegex.test(newLinkPhrase)) {
            errorAlertRef.current?.show("Link phrase cannot be an HTML tag.");
            setTimeout(() => {
                errorAlertRef.current?.close();
            }, 5000);
            return;
        }

        // Check if newLinkHref is an HTML tag
        if (htmlTagRegex.test(newLinkHref)) {
            errorAlertRef.current?.show("Link URL cannot be an HTML tag.");
            setTimeout(() => {
                errorAlertRef.current?.close();
            }, 5000);
            return;
        }

        // Check if newLinkPhrase is too small or too long
        if (newLinkPhrase.split(" ").length < 2 || newLinkPhrase.split(" ").length > 12) {
            errorAlertRef.current?.show("Please enter a phrase that is 2 to 12 words long.");
            setTimeout(() => {
                errorAlertRef.current?.close();
            }, 5000);
            return;
        }

        // Check if newLinkHref is valid URL format
        const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#]+\.[^\s]*$/i;
        if (!urlPattern.test(newLinkHref)) {
            errorAlertRef.current?.show("Please enter a valid link URL.");
            setTimeout(() => {
                errorAlertRef.current?.close();
            }, 5000);
            return;
        }

        // Check if newLinkPhrase is found in the articleContent
        if (articleContent?.indexOf(newLinkPhrase) === -1) {
            errorAlertRef.current?.show(`The phrase is not found in the article content.`);
            setTimeout(() => {
                errorAlertRef.current?.close();
            }, 5000);
            return;
        }

        // Check if newLinkPhrase is part of any heading
        const headingRegex = /\n\n#{1,6}\s.*?\n\n/g;
        const headings = articleContent?.match(headingRegex) || [];
        const isInHeading = headings.some((heading) => heading.includes(newLinkPhrase));
        if (isInHeading) {
            // Phrase is part of a heading, do not replace
            errorAlertRef.current?.show(`The phrase is part of a heading. Please enter another phrase or insert the link using editor.`);
            setTimeout(() => {
                errorAlertRef.current?.close();
            }, 5000);
            return;
        }

        // regex pattern to match newLinkPhrase(excludes in titles/headings)
        const phraseRegex = new RegExp(`(?<!##\\s)\\b${newLinkPhrase}\\b(?!\\s##)`, "gi");

        // Match occurrences of newLinkPhrase in the article content
        const phraseMatches = articleContent?.match(phraseRegex);
        if (phraseMatches && phraseMatches.length > 1) {
            errorAlertRef.current?.show(`The phrase appears multiple times. Please add more words around it or use the editor to insert the link.`);
            setTimeout(() => {
                errorAlertRef.current?.close();
            }, 5000);
            return;
        }

        // check if the newLinkPhrase is already a link text or part of any link text
        // for <a></a> tags
        const linkTextsFromATags: string[] = [];
        const linkRegexForATags = new RegExp(/<a\s+(?:[^>]*?\s+)?href="([^"]+)"[^>]*>(.*?)<\/a>/g);
        let match: RegExpExecArray | null;
        while ((match = linkRegexForATags.exec(articleContent as string)) !== null) {
            linkTextsFromATags.push(match[2]);
        }

        // for []() type of links
        const linkTextsFromMarkdownLinks: string[] = [];
        const markdownLinkRegex = new RegExp(/\[(.*?)\]\((.*?)\)/g);
        while ((match = markdownLinkRegex.exec(articleContent as string)) !== null) {
            linkTextsFromMarkdownLinks.push(match[1]);
        }

        const allLinkTexts = [...linkTextsFromATags, ...linkTextsFromMarkdownLinks];

        let isPhraseMatched = false;
        for (const text of allLinkTexts) {
            if (text === newLinkPhrase || text.includes(newLinkPhrase)) {
                isPhraseMatched = true;
                break;
            }
        }

        if (isPhraseMatched) {
            errorAlertRef.current?.show(`The phrase is already a link or part of a link, please edit its URL from the ${linkType} links tab or add the link using editor.`);
            setTimeout(() => {
                errorAlertRef.current?.close();
            }, 5000);
            return;
        }


        // ---------------- ADD THE LINK --------------------
        // Add link by replacing the text with an anchor tag after all validations are passed

        let linkToAdd = `<a href="${newLinkHref}">${newLinkPhrase}</a>`;

        if ((
            externalBacklinksPreference === "no-follow" ||
            externalBacklinksPreference === "follow")
            && !newLinkHref.includes(active_website_domain as string) // external link
        ) {
            const preference = externalBacklinksPreference.replace("-", "");
            //if its an external link and externalBacklinksPreference is set, add new link accordingly
            linkToAdd = `<a href="${newLinkHref}" rel="${preference}">${newLinkPhrase}</a>`;
        }

        const updatedArticleContent = articleContent?.replace(
            newLinkPhrase, linkToAdd
        );

        setArticleContent(updatedArticleContent);
        analyzeAndUpdateArticleContent(updatedArticleContent as string);
        setNewLinkHref("");
        setNewLinkPhrase("");
        setAddNewLinkModalActive({ active: false, linkType: "" });
        successAlertRef.current?.show("Link added successfully.");
        setTimeout(() => {
            successAlertRef.current?.close();
        }, 3000);
    }


    function postToBlogHandler(keywordHash: string, updatePublishedArticle: boolean = false) {
        errorAlertRef.current?.close();
        successAlertRef.current?.close();

        // show processing modal
        setRequestModalActive(true);
        setModalText(updatePublishedArticle ? "Updating the glossary on your site..." : "Posting glossary to your site...");

        if (keywordHash && selectedIntegration) {
            postGlossary.mutate({
                keywordHash: keywordHash ? keywordHash : "",
                selectedIntegration: selectedIntegration,
                selectedIntegrationUniqueID: selectedIntegrationUniqueID,
                postStatus: publishType,
                updatePublishedArticle: updatePublishedArticle
            }, {
                onSuccess: (response) => {
                    successAlertRef.current?.show(updatePublishedArticle ? `Glossary ${keywordHash} has been successfully updated on your site!` : `Glossary ${keywordHash} was posted to your site successfully!`);
                    setArticlePosted(true);
                    setRequestModalActive(false);
                    setArticlePostedTo(response.data.posted_to);
                    setArticlePostedOn(dayjs(response.data.posted_on).format("hh:mma DD MMMM YYYY"));
                    setArticlePostLink(response.data.link);
                    setTimeout(() => {
                        successAlertRef.current?.close();
                    }, 5000);
                },
                onError: () => {
                    setRequestModalActive(false);
                    errorAlertRef.current?.show(
                        "Publishing failed! WordPress rejected the request. A security plugin or Cloudflare might be blocking it. Here are some quick troubleshooting methods: [Fix the issue](https://abun.com/help/why-is-my-abun-article-not-publishing-to-wordpress) or contact support via live chat."
                    );
                    setTimeout(() => {
                        errorAlertRef.current?.close();
                    }, 5000);
                }
            })
        } else {
            setRequestModalActive(false);
            errorAlertRef.current?.show("Key information is missing. Please try again.");
            setTimeout(() => {
                errorAlertRef.current?.close();
            }, 5000);
        }
    }

    function scheduleGlossaryHandler(keywordHash: string) {

        setRequestModalActive(true);
        setModalText("Scheduling glossary to your site...");

        if (keywordHash && selectedIntegration && selectedIntegrationUniqueID && schedulePublishDateTime) {
            // check if the schedule date is in the future
            const currentDate = new Date();
            try {
                const scheduleDate = schedulePublishDateTime.toDate();
                if (scheduleDate <= currentDate) {
                    // close all alerts
                    successAlertRef.current?.close();
                    errorAlertRef.current?.close();

                    errorAlertRef.current?.show("Please select a future date and time for scheduling.");
                    setTimeout(() => {
                        errorAlertRef.current?.close();
                    }, 5000);
                    return;
                }
            } catch (error) {
                // close all alerts
                successAlertRef.current?.close();
                errorAlertRef.current?.close();

                console.error(error);
                errorAlertRef.current?.show("Date is not in valid ISO format. Please try again.");
                setTimeout(() => {
                    errorAlertRef.current?.close();
                }, 5000);
                return;
            }

            scheduleGlossary.mutate({
                keywordHash: keywordHash,
                articleScheduleDate: schedulePublishDateTime,
                integrationName: selectedIntegration,
                integrationUniqueID: selectedIntegrationUniqueID,
                postStatus: publishType,
            }, {
                onSuccess: (data) => {
                    let responseData = (data as any)["data"];

                    if (responseData["success"]) {
                        // close all alerts
                        successAlertRef.current?.close();
                        errorAlertRef.current?.close();

                        document.querySelector(".publish-dropdown")?.classList.remove("show");
                        setArticleScheduledForPosting(true);
                        setArticleScheduledDatetime(schedulePublishDateTime);
                        setRequestModalActive(false);
                        successAlertRef.current?.show(articleScheduledForPosting ? "Glossary rescheduled successfully!" : "Glossary scheduled successfully!");
                        setTimeout(() => {
                            successAlertRef.current?.close();
                        }, 5000);
                    } else {
                        // close all alerts
                        successAlertRef.current?.close();
                        errorAlertRef.current?.close();

                        errorAlertRef.current?.show(responseData["message"]);
                        setTimeout(() => {
                            errorAlertRef.current?.close();
                        }, 5000);
                    }
                },
                onError: (error) => {
                    setRequestModalActive(false);
                    console.error(error);
                    // close all alerts
                    successAlertRef.current?.close();
                    errorAlertRef.current?.close();

                    errorAlertRef.current?.show("Oops! Something went wrong. Please try again.");
                    setTimeout(() => {
                        errorAlertRef.current?.close();
                    }, 5000);
                },
            });
        } else {
            setRequestModalActive(false);
            errorAlertRef.current?.show("Key information is missing. Please try again.");
            setTimeout(() => {
                errorAlertRef.current?.close();
            }, 5000);
        }
    }

    function truncateSiteDomain(domain: string) {
        if (domain.includes("webflow")) {
            domain = domain.replace(new RegExp("webflow - ", 'g'), '');
        } else if (domain.includes("wix")) {
            domain = domain.replace(new RegExp("wix - ", 'g'), '');
        } else {
            domain = domain.replace(new RegExp("wordpress - ", 'g'), '');
        }

        if (domain.length <= 20) {
            return domain;
        } else {
            domain = domain.substring(0, 17) + "...";
        }

        return domain;
    }

    // if (!pageData) {
    //     return (
    //         <div className={"card mt-4 article-editor-page-card"}>
    //             <div className={"card-content"}>
    //                 <div className={"content is-flex is-justify-content-center"}>
    //                     <p>Oops! Something went wrong :(</p>
    //                 </div>
    //             </div>
    //         </div>
    //     )

    // } 
    if (!pageData.glossary_term) {
        return (
            <div className={"card mt-4 article-editor-page-card"}>
                <div className={"card-content"}>
                    <div className={"content is-flex is-justify-content-center"}>
                        <p style={{ textAlign: "center", fontSize: "1.3rem" }}>
                            Loading Data...<Icon iconName={"spinner"} marginClass={"ml-5"} />
                        </p>
                    </div>
                </div>
            </div>
        )

    } else {
        return (
            <>
                {/* ------------------------------ ONGOING REQUEST MODAL ------------------------------ */}
                <AbunModal active={requestModalActive}
                    headerText={""}
                    closeable={false}
                    hideModal={() => {
                        setRequestModalActive(false)
                    }}>
                    <div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
                        <AbunLoader show={requestModalActive} height="20vh" />
                    </div>
                    <p className={"is-size-4 has-text-centered mb-4"}>{modalText}</p>
                </AbunModal>

                {/* ------------------------------ EDIT TITLE MODAL ------------------------------ */}
                <AbunModal active={editTitleModalActive}
                    headerText={""}
                    closeable={true}
                    hideModal={() => {
                        setEditTitleModalActive(false);
                    }}>
                    <label className={"label"}>
                        Glossary Title:
                        <input type="text" className={"input mt-2"} value={changeTitleValue} onChange={
                            (event) => {
                                setChangeTitleValue(event.target.value);
                            }
                        } />
                    </label>
                    <div className={"mt-6 is-flex is-justify-content-center"}>
                        <GenericButton text={editGlossaryTerm.isLoading ? "Saving..." : "Save of"}
                            type={"success"}
                            icon={"floppy-disk"}
                            disable={editGlossaryTerm.isLoading}
                            additionalClassList={["mr-4"]}
                            clickHandler={() => {
                                if (keywordHash) {
                                    successAlertRef.current?.close();
                                    errorAlertRef.current?.close();
                                    editGlossaryTerm.mutate({ keywordHash: keywordHash, term: changeTitleValue }, {
                                        onSuccess: (data) => {
                                            setArticleTitle(data['data']['term']);
                                            setChangeTitleValue(data['data']['term']);
                                            setEditTitleModalActive(false);
                                            setDisableSave(false)
                                            successAlertRef.current?.show("Glossary title for this post has been updated successfully!");
                                            setTimeout(() => {
                                                successAlertRef.current?.close();
                                            }, 5000);
                                        },
                                        onError: (error) => {
                                            console.error(error);
                                            errorAlertRef.current?.show("Oops! Something went wrong. Please try again.");
                                            setTimeout(() => {
                                                errorAlertRef.current?.close();
                                            }, 5000);
                                        },
                                    });
                                } else {
                                    console.error("glossary title edit failed due to missing keyword hash");
                                }
                            }} />

                    </div>
                </AbunModal>

                {/* ------------------------------ Add New Link Modal ------------------------------ */}
                <AbunModal active={addNewLinkModalActive.active}
                    headerText={`Add New ${addNewLinkModalActive.linkType.charAt(0).toUpperCase() + addNewLinkModalActive.linkType.slice(1)} Link`}
                    closeable={true}
                    hideModal={() => {
                        setAddNewLinkModalActive({ active: false, linkType: "" });
                    }}>
                    <div className={"mt-4"}>
                        <div className="mb-4">
                            <p className="mb-3 is-size-6">Enter article phrase*</p>
                            <Input value={newLinkPhrase} className="add-link-container" type={"text"} placeholder={`enter the phrase from article that you want to set as ${addNewLinkModalActive.linkType} link`} onChange={(value) => setNewLinkPhrase(value)} />
                        </div>
                        <div className="mb-4">
                            <p className="mb-3 is-size-6">Enter {addNewLinkModalActive.linkType} link URL*</p>
                            <Input value={newLinkHref} className="add-link-container" type={"text"} placeholder={`enter ${addNewLinkModalActive.linkType} link URL`} onChange={(value) => setNewLinkHref(value)} />
                        </div>
                        <AbunButton type={"success"}
                            disabled={!newLinkHref || !newLinkPhrase}
                            clickHandler={addNewLinkHandler}
                        >
                            <Icon iconName={"floppy-disk"} additionalClasses={["icon-white", "mr-3"]} />
                            Add
                        </AbunButton>
                    </div>
                </AbunModal>

                {/* show modal to connect internal website */}
                {
                    showConnectWebsiteWarningModal &&
                    <div className={"modal is-active"}>
                        <div className={"modal-background"}></div>
                        <div className={"modal-content"}>
                            <div className={"modal-card"}>
                                <header className={"modal-card-head"}>
                                    <p className={"modal-card-title"}>Connect Your Website</p>
                                    <button type="button" className={"delete"} aria-label="close" onClick={() => setShowConnectWebsiteWarningModal(false)}></button>
                                </header>
                                <section className={"modal-card-body"}>
                                    <p>
                                        Please connect your website to get the competition analysis for your website.
                                    </p>
                                </section>
                                <footer className={"modal-card-foot is-justify-content-center is-align-items-center"}>
                                    <AbunButton type={"primary"} clickHandler={() => {
                                        setShowConnectWebsiteWarningModal(false);
                                        setShowConnectWebsiteModal(true);
                                    }}>Connect Website</AbunButton>
                                </footer>
                            </div>
                        </div>
                    </div>
                }
                {
                    showConnectWebsiteModal && <ConnectWebsite setShowConnectWebsiteModal={setShowConnectWebsiteModal} successAlertRef={successAlertRef} failAlertRef={failAlertRef} />
                }

                <div className="glossary-editor-page">

                    {/* ------------------------- Main Content Card ------------------------- */}
                    <div className={"card article-editor-page-header w-100"} style={{ flexWrap: articlePosted ? "nowrap" : "wrap" }}>
                        <div className={"left-header-section"} style={{ flexWrap: articlePosted ? "nowrap" : "wrap", marginLeft: articlePosted ? "2rem" : "8rem" }}>
                            <span className={"back-btn"} style={{ cursor: "pointer" }} onClick={goBack}>
                                <svg className="back-btn" width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26.0435 12.0003H2.82031M2.82031 12.0003L12.8382 1.98242M2.82031 12.0003L12.8382 22.0181" stroke="black" stroke-opacity="0.5" stroke-width="3" />
                                </svg>
                            </span>
                            <svg className="abun-logo" width="52" height="48" viewBox="0 0 52 48" onClick={() => navigate("/")}>
                                <rect x="2.125" y="4.41016" width="47.9091" height="42.0909" rx="6.5" fill="black" stroke="black" stroke-width="3" />
                                <rect x="0.5" y="0.5" width="49.9091" height="44.0909" rx="7.5" fill="white" stroke="black" />
                                <path d="M40 37.3373H29.7561V34.7968C28.2195 36.6746 24.8618 38 21.4472 38C17.3496 38 12 35.2939 12 29.2189C12 22.5917 17.3496 20.714 21.4472 20.714C25.0325 20.714 28.2764 21.8185 29.7561 23.641V20.8797C29.7561 19.002 27.9919 17.5661 24.6341 17.5661C22.0732 17.5661 19.1707 18.5602 17.0081 20.1617L13.5366 14.0316C17.2358 11.1598 22.3577 10 26.5122 10C33.3415 10 40 12.3195 40 21.211V37.3373ZM25.7154 31.5385C27.3089 31.5385 29.0732 31.0414 29.7561 30.1026V28.6114C29.0732 27.6726 27.3089 27.1755 25.7154 27.1755C24.0081 27.1755 22.1301 27.7278 22.1301 29.3846C22.1301 31.0414 24.0081 31.5385 25.7154 31.5385Z" fill="black" />
                            </svg>
                            <div className={"Tabs"}>
                                <div className={"Tab article-tab active"}>Article</div>
                                <div className={"Tab twya-tab"}>Talk with your Article (Coming Soon)</div>
                            </div>
                        </div>

                        {
                            isSmallScreen ?
                                <>
                                    <div className="is-flex w-100">
                                        <div className={"left-header-section lhs-for-small-screens"}>
                                            <span className={"back-btn"} style={{ cursor: "pointer" }} onClick={goBack}>
                                                <svg className="back-btn" width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M26.0435 12.0003H2.82031M2.82031 12.0003L12.8382 1.98242M2.82031 12.0003L12.8382 22.0181" stroke="black" stroke-opacity="0.5" stroke-width="3" />
                                                </svg>
                                            </span>
                                        </div>
                                        <div className={"right-header-section rhs-for-small-screens w-100"}>
                                            {!articlePosted && !processing &&
                                                <p className={`rhs-for-small-screens-item ${isOptionSidebarCollapsed ? "" : "active"}`} onClick={() => {
                                                    if (isOptionSidebarCollapsed) {
                                                        setIsOptionSidebarCollapsed(false);
                                                        setIsSidebarCollapsed(true);
                                                    } else {
                                                        setIsOptionSidebarCollapsed(true);
                                                    }
                                                }}>Options</p>
                                            }
                                            <p className={`rhs-for-small-screens-item mr-5 ${isSidebarCollapsed ? "" : "active"}`} onClick={() => {
                                                if (isSidebarCollapsed) {
                                                    setIsSidebarCollapsed(false);
                                                    setIsOptionSidebarCollapsed(true);
                                                } else {
                                                    setIsSidebarCollapsed(true);
                                                }
                                            }}>Details</p>
                                        </div>
                                    </div>
                                </> :
                                <div className={"right-header-section"} style={{ flexWrap: articlePosted ? "nowrap" : "wrap", gap: articlePosted ? "1rem" : "4rem" }}>
                                    {/* ------------------------- Copy Text Options ------------------------- */}
                                    {
                                        articleContent && (
                                            <div className={"copy-txt-options"}>
                                                <div className={"copy-txt-as-btn"} onClick={() => {
                                                    document.querySelector(".copy-txt-dropdown")?.classList.toggle("show");
                                                    // close other dropdowns
                                                    document.querySelector(".publish-dropdown")?.classList.remove("show");
                                                    document.querySelector(".download-dropdown")?.classList.remove("show");
                                                }}>
                                                    <span>Copy Text as</span>
                                                    <svg width="5" height="3" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0.420536 0H4.57946C4.95342 0 5.1404 0.46513 4.87653 0.736306L2.79707 2.87339C2.63281 3.0422 2.36719 3.0422 2.20468 2.87339L0.123469 0.736306C-0.140396 0.46513 0.0465814 0 0.420536 0Z" fill="black" />
                                                    </svg>
                                                </div>
                                                <div className={"copy-txt-dropdown"}>
                                                    <button
                                                        className="copy-txt-item copy-markdown-btn"
                                                        onClick={() => {
                                                            setRequestModalActive(true);
                                                            setModalText("Copying markdown...");

                                                            navigator.clipboard.writeText(aiEditorMarkdownContent as string)
                                                                .then(() => {
                                                                    setRequestModalActive(false);
                                                                    // show success alert
                                                                    successAlertRef.current?.show("Markdown copied successfully!");
                                                                    // Close the alert after 3 seconds
                                                                    setTimeout(() => {
                                                                        successAlertRef.current?.close();
                                                                    }, 3000);
                                                                    // document.querySelector(".copy-txt-dropdown")?.classList.remove("show");
                                                                })
                                                                .catch(() => {
                                                                    setRequestModalActive(false);
                                                                    // show error alert
                                                                    errorAlertRef.current?.show("Failed to copy markdown. Please try again.");
                                                                    // Close the alert after 3 seconds
                                                                    setTimeout(() => {
                                                                        errorAlertRef.current?.close();
                                                                    }, 3000);
                                                                });
                                                        }}
                                                    >
                                                        Markdown
                                                    </button>
                                                    <button className={"copy-txt-item"} onClick={() => {
                                                        // show processing modal
                                                        setRequestModalActive(true);
                                                        setModalText("Processing HTML file...");
                                                        navigator.clipboard.writeText(aiEditorHtmlContent as string).then(() => {
                                                            setRequestModalActive(false);
                                                            setModalText("");
                                                            // show success alert
                                                            successAlertRef.current?.show("HTML copied successfully.");
                                                            // Close the alert after 3 seconds
                                                            setTimeout(() => {
                                                                successAlertRef.current?.close();
                                                            }, 3000);
                                                        }).catch(() => {
                                                            setRequestModalActive(false);
                                                            setModalText("");
                                                            // show error alert
                                                            errorAlertRef.current?.show("Failed to copy HTML. Please try again.");
                                                            // Close the alert after 3 seconds
                                                            setTimeout(() => {
                                                                errorAlertRef.current?.close();
                                                            }, 3000);
                                                        })
                                                        // document.querySelector(".copy-txt-dropdown")?.classList.toggle("show");
                                                    }}>
                                                        <span>HTML</span>
                                                    </button>
                                                    <button className={"copy-txt-item"} onClick={() => {
                                                        // plain text
                                                        navigator.clipboard.writeText(aiEditorTextContent as string).then(() => {
                                                            // show success alert
                                                            successAlertRef.current?.show("Text copied successfully.");
                                                            // Close the alert after 3 seconds
                                                            setTimeout(() => {
                                                                successAlertRef.current?.close();
                                                            }, 3000);
                                                        }).catch(() => {
                                                            // show error alert
                                                            errorAlertRef.current?.show("Failed to copy text. Please try again.");
                                                            // Close the alert after 3 seconds
                                                            setTimeout(() => {
                                                                errorAlertRef.current?.close();
                                                            }, 3000);
                                                        })
                                                        // document.querySelector(".copy-txt-dropdown")?.classList.toggle("show");
                                                    }}>
                                                        <span>Raw Text</span>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {/* ------------------------- Download Options ------------------------- */}
                                    {
                                        articleContent && (
                                            <div className={"download-options"}>
                                                <div className={"download-btn"} onClick={() => {
                                                    document.querySelector(".download-dropdown")?.classList.toggle("show");
                                                    // close other dropdowns
                                                    document.querySelector(".publish-dropdown")?.classList.remove("show");
                                                    document.querySelector(".copy-txt-dropdown")?.classList.remove("show");
                                                }}>
                                                    <span>Download</span>
                                                    <svg width="5" height="3" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0.420536 0H4.57946C4.95342 0 5.1404 0.46513 4.87653 0.736306L2.79707 2.87339C2.63281 3.0422 2.36719 3.0422 2.20468 2.87339L0.123469 0.736306C-0.140396 0.46513 0.0465814 0 0.420536 0Z" fill="black" />
                                                    </svg>
                                                </div>
                                                <div className={"download-dropdown"}>
                                                    <button className={"download-item"} onClick={() => {
                                                        // show processing modal
                                                        setRequestModalActive(true);
                                                        setModalText("Processing markdown file...");
                                                        // create a blob with the markdown content
                                                        const markdownBlob = new Blob([aiEditorMarkdownContent as string], { type: 'text/markdown' });
                                                        // create a download link
                                                        const articleMarkdownDownload = document.createElement('a');
                                                        // set the download link href to the blob URL
                                                        articleMarkdownDownload.href = URL.createObjectURL(markdownBlob);
                                                        // set the download link download attribute to the filename
                                                        articleMarkdownDownload.download = `Abun-${articleTitle}-articleUID.md`;
                                                        // append the download link to the body
                                                        document.body.appendChild(articleMarkdownDownload);
                                                        // click the download link
                                                        articleMarkdownDownload.click();
                                                        // remove the download link from the body
                                                        document.body.removeChild(articleMarkdownDownload);
                                                        setRequestModalActive(false);
                                                        setModalText("");
                                                        document.querySelector(".download-dropdown")?.classList.toggle("show");
                                                    }}>
                                                        <span>Markdown</span>
                                                    </button>
                                                    <button className={"download-item"} onClick={() => {
                                                        // show processing modal
                                                        setRequestModalActive(true);
                                                        setModalText("Processing HTML file...");
                                                        // create a blob with the html content
                                                        const htmlBlob = new Blob([aiEditorHtmlContent as string], { type: 'text/html' });
                                                        // create a download link
                                                        const articleHtmlDownload = document.createElement('a');
                                                        // set the download link href to the blob URL
                                                        articleHtmlDownload.href = URL.createObjectURL(htmlBlob);
                                                        // set the download link download attribute to the filename
                                                        articleHtmlDownload.download = `Abun-${articleTitle}-articleUID.html`;
                                                        // append the download link to the body
                                                        document.body.appendChild(articleHtmlDownload);
                                                        // click the download link
                                                        articleHtmlDownload.click();
                                                        // remove the download link from the body
                                                        document.body.removeChild(articleHtmlDownload);
                                                        setRequestModalActive(false);
                                                        setModalText("");
                                                        document.querySelector(".download-dropdown")?.classList.toggle("show");
                                                    }}>
                                                        <span>HTML</span>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {/* ------------------------- Publish Options ------------------------- */}
                                    {
                                        !articlePosted &&
                                        <div className={"publish-options"}>
                                            <div className={"publish-btn"} onClick={() => {
                                                document.querySelector(".publish-dropdown")?.classList.toggle("show");
                                                // close other dropdowns
                                                document.querySelector(".copy-txt-dropdown")?.classList.remove("show");
                                                document.querySelector(".download-dropdown")?.classList.remove("show");
                                            }}>
                                                <span>Publish / Schedule</span>
                                                <svg width="5" height="3" viewBox="0 0 5 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.420536 0H4.57946C4.95342 0 5.1404 0.46513 4.87653 0.736306L2.79707 2.87339C2.63281 3.0422 2.36719 3.0422 2.20468 2.87339L0.123469 0.736306C-0.140396 0.46513 0.0465814 0 0.420536 0Z" fill="black" />
                                                </svg>
                                            </div>
                                            <div className="publish-dropdown">
                                                <div className="card-header">
                                                    Ready to publish your post?
                                                </div>
                                                {
                                                    integrationWithUniqueID.length > 0 ?
                                                        <>
                                                            <div className="card-content space-y-4">
                                                                <div className="radio-group" aria-label="publish options">
                                                                    <label className="form-control-label text-sm">
                                                                        <input
                                                                            type="radio"
                                                                            name="publishOption"
                                                                            value="publish"
                                                                            checked={publishOption === 'publish'}
                                                                            onChange={(e) => setPublishOption(e.target.value)}
                                                                        />
                                                                        Publish it live
                                                                    </label>
                                                                    <p className="text-sm text-muted-foreground">
                                                                        Publish this post immediately
                                                                    </p>

                                                                    <label className="form-control-label text-sm">
                                                                        <input
                                                                            type="radio"
                                                                            name="publishOption"
                                                                            value="schedule"
                                                                            checked={publishOption === 'schedule'}
                                                                            onChange={(e) => setPublishOption(e.target.value)}
                                                                        />
                                                                        Schedule it for later
                                                                    </label>
                                                                    <p className="text-sm text-muted-foreground">
                                                                        Set automatic future publish date
                                                                    </p>
                                                                </div>

                                                                {publishOption === 'schedule' && (
                                                                    <div className="grid grid-cols-2 gap-4">
                                                                        <div className="form-group">
                                                                            <label htmlFor="publishDate">Date</label>
                                                                            <input
                                                                                type="date"
                                                                                id="publishDate"
                                                                                className="form-control"
                                                                                defaultValue={articleScheduledForPosting ? articleScheduledDatetime?.format("YYYY-MM-DD") : schedulePublishDateTime?.format("YYYY-MM-DD")}
                                                                                onChange={(e) => {
                                                                                    setSchedulePublishDateTime(dayjs(e.target.value + " " + schedulePublishTime))
                                                                                    setSchedulePublishDate(e.target.value);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label htmlFor="publishTime">Time</label>
                                                                            <input
                                                                                type="time"
                                                                                id="publishTime"
                                                                                className="form-control"
                                                                                defaultValue={articleScheduledForPosting ? articleScheduledDatetime?.format("HH:mm") : schedulePublishDateTime?.format("HH:mm")}
                                                                                onChange={(e) => {
                                                                                    setSchedulePublishDateTime(dayjs(schedulePublishDate + " " + e.target.value))
                                                                                    setSchedulePublishTime(e.target.value);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                <FormControl fullWidth>
                                                                    <InputLabel id="platform-select-label">Platform</InputLabel>
                                                                    <Select
                                                                        labelId="platform-select-label"
                                                                        id="platform-select-label"
                                                                        value={selectedIntegrationUniqueID}
                                                                        label="Platform"
                                                                        onChange={(e) => setSelectedIntegrationUniqueID(e.target.value)}
                                                                        MenuProps={{
                                                                            // Prevent the dropdown from closing automatically
                                                                            PaperProps: {
                                                                                onMouseDown: (e) => e.stopPropagation(),
                                                                            },
                                                                        }}
                                                                    >
                                                                        {integrationWithUniqueID.filter(integration =>
                                                                            integration.integrationName.toLowerCase().includes("wordpress"))
                                                                            .map((integration, index) => (
                                                                                <MenuItem key={integration.integrationUniqueID} value={integration.integrationUniqueID}
                                                                                    onClick={() => {
                                                                                        setSelectedIntegrationUniqueID(integration.integrationUniqueID);
                                                                                        setIntegrationAndHideDropDownContent(integration.integrationName);
                                                                                    }}
                                                                                >
                                                                                    {integration.integrationName}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </Select>
                                                                </FormControl>

                                                                <FormControl fullWidth>
                                                                    <InputLabel id="type-label">Type</InputLabel>
                                                                    <Select
                                                                        labelId="type-label"
                                                                        id="type"
                                                                        defaultValue={publishType}
                                                                        label="Type"
                                                                        onChange={(e) => setPublishType(e.target.value)}
                                                                        MenuProps={{
                                                                            // Prevent the dropdown from closing automatically
                                                                            PaperProps: {
                                                                                onMouseDown: (e) => e.stopPropagation(),
                                                                            },
                                                                        }}
                                                                    >
                                                                        <MenuItem value="publish">Published Post</MenuItem>
                                                                        <MenuItem value="draft">Draft</MenuItem>
                                                                    </Select>
                                                                </FormControl>

                                                            </div>

                                                            <footer className="p-4">
                                                                <button className="btn btn-primary ml-auto"
                                                                    disabled={processing || articlePosted}
                                                                    onClick={() => {
                                                                        if (keywordHash) {
                                                                            publishOption === 'publish' ? postToBlogHandler(keywordHash) : scheduleGlossaryHandler(keywordHash);
                                                                        } else {
                                                                            console.error("Post to Blog failed. Keyword Hash missing.")
                                                                        }
                                                                    }}
                                                                >
                                                                    {publishOption === 'publish' ? 'Publish' : articleScheduledForPosting ? 'Reschedule' : 'Schedule'}
                                                                </button>
                                                            </footer>
                                                        </>
                                                        :
                                                        <div className="card-content space-y-4">
                                                            <p className="text-sm text-muted-foreground">
                                                                Please integrate your blog to publish your article.
                                                            </p>
                                                            <div className="mb-2">
                                                                <NavLink to={pageURL['new-integration']} className="text-blue-600 hover:text-blue-800 underline">
                                                                    Go to Integrations
                                                                </NavLink>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    }

                                    <div className="save-collapse">
                                        {articlePosted ? (
                                            <>
                                                <AbunButton
                                                    type={"primary"}
                                                    clickHandler={handleSaveClick}
                                                    disabled={disableSave}>
                                                    {saveButtonText}
                                                </AbunButton>
                                                <AbunButton
                                                    type={"primary"}
                                                    clickHandler={handleUpdateArticle}
                                                    disabled={disableUpdatePublish}>
                                                    Update Publish Article
                                                </AbunButton>
                                                <AbunButton
                                                    type={"primary"}
                                                    clickHandler={() => {
                                                        window.open(articlePostLink, '_blank');
                                                    }}>
                                                    View Published Article
                                                    <img src={newTab} alt="new-tab" style={{ height: 'auto', width: '1.4em', filter: 'invert(1)', marginLeft: '0.5em' }} />
                                                </AbunButton>
                                            </>
                                        ) : (
                                            <AbunButton
                                                type={"primary"}
                                                clickHandler={handleSaveClick}
                                                disabled={disableSave}>
                                                {saveButtonText}
                                            </AbunButton>
                                        )}
                                        <svg className={`collapse-button ${isSidebarCollapsed ? "" : "collapsed"}`}
                                            onClick={() => {
                                                if (isSidebarCollapsed) {
                                                    setIsSidebarCollapsed(false);
                                                } else {
                                                    setIsSidebarCollapsed(true);
                                                }
                                            }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V2C16 0.9 15.1 0 14 0ZM10 14.5H2C1.7 14.5 1.5 14.3 1.5 14V2C1.5 1.7 1.7 1.5 2 1.5H10V14.5ZM14.5 14C14.5 14.3 14.3 14.5 14 14.5H11.5V1.5H14C14.3 1.5 14.5 1.7 14.5 2V14Z" fill="black" />
                                        </svg>
                                    </div>
                                </div>
                        }
                    </div>

                    <div className="article-editor-page-body">
                        {
                            (!processing) ?
                                <div className={`article-editor-section ${isSidebarCollapsed ? "collapsed" : ""}`} style={isMediumScreen ? isSidebarCollapsed ? { display: "block" } : { display: "none" } : { display: "block" }}>
                                    {/* ------------------ Article Feedback ------------------ */}
                                    <div className={"article-feedback-container"}>
                                        <svg width="20" height="20" viewBox="0 0 25 25" fill="none" className={`positive-feedback ${articleFeedback === "positive" ? "active" : ""}`} onClick={() => {
                                            if (articleFeedback !== "positive") {
                                                setArticleFeedback("positive");
                                                saveContent(undefined, "positive");
                                            }
                                        }}>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75022 25C1.67903 25 0 23.3211 0 21.25V13.75C0 11.6789 1.67903 10 3.75022 10H5.47657L9.44153 2.07295C10.0394 0.877241 11.2249 0.0960319 12.5465 0.0082624L12.7958 0H13.1258C14.7827 0 16.1385 1.28942 16.2443 2.91953L16.2509 3.125V10H21.2491C21.496 10 21.7424 10.0244 21.9845 10.0728C23.943 10.4645 25.2377 12.3155 24.9634 14.2681L24.9265 14.4854L23.4264 21.9854C23.0904 23.6652 21.6628 24.894 19.9711 24.9935L19.749 25H3.75022ZM5.00029 12.5H3.75022C3.05982 12.5 2.50015 13.0596 2.50015 13.75V21.25C2.50015 21.9404 3.05982 22.5 3.75022 22.5H5.00029V12.5ZM13.1258 2.5H12.7958C12.3749 2.5 11.9862 2.71136 11.7563 3.05575L11.6777 3.19098L7.63241 11.2812C7.56732 11.4113 7.52563 11.5515 7.50885 11.6954L7.50044 11.8402V22.5H19.749C20.2952 22.5 20.7718 22.1466 20.9375 21.6377L20.9748 21.4951L22.4749 13.9951C22.6103 13.3182 22.1712 12.6597 21.4942 12.5243L21.3722 12.5061L21.2491 12.5H16.2509C14.9329 12.5 13.8531 11.4802 13.7577 10.1866L13.7508 10V3.125C13.7508 2.81818 13.5297 2.56299 13.2381 2.51007L13.1258 2.5Z" fill="#6C6C6C" fill-opacity="1" />
                                        </svg>
                                        <svg width="20" height="20" viewBox="0 0 25 25" fill="none" className={`negative-feedback ${articleFeedback === "negative" ? "active" : ""}`} onClick={() => {
                                            if (articleFeedback !== "negative") {
                                                setArticleFeedback("negative");
                                                saveContent(undefined, "negative");
                                            }
                                        }}>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75022 25C1.67903 25 0 23.3211 0 21.25V13.75C0 11.6789 1.67903 10 3.75022 10H5.47657L9.44153 2.07295C10.0394 0.877241 11.2249 0.0960319 12.5465 0.0082624L12.7958 0H13.1258C14.7827 0 16.1385 1.28942 16.2443 2.91953L16.2509 3.125V10H21.2491C21.496 10 21.7424 10.0244 21.9845 10.0728C23.943 10.4645 25.2377 12.3155 24.9634 14.2681L24.9265 14.4854L23.4264 21.9854C23.0904 23.6652 21.6628 24.894 19.9711 24.9935L19.749 25H3.75022ZM5.00029 12.5H3.75022C3.05982 12.5 2.50015 13.0596 2.50015 13.75V21.25C2.50015 21.9404 3.05982 22.5 3.75022 22.5H5.00029V12.5ZM13.1258 2.5H12.7958C12.3749 2.5 11.9862 2.71136 11.7563 3.05575L11.6777 3.19098L7.63241 11.2812C7.56732 11.4113 7.52563 11.5515 7.50885 11.6954L7.50044 11.8402V22.5H19.749C20.2952 22.5 20.7718 22.1466 20.9375 21.6377L20.9748 21.4951L22.4749 13.9951C22.6103 13.3182 22.1712 12.6597 21.4942 12.5243L21.3722 12.5061L21.2491 12.5H16.2509C14.9329 12.5 13.8531 11.4802 13.7577 10.1866L13.7508 10V3.125C13.7508 2.81818 13.5297 2.56299 13.2381 2.51007L13.1258 2.5Z" fill="#6C6C6C" fill-opacity="1" />
                                        </svg>
                                    </div>
                                    <div className="article-editor">
                                        <div className="is-flex">
                                            <h1 className="article-title">
                                                {articleTitle}
                                            </h1>

                                            <div>
                                                &nbsp;
                                                <svg className="edit-title" onClick={() => {
                                                    // set edit title modal active
                                                    setEditTitleModalActive(true);
                                                }} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.28933 8L8 2.28934L5.71065 0L0 5.71069V8H2.28933ZM5.71065 1.00951L6.9905 2.28934L6.06756 3.21227L4.78776 1.93243L5.71065 1.00951ZM4.28298 2.43719L5.56283 3.717L1.99366 7.28617H0.713832V6.00636L4.28298 2.43719Z" fill="black" />
                                                </svg>
                                            </div>

                                        </div>
                                        <div ref={articleEditorDivRef} onBlur={handleContentChange} onInput={handleContentChange} />
                                    </div>
                                </div> :
                                <div className={"article-generating"}>
                                    <Player
                                        autoplay
                                        loop
                                        src="https://lottie.host/91a433df-05fa-4ab3-94b2-2c2a0a16a67f/2SoIqH8Kh3.json"
                                        style={{ height: '300px', width: '300px' }}
                                    >
                                    </Player>
                                    <h2 className={"is-size-3 font-secondary has-text-weight-bold has-text-primary"}>
                                        An Amazing SEO Article is being cooked for your site!
                                    </h2>
                                    <p className={"is-size-5 mt-4"}>Article generation should take around 3-5 minutes</p>
                                    <ul className={"mt-4 mb-4"} style={{ listStyleType: "none" }}>
                                        <li className={"mb-2"}><Icon iconName={"green-checkmark-circle"} />&nbsp;&nbsp;SEO Optimized Article
                                        </li>
                                        <li className={"mb-2"}><Icon iconName={"green-checkmark-circle"} />&nbsp;&nbsp;With Image & Alt Text
                                        </li>
                                        <li className={"mb-2"}><Icon iconName={"green-checkmark-circle"} />&nbsp;&nbsp;With Internal Links</li>
                                        <li className={"mb-2"}><Icon iconName={"green-checkmark-circle"} />&nbsp;&nbsp;With External Links</li>
                                        <li className={"mb-2"}><Icon iconName={"green-checkmark-circle"} />&nbsp;&nbsp;With Featured Image</li>
                                    </ul>
                                </div>
                        }

                        {
                            isSidebarCollapsed === false &&
                            <div className="article-editor-sidebar-container" style={isMediumScreen ? isSidebarCollapsed ? { width: "20%" } : { width: "100%" } : { width: "20%" }}>
                                <div className="sidebar-items">
                                    <h6 onClick={() => {
                                        const statsContainer = document.querySelector(".sidebar-item-contents.stats-container");
                                        const statsArrow = document.querySelector(".stats-arrow");
                                        if (statsContainer && statsArrow) {
                                            statsContainer.classList.toggle("expanded");
                                            statsArrow?.classList.toggle("active");
                                        }
                                    }}>
                                        <svg className="stats-arrow" width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 4.4L5.5 0L11 4.4L10.1 5.6L5.5 2L1 5.6L0 4.4Z" fill="black" fill-opacity="0.5" />
                                        </svg>
                                        Stats & Settings
                                    </h6>
                                    <div className="sidebar-item-contents stats-container expanded">
                                        {/* <div className="stats">
                                            <span>Keyword: </span><span className="stat">{keyword}</span>
                                        </div>
                                        <div className="stats">
                                            <span>Keyword Volume: </span>
                                            <span className="stat">{keywordTraffic}
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    srcSet={locationIsoCode !== "zz" ? `https://flagcdn.com/32x24/${locationIsoCode.toLowerCase()}.png 2x` : "https://img.icons8.com/?size=100&id=3685"}
                                                    src={locationIsoCode !== "zz" ? `https://flagcdn.com/16x12/${locationIsoCode.toLowerCase()}.png` : "https://img.icons8.com/?size=100&id=3685"}
                                                    alt={locationIsoCode}
                                                />
                                            </span>
                                        </div> */}
                                        <div className="stats">
                                            <span>Total Words: </span><span className="stat">{wordCount}</span>
                                        </div>
                                        <div className="stats">
                                            <span>Status: </span><span className="stat">{articlePosted ? "Published" : "Not Published"}</span>
                                        </div>
                                        {articlePosted &&
                                            <>
                                                <div className="stats">
                                                    <span>Platform: </span><span className="stat">{articlePostedTo.charAt(0).toUpperCase() + articlePostedTo.slice(1)}</span>
                                                </div>
                                                <div className="stats">
                                                    <span>Live Link: </span><span className="stat"><a href={articlePostLink} target="_blank" rel="noreferrer">{articlePostLink.slice(0, 20)}...</a></span>
                                                </div>
                                                {articlePostedOn &&
                                                    <div className="stats">
                                                        <span>Publish Date: </span><span className="stat">{articlePostedOn}</span>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className="sidebar-items">
                                    <h6 onClick={() => {
                                        const internalLinks = document.querySelector(".sidebar-item-contents.internal-links");
                                        const internalLinksArrow = document.querySelector(".internal-links-arrow");
                                        if (internalLinks) {
                                            internalLinks.classList.toggle("expanded");
                                            internalLinksArrow?.classList.toggle("active");
                                        }
                                    }}>
                                        <svg className="internal-links-arrow active" width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 4.4L5.5 0L11 4.4L10.1 5.6L5.5 2L1 5.6L0 4.4Z" fill="black" fill-opacity="0.5" />
                                        </svg>
                                        Internal Links
                                    </h6>
                                    <div className="sidebar-item-contents internal-links">
                                        {articleInternalLinks &&
                                            articleInternalLinks.map((link, index) => {
                                                return (
                                                    <div className="backlinks" key={index}>
                                                        <div className="backlink">
                                                            <span>{link.linkTxt}</span>
                                                            <div className="backlink-url">
                                                                {
                                                                    articleInternalLinkEditableIndex === index ?
                                                                        <input className="link-edit-input"
                                                                            type="text"
                                                                            value={link.linkHref}
                                                                            onChange={(e) => {
                                                                                saveEditedLinkHandler("internal", link, e.target.value);
                                                                            }}
                                                                            onBlur={() => setArticleInternalLinkEditableIndex(-1)}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === "Enter") {
                                                                                    setArticleInternalLinkEditableIndex(-1);
                                                                                }
                                                                            }}
                                                                        /> :
                                                                        <>
                                                                            <a className={`link ${articlePosted ? "posted" : ""}`} href={link.linkHref} target="_blank" rel="noreferrer">{link.linkHref}</a>

                                                                            <svg className="edit-link" onClick={() => setArticleInternalLinkEditableIndex(index)} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.28933 8L8 2.28934L5.71065 0L0 5.71069V8H2.28933ZM5.71065 1.00951L6.9905 2.28934L6.06756 3.21227L4.78776 1.93243L5.71065 1.00951ZM4.28298 2.43719L5.56283 3.717L1.99366 7.28617H0.713832V6.00636L4.28298 2.43719Z" fill="black" />
                                                                            </svg>

                                                                        </>
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            articleInternalLinkEditableIndex !== index &&
                                                            <svg className="remove-link" onClick={() => removeLinkHandler(link)} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 6C12 9.31368 9.31368 12 6 12C2.68629 12 0 9.31368 0 6C0 2.68629 2.68629 0 6 0C9.31368 0 12 2.68629 12 6ZM4.18178 4.18179C4.35751 4.00606 4.64243 4.00606 4.81818 4.18179L6 5.36358L7.18176 4.1818C7.3575 4.00607 7.64244 4.00607 7.81818 4.1818C7.99392 4.35754 7.99392 4.64246 7.81818 4.81818L6.63636 6L7.81818 7.18176C7.99392 7.3575 7.99392 7.64244 7.81818 7.81818C7.64244 7.99392 7.3575 7.99392 7.18176 7.81818L6 6.63642L4.81818 7.81818C4.64245 7.99392 4.35752 7.99392 4.18179 7.81818C4.00606 7.64244 4.00606 7.3575 4.18179 7.18182L5.36358 6L4.18178 4.81818C4.00604 4.64245 4.00604 4.35752 4.18178 4.18179Z" fill="black" fill-opacity="0.35" />
                                                            </svg>
                                                        }
                                                    </div>
                                                );
                                            })
                                        }

                                        {
                                            !processing &&
                                            <div className="generate-new-backlink internal-link">
                                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.15" d="M5.125 1C5.125 3.27817 6.97181 5.125 9.25 5.125C6.97181 5.125 5.125 6.97181 5.125 9.25C5.125 6.97181 3.27817 5.125 1 5.125C3.27817 5.125 5.125 3.27817 5.125 1Z" fill="black" />
                                                    <path d="M5.125 1C5.125 3.27817 6.97181 5.125 9.25 5.125C6.97181 5.125 5.125 6.97181 5.125 9.25C5.125 6.97181 3.27817 5.125 1 5.125C3.27817 5.125 5.125 3.27817 5.125 1Z" stroke="black" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <p className="generate-new-backlink-text" onClick={() => {
                                                    if (active_website_domain) { // Check if active_website_domain is set
                                                        setAddNewLinkModalActive({ active: true, linkType: "internal" });
                                                    } else {
                                                        setShowConnectWebsiteWarningModal(true); // Show warning modal if no active domain
                                                    }
                                                }}>
                                                    Add New Internal Link
                                                </p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            isOptionSidebarCollapsed === false &&
                            isSmallScreen &&
                            !articlePosted &&
                            !processing &&
                            <div className="is-flex">
                                <div className="publish-post-options-for-small-screens">
                                    <h2>Ready to publish your post?</h2>
                                    <div className="publish-post-option-container">
                                        <svg
                                            className="close-options-icon"
                                            onClick={() => setIsOptionSidebarCollapsed(true)}
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            style={{ cursor: "pointer", position: "absolute", top: "10px", right: "10px" }}
                                        >
                                            <line x1="18" y1="6" x2="6" y2="18" />
                                            <line x1="6" y1="6" x2="18" y2="18" />
                                        </svg>
                                        <>
                                            {
                                                integrationWithUniqueID.length > 0 ?
                                                    <>
                                                        <div className="publish-post-option">
                                                            <input
                                                                type="radio"
                                                                id="publish"
                                                                name="publish"
                                                                value="publish"
                                                                checked={publishOption === "publish"}
                                                                onChange={(e) => {
                                                                    setPublishOption(e.target.value);
                                                                }}
                                                            />
                                                            <label htmlFor="publish-now" className="ml-2">Publish it live</label>
                                                            <p>Publish this post immediately</p>
                                                        </div>

                                                        <div className="publish-post-option">
                                                            <input
                                                                type="radio"
                                                                id="schedule"
                                                                name="schedule"
                                                                value="schedule"
                                                                checked={publishOption === "schedule"}
                                                                onChange={(e) => {
                                                                    setPublishOption(e.target.value);
                                                                }}
                                                            />
                                                            <label htmlFor="publish-later" className="ml-2">Schedule it for later</label>
                                                            <p>Set automatic future publish date</p>
                                                        </div>

                                                        {publishOption === 'schedule' && (
                                                            <div className="grid grid-cols-2 gap-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="publishDate">Date</label>
                                                                    <input
                                                                        type="date"
                                                                        id="publishDate"
                                                                        className="form-control"
                                                                        defaultValue={articleScheduledForPosting ? articleScheduledDatetime?.format("YYYY-MM-DD") : schedulePublishDateTime?.format("YYYY-MM-DD")}
                                                                        onChange={(e) => {
                                                                            setSchedulePublishDateTime(dayjs(e.target.value + " " + schedulePublishTime))
                                                                            setSchedulePublishDate(e.target.value);
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <label htmlFor="publishTime">Time</label>
                                                                    <input
                                                                        type="time"
                                                                        id="publishTime"
                                                                        className="form-control"
                                                                        defaultValue={articleScheduledForPosting ? articleScheduledDatetime?.format("HH:mm") : schedulePublishDateTime?.format("HH:mm")}
                                                                        onChange={(e) => {
                                                                            setSchedulePublishDateTime(dayjs(schedulePublishDate + " " + e.target.value))
                                                                            setSchedulePublishTime(e.target.value);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}

                                                        <div className="form-group">
                                                            <label htmlFor="platform">Platform</label>
                                                            <Select
                                                                style={{ maxWidth: "100%" }}
                                                                labelId="platform-select-label"
                                                                id="platform-select-label"
                                                                value={selectedIntegrationUniqueID}
                                                                label="Platform"
                                                                onChange={(e) => setSelectedIntegrationUniqueID(e.target.value)}
                                                            >
                                                                {integrationWithUniqueID.map((integration, index) => (
                                                                    <MenuItem key={integration.integrationUniqueID} value={integration.integrationUniqueID}
                                                                        onClick={() => {
                                                                            setSelectedIntegrationUniqueID(integration.integrationUniqueID);
                                                                            setIntegrationAndHideDropDownContent(integration.integrationName);
                                                                        }}
                                                                    >
                                                                        {integration.integrationName}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </div>

                                                        <div className="form-group">
                                                            <label htmlFor="type">Type</label>
                                                            <Select
                                                                fullWidth
                                                                labelId="type-select-label"
                                                                id="type-select-label"
                                                                value={publishType}
                                                                label="Type"
                                                                onChange={(e) => setPublishType(e.target.value)}
                                                            >
                                                                <MenuItem value="publish">Published Post</MenuItem>
                                                                <MenuItem value="draft">Draft</MenuItem>
                                                            </Select>
                                                        </div>

                                                        <button className="publish-post-btn"
                                                            disabled={processing || articlePosted}
                                                            onClick={() => {
                                                                if (keywordHash) {
                                                                    publishOption === 'publish' ? postToBlogHandler(keywordHash) : scheduleGlossaryHandler(keywordHash);
                                                                } else {
                                                                    console.error("Post to Blog failed. Keyword Hash missing.")
                                                                }
                                                            }}
                                                        >
                                                            {publishOption === 'publish' ? 'Publish' : articleScheduledForPosting ? 'Reschedule' : 'Schedule'}
                                                        </button>
                                                    </> :
                                                    <div className="card-content space-y-4">
                                                        <p className="text-sm text-muted-foreground">
                                                            Please integrate your blog to publish your article.
                                                        </p>
                                                        <div className="mt-2">
                                                            <NavLink to={pageURL['new-integration']} className="text-blue-600 hover:text-blue-800 underline">
                                                                Go to Integrations
                                                            </NavLink>
                                                        </div>
                                                    </div>
                                            }
                                            <div className="publish-post-option">
                                                <h3 className={`text-lg font-medium mb-4 ${integrationDone ? "integration-done-mt" : "mt-5"}`}>Copy Text as</h3>
                                                <div className="publish-post-option-contents">

                                                    <div className="publish-post-option publish-post-option-for-small-screens">
                                                        <input
                                                            type="radio"
                                                            id="copy-as-markdown"
                                                            name="copy-as"
                                                            value="markdown"
                                                            checked={copyAs === "markdown"}
                                                            onChange={(e) => {
                                                                setCopyAs(e.target.value);
                                                            }}
                                                        />
                                                        <label htmlFor="copy-as-markdown" className="ml-2" style={{ fontWeight: "400" }}>Markdown</label>
                                                    </div>

                                                    <div className="publish-post-option publish-post-option-for-small-screens">
                                                        <input
                                                            type="radio"
                                                            id="copy-as-html"
                                                            name="copy-as"
                                                            value="html"
                                                            checked={copyAs === "html"}
                                                            onChange={(e) => {
                                                                setCopyAs(e.target.value);
                                                            }}
                                                        />
                                                        <label htmlFor="copy-as-html" className="ml-2" style={{ fontWeight: "400" }}>HTML</label>
                                                    </div>

                                                    <div className="publish-post-option publish-post-option-for-small-screens">
                                                        <input
                                                            type="radio"
                                                            id="copy-as-raw-text"
                                                            name="copy-as"
                                                            value="raw-text"
                                                            checked={copyAs === "raw-text"}
                                                            onChange={(e) => {
                                                                setCopyAs(e.target.value);
                                                            }}
                                                        />
                                                        <label htmlFor="copy-as-raw-text" className="ml-2" style={{ fontWeight: "400" }}>Raw Text</label>
                                                    </div>

                                                    <button className="publish-post-copy-btn"
                                                        onClick={() => {
                                                            if (copyAs === "markdown") {
                                                                setRequestModalActive(true);
                                                                setModalText("Copying markdown...");

                                                                navigator.clipboard.writeText(aiEditorMarkdownContent as string)
                                                                    .then(() => {
                                                                        setRequestModalActive(false);
                                                                        successAlertRef.current?.show("Markdown copied successfully!", 3000);
                                                                        document.querySelector(".copy-txt-dropdown")?.classList.remove("show");
                                                                    })
                                                                    .catch(() => {
                                                                        setRequestModalActive(false);
                                                                        errorAlertRef.current?.show("Failed to copy markdown. Please try again.", 3000);
                                                                    });
                                                            } else if (copyAs === "html") {
                                                                setRequestModalActive(true);
                                                                setModalText("Processing HTML file...");
                                                                navigator.clipboard.writeText(aiEditorHtmlContent as string).then(() => {
                                                                    setRequestModalActive(false);
                                                                    setModalText("");
                                                                    successAlertRef.current?.show("HTML copied successfully.");
                                                                    setTimeout(() => {
                                                                        successAlertRef.current?.close();
                                                                    }, 3000);
                                                                }).catch(() => {
                                                                    setRequestModalActive(false);
                                                                    setModalText("");
                                                                    // show error alert
                                                                    errorAlertRef.current?.show("Failed to copy HTML. Please try again.");
                                                                    // Close the alert after 3 seconds
                                                                    setTimeout(() => {
                                                                        errorAlertRef.current?.close();
                                                                    }, 3000);
                                                                })
                                                                document.querySelector(".copy-txt-dropdown")?.classList.toggle("show");
                                                            } else if (copyAs === "raw-text") {
                                                                navigator.clipboard.writeText(aiEditorTextContent as string).then(() => {
                                                                    successAlertRef.current?.show("Text copied successfully.");
                                                                    setTimeout(() => {
                                                                        successAlertRef.current?.close();
                                                                    }, 3000);
                                                                }).catch(() => {
                                                                    errorAlertRef.current?.show("Failed to copy text. Please try again.");
                                                                    setTimeout(() => {
                                                                        errorAlertRef.current?.close();
                                                                    }, 3000);
                                                                })
                                                                document.querySelector(".copy-txt-dropdown")?.classList.toggle("show");
                                                            }
                                                        }}
                                                    >
                                                        Copy
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <SuccessAlert ref={successAlertRef} />
                <ErrorAlert ref={errorAlertRef} />
            </>
        )
    }
}
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from '@tanstack/react-query';
import { getAICalculatorsQuery } from "../../utils/api";
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import AbunTable from "../../components/AbunTable/AbunTable";
import Icon from "../../components/Icon/Icon";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import "./AICalculatorTable.scss";

interface AICalculator {
    calculator_id: string;
    calc_type: string;
    created_on: string;
}

export default function AICalculatorTable() {
    // --------------------------- CONSTANTS ---------------------------
    const pageSizes = [5, 10, 15, 30, 50, 100, 500];

    // --------------------------- STATES ---------------------------
    const [calculators, setCalculators] = useState<AICalculator[]>([]);

    // --------------------------- REFS ---------------------------
    const errorAlertRef = useRef<any>(null);
    const successAlertRef = useRef<any>(null);

    // --------------------------- HOOKS ---------------------------
    const navigate = useNavigate();

    // --------------------------- QUERIES ---------------------------
    const {
        isLoading,
    } = useQuery({
        ...getAICalculatorsQuery(),
        onSuccess: (response: any) => {
            if (response.data) {
                setCalculators(response.data.table_data);
            }
        },
        onError: () => {
            errorAlertRef.current?.show("Failed to load calculators");
        }
    });

    // --------------------------- HANDLERS ---------------------------
    const handleCreateNewCalculator = () => {
        navigate("/ai-calculator-generator");
    };

    // --------------------------- TABLE CONFIG ---------------------------
    const columnHelper = createColumnHelper<AICalculator>();

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString();
    };

    const columnDefs: ColumnDef<AICalculator, any>[] = [
        columnHelper.accessor('calc_type', {
            header: "Calculator Type",
            cell: (info) => info.getValue()
        }),
        columnHelper.accessor('created_on', {
            header: "Created On",
            cell: (info) => formatDate(info.getValue())
        })
    ];

    return (
        <div className="ai-calculator-table-tp-container">
            <div className="seo-project-header"></div>
            <h1>Create AI Calculator</h1>

            <div className="menu-btns AI-keyword-research-btn" onClick={handleCreateNewCalculator}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
                    <line x1="8" y1="21" x2="16" y2="21"></line>
                    <line x1="12" y1="17" x2="12" y2="21"></line>
                </svg>
                <span className="menu-btn-text">Create New AI Calculator</span>
            </div>

            <hr className="horizontal-rule" />

            <h2>Your AI Calculators</h2>

            <div className="seo-project-abun-table">
                <div className="table-container">
                    {isLoading ? (
                        <div className="loadingDataCard card mt-4" style={{ width: "100%" }}>
                            <div className="card-content">
                                <div className="content is-flex is-justify-content-center">
                                    <p style={{ textAlign: "center", fontSize: "1.3rem" }}>
                                        Loading Data...<Icon iconName="spinner" marginClass="ml-5" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <AbunTable
                            tableContentName="AI Calculators"
                            tableData={calculators}
                            columnDefs={columnDefs}
                            pageSizes={pageSizes}
                            initialPageSize={pageSizes[1]}
                            noDataText="No Calculators Found."
                            searchboxPlaceholderText="Search calculators..."
                            handleRowClick={(row) => {
                                navigate("/ai-calculator-generator", {
                                    state: {
                                        calculatorId: row.calculator_id,
                                        calculatorType: row.calc_type
                                    }
                                });
                            }}
                        />
                    )}
                </div>
            </div>

            <ErrorAlert ref={errorAlertRef} />
            <SuccessAlert ref={successAlertRef} />
        </div>
    );
};

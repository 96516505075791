import React, { useState, useRef, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import "./GSCAnalytics.scss";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Treemap,
    Cell,
} from "recharts";
import DelayedInput from "../../components/DelayedInput/DelayedInput";
import { PageData } from "../KeywordsResearchV2/KeywordResearch";
import { useLoaderData, useNavigate } from "react-router-dom";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import { fetchGSCKeywordsData2 } from "../../utils/api";
import AbunModal from "../../components/AbunModal/AbunModal";
import AbunLoader from "../../components/AbunLoader/AbunLoader";
import GenericButton from "../../components/GenericButton/GenericButton";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Download } from "lucide-react";
import { Search } from "lucide-react";
import Icon from "../../components/Icon/Icon";

interface selectedDomainProps {
    selectedDomain?: string
    handleBackBtnClick: () => void
}

interface GSCKeyword {
    keyword: string
    clicks: number
    impressions: number
    ctr: number
    position: number
}

const CustomTreemapContent = (props) => {
    const { x, y, width, height, name = "", color, size, sizeMarginTop = 4 } = props;

    if (!width || !height || !name) return null; // Prevent rendering empty blocks

    const padding = 5;
    const availableWidth = width - padding * 2;
    const availableHeight = height - padding * 2;

    const words = name.split(" ");
    const lines: string[] = [];
    let currentLine = "";

    words.forEach(word => {
        const testLine = currentLine ? `${currentLine} ${word}` : word;
        if (testLine.length * 6 < availableWidth) {
            currentLine = testLine;
        } else {
            lines.push(currentLine);
            currentLine = word;
        }
    });

    if (currentLine) lines.push(currentLine);

    const lineHeight = 14; // Spacing between lines
    const totalTextHeight = lines.length * lineHeight;

    // Check if text fits; otherwise, reduce font size or truncate
    const fontSize = Math.min(14, availableHeight / lines.length);
    const textFits = totalTextHeight <= availableHeight;

    const formatNumber = (num: number): string => {
        if (num >= 1_000_000_000) return (num / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
        if (num >= 1_000_000) return (num / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
        if (num >= 1_000) return (num / 1_000).toFixed(1).replace(/\.0$/, "") + "K";
        return num.toString();
    };

    return (
        <g>
            {/* Box Background */}
            <rect x={x} y={y} width={width} height={height} fill={color} stroke="#333" />

            {/* Wrapped Text */}
            <text
                x={x + width / 2}
                y={y + (height - totalTextHeight) / 2 + fontSize}
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize={fontSize}
                fontWeight="600"
                fontFamily="Inter, sans-serif"
                fill="black"
                style={{
                    textShadow: "none",
                    strokeWidth: 0,
                    stroke: "none"
                }}
            >
                {textFits ? (
                    lines.map((line, index) => (
                        <tspan key={index} x={x + width / 2} dy={index === 0 ? 0 : lineHeight}>
                            {line}
                        </tspan>
                    ))
                ) : (
                    <tspan>
                        {`${name.substring(0, availableWidth / 6)}...`}
                    </tspan>
                )}
            </text>
            {size && (
                <text
                    x={x + width / 2}
                    y={y + (height - totalTextHeight) / 2 + fontSize + lineHeight * lines.length + sizeMarginTop}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize={fontSize}
                    fontWeight="600"
                    fontFamily="Inter, sans-serif"
                    fill="black"
                    style={{
                        textShadow: "none",
                        strokeWidth: 0,
                        stroke: "none"
                    }}
                >
                    {formatNumber(size)}
                </text>
            )}
        </g>
    );
};


const TreemapChart = ({ gscKeywordsData }) => {
    const sortedData = [...gscKeywordsData]
        .sort((a, b) => b.impressions - a.impressions)
        .slice(0, 20);
    const colors = ["#dab6f0", "#a4d3f5", "#baf0b2", "#f8e79a", "#f7cfa7"];

    const processedData = sortedData.map((item, index) => ({
        name: item.keyword,
        size: item.impressions,
        color: colors[Math.floor(index / 4) % colors.length],
    }));

    return (
        <div style={{ width: "100%", height: 400 }}>
            <ResponsiveContainer>
                <Treemap
                    width={600}
                    height={400}
                    data={processedData}
                    dataKey="size"
                    stroke="#fff"
                    fill="#333"
                    content={<CustomTreemapContent />}
                >
                    {processedData.map((entry, index) => (
                        <Cell key={`cell-${index}`}
                            fill={entry.color} style={{ transition: 'none' }}
                        />
                    ))}
                </Treemap>
            </ResponsiveContainer>
        </div>
    );
};


export const GSCData = (props: selectedDomainProps) => {
    const { pageData } = useLoaderData() as {
        pageData: PageData;
    };
    const [searchQuery, setSearchQuery] = useState("");
    const [pageSize, setPageSize] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const [gscKeywordsData, setGscKeywordsData] = useState<Array<GSCKeyword>>([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [mainModalActive, setMainModalActive] = useState(true);
    const [dateRange, setDateRange] = useState("");
    const [activeTab, setActiveTab] = useState("By Impressions");
    const [isCopied, setIsCopied] = useState(false);
    const [initialData, setInitialData] = useState<Array<GSCKeyword>>([]);
    const dateOptions = ["Last week", "Last 30 days", "Last 3 months", "Last 1 year"];

    const [sortState, setSortState] = useState<{
        activeColumn: keyof GSCKeyword | null;
        direction: 'ascending' | 'descending' | 'not-sorted';
    }>({
        activeColumn: null,
        direction: 'not-sorted',
    });



    // -------------------------- QUERIES --------------------------
    const getGscData = useQuery(fetchGSCKeywordsData2(props.selectedDomain, dateRange));

    // --------------------------- REFS ---------------------------
    const failAlertRef = useRef<any>(null);
    const successAlertRef = useRef<any>(null);

    const navigate = useNavigate();

    const toggleTab = (tab) => {
        setActiveTab(tab);
    };

    const filteredData = gscKeywordsData.filter((item) =>
        item.keyword.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalRows = filteredData.length;
    const totalPages = Math.ceil(totalRows / pageSize);

    const currentData = filteredData.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
    );

    const goToNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const goToPrevPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handlePageNumberChange = (value) => {
        const pageNumber = Number(value);
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const changePageSize = (size) => {
        setPageSize(Number(size));
        setCurrentPage(1);
    };

    const formatNumber = (num: number): string => {
        if (num >= 1_000_000_000) return (num / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "B";
        if (num >= 1_000_000) return (num / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
        if (num >= 1_000) return (num / 1_000).toFixed(1).replace(/\.0$/, "") + "K";
        return num.toString();
    };

    const parseNumber = (str: string): number => {
        if (str.includes("B")) return parseFloat(str) * 1_000_000_000;
        if (str.includes("M")) return parseFloat(str) * 1_000_000;
        if (str.includes("K")) return parseFloat(str) * 1_000;
        return parseFloat(str);
    };

    const createBuckets = (min: number, max: number, isCTR = false): string[] => {
        let buckets: string[] = [];

        if (isCTR) {
            let start = 0;
            let step = 1;
            while (start < max) {
                let next = start + step;
                if (next >= max) next = max;
                buckets.push(`${start}-${next}%`);
                start = next;
                step = step < 2 ? 2 : step + 2;
            }
            return buckets;
        }

        let start = min;
        while (start < max) {
            let next = start < 5 ? start + 4 : start * 5;
            if (next > max) next = max;
            buckets.push(`${formatNumber(start)}-${formatNumber(next)}`);
            start = next + 1;
        }

        return buckets;
    };

    const aggregateData = (queries) => {
        const maxPos = Math.max(...queries.map(q => q.position));
        const maxCTR = Math.max(...queries.map(q => q.ctr));
        const maxImp = Math.max(...queries.map(q => q.impressions));

        // Generate dynamic ranges
        const posRanges = createBuckets(1, maxPos);
        const ctrRanges = createBuckets(0, maxCTR, true);
        const impRanges = createBuckets(1, maxImp);

        // Initialize bucket counters
        const buckets = {
            position: Object.fromEntries(posRanges.map(range => [range, 0])),
            ctr: Object.fromEntries(ctrRanges.map(range => [range, 0])),
            impressions: Object.fromEntries(impRanges.map(range => [range, 0])),
        };

        // Function to find the appropriate bucket
        const assignToBucket = (value: number, ranges: string[]): string => {
            const bucket = ranges.find(range => {
                const [low, high] = range.replace("%", "").split("-").map(parseNumber);
                return value >= low && value <= high;
            });

            return bucket || ranges[ranges.length - 1]; // Ensure it never returns undefined
        };

        // Distribute data into buckets
        queries.forEach(({ position, ctr, impressions }) => {
            buckets.position[assignToBucket(position, posRanges)]++;
            buckets.ctr[assignToBucket(ctr, ctrRanges)]++;
            buckets.impressions[assignToBucket(impressions, impRanges)]++;
        });

        // Convert to array format with formatted values
        return {
            position: Object.entries(buckets.position).map(([range, count]) => ({ range, count })),
            ctr: Object.entries(buckets.ctr).map(([range, count]) => ({ range, count })),
            impressions: Object.entries(buckets.impressions).map(([range, count]) => ({ range, count })),
        };
    };

    const calculateMetrics = (queries) => {
        const totalClicks = queries.reduce((sum, q) => sum + q.clicks, 0);
        const totalImpressions = queries.reduce((sum, q) => sum + q.impressions, 0);
        const avgCTR = totalImpressions > 0 ? ((totalClicks / totalImpressions) * 100).toFixed(2) : "0.00";
        const avgPosition = (queries.reduce((sum, q) => sum + parseFloat(q.position), 0) / queries.length).toFixed(1);

        return {
            totalClicks: formatNumber(totalClicks),
            totalImpressions: formatNumber(totalImpressions),
            rawTotalImpressions: totalImpressions,
            avgCTR,
            avgPosition
        };
    };
    const metrics = calculateMetrics(gscKeywordsData);
    const aggregated = aggregateData(gscKeywordsData);


    function handleBackBtnClick() {
        navigate('/create-article');
    }

    useEffect(() => {
        if (dateRange) {
            successAlertRef.current?.show("GSC Data will be updated based on the selected date range.");
            setIsProcessing(true);
            getGscData.refetch();
        }
    }, [dateRange]);

    useEffect(() => {
        const processGscData = async () => {
            if (getGscData.data) {
                setIsProcessing(true); // Set processing to true immediately

                const keywordMap: Record<string, GSCKeyword> = {};

                // Perform filtering
                (getGscData.data as { rows: any[] })?.rows?.forEach((row: any) => {
                    const keyword = row.keys[0];

                    if (!keywordMap[keyword]) {
                        keywordMap[keyword] = {
                            clicks: row.clicks,
                            keyword: keyword,
                            impressions: row.impressions,
                            ctr: row.ctr.toFixed(2),
                            position: row.position.toFixed(2),
                        };
                    }
                });

                // Convert the object values back into an array
                const gscKeywords = Object.values(keywordMap);

                // Simulate async to ensure state update propagates
                await new Promise((resolve) => setTimeout(resolve, 0));

                setGscKeywordsData(gscKeywords as any);
                setInitialData(gscKeywords as any);
                setIsProcessing(false); // Processing complete
            }
        };

        processGscData();
    }, [getGscData.data]);

    const handleExport = () => {
        const input = document.getElementById("export-content");
        if (!input) {
            return;
        }

        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft > 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save("abun_gsc_report.pdf"); // Triggers download
        }).catch(error => console.error("Error generating PDF:", error));
    };

    const handleCopy = () => {
        if (searchQuery.trim() === "") return; // Prevent copying empty text

        navigator.clipboard.writeText(searchQuery).then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
        });
    };

    const handleSortChange = (column: keyof GSCKeyword, direction: 'ascending' | 'descending' | 'not-sorted') => {
        if (sortState.activeColumn === column) {
            const newDirection = sortState.direction === direction ? 'not-sorted' : direction;
            setSortState({
                activeColumn: column,
                direction: newDirection,
            });
            sortTableData(column, newDirection);
        } else {
            setSortState({
                activeColumn: column,
                direction: direction,
            });
            sortTableData(column, direction);
        }
    };


    const sortTableData = (column: keyof GSCKeyword, direction: 'ascending' | 'descending' | 'not-sorted') => {
        if (direction === 'not-sorted') {
            setGscKeywordsData([...initialData]);
            return;
        }

        const sortedData = [...gscKeywordsData];
        if (direction === 'ascending') {
            sortedData.sort((a, b) => (a[column] > b[column] ? 1 : -1));
        } else if (direction === 'descending') {
            sortedData.sort((a, b) => (a[column] < b[column] ? 1 : -1));
        }
        setGscKeywordsData(sortedData);
    };

    const renderSortIcon = (column: keyof GSCKeyword) => {
        return (
            <div className={`sort-icon-container ${sortState.activeColumn === column ? sortState.direction : 'not-sorted'}`}>
                <span
                    className={`upward-arrow is-block ${sortState.activeColumn === column && sortState.direction === 'ascending' ? 'active' : ''}`}
                    onClick={() => handleSortChange(column, 'ascending')}
                >
                    ▲
                </span>
                <span
                    className={`downward-arrow is-block ${sortState.activeColumn === column && sortState.direction === 'descending' ? 'active' : ''}`}
                    onClick={() => handleSortChange(column, 'descending')}
                >
                    ▼
                </span>
            </div>
        );
    };


    if (getGscData.isLoading || isProcessing) {
        return (
            <div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
                <AbunLoader show={isProcessing || getGscData.isLoading} height="20vh" />
            </div>
        )
    } else if (getGscData.isError) {
        let error = JSON.parse((getGscData.error as Error).message) || null;
        if (!error) error = { message: "Error fetching data from Google Search Console!" }
        return (
            <AbunModal active={mainModalActive}
                headerText={"Error fetching data"}
                closeable={true}
                hideModal={() => {
                    setMainModalActive(false);
                    props.handleBackBtnClick();
                }}>
                <div className="error-div has-text-centered">
                    <p className="my-2">{error.message}</p>
                    <GenericButton text={"Retry"}
                        type={"success"}
                        clickHandler={() => getGscData.refetch()}
                    />
                </div>
            </AbunModal>
        )
    }

    return (
        <div id="export-content">
            <div className="performance-header">
                <h2>Performance on Search results</h2>
                <button onClick={handleExport} className="export-btn">
                    <Download size={18} className="icon" /> Export
                </button>
            </div>

            <div className="filters">
                <span>
                    Search type:
                    <strong> Web</strong>
                </span>
                <span className="divider">|</span>
                <span>
                    Date:
                    <strong>
                        <select
                            value={dateRange ? dateRange : "Last 30 days"}
                            onChange={(e) => setDateRange(e.target.value)}
                            className="filter-dropdown"
                        >
                            {dateOptions.map((option) => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                    </strong>
                </span>
            </div>

            <div className="gsc-stats">
                <div className="stat-card blue">
                    <p>Clicks</p>
                    <h3>{metrics.totalClicks}</h3>
                    <span>Total clicks from Google Search</span>
                </div>
                <div className="stat-card purple">
                    <p>Impressions</p>
                    <h3>{metrics.totalImpressions}</h3>
                    <span>Times your site appeared in search results</span>
                </div>
                <div className="stat-card green">
                    <p>Average CTR</p>
                    <h3>{metrics.avgCTR}%</h3>
                    <span>Percentage of impressions that resulted in clicks</span>
                </div>
                <div className="stat-card orange">
                    <p>Average Position</p>
                    <h3>{metrics.avgPosition}</h3>
                    <span>Average ranking position in search results</span>
                </div>
            </div>

            <div className="gsc-charts">
                {["Position", "CTR", "Impressions"].map((title, index) => (
                    <div className="chart-card" key={index}>
                        <h4>{title}</h4>
                        <ResponsiveContainer width="100%" height={250}>
                            <BarChart data={aggregated[title.toLowerCase()]}>
                                <XAxis type="category" dataKey="range" />
                                <YAxis type="number" scale="log" domain={[1, "auto"]} />
                                <Tooltip />
                                <Bar dataKey="count" fill="#000000" barSize={20} />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                ))}
            </div>

            <br />
            <TreemapChart gscKeywordsData={gscKeywordsData} />
            <div className="gsc-table">
                <div className="table-header">
                    <div className="left-controls">
                        <button className={activeTab === "By Impressions" ? "active" : ""} onClick={() => toggleTab("By Impressions")} >By Impressions</button>
                        <button className={activeTab === "By Numbers" ? "active" : ""} onClick={() => toggleTab("By Numbers")}>By Numbers</button>
                        <span className="entries">25 entries per page</span>
                    </div>
                    <div className="right-controls">
                        <div className="search-wrapper">
                            <Search className="search-icon" size={16} />
                            <input
                                type="text"
                                placeholder="Search for queries..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <button className="copy-btn" onClick={handleCopy}>
                                {isCopied ? "COPIED" : "COPY"}
                            </button>
                        </div>
                    </div>

                </div>
                <table>
                    <thead>
                        <tr>
                            {activeTab === "By Impressions" ? (
                                <>
                                    {/* <th>Query</th> */}
                                    {/* <th>
                                        <div
                                            className={`has-text-centered is-flex is-align-item-center is-justify-content-center}`}
                                        // onClick={header.column.getToggleSortingHandler()}
                                        >
                                            <div className={`sort-icon-container ${sortDirection}`}>
                                                <span className={"upward-arrow is-block"}>▲</span>
                                                <span className={"downward-arrow is-block"}>▼</span>
                                            </div>
                                            Query
                                        </div>
                                    </th>
                                    <th>Clicks</th>
                                    <th>Impressions</th>
                                    <th>CTR</th>
                                    <th>Position</th> */}
                                    <th>
                                        <div
                                            className={`has-text-centered is-flex is-align-item-center is-justify-content-center}`}>
                                            {renderSortIcon('keyword')}
                                            Query
                                        </div>
                                    </th>
                                    <th>
                                        <div
                                            className={`has-text-centered is-flex is-align-item-center is-justify-content-center}`}>
                                            {renderSortIcon('clicks')}
                                            Clicks
                                        </div>
                                    </th>
                                    <th>
                                        <div
                                            className={`has-text-centered is-flex is-align-item-center is-justify-content-center}`}>
                                            {renderSortIcon('impressions')}
                                            Impressions
                                        </div>
                                    </th>
                                    <th>
                                        <div
                                            className={`has-text-centered is-flex is-align-item-center is-justify-content-center}`}>
                                            {renderSortIcon('ctr')}
                                            CTR
                                        </div>
                                    </th>
                                    <th>
                                        <div
                                            className={`has-text-centered is-flex is-align-item-center is-justify-content-center}`}>
                                            {renderSortIcon('position')}
                                            Position
                                        </div>
                                    </th>
                                </>
                            ) : (
                                <>
                                    <th>Total Clicks</th>
                                    <th>Total Impressions</th>
                                    <th>Avg CTR (%)</th>
                                    <th>Avg Position</th>
                                </>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {activeTab === "By Impressions" ? (
                            currentData.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.keyword}</td>
                                    <td>{row.clicks}</td>
                                    <td>
                                        <div className="impression-container">
                                            <div className="progress">
                                                <div className="bar" style={{ width: `${(row.impressions / metrics.rawTotalImpressions) * 100}%` }}></div>
                                            </div>
                                            {row.impressions.toLocaleString()}
                                        </div>
                                    </td>
                                    <td>{row.ctr}%</td>
                                    <td>{row.position}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td>{metrics.totalClicks}</td>
                                <td>{metrics.totalImpressions.toLocaleString()}</td>
                                <td>{metrics.avgCTR}%</td>
                                <td>{metrics.avgPosition}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className={"abun-table-pagination-controls-container"}>
                    <div className={"abun-table-pagination-size-select"}>
                        Total <b>{totalRows}</b> queries&nbsp;&nbsp;|&nbsp;&nbsp;Show&nbsp;
                        <div className="select is-small">
                            <select defaultValue={pageSize} onChange={(e) => changePageSize(e.target.value)}>
                                {[25, 50, 100, 500, 1000].map((size) => (
                                    <option key={size} value={size}>{size}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    &nbsp; per page
                    <div className={"abun-table-pagination-page-control"}>
                        <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg" style={currentPage > 1 ? {
                            transform: "rotate(180deg)"
                        } : { display: "none" }} onClick={goToPrevPage}>
                            <path d="M1 1L8 7.825L1.66667 14" stroke="#929292" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                        <div className={"page-input-container"}>
                            <DelayedInput
                                initialValue={currentPage.toString()}
                                delayInMilliseconds={500}
                                onChange={handlePageNumberChange}
                                resetInput={undefined}
                                additionalClasses={["page-input"]}
                            />
                            &nbsp;/&nbsp;{totalPages}
                        </div>
                        <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg" style={currentPage < totalPages ? {} : { display: "none" }} onClick={goToNextPage}>
                            <path d="M1 1L8 7.825L1.66667 14" stroke="#929292" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                    </div>
                </div>
            </div>
            <ErrorAlert ref={failAlertRef} />
            <SuccessAlert ref={successAlertRef} />
        </div>
    );
};

export default GSCData;
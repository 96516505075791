import { useMutation } from "@tanstack/react-query";
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react";
import { NavLink, useRouteLoaderData, useLocation } from "react-router-dom";
import abun_a_logo from "../../assets/images/branding/abun_a_new.png";
import abunLogo from "../../assets/images/branding/abun_black_text_logo.png";
import defaultWebIcon from "../../assets/images/icons/defaultCompetitorLogos/default-competitor-logo1.png";
import { BasePageData } from "../../pages/Base/Base";
import { ConnectWebsite } from "../../pages/KeywordsResearchV2/KeywordResearch";
import { pageURL } from "../../pages/routes";
import {
	featureRequestMutation,
	switchWebsiteMutation,
} from "../../utils/api";
import AbunModal from "../AbunModal/AbunModal";
import GenericButton from "../GenericButton/GenericButton";
import Icon from "../Icon/Icon";
import TextArea from "../TextArea/TextArea";
import "./NewSidebar.scss";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

interface SidebarProps {
	activeWebsiteDomain: string | null
	activeWebsiteLogo: string | null
	websiteList: Array<UserWebsite>
	basePath: string | null
	hasLtdPlan: boolean
	showCollapsedSidebar: boolean
	currentPlanName: string
	currentPlanDisplayName: string
	setShowCollapsedSidebar: Dispatch<SetStateAction<boolean>>
	successAlertRef: MutableRefObject<any>
	failAlertRef: MutableRefObject<any>
}

interface featureRequestResponse {
	success: boolean,
	message: string
}

export interface UserWebsite {
	domain: string
	logo_url: string
}

export default function NewSidebar(props: SidebarProps) {
	// --------------------------- STATES ---------------------------
	const [hamburgerActive, setHamburgerActive] = useState<boolean>(false);
	const { showCollapsedSidebar, setShowCollapsedSidebar } = props;
	const [featureRequestMessage, setFeatureRequestMessage] = useState("");
	const [featureRequestErrorMessage, setFeatureRequestErrorMessage] = useState("");
	const [featureRequestSuccessMessage, setFeatureRequestSuccessMessage] = useState("");
	const [featureRequestModalActive, setFeatureRequestModalActive] = useState(false);
	const [websiteDropdownActive, setWebsiteDropdownActive] = useState(false);
	const [showConnectWebsiteModal, setShowConnectWebsiteModal] = useState(false);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [isArticleDropdownOpen, setArticleIsDropdownOpen] = useState(true);
	const [isGuestDropdownOpen, setGuestIsDropdownOpen] = useState(false);
	const [isRedditDropdownOpen, setRedditIsDropdownOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const toggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	const toggleArticle = () => {
		setArticleIsDropdownOpen(!isArticleDropdownOpen);
		setRedditIsDropdownOpen(false);
		setGuestIsDropdownOpen(false);
	};

	const toggleGuestFinder = () => {
		setGuestIsDropdownOpen(!isGuestDropdownOpen);
		setArticleIsDropdownOpen(false);
		setRedditIsDropdownOpen(false);
	};

	const toggleRedditFinder = () => {
		setRedditIsDropdownOpen(!isRedditDropdownOpen);
		setArticleIsDropdownOpen(false);
		setGuestIsDropdownOpen(false);
	};

	// ----------------------- NON STATE CONSTANTS -----------------------
	const isProduction = process.env.REACT_APP_DRF_DOMAIN === "https://api.abun.com";
	const basePageData: BasePageData = useRouteLoaderData("base") as BasePageData;

	// --------------------------- MUTATIONS ---------------------------
	const switchActiveWebsite = useMutation(switchWebsiteMutation);
	const featureRequestMut = useMutation(featureRequestMutation);

	// --------------------------- Refs ---------------------------
	const successAlertRef = useRef<any>(null);
	const failAlertRef = useRef<any>(null);

	// =================================================================
	// --------------------------- MAIN CODE ---------------------------
	// =================================================================

	useEffect(() => {
		if (showConnectWebsiteModal) {
			const handleKeyDown = (event: KeyboardEvent) => {
				if (event.key === "Escape") {
					setShowConnectWebsiteModal(false);
				}
			};

			window.addEventListener("keydown", handleKeyDown);

			return () => {
				window.removeEventListener("keydown", handleKeyDown);
			};
		}
	}, [showConnectWebsiteModal]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				dropdownRef.current && // Ensure dropdownRef is set
				!dropdownRef.current.contains(event.target) // Check if click is outside the dropdown
			) {
				setIsDropdownOpen(false); // Close the dropdown
			}
		};

		// Add event listener to capture outside clicks
		document.addEventListener("mousedown", handleClickOutside);

		// Cleanup the event listener on unmount
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	function handleWebsiteChange(newWebsiteDomain: string) {
		switchActiveWebsite.mutate(newWebsiteDomain, {
			onSuccess: () => {
				window.location.href = pageURL['createArticle'];
			},
			onError: (error) => {
				console.error(error);
			}
		});
	}

	function checkForUnsavedChanges(event: any) {
		let unsavedSettings: boolean = JSON.parse(localStorage.getItem("unsavedSettings") || "false");
		if (unsavedSettings) {
			if (window.confirm("You have unsaved changes. Are you sure you want to leave this page?")) {
				localStorage.setItem("unsavedSettings", "false");
			} else {
				// prevent navigation
				event.preventDefault();
				return false;
			}
		} else {
			localStorage.removeItem("unsavedSettings");
		}
	}

	function featureRequestModalContent() {
		return (
			<>
				<div className={"integration-modal-logo"}>
					<img src={abunLogo} alt="abun_logo" width={"auto"} height={64} />
				</div>
				<div className={"block"}>
					<p>
						We're always looking to improve and your feedback is invaluable to us.
					</p>
				</div>
				<div className={"block"}>
					<p>
						If you have a suggestion for a new feature or enhancement, please let us know! Describe the feature you would like to see in below message box.
					</p>
				</div>
				<div className={"block"}>
					<p className={"font-secondary"}>
						We appreciate your input and look forward to hearing your ideas!
					</p>
				</div>
				<div className={"mt-5"}>
					<label className={"label"}>
						Describe the feature you want
						<TextArea value={featureRequestMessage}
							placeholder={"Please Describe Your Idea."}
							onChange={(val) => {
								setFeatureRequestMessage(val);
							}} />
					</label>
				</div>
				<GenericButton text={featureRequestMut.isLoading ? "Sending..." : "Send"}
					type={"success"}
					disable={featureRequestMut.isLoading}
					additionalClassList={["mt-6", "pl-6", "pr-6"]}
					clickHandler={() => {
						setFeatureRequestErrorMessage("");
						setFeatureRequestSuccessMessage("");

						const featureRequestMessageLength = featureRequestMessage.length;
						if (featureRequestMessageLength < 20 || featureRequestMessageLength > 500) {
							setFeatureRequestErrorMessage("Please enter a message between 20-500 characters.");
						} else {
							featureRequestMut.mutate(featureRequestMessage, {
								onSuccess: (data) => {
									let response: featureRequestResponse = data['data'];
									if (response.success) {
										setFeatureRequestSuccessMessage(response.message);
									} else {
										setFeatureRequestErrorMessage(response.message);
									}
								},
								onError: () => {
									setFeatureRequestErrorMessage("Oops! Something went wrong. Please try again in some time.");
								}
							});
						}
					}} />
				<div className="mt-4">
					<p className={"has-text-danger"}>{featureRequestErrorMessage}</p>
					<p className={"has-text-success"}>{featureRequestSuccessMessage}</p>
				</div>
			</>
		)
	}

	const location = useLocation();
	
	useEffect(() => {
	  const pagesWithoutSidebar = ["/checkout/success", "/articles/edit/", "/integration/wp/success", "/integration/webflow/success", "/integration/google/success"];
	  const currentPath = location.pathname;
	
	  const shouldHideSidebar = pagesWithoutSidebar.some(path => currentPath.startsWith(path));
	
	  if (!shouldHideSidebar) {
		window.Tawk_API?.hideWidget();
	  }
	  else{
		window.Tawk_API?.showWidget();
	  }
	}, [location.pathname]);

	const showChatWindow = () => {
		if (window.Tawk_API) {
			window.Tawk_API.showWidget(); // Show the chat widget
			window.Tawk_API.toggle();   // Toggle the chat window
		}
	};
	  
	return (
		<>
			<aside className={`menu new-sidebar ${hamburgerActive ? "full-height" : ""} ${showCollapsedSidebar ? "collapsed" : ""}`}>
				{/* ============================================================================================= */}
				{/* ----------------------------------- Feature Request Modal ----------------------------------- */}
				{/* ============================================================================================= */}
				<AbunModal active={featureRequestModalActive}
					headerText={"Abun Feature Request"}
					closeable={true}
					closeableKey={true}
					hideModal={() => {
						setFeatureRequestModalActive(false);
						setFeatureRequestErrorMessage("");
						setFeatureRequestSuccessMessage("");
					}}>
					{featureRequestModalContent()}
				</AbunModal>

				<div className={"mobile-extra"}>
					<img src={abunLogo} alt="Abun Logo" className={"mobile-logo"} />
					<span className={"hamburger"} onClick={() => {
						setHamburgerActive(!hamburgerActive);
					}}>
						<Icon iconName={"hamburger"} height={"1.65em"} width={"1.65em"} />
					</span>
				</div>
				<section>
					<div className="sidebar-header">
						{/* ------------------------- LOGO ------------------------- */}
						<div className="logo-container">
							<img src={abunLogo} className={`abun-logo ${showCollapsedSidebar ? "is-hidden" : ""}`} alt="Abun Logo" width="128" height="auto" />
							<img src={abun_a_logo} className={`abun-logo ${!showCollapsedSidebar ? "is-hidden" : ""}`} alt="Abun Logo" width="64" height="auto" />
							<div className="triangle-btn is-clickable" onClick={() => setShowCollapsedSidebar((prev) => !prev)} />
						</div>
					</div>

					{/* ------------------------- WEBSITE SELECTION DROPDOWN ------------------------- */}
					<ul className={"menu-list mt-4"}>
						<div className={`dropdown w-100 ${websiteDropdownActive && "is-active"}`}
							onClick={() => {
								setWebsiteDropdownActive(!websiteDropdownActive)
								// on clicking outside the dropdown, close the dropdown
								document.addEventListener("click", (e) => {
									if (!(e.target as HTMLElement).closest(".dropdown")) {
										setWebsiteDropdownActive(false);
									}
								});
							}
							}>
							<div className="dropdown-trigger w-100">
								<button className="button w-100 is-rounded" aria-haspopup="true" aria-controls="dropdown-menu">
									{props.activeWebsiteDomain ?
										<span>
											<img src={props.activeWebsiteLogo as string}
												alt={"website logo"}
												onClick={checkForUnsavedChanges} className={"sidebar-website-list-logo"}
												width={20}
												height={"auto"}
												onError={({ currentTarget }) => {
													currentTarget.onerror = null
													currentTarget.src = defaultWebIcon;
												}} />
											<span className="link-text ml-2">
												{props.activeWebsiteDomain.startsWith('default') && props.activeWebsiteDomain.endsWith('.xyz') ? 'Add Project' : props.activeWebsiteDomain.length > 17
													? props.activeWebsiteDomain.slice(0, 17) + '...' : props.activeWebsiteDomain}
												{/* slice it if website domain name is more than 17 character */}
											</span>
										</span> :
										<span><Icon iconName={"plus-circle"} additionalClasses={["icon-primary"]} /><span className="link-text ml-2">Add Project</span></span>}
									<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1 1L6 6L11 1" stroke="black" strokeLinecap="round" />
									</svg>
								</button>
							</div>
							<div className="dropdown-menu" id="dropdown-menu" role="menu">
								<div className="dropdown-content" style={{ width: showCollapsedSidebar ? "110%" : "80%", }}>
									{props.websiteList.map(website => (
										<div key={website.domain}>
											<div className="dropdown-item sidebar-user-website-list-item"
												onClick={() => {
													if (props.activeWebsiteDomain !== website.domain) {
														handleWebsiteChange(website.domain);
													}
												}}>
												<img src={website.logo_url}
													alt={"website logo"}
													onClick={checkForUnsavedChanges} className={"sidebar-website-list-logo"}
													width={20}
													height={"auto"}
													onError={({ currentTarget }) => {
														currentTarget.onerror = null
														currentTarget.src = defaultWebIcon;
													}} />
												<span className={`ml-2 ${props.activeWebsiteDomain === website.domain ? "has-text-primary" : ""}`}>
													{website.domain.startsWith("default-") && website.domain.endsWith(".xyz") ? "Add Project" : website.domain.length > 19 ? website.domain.slice(0, 19) + '...' : website.domain}
												</span>
											</div>
											<hr className="dropdown-divider" />
										</div>
									))}
									<div className={"dropdown-item sidebar-connect-website-list-item"}>
										<div
											className="sidebar-add-website"
											onClick={() => {
												setShowConnectWebsiteModal(true);
											}}>
											<Icon iconName={"plus-circle"} />
											<span className="ml-2">Add Project</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ul>

					{/* ------------------------- SIDEBAR ITEMS ------------------------- */}
					<div className="sidebar-items">
						<ul className="menu-list mt-4">
							{/* ------------------------- All ARTICLES ------------------------- */}
							<li className="mt-2 articles article-section">
								<div className="article-dropdown" aria-haspopup="true" aria-controls="dropdown-menu" onClick={toggleArticle} >
									<svg xmlns="http://www.w3.org/2000/svg" className="svg-article-icon" fill="none" viewBox="0 0 48 48" height="25" width="25">
										<g>
											<path id="Vector" stroke="#2859c5" d="M7 36.3529c0.3697 6.1654 -2.5 7.4118 -4 7.4118C3 43.7647 9.5 45 19 45c4.4849 0 8.3011 -0.2753 11.0805 -0.566 2.8661 -0.2997 4.9294 -2.7553 4.8495 -5.6359C34.6912 30.1945 33.9035 22.81 33.5424 13c-0.1126 -3.06039 0 -9 4.4576 -10L12 3C8.68629 3 6.00548 5.68197 6.05053 8.99537 6.13668 15.3307 6.3697 25.8418 7 36.3529Z" strokeWidth="3" />
											<path id="Subtract" stroke="#2859c5" d="M37.9998 3c-4.4569 0.99986 -4.5702 6.93788 -4.4576 9.9987 4.3993 -0.0219 7.398 -0.3069 9.2207 -0.5631 1.3335 -0.1874 2.2369 -1.3427 2.2369 -2.68934v-0.6487c0 -3.90244 -2.9999 -6.09756 -6 -6.09756l-1 0Z" strokeWidth="3" />
											<path id="Vector_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M7 36.3529c0.3697 6.1654 -2.5 7.4118 -4 7.4118C3 43.7647 9.5 45 19 45c4.4849 0 8.3011 -0.2753 11.0805 -0.566 2.8661 -0.2997 4.9294 -2.7553 4.8495 -5.6359C34.6912 30.1945 33.9035 22.81 33.5424 13c-0.1126 -3.06039 0 -9 4.4576 -10L12 3C8.68629 3 6.00548 5.68197 6.05053 8.99537 6.13668 15.3307 6.3697 25.8418 7 36.3529Z" strokeWidth="3" />
											<path id="Subtract_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="m37.9996 3.00001 1 -0.00001c3.0001 0 6 2.19512 6 6.09756v0.6487c0 1.34664 -0.9034 2.50194 -2.2369 2.68934 -1.8227 0.2562 -4.8214 0.5412 -9.2207 0.5631" strokeWidth="3" />
											<path id="Vector 1679" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M17 13h10" strokeWidth="3" /><path id="Vector 1680" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m13 20 14 0" strokeWidth="3" />
											<path id="Vector 1681" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="m13.5 27 14 0" strokeWidth="3" /><path id="Vector 1682" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m14 34 14 0" strokeWidth="3" />
										</g>
									</svg>
									<span className="article-text" style={{ marginLeft: '0.84rem' }}>Articles</span>
									<span className="icon is-small drop-down-arrow " style={{ marginLeft: "1rem", }}>
										<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L6 6L11 1" stroke="black" strokeLinecap="round"></path></svg>
									</span>
								</div>

								{isArticleDropdownOpen ?
									<ul >
										{/* ------------------------- CREATE ARTICLE ------------------------- */}
										<li className="articles subcategory-menu">
											<NavLink to={pageURL['createArticle']} className={({ isActive }) => isActive ? "is-active" : ""}
												onClick={(e) => {
													checkForUnsavedChanges(e);
													setHamburgerActive(false);
												}}>
												<span className="articles-li-text ml-4">Create Article</span>
											</NavLink>
										</li>

										{/* ------------------------- SHOW ARTICLES ------------------------- */}
										<li className="articles subcategory-menu">
											<NavLink to={pageURL['showArticles']}
												className={({ isActive }) => isActive ? "is-active" :
													`${document.getElementById("howToArticles")?.classList.contains("is-active") ? "is-active" : `${document.getElementById("listicles")?.classList.contains("is-active") ? "is-active" : ""}`}`}
												onClick={(e) => {
													checkForUnsavedChanges(e);
													setHamburgerActive(false);
												}}>
												<span className="articles-li-text ml-4">Generated Articles</span>
											</NavLink>
										</li>

										{/* ------------------------- ARTICLE KEYWORDS ------------------------- */}
										<li className="articles subcategory-menu">
											<NavLink to={pageURL['keywordResearch']} className={({ isActive }) => isActive ? "is-active" : ""}
												onClick={(e) => {
													checkForUnsavedChanges(e);
													setHamburgerActive(false);
												}}>
												<span className="articles-li-text ml-4">Keyword Projects</span>
											</NavLink>
										</li>

										{/* ------------------------- KEYWORD to ARTICLE ------------------------- */}
										<li className="articles subcategory-menu">
											<NavLink to='/keyword-research?page=ai-keyword'
												onClick={(e) => {
													checkForUnsavedChanges(e);
													setHamburgerActive(false);
												}}>
												<span className="articles-li-text ml-4" style={{ whiteSpace: "nowrap" }}>AI Keyword to Article</span>
											</NavLink>
										</li>

										{/* ------------------------- STEAL KEYWORDS ------------------------- */}
										<li className={"articles subcategory-menu"}>
											{props.currentPlanName === "Trial" ?
												<NavLink to={pageURL['manageSubscription']} className={"upgrade"}
													onClick={(e) => {
														checkForUnsavedChanges(e);
														setHamburgerActive(false);
													}}>
													<span className="articles-li-text ml-4" style={{ whiteSpace: "nowrap" }}>Steal Competitor Keyword</span>
												</NavLink>
												:
												<NavLink to={pageURL['competitorResearch']} className={({ isActive }) => isActive ? "is-active" : ""}
													onClick={(e) => {
														checkForUnsavedChanges(e);
														setHamburgerActive(false);
													}}>
													<span className="articles-li-text ml-4" style={{ whiteSpace: "nowrap" }}>Steal Competitor Keyword</span>
												</NavLink>
											}
										</li>

										{/* ------------------------- GSC KEYWORDS ------------------------- */}
										<li className={"articles subcategory-menu"}>
											{props.currentPlanName === "Trial" ?
												<NavLink to={pageURL['manageSubscription']} className={"upgrade"}
													onClick={(e) => {
														checkForUnsavedChanges(e);
														setHamburgerActive(false);
													}}>
													<span className="articles-li-text ml-4" style={{ whiteSpace: "nowrap" }}>Import Keyword from GSC</span>
												</NavLink>
												:
												<NavLink to='/keyword-research?page=gsc'
													onClick={(e) => {
														checkForUnsavedChanges(e);
														setHamburgerActive(false);
													}}>
													<span className="articles-li-text ml-4" style={{ whiteSpace: "nowrap" }}>Import Keyword from GSC</span>
												</NavLink>
											}
										</li>

										{/* ------------------------- MANUAL KEYWORDS ------------------------- */}
										<li className="articles subcategory-menu">
											<NavLink to='/keyword-research?page=manual'
												onClick={(e) => {
													checkForUnsavedChanges(e);
													setHamburgerActive(false);
												}}>
												<span className="articles-li-text ml-4" style={{ whiteSpace: "nowrap" }}>Manual Keyword to Article</span>
											</NavLink>
										</li>

										{/* ------------------------- BULK KEYWORDS ------------------------- */}
										<li className={"articles subcategory-menu"}>
											{props.currentPlanName === "Trial" ?
												<NavLink to={pageURL['manageSubscription']} className={"upgrade"}
													onClick={(e) => {
														checkForUnsavedChanges(e);
														setHamburgerActive(false);
													}}>
													<span className="articles-li-text ml-4" style={{ whiteSpace: "nowrap" }}>Bulk Keyword to Article</span>
												</NavLink>
												:
												<NavLink to='/keyword-research?page=csv'
													onClick={(e) => {
														checkForUnsavedChanges(e);
														setHamburgerActive(false);
													}}>
													<span className="articles-li-text ml-4" style={{ whiteSpace: "nowrap" }}>Bulk Keyword to Article</span>
												</NavLink>
											}
										</li>

										{/* ------------------------- LONGTAIL KEYWORDS ------------------------- */}
										<li className={"articles subcategory-menu"}>
											{props.currentPlanName === "Trial" ?
												<NavLink to={pageURL['manageSubscription']} className={"upgrade"}
													onClick={(e) => {
														checkForUnsavedChanges(e);
														setHamburgerActive(false);
													}}>
													<span className="articles-li-text ml-4" style={{ whiteSpace: "nowrap" }}>Longtail Keyword to Article</span>
												</NavLink>
												:
												<NavLink to='/keyword-research?page=longtail'
													onClick={(e) => {
														checkForUnsavedChanges(e);
														setHamburgerActive(false);
													}}>
													<span className="articles-li-text ml-4" style={{ whiteSpace: "nowrap" }}>Longtail Keyword to Article</span>
												</NavLink>
											}
										</li>

										{/* ------------------------- SETTINGS ------------------------- */}
										<li className="category-menu settings">
											<NavLink to={props.websiteList.length === 0 ? pageURL['connectWebsite'] : pageURL['settings']} className={({ isActive }) => (isActive && !window.location.href.endsWith("?tab=integration")) ? "is-active settings" : "settings"}
												onClick={(e) => {
													checkForUnsavedChanges(e);
													setHamburgerActive(false);
													let interval = setInterval(() => {
														const imagesTab = document.getElementById("images-tab");
														if (imagesTab) {
															imagesTab.click();
															clearInterval(interval);
														}
													}, 500);
												}}>
												<span className="articles-li-text ml-4">Article Settings</span>
											</NavLink>
										</li>
									</ul>
									: ""}
							</li>

							{/* ------------------------- CONTENT AUTOMATION ------------------------- */}
							<li className="mt-2 autoArticles subcategory-menu">
								{props.currentPlanDisplayName.includes("Trial") ?
									props.hasLtdPlan ? null : (
										<NavLink to={pageURL['manageSubscription']} className={"upgrade"}
											onClick={(e) => {
												checkForUnsavedChanges(e);
												setHamburgerActive(false);
											}}>
											<div className="category">
												<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 24 24" height="48" width="48">
													<g>
														<path id="Subtract" fill="#ffffff" d="M2.4035047916666668 14.614104166666667C2.3985310416666668 14.233070833333334 2.3958333333333335 13.834020833333334 2.3958333333333335 13.416666666666668c0 -2.5854395833333332 0.10343770833333334 -4.467510416666666 0.21182041666666668 -5.729922916666667 0.10471708333333335 -1.2196708333333333 1.0569122916666667 -2.1305666666666667 2.2783129166666667 -2.2130791666666667C6.3641958333333335 5.373854166666667 8.687004166666668 5.270833333333334 11.979166666666668 5.270833333333334c3.2921625 0 5.6149708333333335 0.10302083333333334 7.0932 0.20283125000000002 0.36651458333333337 0.02477291666666667 0.7087354166666667 0.12410416666666668 1.0111375 0.2840020833333333 -0.16406666666666667 -0.9921912500000001 -0.31955625000000004 -1.8018870833333334 -0.45463333333333333 -2.442585625 -0.24509375 -1.1622283333333334 -1.2750625 -1.9346210416666667 -2.47408125 -1.8742795833333334 -1.4488083333333335 0.07291479166666667 -3.7170395833333334 0.24217083333333334 -6.912266666666667 0.6301377083333334 -3.1951791666666667 0.387966875 -5.437439583333333 0.7663791666666666 -6.8610581250000005 1.0422402083333335 -1.1781510416666667 0.22829416666666666 -1.9886087500000003 1.2241558333333333 -1.9420577083333335 2.4105102083333336 0.0508875 1.2969125 0.1864485416666667 3.2579979166666666 0.5223347916666667 5.963229166666667 0.14749229166666666 1.1879020833333334 0.29736125 2.2273104166666666 0.44176291666666667 3.127185416666667Z" strokeWidth="1." />
														<path id="hover" fill="#fff" d="M39.056 16.6c1.565 0.176 2.703 1.401 2.822 2.971 0.136 1.796 0.216 4.243 -0.08 6.366a2.956 2.956 0 0 1 -0.969 1.806c-1.23 1.093 -3.931 3.217 -8.238 5.179a198.938 198.938 0 0 1 -0.382 3.084 1.905 1.905 0 0 1 -1.71 1.655C28.973 37.807 26.487 38 24 38c-2.486 0 -4.973 -0.193 -6.499 -0.34a1.905 1.905 0 0 1 -1.71 -1.654c-0.099 -0.747 -0.229 -1.775 -0.382 -3.084 -4.307 -1.962 -7.009 -4.086 -8.238 -5.179a2.956 2.956 0 0 1 -0.968 -1.806c-0.297 -2.123 -0.217 -4.57 -0.081 -6.366 0.119 -1.57 1.257 -2.795 2.822 -2.971 2.478 -0.28 7.069 -0.6 15.056 -0.6 7.987 0 12.578 0.322 15.056 0.6Z" strokeWidth="3" />
														<path id="Rectangle 1103" fill="#ffffff" d="M12.021045833333334 13.810781250000002c0.04120833333333333 0.8179375000000001 0.6627354166666667 1.4394645833333335 1.4806729166666668 1.4806729166666668C13.97638125 15.315364583333333 14.582910416666667 15.333333333333334 15.333333333333334 15.333333333333334c0.7504229166666667 0 1.3569520833333335 -0.01796875 1.8316145833333333 -0.04187916666666667 0.8179375000000001 -0.04120833333333333 1.4394645833333335 -0.6627354166666667 1.4806729166666668 -1.4806729166666668C18.66953125 13.33611875 18.6875 12.729589583333333 18.6875 11.979166666666668c0 -0.7504229166666667 -0.01796875 -1.3569520833333335 -0.04187916666666667 -1.8316145833333333 -0.04120833333333333 -0.8179375000000001 -0.6627354166666667 -1.4394645833333335 -1.4806729166666668 -1.4806729166666668C16.690285416666665 8.642968750000001 16.08375625 8.625 15.333333333333334 8.625c-0.7504229166666667 0 -1.3569520833333335 0.01796875 -1.8316145833333333 0.04187916666666667 -0.8179375000000001 0.04120833333333333 -1.4394645833333335 0.6627354166666667 -1.4806729166666668 1.4806729166666668C11.997135416666667 10.622214583333333 11.979166666666668 11.228743750000001 11.979166666666668 11.979166666666668c0 0.7504229166666667 0.01796875 1.3569520833333335 0.04187916666666667 1.8316145833333333Z" strokeWidth="1.5" />
														<path id="Rectangle 1100" fill="#ffffff" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M19.608697916666667 3.3036193750000002c-0.24475833333333336 -1.1551366666666667 -1.273625 -1.9228095833333332 -2.4713020833333332 -1.8628370833333334 -1.4472270833333334 0.072464375 -3.7129187500000005 0.24069020833333335 -6.9046 0.62629 -3.1916812500000002 0.38559979166666664 -5.431450000000001 0.7616977083333334 -6.8534825 1.035876875 -1.1768477083333335 0.22689979166666668 -1.9864045833333333 1.216685625 -1.939911041666667 2.3957758333333334 0.05083479166666667 1.2890541666666666 0.18624250000000003 3.2381124999999997 0.5217597916666666 5.926860416666667 0.15179041666666668 1.2164125000000001 0.30610604166666666 2.276185416666667 0.4543697916666667 3.188997916666667" strokeWidth="1.5" />
														<path id="Rectangle 1099" stroke="#2859c5" strokeLinejoin="round" d="M2.60765375 19.146589583333334c0.10471229166666667 1.2196708333333333 1.0569122916666667 2.1305666666666667 2.2783129166666667 2.2130791666666667C6.3641958333333335 21.459479166666664 8.687004166666668 21.5625 11.979166666666668 21.5625c3.2921625 0 5.6149708333333335 -0.10302083333333334 7.0932 -0.20283125000000002 1.2213958333333335 -0.0825125 2.1735958333333336 -0.9934083333333333 2.27829375 -2.2130791666666667C21.45904791666667 17.884177083333334 21.5625 16.00210625 21.5625 13.416666666666668s-0.10345208333333335 -4.467510416666666 -0.21183958333333333 -5.729922916666667c-0.10469791666666667 -1.2196708333333333 -1.0568979166666668 -2.1305666666666667 -2.27829375 -2.2130791666666667C17.594137500000002 5.373854166666667 15.271329166666668 5.270833333333334 11.979166666666668 5.270833333333334c-3.2921625 0 -5.6149708333333335 0.10302083333333334 -7.0932 0.20283125000000002 -1.221400625 0.0825125 -2.1735958333333336 0.9934083333333333 -2.2783129166666667 2.2130791666666667C2.499271041666667 8.949156250000001 2.3958333333333335 10.831227083333333 2.3958333333333335 13.416666666666668s0.10343770833333334 4.467510416666666 0.21182041666666668 5.729922916666667Z" strokeWidth="1.5" />
														<path id="Vector 1469" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M9.104166666666668 9.104166666666668h-3.8333333333333335" strokeWidth="1.5" />
														<path id="Vector 1471" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M9.104166666666668 14.854166666666668h-3.8333333333333335" strokeWidth="1.5" />
														<path id="Vector 1472" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M18.6875 17.729166666666668H5.270833333333334" strokeWidth="1.5" />
														<path id="Vector 1470" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M9.104166666666668 11.979166666666668h-3.8333333333333335" strokeWidth="1.5" />
														<path id="Rectangle 1096" fill="#ffffff" stroke="#2859c5" strokeLinejoin="round" d="M12.021045833333334 13.810781250000002c0.04120833333333333 0.8179375000000001 0.6627354166666667 1.4394645833333335 1.4806729166666668 1.4806729166666668C13.97638125 15.315364583333333 14.582910416666667 15.333333333333334 15.333333333333334 15.333333333333334c0.7504229166666667 0 1.3569520833333335 -0.01796875 1.8316145833333333 -0.04187916666666667 0.8179375000000001 -0.04120833333333333 1.4394645833333335 -0.6627354166666667 1.4806729166666668 -1.4806729166666668C18.66953125 13.33611875 18.6875 12.729589583333333 18.6875 11.979166666666668c0 -0.7504229166666667 -0.01796875 -1.3569520833333335 -0.04187916666666667 -1.8316145833333333 -0.04120833333333333 -0.8179375000000001 -0.6627354166666667 -1.4394645833333335 -1.4806729166666668 -1.4806729166666668C16.690285416666665 8.642968750000001 16.08375625 8.625 15.333333333333334 8.625c-0.7504229166666667 0 -1.3569520833333335 0.01796875 -1.8316145833333333 0.04187916666666667 -0.8179375000000001 0.04120833333333333 -1.4394645833333335 0.6627354166666667 -1.4806729166666668 1.4806729166666668C11.997135416666667 10.622214583333333 11.979166666666668 11.228743750000001 11.979166666666668 11.979166666666668c0 0.7504229166666667 0.01796875 1.3569520833333335 0.04187916666666667 1.8316145833333333Z" strokeWidth="1.5" />
													</g>
												</svg>
												<span className="link-text ml-4">
													Auto Blog
												</span>
											</div>
											<span className="uncollapsed-tag tag is-info is-light is-rounded">
												Upgrade ⚡
											</span>
										</NavLink>
									) : (
										<NavLink to={pageURL['addAutomation']}
											className={({ isActive }) => isActive ? `is-active` : ""}
											onClick={(e) => {
												checkForUnsavedChanges(e);
												setHamburgerActive(false);
											}}>
											<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 24 24" height="24" width="24">
												<g>
													<path id="Subtract" fill="#ffffff" d="M2.4035047916666668 14.614104166666667C2.3985310416666668 14.233070833333334 2.3958333333333335 13.834020833333334 2.3958333333333335 13.416666666666668c0 -2.5854395833333332 0.10343770833333334 -4.467510416666666 0.21182041666666668 -5.729922916666667 0.10471708333333335 -1.2196708333333333 1.0569122916666667 -2.1305666666666667 2.2783129166666667 -2.2130791666666667C6.3641958333333335 5.373854166666667 8.687004166666668 5.270833333333334 11.979166666666668 5.270833333333334c3.2921625 0 5.6149708333333335 0.10302083333333334 7.0932 0.20283125000000002 0.36651458333333337 0.02477291666666667 0.7087354166666667 0.12410416666666668 1.0111375 0.2840020833333333 -0.16406666666666667 -0.9921912500000001 -0.31955625000000004 -1.8018870833333334 -0.45463333333333333 -2.442585625 -0.24509375 -1.1622283333333334 -1.2750625 -1.9346210416666667 -2.47408125 -1.8742795833333334 -1.4488083333333335 0.07291479166666667 -3.7170395833333334 0.24217083333333334 -6.912266666666667 0.6301377083333334 -3.1951791666666667 0.387966875 -5.437439583333333 0.7663791666666666 -6.8610581250000005 1.0422402083333335 -1.1781510416666667 0.22829416666666666 -1.9886087500000003 1.2241558333333333 -1.9420577083333335 2.4105102083333336 0.0508875 1.2969125 0.1864485416666667 3.2579979166666666 0.5223347916666667 5.963229166666667 0.14749229166666666 1.1879020833333334 0.29736125 2.2273104166666666 0.44176291666666667 3.127185416666667Z" strokeWidth="1." />
													<path id="Rectangle 1103" fill="#ffffff" d="M12.021045833333334 13.810781250000002c0.04120833333333333 0.8179375000000001 0.6627354166666667 1.4394645833333335 1.4806729166666668 1.4806729166666668C13.97638125 15.315364583333333 14.582910416666667 15.333333333333334 15.333333333333334 15.333333333333334c0.7504229166666667 0 1.3569520833333335 -0.01796875 1.8316145833333333 -0.04187916666666667 0.8179375000000001 -0.04120833333333333 1.4394645833333335 -0.6627354166666667 1.4806729166666668 -1.4806729166666668C18.66953125 13.33611875 18.6875 12.729589583333333 18.6875 11.979166666666668c0 -0.7504229166666667 -0.01796875 -1.3569520833333335 -0.04187916666666667 -1.8316145833333333 -0.04120833333333333 -0.8179375000000001 -0.6627354166666667 -1.4394645833333335 -1.4806729166666668 -1.4806729166666668C16.690285416666665 8.642968750000001 16.08375625 8.625 15.333333333333334 8.625c-0.7504229166666667 0 -1.3569520833333335 0.01796875 -1.8316145833333333 0.04187916666666667 -0.8179375000000001 0.04120833333333333 -1.4394645833333335 0.6627354166666667 -1.4806729166666668 1.4806729166666668C11.997135416666667 10.622214583333333 11.979166666666668 11.228743750000001 11.979166666666668 11.979166666666668c0 0.7504229166666667 0.01796875 1.3569520833333335 0.04187916666666667 1.8316145833333333Z" strokeWidth="1.5" />
													<path id="Rectangle 1100" fill="#ffffff" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M19.608697916666667 3.3036193750000002c-0.24475833333333336 -1.1551366666666667 -1.273625 -1.9228095833333332 -2.4713020833333332 -1.8628370833333334 -1.4472270833333334 0.072464375 -3.7129187500000005 0.24069020833333335 -6.9046 0.62629 -3.1916812500000002 0.38559979166666664 -5.431450000000001 0.7616977083333334 -6.8534825 1.035876875 -1.1768477083333335 0.22689979166666668 -1.9864045833333333 1.216685625 -1.939911041666667 2.3957758333333334 0.05083479166666667 1.2890541666666666 0.18624250000000003 3.2381124999999997 0.5217597916666666 5.926860416666667 0.15179041666666668 1.2164125000000001 0.30610604166666666 2.276185416666667 0.4543697916666667 3.188997916666667" strokeWidth="1.5" />
													<path id="Rectangle 1099" stroke="#2859c5" strokeLinejoin="round" d="M2.60765375 19.146589583333334c0.10471229166666667 1.2196708333333333 1.0569122916666667 2.1305666666666667 2.2783129166666667 2.2130791666666667C6.3641958333333335 21.459479166666664 8.687004166666668 21.5625 11.979166666666668 21.5625c3.2921625 0 5.6149708333333335 -0.10302083333333334 7.0932 -0.20283125000000002 1.2213958333333335 -0.0825125 2.1735958333333336 -0.9934083333333333 2.27829375 -2.2130791666666667C21.45904791666667 17.884177083333334 21.5625 16.00210625 21.5625 13.416666666666668s-0.10345208333333335 -4.467510416666666 -0.21183958333333333 -5.729922916666667c-0.10469791666666667 -1.2196708333333333 -1.0568979166666668 -2.1305666666666667 -2.27829375 -2.2130791666666667C17.594137500000002 5.373854166666667 15.271329166666668 5.270833333333334 11.979166666666668 5.270833333333334c-3.2921625 0 -5.6149708333333335 0.10302083333333334 -7.0932 0.20283125000000002 -1.221400625 0.0825125 -2.1735958333333336 0.9934083333333333 -2.2783129166666667 2.2130791666666667C2.499271041666667 8.949156250000001 2.3958333333333335 10.831227083333333 2.3958333333333335 13.416666666666668s0.10343770833333334 4.467510416666666 0.21182041666666668 5.729922916666667Z" strokeWidth="1.5" />
													<path id="Vector 1469" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M9.104166666666668 9.104166666666668h-3.8333333333333335" strokeWidth="1.5" />
													<path id="Vector 1471" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M9.104166666666668 14.854166666666668h-3.8333333333333335" strokeWidth="1.5" />
													<path id="Vector 1472" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M18.6875 17.729166666666668H5.270833333333334" strokeWidth="1.5" />
													<path id="Vector 1470" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M9.104166666666668 11.979166666666668h-3.8333333333333335" strokeWidth="1.5" />
													<path id="Rectangle 1096" fill="#ffffff" stroke="#2859c5" strokeLinejoin="round" d="M12.021045833333334 13.810781250000002c0.04120833333333333 0.8179375000000001 0.6627354166666667 1.4394645833333335 1.4806729166666668 1.4806729166666668C13.97638125 15.315364583333333 14.582910416666667 15.333333333333334 15.333333333333334 15.333333333333334c0.7504229166666667 0 1.3569520833333335 -0.01796875 1.8316145833333333 -0.04187916666666667 0.8179375000000001 -0.04120833333333333 1.4394645833333335 -0.6627354166666667 1.4806729166666668 -1.4806729166666668C18.66953125 13.33611875 18.6875 12.729589583333333 18.6875 11.979166666666668c0 -0.7504229166666667 -0.01796875 -1.3569520833333335 -0.04187916666666667 -1.8316145833333333 -0.04120833333333333 -0.8179375000000001 -0.6627354166666667 -1.4394645833333335 -1.4806729166666668 -1.4806729166666668C16.690285416666665 8.642968750000001 16.08375625 8.625 15.333333333333334 8.625c-0.7504229166666667 0 -1.3569520833333335 0.01796875 -1.8316145833333333 0.04187916666666667 -0.8179375000000001 0.04120833333333333 -1.4394645833333335 0.6627354166666667 -1.4806729166666668 1.4806729166666668C11.997135416666667 10.622214583333333 11.979166666666668 11.228743750000001 11.979166666666668 11.979166666666668c0 0.7504229166666667 0.01796875 1.3569520833333335 0.04187916666666667 1.8316145833333333Z" strokeWidth="1.5" />
												</g>
											</svg>
											<span className="link-text ml-4">Auto Blog</span>
										</NavLink>
									)
								}
							</li>

							{/* ------------------------- PROGRAMMATIC SEO ------------------------- */}
							{!isProduction && (
								<li className="mt-2 articles subcategory-menu">
									<NavLink to={pageURL['titleProject']}
										className={({ isActive }) => isActive ? "is-active" : ""}
										onClick={(e) => {
											checkForUnsavedChanges(e);
											setHamburgerActive(false);
										}}>
										<svg xmlns="http://www.w3.org/2000/svg" className="programmatic-seo-svg" fill="none" viewBox="0 0 48 48" id="Polaroid--Streamline-Plump" height="48" width="48">
                                            <g id="polaroid--photos-polaroid-picture-camera-photography-photo-pictures-image">
                                                <path id="Subtract" fill="#8fbffa" d="M12.8947 39.1969c0.0631 -0.1699 0.2275 -0.2805 0.4087 -0.2753 1.6734 0.0482 3.5683 0.078 5.6962 0.078 4.9802 0 8.6841 -0.1632 11.2582 -0.3437 4.546 -0.3188 8.0794 -3.8522 8.3982 -8.3982 0.1805 -2.5741 0.3436 -6.278 0.3436 -11.2581 0 -1.8399 -0.0222 -3.5056 -0.0594 -5.0045 1.2025 0.3778 2.24 0.7214 3.1219 1.0253 2.4239 0.8351 3.8035 3.2247 3.3148 5.7414 -0.4729 2.4355 -1.2525 5.9644 -2.5189 10.6906 -1.2664 4.7263 -2.3557 8.1721 -3.1639 10.5178 -0.8351 2.4239 -3.2247 3.8035 -5.7414 3.3148 -2.4355 -0.4729 -5.9644 -1.2525 -10.6906 -2.5189 -4.459 -1.1947 -7.7783 -2.2319 -10.1093 -3.0241 -0.2245 -0.0763 -0.3406 -0.3228 -0.2581 -0.5451Z" stroke-width="3"></path>
                                                <path id="Subtract_2" fill="#8fbffa" d="M12.8945 39.1981c0.0629 -0.1704 0.2276 -0.2815 0.4092 -0.2763 1.6735 0.0482 3.5685 0.078 5.6966 0.078 4.9801 0 8.684 -0.1631 11.2581 -0.3436 0.9141 -0.0641 1.7873 -0.2582 2.6016 -0.5644l7.4632 1.9997c-0.2247 0.6915 -0.4347 1.3173 -0.6285 1.8797 -0.8351 2.4239 -3.2247 3.8035 -5.7414 3.3148 -2.4355 -0.4729 -5.9644 -1.2525 -10.6906 -2.5189 -4.4592 -1.1948 -7.7787 -2.232 -10.1097 -3.0242 -0.2243 -0.0763 -0.3406 -0.3225 -0.2585 -0.5448Z" stroke-width="3"></path>
                                                <path id="Rectangle 1096" fill="#8fbffa" d="M3.33388 29.9783c0.17934 2.5574 2.13038 4.5085 4.68782 4.6878C10.4966 34.8397 14.107 35 19 35c4.893 0 8.5034 -0.1603 10.9783 -0.3339 2.5574 -0.1793 4.5085 -2.1304 4.6878 -4.6878C34.8397 27.5034 35 23.893 35 19c0 -4.893 -0.1603 -8.5034 -0.3339 -10.9783 -0.1793 -2.55744 -2.1304 -4.50848 -4.6878 -4.68782C27.5034 3.16032 23.893 3 19 3c-4.893 0 -8.5034 0.16032 -10.9783 0.33387 -2.55744 0.17935 -4.50848 2.13039 -4.68782 4.68783C3.16032 10.4966 3 14.107 3 19c0 4.893 0.16032 8.5034 0.33388 10.9783Z" stroke-width="3"></path>
                                                <path id="Intersect" fill="#ffffff" d="M8.02126 34.6661c-2.55744 -0.1793 -4.50849 -2.1304 -4.68783 -4.6878 -0.04162 -0.5935 -0.08247 -1.2522 -0.12054 -1.9783H34.7862c-0.0381 0.7261 -0.0789 1.3848 -0.1205 1.9783 -0.1794 2.5574 -2.1304 4.5085 -4.6879 4.6878 -2.4749 0.1736 -6.0853 0.3339 -10.9782 0.3339 -4.893 0 -8.5034 -0.1603 -10.97834 -0.3339Z" stroke-width="3"></path>
                                                <path id="Rectangle 1095" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M40 14.335c0.7646 0.2471 1.4512 0.477 2.0629 0.6878 2.4239 0.8352 3.8035 3.2247 3.3148 5.7414 -0.4729 2.4355 -1.2525 5.9644 -2.5189 10.6906 -1.2664 4.7263 -2.3557 8.1722 -3.1639 10.5178 -0.8351 2.4239 -3.2246 3.8035 -5.7413 3.3148 -2.4356 -0.4729 -5.9644 -1.2525 -10.6907 -2.5189 -4.2426 -1.1368 -7.4534 -2.1309 -9.7629 -2.907" stroke-width="3"></path>
                                                <path id="Vector 906" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M40.125 40.1875 34 38.5463" stroke-width="3"></path>
                                                <path id="Rectangle 1095_2" stroke="#2859c5" stroke-linejoin="round" d="M3.33388 29.9783c0.17934 2.5574 2.13038 4.5085 4.68782 4.6878C10.4966 34.8397 14.107 35 19 35c4.893 0 8.5034 -0.1603 10.9783 -0.3339 2.5574 -0.1793 4.5085 -2.1304 4.6878 -4.6878C34.8397 27.5034 35 23.893 35 19c0 -4.893 -0.1603 -8.5034 -0.3339 -10.9783 -0.1793 -2.55744 -2.1304 -4.50848 -4.6878 -4.68782C27.5034 3.16032 23.893 3 19 3c-4.893 0 -8.5034 0.16032 -10.9783 0.33387 -2.55744 0.17935 -4.50848 2.13039 -4.68782 4.68783C3.16032 10.4966 3 14.107 3 19c0 4.893 0.16032 8.5034 0.33388 10.9783Z" stroke-width="3"></path>
                                                <path id="Vector 906_2" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M3.5 28h31" stroke-width="3"></path>
                                            </g>
                                        </svg>
										<span className="link-text ml-4">Programmatic SEO</span>
									</NavLink>
								</li>)}

							{/* ------------------------- GLOSSARY GENERATOR  ------------------------- */}
							{!isProduction && (
								<li className="mt-2 subcategory-menu pricing-plan">
									<NavLink to={pageURL['glossaryHome']} className={({ isActive }) => isActive ? "is-active" : ""}
										onClick={(e) => {
											checkForUnsavedChanges(e);
											setHamburgerActive(false);
										}}>
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" id="Ascending-Alphabetical-Order--Streamline-Plump" height="48" width="48">
											<g id="ascending-alphabetical-order">
												<path id="Union" fill="#ffffff" d="M39.891 36.405c1.591 0.036 2.772 0.094 3.637 0.156 1.049 0.074 1.506 1.164 0.849 1.985 -2.976 3.719 -5.768 5.755 -7.126 6.617a1.587 1.587 0 0 1 -1.72 0c-1.358 -0.862 -4.15 -2.898 -7.126 -6.617 -0.657 -0.82 -0.2 -1.911 0.849 -1.985 0.865 -0.062 2.046 -0.12 3.637 -0.156V20c0 -8.523 0.152 -13.007 0.255 -15.076 0.048 -0.945 0.612 -1.734 1.551 -1.84 0.439 -0.05 0.995 -0.084 1.694 -0.084s1.255 0.035 1.694 0.084c0.94 0.106 1.504 0.895 1.55 1.84 0.105 2.07 0.256 6.553 0.256 15.076v16.405Z" stroke-width="3"></path>
												<path id="Vector" fill="#8fbffa" d="M16 17.155h-5.37c-0.209 0.7 -0.419 1.295 -0.624 1.902 -0.32 0.946 -1.041 1.75 -2.032 1.87 -1.13 0.139 -2.347 0.047 -3.204 -0.497 -0.777 -0.544 -0.91 -1.472 -0.648 -2.326 1.133 -3.7 3.428 -9.955 4.631 -13.187 0.499 -1.338 1.461 -2.518 2.868 -2.764A10.694 10.694 0 0 1 13.448 2c1.46 0 3.87 0.22 4.468 1.843 1.792 4.737 3.198 9.64 4.61 14.509 0.294 1.009 0 2.114 -1.014 2.388 -1.163 0.313 -2.657 0.351 -3.681 0.071 -0.58 -0.158 -0.929 -0.686 -1.108 -1.258a94.007 94.007 0 0 1 -0.724 -2.398ZM13.511 8a0.127 0.127 0 0 0 -0.122 0.093l-0.946 3.407h2.112l-0.92 -3.406A0.127 0.127 0 0 0 13.511 8Z" stroke-width="3"></path>
												<path id="Vector_2" fill="#8fbffa" d="M7.479 46c-1.33 0 -2.825 -0.36 -3.284 -1.608 -0.292 -0.796 -0.207 -1.564 -0.063 -2.107 0.141 -0.535 0.491 -0.973 0.879 -1.367 2.197 -2.236 8.453 -8.693 8.453 -8.693H6.776c-0.898 0 -1.799 -0.383 -2.145 -1.211a4.444 4.444 0 0 1 -0.357 -1.705c0 -1.468 0.98 -2.309 2.422 -2.309h12.56c1.163 0 2.434 0.25 3.05 1.236 0.646 1.032 0.465 2.079 0.242 2.713 -0.148 0.42 -0.434 0.76 -0.744 1.079 -2.15 2.202 -8.34 8.747 -8.34 8.747h7.044c0.966 0 1.907 0.46 2.21 1.378 0.175 0.533 0.282 1.092 0.282 1.57C23 45.257 22.148 46 20.616 46H7.479Z" stroke-width="3"></path>
												<path id="Vector_3" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M7.479 46c-1.33 0 -2.825 -0.36 -3.284 -1.608 -0.292 -0.796 -0.207 -1.564 -0.063 -2.107 0.141 -0.535 0.491 -0.973 0.879 -1.367 2.197 -2.236 8.453 -8.693 8.453 -8.693H6.776c-0.898 0 -1.799 -0.383 -2.145 -1.211a4.444 4.444 0 0 1 -0.357 -1.705c0 -1.468 0.98 -2.309 2.422 -2.309h12.56c1.163 0 2.434 0.25 3.05 1.236 0.646 1.032 0.465 2.079 0.242 2.713 -0.148 0.42 -0.434 0.76 -0.744 1.079 -2.15 2.202 -8.34 8.747 -8.34 8.747h7.044c0.966 0 1.907 0.46 2.21 1.378 0.175 0.533 0.282 1.092 0.282 1.57C23 45.257 22.148 46 20.616 46H7.479Z" stroke-width="3"></path>
												<path id="Vector_4" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M16 17.155h-5.37c-0.209 0.7 -0.419 1.295 -0.624 1.902 -0.32 0.946 -1.041 1.75 -2.032 1.87 -1.13 0.139 -2.347 0.047 -3.204 -0.497 -0.777 -0.544 -0.91 -1.472 -0.648 -2.326 1.133 -3.7 3.428 -9.955 4.631 -13.187 0.499 -1.338 1.461 -2.518 2.868 -2.764A10.694 10.694 0 0 1 13.448 2c1.46 0 3.87 0.22 4.468 1.843 1.792 4.737 3.198 9.64 4.61 14.509 0.294 1.009 0 2.114 -1.014 2.388 -1.163 0.313 -2.657 0.351 -3.681 0.071 -0.58 -0.158 -0.929 -0.686 -1.108 -1.258a94.007 94.007 0 0 1 -0.724 -2.398ZM13.511 8a0.127 0.127 0 0 0 -0.122 0.093l-0.946 3.407h2.112l-0.92 -3.406A0.127 0.127 0 0 0 13.511 8Z" stroke-width="3"></path>
												<path id="Union_2" fill="#ffffff" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M39.891 36.405c1.591 0.036 2.772 0.094 3.637 0.156 1.049 0.074 1.506 1.164 0.849 1.985 -2.976 3.719 -5.768 5.755 -7.126 6.617a1.587 1.587 0 0 1 -1.72 0c-1.358 -0.862 -4.15 -2.898 -7.126 -6.617 -0.657 -0.82 -0.2 -1.911 0.849 -1.985 0.865 -0.062 2.046 -0.12 3.637 -0.156V20c0 -8.523 0.152 -13.007 0.255 -15.076 0.048 -0.945 0.612 -1.734 1.551 -1.84 0.439 -0.05 0.995 -0.084 1.694 -0.084s1.255 0.035 1.694 0.084c0.94 0.106 1.504 0.895 1.55 1.84 0.105 2.07 0.256 6.553 0.256 15.076v16.405Z" stroke-width="3"></path>
											</g>
										</svg>
										<span className="link-text ml-4">Glossary Generator</span>
									</NavLink>
								</li>
							)}

							{!isProduction && (
								<li className="mt-2 articles subcategory-menu">
									<NavLink to={pageURL['blogFinder']} className={({ isActive }) => isActive ? "is-active" : ""}
										onClick={(e) => {
											checkForUnsavedChanges(e);
											setHamburgerActive(false);
										}}>
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" id="No-Poverty--Streamline-Plump" height="48" width="48">
											<g id="no-poverty"><path id="Ellipse 138" fill="#8fbffa" d="M40 9c0 3.3137 -2.6863 6 -6 6s-6 -2.6863 -6 -6c0 -3.31371 2.6863 -6 6 -6s6 2.68629 6 6Z" stroke-width="3"></path>
												<path id="Ellipse 139" fill="#8fbffa" d="M8 9c0 3.3137 2.6863 6 6 6s6 -2.6863 6 -6c0 -3.31371 -2.6863 -6 -6 -6S8 5.68629 8 9Z" stroke-width="3"></path>
												<path id="Ellipse 140" fill="#ffffff" d="M18 17c0 3.3137 2.6863 6 6 6s6 -2.6863 6 -6 -2.6863 -6 -6 -6 -6 2.6863 -6 6Z" stroke-width="3"></path>
												<path id="Union" fill="#ffffff" d="M28.8556 23.1174c2.0006 0.1073 3.5871 1.5579 3.9378 3.5305 0.3226 1.8145 0.7014 4.2829 0.9782 7.1199 0.1543 1.581 -1.0049 2.9535 -2.5907 3.0454 -0.4581 0.0266 -0.972 0.0527 -1.5418 0.0766l-0.3968 5.374c-0.0979 1.3261 -1.0588 2.4288 -2.3806 2.5738 -0.8188 0.0899 -1.8067 0.1624 -2.8615 0.1624s-2.0426 -0.0725 -2.8614 -0.1624c-1.3218 -0.145 -2.2827 -1.2477 -2.3806 -2.5738l-0.3969 -5.374c-0.5697 -0.0239 -1.0835 -0.05 -1.5416 -0.0766 -1.5858 -0.0918 -2.7448 -1.4644 -2.5906 -3.0453 0.2768 -2.8372 0.6557 -5.3056 0.9783 -7.1202 0.3507 -1.9725 1.937 -3.423 3.9376 -3.5303C20.3802 23.0512 21.986 23 24 23c2.0142 0 3.6202 0.0512 4.8556 0.1174Z" stroke-width="3"></path>
												<path id="Subtract" fill="#8fbffa" d="M18 16.9999c0 3.3137 2.6863 6 6 6 -2.014 0 -3.6201 0.0511 -4.8553 0.1174 -2.0005 0.1073 -3.5868 1.5578 -3.9375 3.5303 -0.3227 1.8146 -0.7015 4.283 -0.9783 7.1202 -0.1543 1.5809 1.0048 2.9534 2.5906 3.0453 0.4581 0.0265 0.9719 0.0526 1.5416 0.0766l0.3968 5.3739c0.0383 0.5174 0.2078 1.0008 0.4789 1.4065 -0.4247 0.311 -0.8519 0.606 -1.2913 0.8424 -0.3522 0.1894 -0.7436 0.3129 -1.1648 0.3546 -0.7753 0.0768 -1.6916 0.1328 -2.7153 0.1328 -0.9933 0 -1.8856 -0.0527 -2.646 -0.126 -1.67643 -0.1615 -2.87699 -1.6146 -3.02305 -3.3647l-0.80879 -9.6905c-0.67975 -0.0378 -1.29041 -0.0947 -1.82856 -0.1603 -1.71001 -0.2085 -2.85272 -1.7751 -2.75295 -3.5684 0.17661 -3.1742 0.59468 -6.1382 0.98739 -8.3852 0.43891 -2.5112 2.4526 -4.3321 4.89592 -4.4611 3.14234 -0.166 5.89324 -0.219 9.44924 -0.2359 -0.2193 0.6233 -0.3386 1.2938 -0.3386 1.9921Z" stroke-width="3"></path>
												<path id="Subtract_2" fill="#8fbffa" d="M29.6614 15.0078c0.2193 0.6233 0.3386 1.2938 0.3386 1.9921 0 3.3137 -2.6863 6 -6 6 2.0142 0 3.62 0.0511 4.8554 0.1174 2.0006 0.1073 3.587 1.5579 3.9377 3.5305 0.3227 1.8145 0.7015 4.2828 0.9783 7.1199 0.1542 1.581 -1.0049 2.9535 -2.5907 3.0454 -0.4581 0.0265 -0.972 0.0526 -1.5418 0.0765l-0.3968 5.3741c-0.0382 0.5173 -0.2078 1.0007 -0.4789 1.4064 0.4247 0.311 0.8519 0.606 1.2913 0.8424 0.3522 0.1894 0.7436 0.3129 1.1648 0.3546 0.7753 0.0768 1.6916 0.1328 2.7153 0.1328 0.9933 0 1.8856 -0.0527 2.646 -0.126 1.6764 -0.1615 2.877 -1.6146 3.023 -3.3647l0.8088 -9.6905c0.6798 -0.0378 1.2905 -0.0947 1.8286 -0.1603 1.71 -0.2085 2.8527 -1.7751 2.7529 -3.5684 -0.1766 -3.1742 -0.5946 -6.1382 -0.9873 -8.3852 -0.439 -2.5112 -2.4526 -4.3321 -4.896 -4.4611 -3.1423 -0.166 -5.8932 -0.219 -9.4492 -0.2359Z" stroke-width="3"></path>
												<path id="Ellipse 135" stroke="#2859c5" stroke-linejoin="round" d="M28 9a6 6 0 1 0 12 0 6 6 0 1 0 -12 0" stroke-width="3"></path><path id="Ellipse 136" stroke="#2859c5" stroke-linejoin="round" d="M20 9A6 6 0 1 1 8 9a6 6 0 1 1 12 0" stroke-width="3"></path><path id="Ellipse 137" fill="#ffffff" stroke="#2859c5" stroke-linejoin="round" d="M30 17a6 6 0 1 1 -12 0 6 6 0 1 1 12 0" stroke-width="3"></path><path id="Subtract_3" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M18.3386 15.0078c-3.556 0.0169 -6.3069 0.0699 -9.44924 0.2359 -2.44332 0.129 -4.45701 1.9499 -4.89592 4.4611 -0.39271 2.247 -0.81078 5.211 -0.98739 8.3852 -0.09977 1.7933 1.04294 3.3599 2.75295 3.5684 0.53815 0.0656 1.14881 0.1225 1.82856 0.1603l0.80879 9.6905c0.14606 1.7501 1.34662 3.2032 3.02305 3.3647 0.7604 0.0733 1.6527 0.126 2.646 0.126 1.0237 0 1.94 -0.056 2.7153 -0.1328 0.9745 -0.0965 1.7893 -0.6308 2.3288 -1.4068" stroke-width="3"></path>
												<path id="Subtract_4" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M28.8906 43.4603c0.5395 0.776 1.3543 1.3104 2.3287 1.4068 0.7753 0.0768 1.6917 0.1328 2.7154 0.1328 0.9933 0 1.8856 -0.0527 2.646 -0.126 1.6764 -0.1615 2.877 -1.6146 3.023 -3.3647l0.8088 -9.6905c0.6798 -0.0378 1.2904 -0.0947 1.8286 -0.1603 1.71 -0.2085 2.8527 -1.7751 2.7529 -3.5684 -0.1766 -3.1742 -0.5946 -6.1382 -0.9873 -8.3852 -0.439 -2.5112 -2.4527 -4.3321 -4.896 -4.4611 -3.1423 -0.166 -5.8933 -0.219 -9.4492 -0.2359" stroke-width="3"></path><path id="Union_2" fill="#ffffff" stroke="#2859c5" stroke-linejoin="round" d="M28.8556 23.1174c2.0006 0.1073 3.5871 1.5579 3.9378 3.5305 0.3226 1.8145 0.7014 4.2829 0.9782 7.1199 0.1543 1.581 -1.0049 2.9535 -2.5907 3.0454 -0.4581 0.0266 -0.972 0.0527 -1.5418 0.0766l-0.3968 5.374c-0.0979 1.3261 -1.0588 2.4288 -2.3806 2.5738 -0.8188 0.0899 -1.8067 0.1624 -2.8615 0.1624s-2.0426 -0.0725 -2.8614 -0.1624c-1.3218 -0.145 -2.2827 -1.2477 -2.3806 -2.5738l-0.3969 -5.374c-0.5697 -0.0239 -1.0835 -0.05 -1.5416 -0.0766 -1.5858 -0.0918 -2.7448 -1.4644 -2.5906 -3.0453 0.2768 -2.8372 0.6557 -5.3056 0.9783 -7.1202 0.3507 -1.9725 1.937 -3.423 3.9376 -3.5303C20.3802 23.0512 21.986 23 24 23c2.0142 0 3.6202 0.0512 4.8556 0.1174Z" stroke-width="3"></path>
											</g>
										</svg>
										<span className="link-text ml-4">Blog Author Finder</span>
									</NavLink>
								</li>
							)}


							{/* ------------------------- GUEST POST FINDER ------------------------- */}
							{!isProduction && (
								<li className="mt-2 articles article-section">
									<div className="article-dropdown" aria-haspopup="true" aria-controls="dropdown-menu" onClick={(e) => {
										checkForUnsavedChanges(e);
										setHamburgerActive(false);
										toggleGuestFinder();
									}} >
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" className="svg-article-icon">
											<g>
												<path id="Vector" fill="#ffffff" d="M24 3c6.074 0 9.586 0.256 11.573 0.51 1.618 0.205 2.926 1.179 3.811 2.549 1.148 1.774 2.802 4.493 4.145 7.338 1.1 2.332 1.47 4.918 1.47 7.497L45 30.5c0 3.865 -0.203 7.058 -0.425 9.4 -0.253 2.654 -2.398 4.619 -5.06 4.754 -3.32 0.168 -8.487 0.346 -15.515 0.346 -7.028 0 -12.195 -0.178 -15.515 -0.346 -2.662 -0.136 -4.808 -2.1 -5.06 -4.754A99.906 99.906 0 0 1 3 30.5l0 -9.606c0 -2.58 0.37 -5.165 1.471 -7.497 1.343 -2.845 2.997 -5.564 4.145 -7.338 0.885 -1.37 2.193 -2.344 3.81 -2.55C14.415 3.256 17.927 3 24 3Z" stroke-width="3"></path>
												<path id="Rectangle 1095" fill="#8fbffa" d="M3.425 39.974c0.245 2.586 2.323 4.485 4.916 4.633 3.314 0.19 8.53 0.393 15.659 0.393 7.13 0 12.345 -0.203 15.659 -0.393 2.593 -0.148 4.671 -2.047 4.916 -4.633 0.222 -2.355 0.425 -5.678 0.425 -9.974s-0.203 -7.619 -0.425 -9.974c-0.245 -2.586 -2.323 -4.485 -4.916 -4.633C36.345 15.203 31.129 15 24 15c-7.13 0 -12.345 0.203 -15.659 0.393 -2.593 0.148 -4.671 2.047 -4.916 4.633C3.203 22.381 3 25.704 3 30s0.203 7.619 0.425 9.974Z" stroke-width="3"></path>
												<path id="Vector_2" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M24 3c6.074 0 9.586 0.256 11.573 0.51 1.618 0.205 2.926 1.179 3.811 2.549 1.148 1.774 2.802 4.493 4.145 7.338 1.1 2.332 1.47 4.918 1.47 7.497L45 30.5c0 3.865 -0.203 7.058 -0.425 9.4 -0.253 2.654 -2.398 4.619 -5.06 4.754 -3.32 0.168 -8.487 0.346 -15.515 0.346 -7.028 0 -12.195 -0.178 -15.515 -0.346 -2.662 -0.136 -4.808 -2.1 -5.06 -4.754A99.906 99.906 0 0 1 3 30.5l0 -9.606c0 -2.58 0.37 -5.165 1.471 -7.497 1.343 -2.845 2.997 -5.564 4.145 -7.338 0.885 -1.37 2.193 -2.344 3.81 -2.55C14.415 3.256 17.927 3 24 3Z" stroke-width="3"></path>
												<path id="Rectangle 1096" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M3.425 39.974c0.245 2.586 2.323 4.485 4.916 4.633 3.314 0.19 8.53 0.393 15.659 0.393 7.13 0 12.345 -0.203 15.659 -0.393 2.593 -0.148 4.671 -2.047 4.916 -4.633 0.222 -2.355 0.425 -5.678 0.425 -9.974s-0.203 -7.619 -0.425 -9.974c-0.245 -2.586 -2.323 -4.485 -4.916 -4.633C36.345 15.203 31.129 15 24 15c-7.13 0 -12.345 0.203 -15.659 0.393 -2.593 0.148 -4.671 2.047 -4.916 4.633C3.203 22.381 3 25.704 3 30s0.203 7.619 0.425 9.974Z" stroke-width="3"></path><path id="Vector 844" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M24 3v12" stroke-width="3"></path>
												<path id="Union" fill="#ffffff" d="M16.744 28.676c-0.971 1.24 -0.296 2.726 1.277 2.818a78.86 78.86 0 0 0 2.59 0.104c0.074 1.83 0.169 3.67 0.238 4.928 0.053 0.98 0.685 1.838 1.655 1.99 0.437 0.067 0.945 0.117 1.497 0.117 0.552 0 1.06 -0.05 1.498 -0.118 0.971 -0.15 1.604 -1.01 1.657 -1.991 0.068 -1.259 0.162 -3.096 0.235 -4.926a77.622 77.622 0 0 0 2.588 -0.104c1.574 -0.092 2.25 -1.578 1.278 -2.818a62.244 62.244 0 0 0 -2.347 -2.804c-1.539 -1.743 -2.716 -2.783 -3.532 -3.396 -0.84 -0.632 -1.914 -0.632 -2.755 0 -0.815 0.613 -1.992 1.654 -3.531 3.396a62.205 62.205 0 0 0 -2.348 2.804Z" stroke-width="3"></path>
												<path id="Union_2" fill="#ffffff" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M16.744 28.676c-0.971 1.24 -0.296 2.726 1.277 2.818a78.86 78.86 0 0 0 2.59 0.104c0.074 1.83 0.169 3.67 0.238 4.928 0.053 0.98 0.685 1.838 1.655 1.99 0.437 0.067 0.945 0.117 1.497 0.117 0.552 0 1.06 -0.05 1.498 -0.118 0.971 -0.15 1.604 -1.01 1.657 -1.991 0.068 -1.259 0.162 -3.096 0.235 -4.926a77.622 77.622 0 0 0 2.588 -0.104c1.574 -0.092 2.25 -1.578 1.278 -2.818a62.244 62.244 0 0 0 -2.347 -2.804c-1.539 -1.743 -2.716 -2.783 -3.532 -3.396 -0.84 -0.632 -1.914 -0.632 -2.755 0 -0.815 0.613 -1.992 1.654 -3.531 3.396a62.205 62.205 0 0 0 -2.348 2.804Z" stroke-width="3"></path>
											</g>
										</svg>
										<span className="article-text ml-3">Guest Post Finder</span>
										<span className="icon is-small drop-down-arrow " style={{ marginLeft: "1rem", }}>
											<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L6 6L11 1" stroke="black" stroke-linecap="round"></path></svg>
										</span>
									</div>

									{isGuestDropdownOpen ?
										<ul >
											{/* ------------------------- CREATE GUEST POST FINDER ------------------------- */}
											<li className="articles subcategory-menu">
												<NavLink to={pageURL['guestPostFinder']} className={({ isActive }) => isActive ? "is-active" : ""}
													onClick={(e) => {
														checkForUnsavedChanges(e);
														setHamburgerActive(false);
													}}>
													<span className="articles-li-text ml-4">Create Guest Post Finder</span>
												</NavLink>
											</li>

											{/* ------------------------- GUEST POST FINDER PROJECTS ------------------------- */}
											<li className="articles subcategory-menu">
												<NavLink to={pageURL['guestPostFinderTable']}
													className={({ isActive }) => isActive ? "is-active" : ""}
													onClick={(e) => {
														checkForUnsavedChanges(e);
														setHamburgerActive(false);
													}}>
													<span className="articles-li-text ml-4">Guest post Finder Projects</span>
												</NavLink>
											</li>
										</ul>
										: ""}
								</li>
							)}

							{/* ------------------------- Reddit Post Finder ------------------------- */}
							{!isProduction && (
								<li className="mt-2 articles article-section">
									<div className="article-dropdown" aria-haspopup="true" aria-controls="dropdown-menu" onClick={toggleRedditFinder} >
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 24 24" height="24" width="24">
											<g>
												<path id="hover" fill="#fff" d="M19.35785416666667 3.6646666666666667c-2.955020833333333 -2.954541666666667 -7.745250000000001 -2.954541666666667 -10.699791666666666 0l-1.141375 1.141375a1.9166666666666667 1.9166666666666667 0 0 0 0 2.7106458333333334l0.8557916666666667 0.8557916666666667a1.9166666666666667 1.9166666666666667 0 0 0 2.7106458333333334 0l1.141375 -1.1408958333333332a2.5218541666666665 2.5218541666666665 0 0 1 3.5664375 3.5664375l-1.141375 1.141375a1.9166666666666667 1.9166666666666667 0 0 0 0 2.7106458333333334l0.8562708333333333 0.8557916666666667a1.9166666666666667 1.9166666666666667 0 0 0 2.7106458333333334 0l1.141375 -1.141375c2.954541666666667 -2.954541666666667 2.954541666666667 -7.745250000000001 0 -10.699791666666666Z" strokeWidth="1"></path>

												<path id="hover" fill="#ffffff" d="M3.6641875 19.357375c-2.954541666666667 -2.954541666666667 -2.954541666666667 -7.745250000000001 0 -10.699791666666666l1.141375 -1.141375a1.9166666666666667 1.9166666666666667 0 0 1 2.7106458333333334 0l0.8557916666666667 0.8562708333333333a1.9166666666666667 1.9166666666666667 0 0 1 0 2.7106458333333334l-1.141375 1.141375a2.5218541666666665 2.5218541666666665 0 0 0 3.5669166666666667 3.5664375l1.141375 -1.141375a1.9166666666666667 1.9166666666666667 0 0 1 2.7106458333333334 0l0.8557916666666667 0.8557916666666667a1.9166666666666667 1.9166666666666667 0 0 1 0 2.7106458333333334l-1.141375 1.141375c-2.954541666666667 2.954541666666667 -7.745250000000001 2.954541666666667 -10.699791666666666 0Z" strokeWidth="1"></path>
												<path id="Rectangle 1673" fill="#ffffff" d="M7.230625 15.790458333333333a2.5218541666666665 2.5218541666666665 0 0 1 0 -3.5664375l4.993875 -4.992916666666667a2.5218541666666665 2.5218541666666665 0 0 1 3.5664375 3.5664375l-4.992916666666667 4.992916666666667a2.5218541666666665 2.5218541666666665 0 0 1 -3.5669166666666667 0Z" strokeWidth="1"></path><path id="Rectangle 1672" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M7.231104166666666 15.790458333333333a2.5218541666666665 2.5218541666666665 0 0 1 0 -3.5664375l4.992916666666667 -4.992916666666667a2.5218541666666665 2.5218541666666665 0 0 1 3.5669166666666667 3.5664375l-4.992916666666667 4.992916666666667a2.5218541666666665 2.5218541666666665 0 0 1 -3.5669166666666667 0Z" strokeWidth="1"></path>
												<path id="Union_3" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M3.6641875 19.357375c-2.954541666666667 -2.954541666666667 -2.954541666666667 -7.745250000000001 0 -10.699791666666666l1.141375 -1.141375a1.9166666666666667 1.9166666666666667 0 0 1 2.7106458333333334 0l0.8557916666666667 0.8562708333333333a1.9166666666666667 1.9166666666666667 0 0 1 0 2.7106458333333334l-1.141375 1.141375a2.5218541666666665 2.5218541666666665 0 0 0 3.5669166666666667 3.5664375l1.141375 -1.141375a1.9166666666666667 1.9166666666666667 0 0 1 2.7106458333333334 0l0.8557916666666667 0.8557916666666667a1.9166666666666667 1.9166666666666667 0 0 1 0 2.7106458333333334l-1.141375 1.141375c-2.954541666666667 2.954541666666667 -7.745250000000001 2.954541666666667 -10.699791666666666 0Z" strokeWidth="1"></path>
												<path id="Union_4" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M19.35785416666667 3.6646666666666667c-2.955020833333333 -2.954541666666667 -7.745250000000001 -2.954541666666667 -10.699791666666666 0l-1.141375 1.141375a1.9166666666666667 1.9166666666666667 0 0 0 0 2.7106458333333334l0.8557916666666667 0.8557916666666667a1.9166666666666667 1.9166666666666667 0 0 0 2.7106458333333334 0l1.141375 -1.1408958333333332a2.5218541666666665 2.5218541666666665 0 0 1 3.5664375 3.5664375l-1.141375 1.141375a1.9166666666666667 1.9166666666666667 0 0 0 0 2.7106458333333334l0.8562708333333333 0.8557916666666667a1.9166666666666667 1.9166666666666667 0 0 0 2.7106458333333334 0l1.141375 -1.141375c2.954541666666667 -2.954541666666667 2.954541666666667 -7.745250000000001 0 -10.699791666666666Z" strokeWidth="1">
												</path>
											</g>
										</svg>
										<span className="article-text ml-3">Reddit Post Finder</span>
										<span className="icon is-small drop-down-arrow " style={{ marginLeft: "1rem", }}>
											<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L6 6L11 1" stroke="black" stroke-linecap="round"></path></svg>
										</span>
									</div>

									{isRedditDropdownOpen ?
										<ul >
											{/* ------------------------- CREATE REDDIT POST FINDER ------------------------- */}
											<li className="articles subcategory-menu">
												<NavLink to={pageURL['redditPostFinder']} className={({ isActive }) => isActive ? "is-active" : ""}
													onClick={(e) => {
														checkForUnsavedChanges(e);
														setHamburgerActive(false);
													}}>
													<span className="articles-li-text ml-4">Create Reddit Post Finder</span>
												</NavLink>
											</li>

											{/* ------------------------- REDDIT POST FINDER PROJECTS ------------------------- */}
											<li className="articles subcategory-menu">
												<NavLink to={pageURL['redditPostFinderTable']}
													className={({ isActive }) => isActive ? "is-active" : ""}
													onClick={(e) => {
														checkForUnsavedChanges(e);
														setHamburgerActive(false);
													}}>
													<span className="articles-li-text ml-4">Reddit post Finder Projects</span>
												</NavLink>
											</li>
										</ul>
										: ""}
								</li>
							)}

							{/* ------------------------- Internal Links ------------------------- */}
							<li className="mt-2 indexation category-menu">
								<NavLink to={pageURL['internalLinks']}
									className={({ isActive }) => isActive ? "is-active" : ""}
									onClick={(e) => {
										checkForUnsavedChanges(e);
										setHamburgerActive(false);
									}}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
										<g>
											<path id="Rectangle 65" fill="#8fbffa" d="M42.248 44.962c1.438 -0.055 2.534 -0.986 2.657 -2.42A29.99 29.99 0 0 0 45 40c0 -1.05 -0.04 -1.884 -0.096 -2.542 -0.122 -1.434 -1.218 -2.365 -2.656 -2.42A59.122 59.122 0 0 0 40 35c-0.914 0 -1.653 0.015 -2.248 0.038 -1.438 0.055 -2.534 0.986 -2.657 2.42A29.99 29.99 0 0 0 35 40c0 1.05 0.04 1.884 0.096 2.542 0.122 1.434 1.218 2.365 2.656 2.42A58.69 58.69 0 0 0 40 45c0.914 0 1.653 -0.015 2.248 -0.038Z" strokeWidth="3"></path>
											<path id="hover" fill="#ffffff" d="M42.248 28.962c1.438 -0.055 2.534 -0.986 2.657 -2.42A29.99 29.99 0 0 0 45 24c0 -1.05 -0.04 -1.884 -0.096 -2.542 -0.122 -1.434 -1.218 -2.365 -2.656 -2.42A59.122 59.122 0 0 0 40 19c-0.914 0 -1.653 0.015 -2.248 0.038 -1.438 0.055 -2.534 0.986 -2.657 2.42A29.99 29.99 0 0 0 35 24c0 1.05 0.04 1.884 0.096 2.542 0.122 1.434 1.218 2.365 2.656 2.42A58.69 58.69 0 0 0 40 29c0.914 0 1.653 -0.015 2.248 -0.038Z" strokeWidth="3"></path>
											<path id="Rectangle 63" fill="#8fbffa" d="M42.248 12.962c1.438 -0.055 2.534 -0.986 2.657 -2.42A29.99 29.99 0 0 0 45 8c0 -1.05 -0.04 -1.884 -0.096 -2.542 -0.122 -1.434 -1.218 -2.365 -2.656 -2.42A59.155 59.155 0 0 0 40 3c-0.914 0 -1.653 0.015 -2.248 0.038 -1.438 0.055 -2.534 0.986 -2.657 2.42A29.988 29.988 0 0 0 35 8c0 1.05 0.04 1.884 0.096 2.542 0.122 1.434 1.218 2.365 2.656 2.42A58.69 58.69 0 0 0 40 13c0.914 0 1.653 -0.015 2.248 -0.038Z" strokeWidth="3"></path>
											<path id="hover" fill="#ffffff" d="M19.305 33.831c1.723 -0.114 3.06 -1.236 3.303 -2.945 0.21 -1.475 0.392 -3.68 0.392 -6.886s-0.183 -5.411 -0.392 -6.886c-0.242 -1.709 -1.58 -2.83 -3.303 -2.945C17.923 14.077 15.901 14 13 14c-2.9 0 -4.923 0.077 -6.305 0.169 -1.723 0.114 -3.06 1.236 -3.303 2.945C3.182 18.59 3 20.794 3 24s0.183 5.411 0.392 6.886c0.243 1.709 1.58 2.83 3.303 2.945C8.077 33.923 10.099 34 13 34c2.9 0 4.923 -0.077 6.305 -0.169Z" strokeWidth="3"></path>
											<path id="Vector 978" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="m23 24 12 0" strokeWidth="3"></path>
											<path id="Rectangle 56" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M19.305 33.831c1.723 -0.114 3.06 -1.236 3.303 -2.945 0.21 -1.475 0.392 -3.68 0.392 -6.886s-0.183 -5.411 -0.392 -6.886c-0.242 -1.709 -1.58 -2.83 -3.303 -2.945C17.923 14.077 15.901 14 13 14c-2.9 0 -4.923 0.077 -6.305 0.169 -1.723 0.114 -3.06 1.236 -3.303 2.945C3.182 18.59 3 20.794 3 24s0.183 5.411 0.392 6.886c0.243 1.709 1.58 2.83 3.303 2.945C8.077 33.923 10.099 34 13 34c2.9 0 4.923 -0.077 6.305 -0.169Z" strokeWidth="3"></path>
											<path id="Rectangle 58" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M42.248 12.962c1.438 -0.055 2.534 -0.986 2.657 -2.42A29.99 29.99 0 0 0 45 8c0 -1.05 -0.04 -1.884 -0.096 -2.542 -0.122 -1.434 -1.218 -2.365 -2.656 -2.42A59.155 59.155 0 0 0 40 3c-0.914 0 -1.653 0.015 -2.248 0.038 -1.438 0.055 -2.534 0.986 -2.657 2.42A29.988 29.988 0 0 0 35 8c0 1.05 0.04 1.884 0.096 2.542 0.122 1.434 1.218 2.365 2.656 2.42A58.69 58.69 0 0 0 40 13c0.914 0 1.653 -0.015 2.248 -0.038Z" strokeWidth="3"></path>
											<path id="Rectangle 60" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M42.248 28.962c1.438 -0.055 2.534 -0.986 2.657 -2.42A29.99 29.99 0 0 0 45 24c0 -1.05 -0.04 -1.884 -0.096 -2.542 -0.122 -1.434 -1.218 -2.365 -2.656 -2.42A59.122 59.122 0 0 0 40 19c-0.914 0 -1.653 0.015 -2.248 0.038 -1.438 0.055 -2.534 0.986 -2.657 2.42A29.99 29.99 0 0 0 35 24c0 1.05 0.04 1.884 0.096 2.542 0.122 1.434 1.218 2.365 2.656 2.42A58.69 58.69 0 0 0 40 29c0.914 0 1.653 -0.015 2.248 -0.038Z" strokeWidth="3"></path>
											<path id="Rectangle 61" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M42.248 44.962c1.438 -0.055 2.534 -0.986 2.657 -2.42A29.99 29.99 0 0 0 45 40c0 -1.05 -0.04 -1.884 -0.096 -2.542 -0.122 -1.434 -1.218 -2.365 -2.656 -2.42A59.122 59.122 0 0 0 40 35c-0.914 0 -1.653 0.015 -2.248 0.038 -1.438 0.055 -2.534 0.986 -2.657 2.42A29.99 29.99 0 0 0 35 40c0 1.05 0.04 1.884 0.096 2.542 0.122 1.434 1.218 2.365 2.656 2.42A58.69 58.69 0 0 0 40 45c0.914 0 1.653 -0.015 2.248 -0.038Z" strokeWidth="3"></path>
											<path id="Subtract" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="m35.002 39.546 -2.364 -0.215A4 4 0 0 1 29 35.347l0 -22.694a4 4 0 0 1 3.638 -3.984l2.364 -0.214" strokeWidth="3"></path>
										</g>
									</svg>
									<span className="link-text ml-4">Internal Links</span>
								</NavLink>
							</li>

							{/* ------------------------- Free BackLinks ------------------------- */}
							<li className="mt-2 indexation category-menu">
								<NavLink to={"/backlinks"} className={({ isActive }) => isActive ? "is-active" : ""}
									onClick={(e) => {
										checkForUnsavedChanges(e);
										setHamburgerActive(false);
									}}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 24 24" height="24" width="24">
										<g>
											<path id="hover" fill="#fff" d="M19.35785416666667 3.6646666666666667c-2.955020833333333 -2.954541666666667 -7.745250000000001 -2.954541666666667 -10.699791666666666 0l-1.141375 1.141375a1.9166666666666667 1.9166666666666667 0 0 0 0 2.7106458333333334l0.8557916666666667 0.8557916666666667a1.9166666666666667 1.9166666666666667 0 0 0 2.7106458333333334 0l1.141375 -1.1408958333333332a2.5218541666666665 2.5218541666666665 0 0 1 3.5664375 3.5664375l-1.141375 1.141375a1.9166666666666667 1.9166666666666667 0 0 0 0 2.7106458333333334l0.8562708333333333 0.8557916666666667a1.9166666666666667 1.9166666666666667 0 0 0 2.7106458333333334 0l1.141375 -1.141375c2.954541666666667 -2.954541666666667 2.954541666666667 -7.745250000000001 0 -10.699791666666666Z" strokeWidth="1"></path>
											<path id="hover" fill="#ffffff" d="M3.6641875 19.357375c-2.954541666666667 -2.954541666666667 -2.954541666666667 -7.745250000000001 0 -10.699791666666666l1.141375 -1.141375a1.9166666666666667 1.9166666666666667 0 0 1 2.7106458333333334 0l0.8557916666666667 0.8562708333333333a1.9166666666666667 1.9166666666666667 0 0 1 0 2.7106458333333334l-1.141375 1.141375a2.5218541666666665 2.5218541666666665 0 0 0 3.5669166666666667 3.5664375l1.141375 -1.141375a1.9166666666666667 1.9166666666666667 0 0 1 2.7106458333333334 0l0.8557916666666667 0.8557916666666667a1.9166666666666667 1.9166666666666667 0 0 1 0 2.7106458333333334l-1.141375 1.141375c-2.954541666666667 2.954541666666667 -7.745250000000001 2.954541666666667 -10.699791666666666 0Z" strokeWidth="1"></path>
											<path id="Rectangle 1673" fill="#ffffff" d="M7.230625 15.790458333333333a2.5218541666666665 2.5218541666666665 0 0 1 0 -3.5664375l4.993875 -4.992916666666667a2.5218541666666665 2.5218541666666665 0 0 1 3.5664375 3.5664375l-4.992916666666667 4.992916666666667a2.5218541666666665 2.5218541666666665 0 0 1 -3.5669166666666667 0Z" strokeWidth="1"></path><path id="Rectangle 1672" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M7.231104166666666 15.790458333333333a2.5218541666666665 2.5218541666666665 0 0 1 0 -3.5664375l4.992916666666667 -4.992916666666667a2.5218541666666665 2.5218541666666665 0 0 1 3.5669166666666667 3.5664375l-4.992916666666667 4.992916666666667a2.5218541666666665 2.5218541666666665 0 0 1 -3.5669166666666667 0Z" strokeWidth="1"></path>
											<path id="Union_3" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M3.6641875 19.357375c-2.954541666666667 -2.954541666666667 -2.954541666666667 -7.745250000000001 0 -10.699791666666666l1.141375 -1.141375a1.9166666666666667 1.9166666666666667 0 0 1 2.7106458333333334 0l0.8557916666666667 0.8562708333333333a1.9166666666666667 1.9166666666666667 0 0 1 0 2.7106458333333334l-1.141375 1.141375a2.5218541666666665 2.5218541666666665 0 0 0 3.5669166666666667 3.5664375l1.141375 -1.141375a1.9166666666666667 1.9166666666666667 0 0 1 2.7106458333333334 0l0.8557916666666667 0.8557916666666667a1.9166666666666667 1.9166666666666667 0 0 1 0 2.7106458333333334l-1.141375 1.141375c-2.954541666666667 2.954541666666667 -7.745250000000001 2.954541666666667 -10.699791666666666 0Z" strokeWidth="1"></path>
											<path id="Union_4" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M19.35785416666667 3.6646666666666667c-2.955020833333333 -2.954541666666667 -7.745250000000001 -2.954541666666667 -10.699791666666666 0l-1.141375 1.141375a1.9166666666666667 1.9166666666666667 0 0 0 0 2.7106458333333334l0.8557916666666667 0.8557916666666667a1.9166666666666667 1.9166666666666667 0 0 0 2.7106458333333334 0l1.141375 -1.1408958333333332a2.5218541666666665 2.5218541666666665 0 0 1 3.5664375 3.5664375l-1.141375 1.141375a1.9166666666666667 1.9166666666666667 0 0 0 0 2.7106458333333334l0.8562708333333333 0.8557916666666667a1.9166666666666667 1.9166666666666667 0 0 0 2.7106458333333334 0l1.141375 -1.141375c2.954541666666667 -2.954541666666667 2.954541666666667 -7.745250000000001 0 -10.699791666666666Z" strokeWidth="1">
											</path>
										</g>
									</svg>
									<span className="link-text ml-4">Free Backlinks</span>
								</NavLink>
							</li>

							{/* ------------------------- GSC Analytics ------------------------- */}
							{!isProduction && (
								<li className="mt-2 subcategory-menu pricing-plan">
									{props.currentPlanName === "Trial" ?
										<NavLink to={pageURL['manageSubscription']} className={"upgrade"}
											onClick={(e) => {
												checkForUnsavedChanges(e);
												setHamburgerActive(false);
											}}>
											<div className="category">
												<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" id="Content-Statistic--Streamline-Plump" height="48" width="48">
													<g id="content-statistic--product-data-analysis-analytics-graph-line-business-board-chart">
														<path id="Rectangle 1097" fill="#8fbffa" d="M39.743 44.461c2.555 -0.208 4.51 -2.163 4.718 -4.718C44.723 36.515 45 31.364 45 24c0 -7.364 -0.277 -12.515 -0.539 -15.743 -0.208 -2.555 -2.163 -4.51 -4.718 -4.718C36.515 3.277 31.364 3 24 3c-7.364 0 -12.515 0.277 -15.743 0.539 -2.555 0.208 -4.51 2.163 -4.718 4.718C3.277 11.485 3 16.636 3 24c0 7.364 0.277 12.515 0.539 15.743 0.208 2.555 2.163 4.51 4.718 4.718C11.485 44.723 16.636 45 24 45c7.364 0 12.515 -0.277 15.743 -0.539Z" stroke-width="3"></path>
														<path id="Vector 79 (Stroke)" fill="#ffffff" fill-rule="evenodd" d="M31.659 34.651a3.718 3.718 0 0 1 -4.973 0.525 180.782 180.782 0 0 1 -4.941 -3.73 0.98 0.98 0 0 0 -1.402 0.184c-1.126 1.552 -2.378 3.507 -3.384 5.137 -1.174 1.9 -3.487 2.876 -5.345 1.636a12.006 12.006 0 0 1 -1.323 -1.021c-1.577 -1.395 -1.414 -3.735 -0.047 -5.335 2.15 -2.517 4.727 -5.362 7.225 -7.79 1.458 -1.417 3.752 -1.366 5.274 -0.017 1.521 1.348 3.428 2.987 4.957 4.138a0.952 0.952 0 0 0 1.274 -0.116c1.721 -1.853 4.116 -4.704 5.619 -6.52 0.916 -1.108 2.286 -1.858 3.51 -1.104 0.29 0.18 0.571 0.387 0.824 0.61 0.947 0.838 0.778 2.193 0.143 3.286 -1.212 2.087 -3.635 5.874 -7.411 10.117Z" clip-rule="evenodd" stroke-width="3"></path>
														<path id="Rectangle 1096" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M39.743 44.461c2.555 -0.208 4.51 -2.163 4.718 -4.718C44.723 36.515 45 31.364 45 24c0 -7.364 -0.277 -12.515 -0.539 -15.743 -0.208 -2.555 -2.163 -4.51 -4.718 -4.718C36.515 3.277 31.364 3 24 3c-7.364 0 -12.515 0.277 -15.743 0.539 -2.555 0.208 -4.51 2.163 -4.718 4.718C3.277 11.485 3 16.636 3 24c0 7.364 0.277 12.515 0.539 15.743 0.208 2.555 2.163 4.51 4.718 4.718C11.485 44.723 16.636 45 24 45c7.364 0 12.515 -0.277 15.743 -0.539Z" stroke-width="3"></path>
														<path id="Vector 79 (Stroke)_2" fill-rule="evenodd" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M31.66 34.652a3.718 3.718 0 0 1 -4.974 0.524 180.782 180.782 0 0 1 -4.94 -3.73 0.98 0.98 0 0 0 -1.402 0.184c-1.127 1.552 -2.378 3.507 -3.385 5.137 -1.173 1.9 -3.487 2.876 -5.344 1.636a11.934 11.934 0 0 1 -1.324 -1.021c-1.576 -1.395 -1.414 -3.735 -0.046 -5.335 2.15 -2.517 4.727 -5.362 7.224 -7.79 1.459 -1.417 3.752 -1.366 5.274 -0.017 1.522 1.348 3.428 2.987 4.958 4.138a0.952 0.952 0 0 0 1.274 -0.116c1.72 -1.853 4.115 -4.704 5.618 -6.52 0.917 -1.107 2.286 -1.858 3.51 -1.104 0.29 0.18 0.572 0.387 0.824 0.61 0.947 0.838 0.778 2.193 0.143 3.286 -1.212 2.087 -3.635 5.874 -7.41 10.118Z" clip-rule="evenodd" stroke-width="3"></path>
														<path id="Vector 1016" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M11 11h10" stroke-width="3"></path><path id="Vector 1017" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M11 17h6" stroke-width="3"></path>
													</g>
												</svg>
												<span className="link-text ml-4">
													GSC Analytics
												</span>
											</div>
											<span className="uncollapsed-tag tag is-info is-light is-rounded">
												Upgrade ⚡
											</span>
										</NavLink>
										:
										<NavLink to={pageURL['GSCAnalytics']} className={({ isActive }) => isActive ? "is-active" : ""}
											onClick={(e) => {
												checkForUnsavedChanges(e);
												setHamburgerActive(false);
											}}>
											<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" id="Content-Statistic--Streamline-Plump" height="48" width="48">
												<g id="content-statistic--product-data-analysis-analytics-graph-line-business-board-chart">
													<path id="Rectangle 1097" fill="#8fbffa" d="M39.743 44.461c2.555 -0.208 4.51 -2.163 4.718 -4.718C44.723 36.515 45 31.364 45 24c0 -7.364 -0.277 -12.515 -0.539 -15.743 -0.208 -2.555 -2.163 -4.51 -4.718 -4.718C36.515 3.277 31.364 3 24 3c-7.364 0 -12.515 0.277 -15.743 0.539 -2.555 0.208 -4.51 2.163 -4.718 4.718C3.277 11.485 3 16.636 3 24c0 7.364 0.277 12.515 0.539 15.743 0.208 2.555 2.163 4.51 4.718 4.718C11.485 44.723 16.636 45 24 45c7.364 0 12.515 -0.277 15.743 -0.539Z" stroke-width="3"></path>
													<path id="Vector 79 (Stroke)" fill="#ffffff" fill-rule="evenodd" d="M31.659 34.651a3.718 3.718 0 0 1 -4.973 0.525 180.782 180.782 0 0 1 -4.941 -3.73 0.98 0.98 0 0 0 -1.402 0.184c-1.126 1.552 -2.378 3.507 -3.384 5.137 -1.174 1.9 -3.487 2.876 -5.345 1.636a12.006 12.006 0 0 1 -1.323 -1.021c-1.577 -1.395 -1.414 -3.735 -0.047 -5.335 2.15 -2.517 4.727 -5.362 7.225 -7.79 1.458 -1.417 3.752 -1.366 5.274 -0.017 1.521 1.348 3.428 2.987 4.957 4.138a0.952 0.952 0 0 0 1.274 -0.116c1.721 -1.853 4.116 -4.704 5.619 -6.52 0.916 -1.108 2.286 -1.858 3.51 -1.104 0.29 0.18 0.571 0.387 0.824 0.61 0.947 0.838 0.778 2.193 0.143 3.286 -1.212 2.087 -3.635 5.874 -7.411 10.117Z" clip-rule="evenodd" stroke-width="3"></path>
													<path id="Rectangle 1096" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M39.743 44.461c2.555 -0.208 4.51 -2.163 4.718 -4.718C44.723 36.515 45 31.364 45 24c0 -7.364 -0.277 -12.515 -0.539 -15.743 -0.208 -2.555 -2.163 -4.51 -4.718 -4.718C36.515 3.277 31.364 3 24 3c-7.364 0 -12.515 0.277 -15.743 0.539 -2.555 0.208 -4.51 2.163 -4.718 4.718C3.277 11.485 3 16.636 3 24c0 7.364 0.277 12.515 0.539 15.743 0.208 2.555 2.163 4.51 4.718 4.718C11.485 44.723 16.636 45 24 45c7.364 0 12.515 -0.277 15.743 -0.539Z" stroke-width="3"></path>
													<path id="Vector 79 (Stroke)_2" fill-rule="evenodd" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M31.66 34.652a3.718 3.718 0 0 1 -4.974 0.524 180.782 180.782 0 0 1 -4.94 -3.73 0.98 0.98 0 0 0 -1.402 0.184c-1.127 1.552 -2.378 3.507 -3.385 5.137 -1.173 1.9 -3.487 2.876 -5.344 1.636a11.934 11.934 0 0 1 -1.324 -1.021c-1.576 -1.395 -1.414 -3.735 -0.046 -5.335 2.15 -2.517 4.727 -5.362 7.224 -7.79 1.459 -1.417 3.752 -1.366 5.274 -0.017 1.522 1.348 3.428 2.987 4.958 4.138a0.952 0.952 0 0 0 1.274 -0.116c1.72 -1.853 4.115 -4.704 5.618 -6.52 0.917 -1.107 2.286 -1.858 3.51 -1.104 0.29 0.18 0.572 0.387 0.824 0.61 0.947 0.838 0.778 2.193 0.143 3.286 -1.212 2.087 -3.635 5.874 -7.41 10.118Z" clip-rule="evenodd" stroke-width="3"></path>
													<path id="Vector 1016" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M11 11h10" stroke-width="3"></path><path id="Vector 1017" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M11 17h6" stroke-width="3"></path>
												</g>
											</svg>
											<span className="link-text ml-4">GSC Analytics</span>
										</NavLink>
									}
								</li>
							)}

							{/* ------------------------- INTEGRATIONS ------------------------- */}
							<li className={"mt-2 indexation category-menu"}>
								{props.currentPlanName === "Trial" ?
									<NavLink to={pageURL['manageSubscription']} className={"upgrade"}
										onClick={(e) => {
											checkForUnsavedChanges(e);
											setHamburgerActive(false);
										}}>
										<div className="category">
											<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
												<g>
													<path id="Subtract" fill="#ffffff" d="M20.376 37.884c0.193 2.371 0.413 4.547 0.539 5.74 0.064 0.606 0.425 1.119 1.024 1.225 0.463 0.082 1.128 0.15 2.061 0.15s1.599 -0.068 2.06 -0.15c0.6 -0.106 0.961 -0.62 1.025 -1.225 0.126 -1.193 0.346 -3.369 0.54 -5.74a61.832 61.832 0 0 1 -3.625 0.115c-1.248 0 -2.497 -0.048 -3.624 -0.115Z" strokeWidth="3" />
													<path id="hover" fill="#fff" d="M39.056 16.6c1.565 0.176 2.703 1.401 2.822 2.971 0.136 1.796 0.216 4.243 -0.08 6.366a2.956 2.956 0 0 1 -0.969 1.806c-1.23 1.093 -3.931 3.217 -8.238 5.179a198.938 198.938 0 0 1 -0.382 3.084 1.905 1.905 0 0 1 -1.71 1.655C28.973 37.807 26.487 38 24 38c-2.486 0 -4.973 -0.193 -6.499 -0.34a1.905 1.905 0 0 1 -1.71 -1.654c-0.099 -0.747 -0.229 -1.775 -0.382 -3.084 -4.307 -1.962 -7.009 -4.086 -8.238 -5.179a2.956 2.956 0 0 1 -0.968 -1.806c-0.297 -2.123 -0.217 -4.57 -0.081 -6.366 0.119 -1.57 1.257 -2.795 2.822 -2.971 2.478 -0.28 7.069 -0.6 15.056 -0.6 7.987 0 12.578 0.322 15.056 0.6Z" strokeWidth="3" />
													<path id="Subtract_2" fill="#ffffff" d="M29.144 5.742c0.066 -1.435 0.864 -2.658 2.299 -2.729a11.322 11.322 0 0 1 1.114 0c1.435 0.07 2.233 1.294 2.299 2.73C34.929 7.325 35 9.916 35 14c0 0.814 -0.003 1.57 -0.008 2.269a157.085 157.085 0 0 0 -5.986 -0.222C29.002 15.41 29 14.729 29 14c0 -4.083 0.071 -6.674 0.144 -8.258Z" strokeWidth="3" />
													<path id="Subtract_3" fill="#ffffff" d="M18.856 5.742c-0.066 -1.435 -0.864 -2.658 -2.299 -2.729a11.322 11.322 0 0 0 -1.114 0c-1.435 0.07 -2.233 1.294 -2.299 2.73C13.071 7.325 13 9.916 13 14c0 0.814 0.003 1.57 0.008 2.269 1.615 -0.094 3.589 -0.174 5.986 -0.222 0.004 -0.637 0.006 -1.318 0.006 -2.047 0 -4.083 -0.071 -6.674 -0.144 -8.258Z" strokeWidth="3" />
													<path id="Union_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M39.056 16.6c1.565 0.176 2.703 1.401 2.822 2.971 0.136 1.796 0.216 4.243 -0.08 6.366a2.956 2.956 0 0 1 -0.969 1.806c-1.23 1.093 -3.931 3.217 -8.238 5.179a198.938 198.938 0 0 1 -0.382 3.084 1.905 1.905 0 0 1 -1.71 1.655C28.973 37.807 26.487 38 24 38c-2.486 0 -4.973 -0.193 -6.499 -0.34a1.905 1.905 0 0 1 -1.71 -1.654c-0.099 -0.747 -0.229 -1.775 -0.382 -3.084 -4.307 -1.962 -7.009 -4.086 -8.238 -5.179a2.956 2.956 0 0 1 -0.968 -1.806c-0.297 -2.123 -0.217 -4.57 -0.081 -6.366 0.119 -1.57 1.257 -2.795 2.822 -2.971 2.478 -0.28 7.069 -0.6 15.056 -0.6 7.987 0 12.578 0.322 15.056 0.6Z" strokeWidth="3" />
													<path id="Subtract_4" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M13.008 16.269C13.003 15.569 13 14.814 13 14c0 -4.083 0.071 -6.674 0.144 -8.258 0.066 -1.435 0.864 -2.658 2.299 -2.729a11.322 11.322 0 0 1 1.114 0c1.436 0.07 2.233 1.294 2.299 2.73C18.929 7.325 19 9.916 19 14c0 0.729 -0.002 1.41 -0.006 2.047" strokeWidth="3" />
													<path id="Subtract_5" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M29.006 16.047C29.002 15.41 29 14.729 29 14c0 -4.083 0.071 -6.674 0.144 -8.258 0.066 -1.435 0.864 -2.658 2.299 -2.729a11.322 11.322 0 0 1 1.114 0c1.435 0.07 2.233 1.294 2.299 2.73C34.929 7.325 35 9.916 35 14c0 0.814 -0.003 1.57 -0.008 2.269" strokeWidth="3" />
													<path id="Subtract_6" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M20.375 37.885c0.193 2.371 0.413 4.547 0.539 5.74 0.064 0.606 0.425 1.119 1.024 1.225 0.463 0.082 1.128 0.15 2.061 0.15s1.599 -0.068 2.06 -0.15c0.6 -0.106 0.961 -0.62 1.025 -1.225 0.126 -1.193 0.346 -3.369 0.54 -5.74" strokeWidth="3" />
												</g>
											</svg>
											<span className="link-text ml-4">
												Integrations
											</span>
										</div>
										<span className="uncollapsed-tag tag is-info is-light is-rounded">
											Upgrade ⚡
										</span>
									</NavLink>
									:
									<NavLink
										to={props.websiteList.length === 0 ? pageURL['connectWebsite'] : pageURL['new-integration']}
										className={({ isActive }) => isActive ? "is-active" : ""}
										onClick={(e) => {
											checkForUnsavedChanges(e);
											setHamburgerActive(false);
											let interval = setInterval(() => {
												const integrationTab = document.getElementById("integration-tab");
												if (integrationTab) {
													integrationTab.click();
													clearInterval(interval);
												}
											}, 500);
										}}>
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
											<g>
												<path id="Subtract" fill="#ffffff" d="M20.376 37.884c0.193 2.371 0.413 4.547 0.539 5.74 0.064 0.606 0.425 1.119 1.024 1.225 0.463 0.082 1.128 0.15 2.061 0.15s1.599 -0.068 2.06 -0.15c0.6 -0.106 0.961 -0.62 1.025 -1.225 0.126 -1.193 0.346 -3.369 0.54 -5.74a61.832 61.832 0 0 1 -3.625 0.115c-1.248 0 -2.497 -0.048 -3.624 -0.115Z" strokeWidth="3" />
												<path id="hover" fill="#fff" d="M39.056 16.6c1.565 0.176 2.703 1.401 2.822 2.971 0.136 1.796 0.216 4.243 -0.08 6.366a2.956 2.956 0 0 1 -0.969 1.806c-1.23 1.093 -3.931 3.217 -8.238 5.179a198.938 198.938 0 0 1 -0.382 3.084 1.905 1.905 0 0 1 -1.71 1.655C28.973 37.807 26.487 38 24 38c-2.486 0 -4.973 -0.193 -6.499 -0.34a1.905 1.905 0 0 1 -1.71 -1.654c-0.099 -0.747 -0.229 -1.775 -0.382 -3.084 -4.307 -1.962 -7.009 -4.086 -8.238 -5.179a2.956 2.956 0 0 1 -0.968 -1.806c-0.297 -2.123 -0.217 -4.57 -0.081 -6.366 0.119 -1.57 1.257 -2.795 2.822 -2.971 2.478 -0.28 7.069 -0.6 15.056 -0.6 7.987 0 12.578 0.322 15.056 0.6Z" strokeWidth="3" />
												<path id="Subtract_2" fill="#ffffff" d="M29.144 5.742c0.066 -1.435 0.864 -2.658 2.299 -2.729a11.322 11.322 0 0 1 1.114 0c1.435 0.07 2.233 1.294 2.299 2.73C34.929 7.325 35 9.916 35 14c0 0.814 -0.003 1.57 -0.008 2.269a157.085 157.085 0 0 0 -5.986 -0.222C29.002 15.41 29 14.729 29 14c0 -4.083 0.071 -6.674 0.144 -8.258Z" strokeWidth="3" />
												<path id="Subtract_3" fill="#ffffff" d="M18.856 5.742c-0.066 -1.435 -0.864 -2.658 -2.299 -2.729a11.322 11.322 0 0 0 -1.114 0c-1.435 0.07 -2.233 1.294 -2.299 2.73C13.071 7.325 13 9.916 13 14c0 0.814 0.003 1.57 0.008 2.269 1.615 -0.094 3.589 -0.174 5.986 -0.222 0.004 -0.637 0.006 -1.318 0.006 -2.047 0 -4.083 -0.071 -6.674 -0.144 -8.258Z" strokeWidth="3" />
												<path id="Union_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M39.056 16.6c1.565 0.176 2.703 1.401 2.822 2.971 0.136 1.796 0.216 4.243 -0.08 6.366a2.956 2.956 0 0 1 -0.969 1.806c-1.23 1.093 -3.931 3.217 -8.238 5.179a198.938 198.938 0 0 1 -0.382 3.084 1.905 1.905 0 0 1 -1.71 1.655C28.973 37.807 26.487 38 24 38c-2.486 0 -4.973 -0.193 -6.499 -0.34a1.905 1.905 0 0 1 -1.71 -1.654c-0.099 -0.747 -0.229 -1.775 -0.382 -3.084 -4.307 -1.962 -7.009 -4.086 -8.238 -5.179a2.956 2.956 0 0 1 -0.968 -1.806c-0.297 -2.123 -0.217 -4.57 -0.081 -6.366 0.119 -1.57 1.257 -2.795 2.822 -2.971 2.478 -0.28 7.069 -0.6 15.056 -0.6 7.987 0 12.578 0.322 15.056 0.6Z" strokeWidth="3" />
												<path id="Subtract_4" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M13.008 16.269C13.003 15.569 13 14.814 13 14c0 -4.083 0.071 -6.674 0.144 -8.258 0.066 -1.435 0.864 -2.658 2.299 -2.729a11.322 11.322 0 0 1 1.114 0c1.436 0.07 2.233 1.294 2.299 2.73C18.929 7.325 19 9.916 19 14c0 0.729 -0.002 1.41 -0.006 2.047" strokeWidth="3" />
												<path id="Subtract_5" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M29.006 16.047C29.002 15.41 29 14.729 29 14c0 -4.083 0.071 -6.674 0.144 -8.258 0.066 -1.435 0.864 -2.658 2.299 -2.729a11.322 11.322 0 0 1 1.114 0c1.435 0.07 2.233 1.294 2.299 2.73C34.929 7.325 35 9.916 35 14c0 0.814 -0.003 1.57 -0.008 2.269" strokeWidth="3" />
												<path id="Subtract_6" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M20.375 37.885c0.193 2.371 0.413 4.547 0.539 5.74 0.064 0.606 0.425 1.119 1.024 1.225 0.463 0.082 1.128 0.15 2.061 0.15s1.599 -0.068 2.06 -0.15c0.6 -0.106 0.961 -0.62 1.025 -1.225 0.126 -1.193 0.346 -3.369 0.54 -5.74" strokeWidth="3" />
											</g>
										</svg>
										<span className="link-text ml-4">Integrations</span>
									</NavLink>
								}
							</li>

							{/* ------------------------- MANAGE SUBSCRIPTION ------------------------- */}
							<li className="mt-2 category-menu pricing-plan">
								<NavLink to={pageURL['manageSubscription']}
									className={({ isActive }) => isActive ? "is-active" : ""}
									onClick={(e) => {
										checkForUnsavedChanges(e);
										setHamburgerActive(false);
									}}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
										<g>
											<path id="Ellipse 20" fill="#ffffff" d="M24 45c11.598 0 21 -9.402 21 -21S35.598 3 24 3 3 12.402 3 24s9.402 21 21 21Z" strokeWidth="3" />
											<path id="hover" fill="#ffffff" d="M22.225 12.423c0.746 -1.439 2.804 -1.439 3.55 0l2.811 5.418 5.959 1.174c1.523 0.3 2.138 2.145 1.1 3.3l-4.225 4.698 0.776 6.288c0.196 1.584 -1.45 2.747 -2.878 2.035L24 32.68l-5.317 2.655c-1.428 0.712 -3.074 -0.451 -2.879 -2.035l0.776 -6.288 -4.225 -4.699c-1.038 -1.154 -0.422 -3 1.101 -3.3l5.959 -1.173 2.81 -5.418Z" strokeWidth="3" />
											<path id="Ellipse 19" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M24 3a21 21 0 1 0 0 42 21 21 0 1 0 0 -42" strokeWidth="3" />
											<path id="Star 4" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M22.224 12.423c0.747 -1.439 2.805 -1.439 3.551 0l2.81 5.418 5.96 1.174c1.522 0.3 2.138 2.145 1.1 3.3l-4.225 4.698 0.776 6.288c0.195 1.584 -1.45 2.747 -2.878 2.035L24 32.68l-5.318 2.655c-1.427 0.712 -3.073 -0.451 -2.878 -2.035l0.776 -6.288 -4.225 -4.699c-1.038 -1.154 -0.422 -3 1.1 -3.3l5.96 -1.173 2.81 -5.418Z" strokeWidth="3" />
										</g>
									</svg>
									<span className="link-text ml-4">Subscription</span>
								</NavLink>
							</li>

							{/* ------------------------- AI MINI TOOL - AI Calculator Generator  ------------------------- */}
							{!isProduction && (
								<li className="mt-2 subcategory-menu pricing-plan">
									<NavLink to={pageURL["aiCalculatorGenerator"]} className={({ isActive }) => isActive ? "is-active" : ""}
										onClick={(e) => {
											checkForUnsavedChanges(e);
											setHamburgerActive(false);
										}}>
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" id="Calculator-1--Streamline-Plump" height="48" width="48">
										    <g id="calculator-1--shop-shopping-pay-payment-store-cash-calculate-math">
											    <path id="Rectangle 1097" fill="#8fbffa" d="M3.53896 8.25718c0.20761 -2.5553 2.16291 -4.5106 4.71821 -4.71821C11.4854 3.27668 16.636 3 24 3c7.364 0 12.5146 0.27667 15.7428 0.53896 2.5553 0.20761 4.5106 2.16291 4.7182 4.71821C44.7233 11.4854 45 16.636 45 24c0 7.364 -0.2767 12.5146 -0.539 15.7428 -0.2076 2.5553 -2.1629 4.5106 -4.7182 4.7182C36.5146 44.7233 31.364 45 24 45c-7.364 0 -12.5146 -0.2767 -15.74282 -0.539 -2.5553 -0.2076 -4.5106 -2.1629 -4.71821 -4.7182C3.27668 36.5146 3 31.364 3 24c0 -7.364 0.27667 -12.5146 0.53896 -15.74282Z" stroke-width="3"></path>
											    <path id="Intersect" fill="#ffffff" d="M9.06696 17.9705c-0.02621 1.1158 0.87498 2.0296 1.99104 2.0296h25.884c1.1161 0 2.0173 -0.9138 1.9911 -2.0296 -0.063 -2.6798 -0.1602 -4.6917 -0.2466 -6.0807 -0.0871 -1.4004 -1.1759 -2.48914 -2.5763 -2.57625C33.8255 9.17145 29.8557 9 24 9c-5.8557 0 -9.8255 0.17145 -12.1102 0.31355 -1.4004 0.08711 -2.48913 1.17585 -2.57624 2.57625 -0.08639 1.389 -0.18363 3.4009 -0.2466 6.0807Z" stroke-width="3"></path><path id="Rectangle 1096" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M3.53896 8.25718c0.20761 -2.5553 2.16291 -4.5106 4.71821 -4.71821C11.4854 3.27668 16.636 3 24 3c7.364 0 12.5146 0.27667 15.7428 0.53896 2.5553 0.20761 4.5106 2.16291 4.7182 4.71821C44.7233 11.4854 45 16.636 45 24c0 7.364 -0.2767 12.5146 -0.539 15.7428 -0.2076 2.5553 -2.1629 4.5106 -4.7182 4.7182C36.5146 44.7233 31.364 45 24 45c-7.364 0 -12.5146 -0.2767 -15.74282 -0.539 -2.5553 -0.2076 -4.5106 -2.1629 -4.71821 -4.7182C3.27668 36.5146 3 31.364 3 24c0 -7.364 0.27667 -12.5146 0.53896 -15.74282Z" stroke-width="3"></path>
											    <path id="Intersect_2" fill="#ffffff" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M9.06599 17.9705c-0.02622 1.1158 0.87498 2.0296 1.99101 2.0296h25.884c1.1161 0 2.0173 -0.9138 1.9911 -2.0296 -0.063 -2.6798 -0.1602 -4.6917 -0.2466 -6.0807 -0.0871 -1.4004 -1.1759 -2.48914 -2.5763 -2.57625C33.8246 9.17145 29.8548 9 23.999 9c-5.8557 0 -9.8255 0.17145 -12.1102 0.31355 -1.4004 0.08711 -2.48911 1.17585 -2.57621 2.57625 -0.0864 1.389 -0.18364 3.4009 -0.2466 6.0807Z" stroke-width="3"></path><path id="Vector 1422" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M11 28h4" stroke-width="3"></path>
											    <path id="Vector 1425" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M11 37h4" stroke-width="3"></path>
											    <path id="Vector 1423" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M22 28h4" stroke-width="3"></path>
											    <path id="Vector 1426" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M22 37h4" stroke-width="3"></path>
											    <path id="Vector 1424" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M33 28h4" stroke-width="3"></path>
											    <path id="Vector 1427" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M33 37h4" stroke-width="3"></path>
											</g>
										</svg>
										<span className="link-text ml-4">AI Calculator Generator</span>
									</NavLink>
								</li>
							)}

							{/* ------------------------- Article Content Schedule ------------------------- */}
							{!isProduction && (<li className="mt-2 subcategory-menu pricing-plan">
								<NavLink to={"/content-scheduler"} className={({ isActive }) => isActive ? "is-active" : ""}
									onClick={(e) => {
										checkForUnsavedChanges(e);
										setHamburgerActive(false);
									}}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
										<g>
											<path id="Rectangle 1096" fill="#ffffff" d="M39.745 44.543c2.553 -0.187 4.533 -2.113 4.75 -4.664 0.252 -2.939 0.505 -7.478 0.505 -13.848 0 -6.37 -0.253 -10.909 -0.504 -13.848 -0.218 -2.55 -2.198 -4.476 -4.75 -4.664 -3.229 -0.237 -8.38 -0.488 -15.746 -0.488s-12.517 0.25 -15.745 0.488c-2.554 0.188 -4.532 2.113 -4.75 4.664C3.252 15.123 3 19.662 3 26.031c0 6.37 0.253 10.91 0.504 13.848 0.219 2.551 2.197 4.477 4.75 4.664 3.229 0.238 8.38 0.488 15.746 0.488s12.517 -0.25 15.745 -0.488Z" stroke-width="3"></path>
											<path id="Rectangle 1101" fill="#8fbffa" d="M10.043 10.628c0.042 1.25 0.791 2.277 2.04 2.348 0.27 0.015 0.575 0.024 0.917 0.024 0.342 0 0.646 -0.009 0.916 -0.024 1.25 -0.07 2 -1.097 2.041 -2.348a78.576 78.576 0 0 0 0 -5.256c-0.042 -1.25 -0.791 -2.277 -2.04 -2.348a17.668 17.668 0 0 0 -1.833 0c-1.25 0.07 -2 1.097 -2.041 2.348a78.573 78.573 0 0 0 0 5.256Z" stroke-width="3"></path>
											<path id="Rectangle 1102" fill="#8fbffa" d="M21.043 10.628c0.042 1.25 0.791 2.277 2.04 2.348 0.27 0.015 0.575 0.024 0.917 0.024 0.342 0 0.646 -0.009 0.916 -0.024 1.25 -0.07 2 -1.097 2.041 -2.348a78.576 78.576 0 0 0 0 -5.256c-0.042 -1.25 -0.791 -2.277 -2.04 -2.348a17.668 17.668 0 0 0 -1.833 0c-1.25 0.07 -2 1.097 -2.041 2.348a78.573 78.573 0 0 0 0 5.256Z" stroke-width="3"></path>
											<path id="Rectangle 1103" fill="#8fbffa" d="M32.043 10.628c0.042 1.25 0.791 2.277 2.04 2.348 0.27 0.015 0.575 0.024 0.917 0.024 0.342 0 0.646 -0.009 0.916 -0.024 1.25 -0.07 2 -1.097 2.041 -2.348a78.576 78.576 0 0 0 0 -5.256c-0.042 -1.25 -0.791 -2.277 -2.04 -2.348a17.668 17.668 0 0 0 -1.833 0c-1.25 0.07 -2 1.097 -2.041 2.348a78.573 78.573 0 0 0 0 5.256Z" stroke-width="3"></path>
											<path id="Vector 829" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="m15 25 18 0" stroke-width="3"></path>
											<path id="Vector 830" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M15 34h9" stroke-width="3"></path>
											<path id="Rectangle 1098" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M10.043 10.628c0.042 1.25 0.791 2.277 2.04 2.348 0.27 0.015 0.575 0.024 0.917 0.024 0.342 0 0.646 -0.009 0.916 -0.024 1.25 -0.07 2 -1.097 2.041 -2.348a78.575 78.575 0 0 0 0 -5.256c-0.042 -1.25 -0.791 -2.277 -2.04 -2.348a17.668 17.668 0 0 0 -1.833 0c-1.25 0.07 -2 1.097 -2.041 2.348a78.573 78.573 0 0 0 0 5.256Z" stroke-width="3"></path>
											<path id="Rectangle 1099" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M21.043 10.628c0.042 1.25 0.791 2.277 2.04 2.348 0.27 0.015 0.575 0.024 0.917 0.024 0.342 0 0.646 -0.009 0.916 -0.024 1.25 -0.07 2 -1.097 2.041 -2.348a78.575 78.575 0 0 0 0 -5.256c-0.042 -1.25 -0.791 -2.277 -2.04 -2.348a17.668 17.668 0 0 0 -1.833 0c-1.25 0.07 -2 1.097 -2.041 2.348a78.573 78.573 0 0 0 0 5.256Z" stroke-width="3"></path>
											<path id="Rectangle 1100" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M32.043 10.628c0.042 1.25 0.791 2.277 2.04 2.348 0.27 0.015 0.575 0.024 0.917 0.024 0.342 0 0.646 -0.009 0.916 -0.024 1.25 -0.07 2 -1.097 2.041 -2.348a78.575 78.575 0 0 0 0 -5.256c-0.042 -1.25 -0.791 -2.277 -2.04 -2.348a17.668 17.668 0 0 0 -1.833 0c-1.25 0.07 -2 1.097 -2.041 2.348a78.573 78.573 0 0 0 0 5.256Z" stroke-width="3"></path>
											<path id="Subtract" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M10.002 7.371c-0.635 0.039 -1.217 0.078 -1.747 0.117 -2.554 0.188 -4.532 2.113 -4.75 4.664C3.252 15.092 3 19.631 3 26c0 6.37 0.253 10.909 0.504 13.848 0.219 2.551 2.197 4.477 4.75 4.664C11.484 44.75 16.635 45 24 45s12.517 -0.25 15.745 -0.488c2.553 -0.187 4.533 -2.113 4.75 -4.664C44.748 36.908 45 32.37 45 26c0 -6.37 -0.253 -10.909 -0.504 -13.848 -0.218 -2.55 -2.198 -4.476 -4.75 -4.664 -0.531 -0.04 -1.113 -0.078 -1.748 -0.117" stroke-width="3"></path>
											<path id="Subtract_2" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M21.006 7.014c-1.828 0.018 -3.496 0.051 -5.01 0.095" stroke-width="3"></path>
											<path id="Subtract_3" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M32.005 7.11a260.984 260.984 0 0 0 -5.01 -0.096" stroke-width="3"></path>
										</g>
									</svg>
									<span className="link-text ml-4">Content Calendar</span>
								</NavLink>
							</li>
							)}

							{/* ------------------------- Affiliate Program  ------------------------- */}
							<li className="mt-2 subcategory-menu pricing-plan">
								<NavLink to={"https://abun.com/affiliate"} target="_blank"
									onClick={(e) => {
										checkForUnsavedChanges(e);
										setHamburgerActive(false);
									}}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 24 24" height="24" width="24">
										<g>
											<path id="Ellipse 23" fill="#ffffff" d="M11.5 1.4375c5.557375 0 10.0625 4.505125 10.0625 10.0625s-4.505125 10.0625 -10.0625 10.0625S1.4375 17.057375 1.4375 11.5 5.942625 1.4375 11.5 1.4375Z" strokeWidth="1" />
											<path id="Ellipse 22" fill="#8fbffa" d="M11.5 3.8333333333333335c4.234395833333333 0 7.666666666666667 3.4322708333333334 7.666666666666667 7.666666666666667s-3.4322708333333334 7.666666666666667 -7.666666666666667 7.666666666666667S3.8333333333333335 15.734395833333336 3.8333333333333335 11.5 7.265604166666667 3.8333333333333335 11.5 3.8333333333333335Z" strokeWidth="1" />
											<path id="Ellipse 19" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M11.5 1.4375c5.557375 0 10.0625 4.505125 10.0625 10.0625s-4.505125 10.0625 -10.0625 10.0625S1.4375 17.057375 1.4375 11.5 5.942625 1.4375 11.5 1.4375Z" strokeWidth="1.5" />
											{/* <path id="Ellipse 21" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M11.5 3.8333333333333335c4.234395833333333 0 7.666666666666667 3.4322708333333334 7.666666666666667 7.666666666666667s-3.4322708333333334 7.666666666666667 -7.666666666666667 7.666666666666667S3.8333333333333335 15.734395833333336 3.8333333333333335 11.5 7.265604166666667 3.8333333333333335 11.5 3.8333333333333335Z" strokeWidth="1.5"/> */}
											<path id="Vector" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M13.65625 9.079729166666668S12.79375 8.418958333333334 11.5 8.418958333333334c-1.078125 0 -2.15625 0.6602916666666666 -2.15625 1.5405208333333333 0 2.199854166666667 4.3125 0.87975 4.3125 3.0800833333333335 0 0.8802291666666667 -1.078125 1.5400416666666668 -2.15625 1.5400416666666668 -1.2937500000000002 0 -2.15625 -0.6598125 -2.15625 -0.6598125" strokeWidth="1.5" />
											<path id="Vector 837" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M11.5 8.4194375V7.1875" strokeWidth="1.5" />
											<path id="Vector 838" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M11.5 15.8125v-1.2314583333333333" strokeWidth="1.5" />
										</g>
									</svg>
									<span className="link-text ml-4">Affiliate Program </span>
								</NavLink>
							</li>

							{/* ------------------------- Get Help  ------------------------- */}
							<li className="mt-2 subcategory-menu pricing-plan">
								<NavLink to={"https://abun.com/help"} target="_blank" className={({ isActive }) => isActive ? "is-active" : ""}
									onClick={(e) => {
										checkForUnsavedChanges(e);
										setHamburgerActive(false);
									}}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" id="Information-Circle--Streamline-Plump" height="48" width="48">
										<g id="information-circle--information-frame-info-more-help-point-circle"><path id="Ellipse 69" fill="#8fbffa" d="M24 3c11.598 0 21 9.402 21 21s-9.402 21 -21 21S3 35.598 3 24 12.402 3 24 3Z" stroke-width="3"></path>
											<path id="Ellipse 68" fill="#ffffff" d="M20.5 12.5a3.5 3.5 0 1 0 7 0 3.5 3.5 0 1 0 -7 0" stroke-width="3"></path>
											<path id="Union" fill="#ffffff" d="M24.944 20.026c1.138 0.066 1.866 0.95 1.937 2.088C26.944 23.14 27 24.697 27 27v5.028c1.27 0.027 2.217 0.068 2.912 0.11 1.094 0.067 1.972 0.74 2.053 1.834 0.022 0.294 0.035 0.634 0.035 1.028s-0.013 0.734 -0.035 1.029c-0.08 1.092 -0.959 1.766 -2.053 1.833 -1.16 0.07 -3.022 0.138 -5.912 0.138s-4.753 -0.068 -5.912 -0.138c-1.094 -0.067 -1.972 -0.74 -2.053 -1.834A14.009 14.009 0 0 1 16 35c0 -0.394 0.013 -0.734 0.035 -1.029 0.08 -1.092 0.959 -1.766 2.053 -1.833a72.898 72.898 0 0 1 2.912 -0.11V27c0 -0.352 0.001 -0.686 0.004 -1.004a32.141 32.141 0 0 1 -1.985 -0.033c-1.187 -0.066 -1.98 -0.941 -2.01 -2.13a33.341 33.341 0 0 1 0 -1.667c0.03 -1.188 0.823 -2.063 2.01 -2.129 0.407 -0.022 0.896 -0.037 1.481 -0.037H24c0.356 0 0.67 0.01 0.944 0.026Z" stroke-width="3"></path>
											<path id="Ellipse 18" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M24 3c11.598 0 21 9.402 21 21s-9.402 21 -21 21S3 35.598 3 24 12.402 3 24 3Z" stroke-width="3"></path>
											<path id="Union_2" fill="#ffffff" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M24.944 20.026c1.138 0.066 1.866 0.95 1.937 2.088C26.944 23.14 27 24.697 27 27v5.028c1.27 0.027 2.217 0.068 2.912 0.11 1.094 0.067 1.972 0.74 2.053 1.834 0.022 0.294 0.035 0.634 0.035 1.028s-0.013 0.734 -0.035 1.029c-0.08 1.092 -0.959 1.766 -2.053 1.833 -1.16 0.07 -3.022 0.138 -5.912 0.138s-4.753 -0.068 -5.912 -0.138c-1.094 -0.067 -1.972 -0.74 -2.053 -1.834A14.009 14.009 0 0 1 16 35c0 -0.394 0.013 -0.734 0.035 -1.029 0.08 -1.092 0.959 -1.766 2.053 -1.833a72.898 72.898 0 0 1 2.912 -0.11V27c0 -0.352 0.001 -0.686 0.004 -1.004a32.141 32.141 0 0 1 -1.985 -0.033c-1.187 -0.066 -1.98 -0.941 -2.01 -2.13a33.341 33.341 0 0 1 0 -1.667c0.03 -1.188 0.823 -2.063 2.01 -2.129 0.407 -0.022 0.896 -0.037 1.481 -0.037H24c0.356 0 0.67 0.01 0.944 0.026Z" stroke-width="3"></path>
                                            <path id="Ellipse 67" fill="#ffffff" stroke="#2859c5" stroke-linecap="round" stroke-linejoin="round" d="M20.5 12.5a3.5 3.5 0 1 0 7 0 3.5 3.5 0 1 0 -7 0" stroke-width="3"></path>
										</g>
									</svg>
									<span className="link-text ml-4">Help Center</span>
								</NavLink>
							</li>

							{/* ------------------------- Updates  ------------------------- */}
							<li className="mt-2 subcategory-menu pricing-plan">
								<NavLink to={"/updates"} className={({ isActive }) => isActive ? "is-active" : ""}
									onClick={(e) => {
										checkForUnsavedChanges(e);
										setHamburgerActive(false);
									}}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" id="Ringing-Bell-Notification--Streamline-Plump" height="48" width="48"><g id="ringing-bell-notification--notification-vibrate-ring-sound-alarm-alert-bell-noise"><path id="Subtract" fill="#ffffff" d="M16.048 37.878A235.7 235.7 0 0 0 24 38c3.05 0 5.683 -0.045 7.953 -0.122a8.001 8.001 0 0 1 -15.905 0Z" strokeWidth="3"></path><path id="Union" fill="#8fbffa" d="M39.195 16.949C38.52 9.059 31.918 3 24 3 16.08 3 9.48 9.06 8.804 16.949l-0.479 5.59a16 16 0 0 1 -2.326 7.036l-1.47 2.384c-1.478 2.393 -0.672 4.866 2.116 5.237C9.833 37.62 15.157 38 24 38c8.842 0 14.166 -0.38 17.355 -0.804 2.788 -0.371 3.594 -2.844 2.116 -5.237l-1.47 -2.384a16 16 0 0 1 -2.327 -7.037l-0.479 -5.59Z" strokeWidth="3"></path><path id="Union_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M39.196 16.949C38.519 9.059 31.918 3 24 3S9.48 9.06 8.804 16.949l-0.479 5.59A16 16 0 0 1 6 29.574l-1.472 2.384c-1.477 2.393 -0.671 4.866 2.117 5.237C9.833 37.62 15.157 38 24 38s14.166 -0.38 17.355 -0.804c2.788 -0.371 3.594 -2.844 2.117 -5.237L42 29.575a16.001 16.001 0 0 1 -2.325 -7.037l-0.48 -5.59Z" strokeWidth="3"></path><path id="Subtract_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M16.048 37.878a8.001 8.001 0 0 0 15.904 0" strokeWidth="3"></path><path id="Vector 1499" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M3.015 17C2.849 14.333 4.013 7.8 10 3" strokeWidth="3"></path><path id="Vector 1500" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M44.985 17c0.166 -2.667 -0.998 -9.2 -6.985 -14" strokeWidth="3"></path></g></svg>
									<span className="link-text ml-4">Updates</span>
								</NavLink>
							</li>
							
							{/* ------------------------- Other Products  ------------------------- */}
							<li className="mt-2 subcategory-menu">
								<a href="#" id="chatWindowbutton" onClick={showChatWindow}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
										<g>
											<path id="Subtract" fill="#8fbffa" d="M24.879 3.018c0.41 0.017 0.608 0.502 0.36 0.829A13.44 13.44 0 0 0 22.5 12c0 7.456 6.044 13.5 13.5 13.5a13.44 13.44 0 0 0 8.153 -2.74c0.327 -0.247 0.812 -0.048 0.829 0.361 0.012 0.292 0.018 0.585 0.018 0.879 0 11.598 -9.402 21 -21 21a20.902 20.902 0 0 1 -10.676 -2.912c-2.356 0.877 -5.038 1.745 -7.681 2.37a1.797 1.797 0 0 1 -2.197 -2.119c0.563 -2.759 1.422 -5.52 2.33 -7.896A20.903 20.903 0 0 1 3 24C3 12.402 12.402 3 24 3c0.294 0 0.587 0.006 0.879 0.018Z" stroke-width="3"></path>
											<path id="Ellipse 4" d="M45 12a9 9 0 1 1 -18 0 9 9 0 0 1 18 0Z" stroke-width="3" stroke="#2859c5"></path>
											<path id="Union" stroke-linecap="round" stroke-linejoin="round" d="M24 3C12.402 3 3 12.402 3 24c0 3.802 1.01 7.367 2.777 10.443 -0.91 2.377 -1.768 5.137 -2.331 7.896a1.797 1.797 0 0 0 2.197 2.12c2.643 -0.626 5.325 -1.494 7.681 -2.371A20.902 20.902 0 0 0 24 45c11.598 0 21 -9.402 21 -21 0 -0.335 -0.008 -0.669 -0.023 -1" stroke-width="3" stroke="#2859c5"></path>
											<path id="Ellipse 3" stroke-linejoin="round" d="M45 12a9 9 0 1 1 -18 0 9 9 0 0 1 18 0Z" stroke-width="3" stroke="#2859c5" fill="#fff"></path>
										</g>
									</svg>
									<span className="link-text ml-4">Live Chat Support</span>
								</a>
							</li>

							{/* ------------------------- Other Products  ------------------------- */}
							<li className="mt-2 subcategory-product subcategory-menu  is-flex is-align-items-center ml-3">
								<div className="other-product-container" onClick={toggleDropdown} ref={dropdownRef}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" id="Erlenmeyer-Flask--Streamline-Plump" height="48" width="48">
										<g id="erlenmeyer-flask--science-experiment-lab-flask-chemistry-solution">
											<path id="Union" fill="#8fbffa" d="M24 3c-3.965 0 -7.161 0.258 -9.316 0.506 -1.451 0.167 -2.11 1.796 -1.25 2.976l1.957 2.683a4 4 0 0 1 0.737 1.86l0.75 6.004a2.025 2.025 0 0 1 -0.572 1.669C9.814 25.267 6.249 29.973 4.482 32.59c-1.042 1.545 -1.496 3.38 -1.392 5.24l0.013 0.229c0.225 3.698 3.19 6.282 6.886 6.53 3.234 0.217 7.848 0.411 14.01 0.411 6.161 0 10.775 -0.194 14.01 -0.411 3.696 -0.248 6.66 -2.832 6.885 -6.53l0.013 -0.23c0.106 -1.86 -0.349 -3.694 -1.39 -5.238 -1.767 -2.618 -5.332 -7.324 -11.825 -13.893a2.025 2.025 0 0 1 -0.572 -1.669l0.75 -6.003a4 4 0 0 1 0.738 -1.861l1.956 -2.683c0.86 -1.18 0.202 -2.81 -1.249 -2.976C31.161 3.258 27.964 3 24 3Z" strokeWidth="3"></path>
											<path id="Union_2" stroke="#2859c5" strokeLinejoin="round" d="M24 3c-3.965 0 -7.16 0.258 -9.316 0.506 -1.45 0.167 -2.11 1.796 -1.25 2.976l1.957 2.683a4 4 0 0 1 0.737 1.86l0.75 6.004a2.025 2.025 0 0 1 -0.572 1.669C9.814 25.267 6.25 29.973 4.482 32.59c-1.042 1.544 -1.496 3.379 -1.39 5.239l0.013 0.229c0.225 3.698 3.189 6.282 6.886 6.53 3.234 0.217 7.848 0.411 14.01 0.411 6.16 0 10.774 -0.194 14.009 -0.411 3.696 -0.248 6.66 -2.832 6.885 -6.53l0.014 -0.23c0.105 -1.86 -0.35 -3.694 -1.391 -5.238 -1.767 -2.618 -5.332 -7.324 -11.824 -13.893a2.025 2.025 0 0 1 -0.572 -1.669l0.75 -6.003a4 4 0 0 1 0.737 -1.861l1.957 -2.683c0.86 -1.18 0.201 -2.81 -1.25 -2.976C31.161 3.258 27.965 3 24 3Z" strokeWidth="3"></path>
											<path id="Union_3" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M37 33.762s-1.571 -3.143 -7.333 -8.905" strokeWidth="3"></path>
										</g>
									</svg>

									<div className={`dropdown ml-4 ${isDropdownOpen ? "is-active" : ""}`}>
										<div className="dropdown-trigger">
											<button className="button p-1 dropdown-menu-btn " aria-haspopup="true" aria-controls="dropdown-menu" style={{ width: "fit-content", border: "none", outline: "none", boxShadow: "none" }}>
												<span className="other-product-text">Other Products</span>
												<span className="icon is-small drop-down-arrow " style={{ marginLeft: "1rem", }}>
													<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" className="arrow-icon"><path d="M1 1L6 6L11 1" stroke="black" strokeLinecap="round"></path></svg>
												</span>
											</button>
										</div>
										<div className="dropdown-menu" id="dropdown-menu" role="menu">
											<div className="dropdown-content m-0" style={{ width: "72%" }}>
												<a href="https://deliveryman.ai/early-access?ref=abun-dashboard" target="_blank" rel="noopener noreferrer" className="px-3" onClick={() => setIsDropdownOpen(false)}>Deliveryman.ai</a>
												<hr className="dropdown-divider m-0" />
												<a href="https://draftss.com?ref=abun-dashboard" target="_blank" rel="noopener noreferrer" className=" px-3 " onClick={() => setIsDropdownOpen(false)}>Draftss.com</a>
												<hr className="dropdown-divider m-0" />
												<a href="https://aicallcenter.co?ref=abun-dashboard" target="_blank" rel="noopener noreferrer" className=" px-3 " onClick={() => setIsDropdownOpen(false)}>AICallCenter</a>
												<hr className="dropdown-divider m-0" />
												<a href="https://clientportalos.com?ref=abun-dashboard" target="_blank" rel="noopener noreferrer" className="px-3 " onClick={() => setIsDropdownOpen(false)}>ClientPortalOS</a>
												<hr className="dropdown-divider m-0" />
											</div>
										</div>
									</div>
								</div>
							</li>

							{/* ------------------------- AI Keyword Results ------------------------- */}
							{/* <li className="mt-2 articles subcategory-menu">
								<NavLink to={pageURL['showKeywords']}
									onClick={checkForUnsavedChanges} className={({ isActive }) => isActive ? "is-active" : ""}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
										<g>
											<path id="Vector" fill="#3f77f8" d="M7 36.3529c0.3697 6.1654 -2.5 7.4118 -4 7.4118C3 43.7647 9.5 45 19 45c4.4849 0 8.3011 -0.2753 11.0805 -0.566 2.8661 -0.2997 4.9294 -2.7553 4.8495 -5.6359C34.6912 30.1945 33.9035 22.81 33.5424 13c-0.1126 -3.06039 0 -9 4.4576 -10L12 3C8.68629 3 6.00548 5.68197 6.05053 8.99537 6.13668 15.3307 6.3697 25.8418 7 36.3529Z" strokeWidth="3" />
											<path id="Subtract" fill="#3f77f8" d="M37.9998 3c-4.4569 0.99986 -4.5702 6.93788 -4.4576 9.9987 4.3993 -0.0219 7.398 -0.3069 9.2207 -0.5631 1.3335 -0.1874 2.2369 -1.3427 2.2369 -2.68934v-0.6487c0 -3.90244 -2.9999 -6.09756 -6 -6.09756l-1 0Z" strokeWidth="3" />
											<path id="Vector_2" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="M7 36.3529c0.3697 6.1654 -2.5 7.4118 -4 7.4118C3 43.7647 9.5 45 19 45c4.4849 0 8.3011 -0.2753 11.0805 -0.566 2.8661 -0.2997 4.9294 -2.7553 4.8495 -5.6359C34.6912 30.1945 33.9035 22.81 33.5424 13c-0.1126 -3.06039 0 -9 4.4576 -10L12 3C8.68629 3 6.00548 5.68197 6.05053 8.99537 6.13668 15.3307 6.3697 25.8418 7 36.3529Z" strokeWidth="3" />
											<path id="Subtract_2" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m37.9996 3.00001 1 -0.00001c3.0001 0 6 2.19512 6 6.09756v0.6487c0 1.34664 -0.9034 2.50194 -2.2369 2.68934 -1.8227 0.2562 -4.8214 0.5412 -9.2207 0.5631" strokeWidth="3" />
											<path id="Vector 1679" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="M17 13h10" strokeWidth="3" /><path id="Vector 1680" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m13 20 14 0" strokeWidth="3" />
											<path id="Vector 1681" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m13.5 27 14 0" strokeWidth="3" /><path id="Vector 1682" stroke="#3f77f8" strokeLinecap="round" strokeLinejoin="round" d="m14 34 14 0" strokeWidth="3" />
										</g>
									</svg>
									<span className="link-text ml-4">AI Keyword Results</span>
								</NavLink>
							</li> */}


							{/* ------------------------- INDEXATION ------------------------- */}
							{/* <li className={"mt-2 indexation category-menu"}>
								{props.currentPlanName === "Trial" ?
									<NavLink to={pageURL['manageSubscription']} className={"upgrade"} onClick={checkForUnsavedChanges}>
										<div className="category">
											<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
												<g>
													<path id="hover" fill="#fff" d="M20.746 26.794a97.66 97.66 0 0 1 -6.657 -1.038c-1.442 -0.28 -1.987 -1.956 -1.005 -3.049 2.512 -2.797 7.006 -7.605 11.87 -11.883 1.29 -1.134 3.235 -0.14 3.116 1.574 -0.25 3.597 -0.543 6.527 -0.815 8.808 2.75 0.336 5.009 0.72 6.656 1.038 1.443 0.28 1.987 1.956 1.006 3.049 -2.512 2.796 -7.006 7.605 -11.87 11.883 -1.29 1.134 -3.235 0.14 -3.116 -1.574 0.25 -3.597 0.542 -6.527 0.815 -8.808Z" strokeWidth="3" />
													<path id="Ellipse 32" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M44.788 21C43.332 10.823 34.58 3 24 3S4.67 10.823 3.213 21" strokeWidth="3" />
													<path id="Ellipse 582" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M27 44.787a20.847 20.847 0 0 0 4.798 -1.282M21 44.787a20.81 20.81 0 0 1 -4.829 -1.295M44.788 27a20.855 20.855 0 0 1 -1.283 4.797M3.213 27c0.239 1.67 0.674 3.278 1.282 4.797m6.515 8.704a21.125 21.125 0 0 1 -3.531 -3.536m33.043 0a21.126 21.126 0 0 1 -3.558 3.557" strokeWidth="3" />
													<path id="Union_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M20.746 26.794a97.66 97.66 0 0 1 -6.657 -1.038c-1.442 -0.28 -1.987 -1.956 -1.005 -3.049 2.512 -2.797 7.006 -7.605 11.87 -11.883 1.29 -1.134 3.235 -0.14 3.116 1.574 -0.25 3.597 -0.543 6.527 -0.815 8.808 2.75 0.336 5.009 0.72 6.656 1.038 1.443 0.28 1.987 1.956 1.006 3.049 -2.512 2.796 -7.006 7.605 -11.87 11.883 -1.29 1.134 -3.235 0.14 -3.116 -1.574 0.25 -3.597 0.542 -6.527 0.815 -8.808Z" strokeWidth="3" />
												</g>
											</svg>
											<span className="link-text ml-4">
												Fast Google Index
											</span>
										</div>
										<span className="uncollapsed-tag tag is-info is-light is-rounded">
											Upgrade ⚡
										</span>
									</NavLink>
									:
									<NavLink to={pageURL['indexation']}
										onClick={checkForUnsavedChanges} className={({ isActive }) => isActive ? "is-active " : ""}>
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
											<g>
												<path id="hover" fill="#fff" d="M20.746 26.794a97.66 97.66 0 0 1 -6.657 -1.038c-1.442 -0.28 -1.987 -1.956 -1.005 -3.049 2.512 -2.797 7.006 -7.605 11.87 -11.883 1.29 -1.134 3.235 -0.14 3.116 1.574 -0.25 3.597 -0.543 6.527 -0.815 8.808 2.75 0.336 5.009 0.72 6.656 1.038 1.443 0.28 1.987 1.956 1.006 3.049 -2.512 2.796 -7.006 7.605 -11.87 11.883 -1.29 1.134 -3.235 0.14 -3.116 -1.574 0.25 -3.597 0.542 -6.527 0.815 -8.808Z" strokeWidth="3" />
												<path id="Ellipse 32" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M44.788 21C43.332 10.823 34.58 3 24 3S4.67 10.823 3.213 21" strokeWidth="3" />
												<path id="Ellipse 582" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M27 44.787a20.847 20.847 0 0 0 4.798 -1.282M21 44.787a20.81 20.81 0 0 1 -4.829 -1.295M44.788 27a20.855 20.855 0 0 1 -1.283 4.797M3.213 27c0.239 1.67 0.674 3.278 1.282 4.797m6.515 8.704a21.125 21.125 0 0 1 -3.531 -3.536m33.043 0a21.126 21.126 0 0 1 -3.558 3.557" strokeWidth="3" />
												<path id="Union_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M20.746 26.794a97.66 97.66 0 0 1 -6.657 -1.038c-1.442 -0.28 -1.987 -1.956 -1.005 -3.049 2.512 -2.797 7.006 -7.605 11.87 -11.883 1.29 -1.134 3.235 -0.14 3.116 1.574 -0.25 3.597 -0.543 6.527 -0.815 8.808 2.75 0.336 5.009 0.72 6.656 1.038 1.443 0.28 1.987 1.956 1.006 3.049 -2.512 2.796 -7.006 7.605 -11.87 11.883 -1.29 1.134 -3.235 0.14 -3.116 -1.574 0.25 -3.597 0.542 -6.527 0.815 -8.808Z" strokeWidth="3" />
											</g>
										</svg>
										<span className="link-text ml-4">Fast Google Index</span>
									</NavLink>
								}
							</li> */}

							{/* ------------------------- Feature Request Form ------------------------- */}
							{/* <li className="mt-2 subcategory-menu pricing-plan"> */}
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							{/* <a onClick={() => setFeatureRequestModalActive(true)}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.75 -0.75 24 24" height="24" width="24">
										<g>
											<path id="Union" d="M3.8566124999999998 18.550593749999997c-1.1900765625 -0.07621875 -2.1283499999999997 -0.953859375 -2.2371140625 -2.1414375C1.5097593749999998 15.210984374999999 1.40625 13.438640625 1.40625 11.015625c0 -2.423015625 0.103509375 -4.195359375000001 0.2132484375 -5.39353125 0.1087640625 -1.187559375 1.0470421875 -2.065228125 2.2371140625 -2.1414515625C5.36896875 3.3837796875 7.787531249999999 3.28125 11.25 3.28125s5.88103125 0.10252968750000001 7.39340625 0.199396875c1.1900625 0.0762234375 2.1283125 0.9538875 2.237109375 2.1414468749999997C20.990250000000003 6.820265624999999 21.09375 8.592609375 21.09375 11.015625c0 2.423015625 -0.1035 4.195359375000001 -0.213234375 5.39353125 -0.108796875 1.187578125 -1.047046875 2.06521875 -2.237109375 2.1414375 -1.4619843750000001 0.09365625 -3.770765625 0.1925625 -7.05084375 0.19907812500000002l-3.0136875 2.5831874999999997C7.9707187500000005 21.854109375 7.03125 21.422015625 7.03125 20.6210625v-1.928203125c-1.3245 -0.038343749999999996 -2.37594375 -0.091078125 -3.1746375000000002 -0.142265625Z" strokeWidth="1.5" />
											<path id="Rectangle 1845" fill="#ffffff" d="M13.033078125 1.4044874999999999c-0.031265625 -0.259715625 -0.280453125 -0.380925 -0.5107968749999999 -0.25696875 -0.32071875 0.1725609375 -0.830859375 0.47074687499999995 -1.559015625 0.962034375 -0.597328125 0.402984375 -0.974578125 0.71934375 -1.21059375 0.9490921875 -0.27281249999999996 0.2655703125 -0.27285937499999996 0.6487640625000001 -0.000046875 0.9143484374999999 0.23601562499999998 0.2297671875 0.613265625 0.5461265625 1.210640625 0.94906875 0.728109375 0.49115625 1.23825 0.7893281249999999 1.558921875 0.9619218749999999 0.230390625 0.12398437500000001 0.4796250000000001 0.002765625 0.510890625 -0.25696875 0.045328125 -0.376546875 0.091921875 -1.02725625 0.091921875 -2.1112125 0 -1.08406875 -0.04659375 -1.7348249999999998 -0.091921875 -2.1113156249999996Z" strokeWidth="1.5" />
											<g>
												<path d="M12.65625 11.25c0 0.776671875 -0.629578125 1.40625 -1.40625 1.40625s-1.40625 -0.629578125 -1.40625 -1.40625 0.629578125 -1.40625 1.40625 -1.40625 1.40625 0.629578125 1.40625 1.40625Z" strokeWidth="1.5" />
												<path d="M17.8125 11.25c0 0.776671875 -0.629578125 1.40625 -1.40625 1.40625s-1.40625 -0.629578125 -1.40625 -1.40625 0.629578125 -1.40625 1.40625 -1.40625 1.40625 0.629578125 1.40625 1.40625Z" strokeWidth="1.5" />
												<path d="M7.5 11.25c0 0.776671875 -0.629578125 1.40625 -1.40625 1.40625s-1.40625 -0.629578125 -1.40625 -1.40625 0.629578125 -1.40625 1.40625 -1.40625 1.40625 0.629578125 1.40625 1.40625Z" strokeWidth="1.5" />
											</g>
											<path id="Union_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M13.125 3.291778125c2.480390625 0.028537499999999997 4.295625 0.11055937499999999 5.51840625 0.188878125 1.1900625 0.0762234375 2.1283125 0.9538921874999999 2.237109375 2.1414375C20.990250000000003 6.820265624999999 21.09375 8.592609375 21.09375 11.015625c0 2.423015625 -0.1035 4.1954062500000004 -0.213234375 5.3935781249999994 -0.108796875 1.18753125 -1.047046875 2.0651718750000003 -2.237109375 2.1414375 -1.4619843750000001 0.093609375 -3.770765625 0.1925625 -7.05084375 0.19903125l-3.0136875 2.5831874999999997C7.9707187500000005 21.854109375 7.03125 21.422015625 7.03125 20.6210625v-1.9281562500000002c-1.3245 -0.038390625 -2.37594375 -0.091125 -3.1746375000000002 -0.142265625 -1.1900765625 -0.076265625 -2.1283499999999997 -0.9539062500000001 -2.2371140625 -2.1414375C1.5097593749999998 15.211031250000001 1.40625 13.438640625 1.40625 11.015625c0 -2.42296875 0.103509375 -4.195359375000001 0.2132484375 -5.39353125 0.1087640625 -1.1875453125 1.0470421875 -2.0652140625 2.2371140625 -2.1414375C4.65530625 3.429496875 5.70675 3.3767625 7.03125 3.3383859375" strokeWidth="1.5" />
											<path id="Rectangle 1846" stroke="#2859c5" fill="#ffffff" strokeLinecap="round" strokeLinejoin="round" d="M13.033078125 1.4044874999999999c-0.031265625 -0.259715625 -0.280453125 -0.380925 -0.5107968749999999 -0.25696875 -0.32071875 0.1725609375 -0.830859375 0.47074687499999995 -1.559015625 0.962034375 -0.597328125 0.402984375 -0.974578125 0.71934375 -1.21059375 0.9490921875 -0.27281249999999996 0.2655703125 -0.27285937499999996 0.6487640625000001 -0.000046875 0.9143484374999999 0.23601562499999998 0.2297671875 0.613265625 0.5461265625 1.210640625 0.94906875 0.728109375 0.49115625 1.23825 0.7893281249999999 1.558921875 0.9619218749999999 0.230390625 0.12398437500000001 0.4796250000000001 0.002765625 0.510890625 -0.25696875 0.045328125 -0.376546875 0.091921875 -1.02725625 0.091921875 -2.1112125 0 -1.08406875 -0.04659375 -1.7348249999999998 -0.091921875 -2.1113156249999996Z" strokeWidth="1.5" />
											<path id="Vector_2" stroke="#2859c5" fill="#ffffff" strokeLinecap="round" strokeLinejoin="round" d="M7.5 11.25c0 0.776671875 -0.629578125 1.40625 -1.40625 1.40625s-1.40625 -0.629578125 -1.40625 -1.40625 0.629578125 -1.40625 1.40625 -1.40625 1.40625 0.629578125 1.40625 1.40625Z" strokeWidth="1.5" />
											<path id="Vector_3" stroke="#2859c5" fill="#ffffff" strokeLinecap="round" strokeLinejoin="round" d="M12.65625 11.25c0 0.776671875 -0.629578125 1.40625 -1.40625 1.40625s-1.40625 -0.629578125 -1.40625 -1.40625 0.629578125 -1.40625 1.40625 -1.40625 1.40625 0.629578125 1.40625 1.40625Z" strokeWidth="1.5" />
											<path id="Vector_4" stroke="#2859c5" fill="#ffffff" strokeLinecap="round" strokeLinejoin="round" d="M17.8125 11.25c0 0.776671875 -0.629578125 1.40625 -1.40625 1.40625s-1.40625 -0.629578125 -1.40625 -1.40625 0.629578125 -1.40625 1.40625 -1.40625 1.40625 0.629578125 1.40625 1.40625Z" strokeWidth="1.5" />
										</g>
									</svg>
									<span className="link-text ml-4">Feature Request</span>
								</a>
							</li> */}

							{/* ------------------------- Tutorials -------------------------
							<li className="mt-2 subcategory-menu pricing-plan">
								<NavLink to={pageURL['tutorials']} className={({ isActive }) => (isActive && !window.location.href.endsWith("?tab=integration")) ? "is-active settings" : "settings"}
									onClick={(e) => {
										checkForUnsavedChanges(e);
										let interval = setInterval(() => {
											const imagesTab = document.getElementById("images-tab");
											if (imagesTab) {
												imagesTab.click();
												clearInterval(interval);
											}
										}, 500);
									}}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="48" width="48">
										<g>
											<path id="Rectangle 1105" fill="#8fbffa" d="M3.539 8.257c0.208 -2.555 2.163 -4.51 4.718 -4.718C11.485 3.277 16.636 3 24 3c7.364 0 12.515 0.277 15.743 0.539 2.555 0.208 4.51 2.163 4.718 4.718C44.723 11.485 45 16.636 45 24c0 7.364 -0.277 12.515 -0.539 15.743 -0.208 2.555 -2.163 4.51 -4.718 4.718C36.515 44.723 31.364 45 24 45c-7.364 0 -12.515 -0.277 -15.743 -0.539 -2.555 -0.208 -4.51 -2.163 -4.718 -4.718C3.277 36.515 3 31.364 3 24c0 -7.364 0.277 -12.515 0.539 -15.743Z" strokeWidth="3"></path>
											<path id="Intersect" fill="#ffffff" d="M44.806 14H3.196c0.098 -2.345 0.222 -4.25 0.344 -5.743 0.207 -2.555 2.163 -4.51 4.718 -4.718C11.486 3.277 16.637 3 24 3c7.364 0 12.514 0.277 15.743 0.539 2.555 0.208 4.51 2.163 4.718 4.718 0.121 1.494 0.245 3.398 0.344 5.743Z" strokeWidth="3"></path>
											<path id="Rectangle 1106" fill="#ffffff" d="M17.353 23.873c0.25 -1.73 1.775 -2.714 3.405 -2.084 1.144 0.443 2.686 1.133 4.713 2.21 1.938 1.03 3.432 1.941 4.56 2.695 1.758 1.175 1.758 3.435 0 4.61 -1.128 0.754 -2.622 1.665 -4.56 2.695 -2.027 1.077 -3.569 1.767 -4.713 2.21 -1.63 0.63 -3.155 -0.354 -3.405 -2.084A35.992 35.992 0 0 1 17 28.999c0 -2.078 0.163 -3.81 0.353 -5.126Z" strokeWidth="3"></path>
											<path id="Rectangle 1104" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M3.539 8.257c0.208 -2.555 2.163 -4.51 4.718 -4.718C11.485 3.277 16.636 3 24 3c7.364 0 12.515 0.277 15.743 0.539 2.555 0.208 4.51 2.163 4.718 4.718C44.723 11.485 45 16.636 45 24c0 7.364 -0.277 12.515 -0.539 15.743 -0.208 2.555 -2.163 4.51 -4.718 4.718C36.515 44.723 31.364 45 24 45c-7.364 0 -12.515 -0.277 -15.743 -0.539 -2.555 -0.208 -4.51 -2.163 -4.718 -4.718C3.277 36.515 3 31.364 3 24c0 -7.364 0.277 -12.515 0.539 -15.743Z" strokeWidth="3"></path>
											<path id="Rectangle 128" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M17.353 23.874c0.25 -1.73 1.775 -2.714 3.405 -2.084 1.144 0.443 2.686 1.133 4.713 2.21 1.938 1.03 3.432 1.941 4.56 2.695 1.758 1.175 1.758 3.435 0 4.61 -1.128 0.754 -2.622 1.665 -4.56 2.695 -2.027 1.077 -3.569 1.767 -4.713 2.21 -1.63 0.63 -3.155 -0.354 -3.405 -2.084A35.992 35.992 0 0 1 17 29c0 -2.078 0.163 -3.81 0.353 -5.126Z" strokeWidth="3"></path>
											<path id="Intersect_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M44.806 14H3.196" strokeWidth="3"></path>
											<path id="Vector 167" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="m20.289 3.15 -8.008 10.677" strokeWidth="3"></path>
											<path id="Vector 168" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="m36.717 3.378 -7.852 10.469" strokeWidth="3"></path>
										</g>
									</svg>
									<span className="link-text ml-4">Tutorials</span>
								</NavLink>
							</li> */}


						</ul>
					</div>

					{/* ------------------------- PROFILE PAGE ------------------------- */}
					<div className={"sidebar-footer"}>
						<ul className={"menu-list"}>
							<li className="category-menu profile">
								<NavLink to={pageURL['profile']} className={({ isActive }) => isActive ? "is-active" : ""}
									onClick={(e) => {
										checkForUnsavedChanges(e);
										setHamburgerActive(false);
									}}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 24 24" height="24" width="24">
										<g>
											<path id="hover" fill="#ffffff" d="M18.48495625 1.2330395833333334C18.91635 1.1847875 19.35814166666667 1.3280583333333333 19.621635416666667 1.6730104166666666C21.055014583333335 3.5492833333333333 21.055014583333335 7.9626 21.055014583333335 10.995964583333334C21.055014583333335 11.211254166666668 21.044329166666667 11.418877083333333 21.022622916666666 11.618689583333333C21.358231250000003 12.622735416666668 21.54501041666667 13.694583333333332 21.54501041666667 14.8128625C21.54501041666667 16.072927083333333 21.5449625 21.776064583333334 11.254954166666668 21.776064583333334C0.9649937500000001 21.776064583333334 0.9649937500000001 16.072927083333333 0.9649937500000001 14.8128625C0.9649937500000001 13.694583333333332 1.1517729166666668 12.6226875 1.48738125 11.618641666666667C1.465675 11.418877083333333 1.4549895833333335 11.211254166666668 1.4549895833333335 10.995964583333334C1.4549895833333335 7.9626 1.4549895833333335 3.5492833333333333 2.88836875 1.6730104166666666C3.1519104166666665 1.3280583333333333 3.5936541666666666 1.1847875 4.025047916666667 1.2330395833333334C6.1307937500000005 1.46855 7.767675 2.8007770833333336 8.9472875 4.4645874999999995C9.713570833333334 4.249777083333333 10.490204166666668 4.136070833333333 11.254954166666668 4.136070833333333C12.019752083333335 4.136070833333333 12.796433333333335 4.249777083333333 13.562716666666667 4.464635416666667C14.742329166666668 2.800825 16.379210416666666 1.46855 18.48495625 1.2330395833333334Z" strokeWidth="1" />
											<path id="Rectangle 1097" fill="#ffffff" d="M15.758833333333333 7.611610416666667C14.931264583333334 7.647308333333334 14.280556250000002 8.250914583333333 14.232927083333333 9.0778125C14.21069375 9.4645 14.194977083333335 9.9383 14.194977083333335 10.506160416666667S14.21069375 11.547868750000001 14.232927083333333 11.934508333333333C14.280556250000002 12.761454166666667 14.931264583333334 13.3650125 15.758833333333333 13.400758333333334C16.3415 13.425866666666668 17.120002083333336 13.446183333333334 18.11499166666667 13.446183333333334C19.10998125 13.446183333333334 19.888483333333333 13.425866666666668 20.47115 13.400758333333334C21.298718750000003 13.3650125 21.949427083333333 12.761454166666667 21.99705625 11.934508333333333C22.019337500000002 11.547868750000001 22.035006250000002 11.074020833333334 22.035006250000002 10.506160416666667S22.019337500000002 9.4645 21.99705625 9.0778125C21.949427083333333 8.250914583333333 21.298718750000003 7.647308333333334 20.47115 7.611610416666667C19.888483333333333 7.586454166666667 19.10998125 7.566185416666667 18.11499166666667 7.566185416666667C17.120002083333336 7.566185416666667 16.3415 7.586454166666667 15.758833333333333 7.611610416666667Z" strokeWidth="1.5" />
											<path id="Rectangle 1098" fill="#ffffff" d="M5.468825 7.611610416666667C4.64125625 7.647308333333334 3.9905479166666664 8.250914583333333 3.94291875 9.0778125C3.9206854166666667 9.4645 3.9050166666666666 9.9383 3.9050166666666666 10.506160416666667S3.9206854166666667 11.547868750000001 3.94291875 11.934508333333333C3.9905479166666664 12.761454166666667 4.64125625 13.3650125 5.468825 13.400758333333334C6.051491666666667 13.425866666666668 6.82999375 13.446183333333334 7.824983333333334 13.446183333333334C8.819972916666668 13.446183333333334 9.598522916666667 13.425866666666668 10.181141666666667 13.400758333333334C11.008710416666666 13.3650125 11.659466666666667 12.761454166666667 11.707095833333335 11.934508333333333C11.729329166666668 11.547868750000001 11.744997916666666 11.074020833333334 11.744997916666666 10.506160416666667S11.729329166666668 9.4645 11.707095833333335 9.0778125C11.659466666666667 8.250914583333333 11.008710416666666 7.647308333333334 10.181141666666667 7.611610416666667C9.598522916666667 7.586454166666667 8.819972916666668 7.566185416666667 7.824983333333334 7.566185416666667C6.82999375 7.566185416666667 6.051491666666667 7.586454166666667 5.468825 7.611610416666667Z" strokeWidth="1.5" />
											<path id="Ellipse 18" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M4.864452083333333 16.876154166666666C5.610610416666667 17.70640625 6.937039583333333 18.46368125 9.29501875 18.732589583333333" strokeWidth="1.5" />
											<path id="Vector 2112" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M14.194977083333335 10.506160416666667H11.744997916666666" strokeWidth="1" />
											<path id="Vector 2113" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M3.9050166666666666 10.506160416666667H1.4549895833333335" strokeWidth="1" />
											<path id="glass" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M15.758833333333333 7.611610416666667C14.931264583333334 7.647308333333334 14.280556250000002 8.250914583333333 14.232927083333333 9.0778125C14.21069375 9.4645 14.194977083333335 9.9383 14.194977083333335 10.506160416666667S14.21069375 11.547868750000001 14.232927083333333 11.934508333333333C14.280556250000002 12.761454166666667 14.931264583333334 13.3650125 15.758833333333333 13.400758333333334C16.3415 13.425866666666668 17.120002083333336 13.446183333333334 18.11499166666667 13.446183333333334C19.10998125 13.446183333333334 19.888483333333333 13.425866666666668 20.47115 13.400758333333334C21.298718750000003 13.3650125 21.949427083333333 12.761454166666667 21.99705625 11.934508333333333C22.019337500000002 11.547868750000001 22.035006250000002 11.074020833333334 22.035006250000002 10.506160416666667S22.019337500000002 9.4645 21.99705625 9.0778125C21.949427083333333 8.250914583333333 21.298718750000003 7.647308333333334 20.47115 7.611610416666667C19.888483333333333 7.586454166666667 19.10998125 7.566185416666667 18.11499166666667 7.566185416666667C17.120002083333336 7.566185416666667 16.3415 7.586454166666667 15.758833333333333 7.611610416666667Z" strokeWidth="1.5" />
											<path id="Subtract" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M21.393306250000002 13.077272916666667C21.492589583333334 13.640677083333335 21.54501041666667 14.220277083333334 21.54501041666667 14.8128625C21.54501041666667 16.072927083333333 21.5449625 21.776064583333334 11.254954166666668 21.776064583333334C0.9649937500000001 21.776064583333334 0.9649937500000001 16.072927083333333 0.9649937500000001 14.8128625C0.9649937500000001 13.694583333333332 1.1517729166666668 12.6226875 1.48738125 11.618641666666667C1.465675 11.418877083333333 1.4549895833333335 11.211254166666668 1.4549895833333335 10.995964583333334C1.4549895833333335 7.9626 1.4549895833333335 3.5492833333333333 2.88836875 1.6730104166666666C3.1519104166666665 1.3280583333333333 3.5936541666666666 1.1847875 4.025047916666667 1.2330395833333334C6.1307937500000005 1.46855 7.767675 2.8007770833333336 8.9472875 4.4645874999999995C9.713570833333334 4.249777083333333 10.490204166666668 4.136070833333333 11.254954166666668 4.136070833333333C12.019752083333335 4.136070833333333 12.796433333333335 4.249777083333333 13.562716666666667 4.464635416666667C14.742329166666668 2.800825 16.379210416666666 1.46855 18.48495625 1.2330395833333334C18.91635 1.1847875 19.35814166666667 1.3280583333333333 19.621635416666667 1.6730104166666666C20.58715625 2.9368125 20.90225625 5.3517166666666665 21.00518125 7.720668750000001" strokeWidth="1.5" />
											<path id="glass" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M5.468825 7.611610416666667C4.64125625 7.647308333333334 3.9905479166666664 8.250914583333333 3.94291875 9.0778125C3.9206854166666667 9.4645 3.9050166666666666 9.9383 3.9050166666666666 10.506160416666667S3.9206854166666667 11.547868750000001 3.94291875 11.934508333333333C3.9905479166666664 12.761454166666667 4.64125625 13.3650125 5.468825 13.400758333333334C6.051491666666667 13.425866666666668 6.82999375 13.446183333333334 7.824983333333334 13.446183333333334C8.819972916666668 13.446183333333334 9.598522916666667 13.425866666666668 10.181141666666667 13.400758333333334C11.008710416666666 13.3650125 11.659466666666667 12.761454166666667 11.707095833333335 11.934508333333333C11.729329166666668 11.547868750000001 11.744997916666666 11.074020833333334 11.744997916666666 10.506160416666667S11.729329166666668 9.4645 11.707095833333335 9.0778125C11.659466666666667 8.250914583333333 11.008710416666666 7.647308333333334 10.181141666666667 7.611610416666667C9.598522916666667 7.586454166666667 8.819972916666668 7.566185416666667 7.824983333333334 7.566185416666667C6.82999375 7.566185416666667 6.051491666666667 7.586454166666667 5.468825 7.611610416666667Z" strokeWidth="1.5" />
										</g>
									</svg>
									<span className="link-text ml-4">Profile</span>
								</NavLink>
							</li>
						</ul>
					</div>
				</section>
				<div className={"sidebar-progress-bar"}></div>
			</aside >
			{
				showConnectWebsiteModal &&
				<ConnectWebsite setShowConnectWebsiteModal={setShowConnectWebsiteModal}
					failAlertRef={failAlertRef}
					successAlertRef={successAlertRef}
					basePageData={basePageData}
					navigateOrReload="reload" />
			}
		</>
	)
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./pages/routes";
import './index.scss';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Hotjar from '@hotjar/browser';
import ReactGA from "react-ga4";
import SiteMaintenance from './pages/SiteMaintenance';
import { PostHogProvider } from 'posthog-js/react';
import { quantum } from 'ldrs';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

// ---------- Quantum Loader ----------------
quantum.register();

// ----------------------------------------------------

// ---------- ReactGA Initialization ----------------
ReactGA.initialize("G-S6CPNCZ81Q"); // GA Measurement ID

// ---------------- HOTJAR INTEGRATION ----------------
if (process.env.REACT_APP_USE_HOTJAR === "1") {
  const siteId = 3746606;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion);
}
// ----------------------------------------------------

let browserRouter: any;

if (process.env.REACT_APP_ISMAINTENANCE) {
  browserRouter = createBrowserRouter([{
    element: <SiteMaintenance />,
    path: "*"
  }])
} else {
  browserRouter = createBrowserRouter(routes);
}

declare global {
  interface Window {
    Tawk_API?: any;
    Tawk_LoadStart?: Date;
  }
}

const router = browserRouter;
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function initializeTawkTo() {
  window.Tawk_LoadStart = new Date();
  const script = document.createElement("script");
  script.async = true;
  script.src = "https://embed.tawk.to/64e32eb694cf5d49dc6b9649/1h8bmsuu8";
  script.charset = "UTF-8";
  script.setAttribute("crossorigin", "*");
  document.head.appendChild(script);
}

initializeTawkTo()

if (process.env.REACT_APP_USE_POSTHOG === "1") {
  const options = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  }
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient} >
        <PostHogProvider
          apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
          options={options}
        >
          <RouterProvider router={router} />
        </PostHogProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );

} else {
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient} >
        <RouterProvider router={router} />
      </QueryClientProvider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AbunButton from "../../components/AbunButton/AbunButton";
import AbunModal from "../../components/AbunModal/AbunModal";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import { getRedditPostFinderQueries, makeApiRequest } from "../../utils/api";

interface RedditQuery {
    id: string,
    query: string,
    limit: number,
    is_processing: boolean,
    created_at: Date
}

export default function RedditPostFinder() {
    
    // -------------------------- STATES --------------------------
    const [queries, setQueries] = useState<Array<RedditQuery>>([]);
    const [topic, setTopic] = useState("")
    const [limit, setLimit] = useState(10);
    const navigate = useNavigate();
    const [rpfQueriesGenerated, setRpfQueriesGenerated] = useState(0);
    const [maxRpfAllowed, setMaxRpfAllowed] = useState(0);
    const [showWarning, setShowWarning] = useState(false);


    // -------------------------- QUERIES --------------------------
    const {
        isFetching,
        isError,
        data,
        refetch
    } = useQuery({
        queryKey: ['getRedditPostFinderQueries'],
        queryFn: getRedditPostFinderQueries,
        refetchOnWindowFocus: false
    });


    // -------------------------- REFS --------------------------
    const errorAlertRef = useRef<any>(null);
    const successAlertRef = useRef<any>(null);

    // ---------------------- EFFECTS ----------------------
    useEffect(() => {
        if (data) {
            setQueries(data['data']['queries']);
            setRpfQueriesGenerated(data.data.rpf_queries_generated);
            setMaxRpfAllowed(data.data.max_rpf_allowed);
        }
    }, [data]);

    useEffect(() => {
        document.title = "Reddit Post Finder | Abun"
    }, []);

    // Creative Logic Implemented on keyword Click
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        errorAlertRef.current?.close();
        // Check if the topic is empty
        if (!topic || !limit) {
            return;
        }
        try {
            const response = await makeApiRequest(
                `/api/frontend/post-reddit-finder-query/`,
                'post',
                {
                    query: topic,
                    limit: limit
                }
            );

            const data = response.data;
            if (data.success) {
                localStorage.setItem('task_id', data.task_id);
                successAlertRef.current?.show('Query submitted. It will take few minutes to complete.');
                navigate(`/reddit-post-finder/view/${data.id}/`);
            } else {
                if (data.reason === "max_limit_reached") {
                    errorAlertRef.current?.show('You have reached your maximum limit for this plan. Please Upgrade !');
                }
                else {
                    errorAlertRef.current?.show('Failed to fetch queries from backend API.');
                }
            }
        } catch (err) {
            errorAlertRef.current?.show('An error occurred while fetching GPF queries.');
        }
    }

    const handleProceedClick = async (e) => {
        const updatedLimit = maxRpfAllowed - rpfQueriesGenerated;
        setLimit(updatedLimit); // Update limit
        setShowWarning(false); // Close the modal

        e.preventDefault();
        errorAlertRef.current?.close();
        // Check if the topic is empty
        if (!topic || !limit) {
            return;
        }
        try {
            const response = await makeApiRequest(
                `/api/frontend/post-reddit-finder-query/`,
                'post',
                {
                    query: topic,
                    limit: updatedLimit
                }
            );

            const data = response.data;
            if (data.success) {
                localStorage.setItem('task_id', data.task_id);
                successAlertRef.current?.show('Query submitted. It will take few minutes to complete.');
                navigate(`/reddit-post-finder/view/${data.id}/`);
            } else {
                if (data.reason === "max_limit_reached") {
                    errorAlertRef.current?.show('You have reached your maximum limit for this plan. Please Upgrade !');
                }
                else {
                    errorAlertRef.current?.show('Failed to fetch queries from backend API.');
                }
            }
        } catch (err) {
            errorAlertRef.current?.show('An error occurred while fetching gpf queries.');
        }
    };

    // ============================================================
    // --------------------- MAIN RENDER CODE ---------------------
    // ============================================================
    if (isError) {
        return (
            <section className="section">
                <div className="container">
                    <div className="box">
                        <h1 className="title has-text-centered">Find Blogs with Reddit Post
                            Opportunities for your Topic/Niche</h1>
                        <p className="has-text-centered is-size-5">
                            Failed to load data. Please try again later.
                        </p>
                    </div>
                </div>
            </section>
        );
    } else {
        return (
            <>
                <div className="card w-100" style={{minHeight:'50vh'}}>
                    <div className={"card-content"}>
                    <div className={"is-flex is-justify-content-center is-align-items-center is-flex-direction-column has-text-centered"}>
                            <h1 style={{ fontWeight: "800", fontSize: "2rem", fontFamily: "Bricolage Grotesque"}}>Reddit Post Finder</h1>
                            <p className={"is-size-6 mt-2 mb-2"}>Find Reddit Post Opportunities for your Topic/Niche</p>
                        </div>
                        <div className="{w-25 is-flex is-align-items-center is-flex-direction-column my-3}">
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault(); // Prevent the default form submission behavior
                                    if (
                                        limit > maxRpfAllowed ||
                                        (maxRpfAllowed - rpfQueriesGenerated < limit && maxRpfAllowed - rpfQueriesGenerated > 0)
                                    ) {
                                        setShowWarning(true);
                                    } else {
                                        handleSubmit(e);
                                    }
                                }}>
                                <label className="ca-label">Topic</label>
                                <input
                                    className="ca-input"
                                    type="text"
                                    placeholder="Enter topic"
                                    value={topic}
                                    onChange={(e) => setTopic(e.target.value)}
                                    style={{ marginTop: '3px', textAlign: 'start' }}
                                    required
                                />
                                <label className="ca-label">How many opportunities do you want to
                                    find? </label>
                                <span className="text-muted"> (It should be between 10-400)</span><br />
                                <input
                                    className="ca-input"
                                    type="number"
                                    placeholder="Enter limit"
                                    value={limit}
                                    onChange={(e) => setLimit(parseInt(e.target.value))}
                                    style={{ marginTop: '3px', textAlign: 'start' }}
                                    required
                                />

                                <button type={"submit"} className="button is-success"
                                    disabled={isFetching || queries.some((query) => query.query === topic && query.is_processing)}>PROCEED</button>
                            </form>
                        </div>
                        <SuccessAlert ref={successAlertRef} />
                        <ErrorAlert ref={errorAlertRef} />

                        {/************************* Warning Alert ***************************/}
                        <AbunModal
                            active={showWarning}
                            headerText={"Usage Limit Alert"}
                            closeable={false}
                            hideModal={() => setShowWarning(false)}>
                            <div>
                                <p className={"has-text-centered mt-4"}>
                                    You have only {maxRpfAllowed - rpfQueriesGenerated} searches left in your account.<br />Do you want to proceed?
                                </p>
                                <div className={"mt-6 has-text-centered is-flex is-justify-content-center is-align-items-center"}>
                                    <AbunButton type={"danger"} clickHandler={handleProceedClick}>PROCEED</AbunButton>
                                    <AbunButton type={"primary"} className={"ml-4"} clickHandler={() => setShowWarning(false)}>CANCEL</AbunButton>
                                </div>
                            </div>
                        </AbunModal>

                    </div>
                </div>
            </>
        );
    }
}

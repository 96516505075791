import { Slider } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from "react";
import Select from 'react-select';
import { Tooltip } from 'react-tooltip';
import { saveSettingsMutation } from "../../utils/api";

interface ToneOfArticleProps{
    errorAlertRef: MutableRefObject<any>
    successAlertRef: MutableRefObject<any> 
    toneOfArticle: string
    scaleOfTone: number
    updatePageData?: () => void
    setUnsavedChanges?: Dispatch<SetStateAction<boolean>>   
    boxStyle?: React.CSSProperties;
}

export default function ToneOfArticle(props: ToneOfArticleProps) {
    // --------------------- STATES ---------------------
    const [selectedTone, setSelectedTone] = useState<string>("");
    const [customTone, setCustomTone] = useState<string>("");
    const [sliderValue, setSliderValue] = useState<number>(3);
    
    // ---------------------------- MUTATIONS ----------------------------
    const saveSettings = useMutation(saveSettingsMutation);
    
    const intensityLabels = {
        1: "Subtle",
        2: "Mild",
        3: "Moderate",
        4: "Strong",
        5: "Intense"
    };
    
    useEffect(() => {
        if (props.toneOfArticle && !["off", "exciting", "humorous", "funny", "formal", "serious", "conversational", "other"].includes(props.toneOfArticle)) {
            setSelectedTone("other"); 
            setCustomTone(props.toneOfArticle);
            setSliderValue(props.scaleOfTone)
        } else {
            setSelectedTone(props.toneOfArticle);
            setSliderValue(props.toneOfArticle === "off" ? 0 : props.scaleOfTone)
            setCustomTone(""); 
        }
    }, [props.toneOfArticle]);
    
    const tones = ["Off", "Exciting", "Humorous", "Funny", "Formal", "Serious", "Conversational", "Other"];
    const toneOptions = tones.map((tone) => ({
        value: tone.toLowerCase(),
        label: tone,
    }));
    
    const selectedToneOption = toneOptions.find(option => option.value === selectedTone) || { value: "other", label: "Other" };
    
    // --------------------- FUNCTIONS ---------------------
    function handleToneSave(tone: string, range?: number) {        
        // Trigger save settings immediately
        saveSettings.mutate({
            settingsToSave: [
                { settingName: 'tone_of_article', settingValue: tone },
                { settingName: 'scale_of_tone', settingValue: range? range : sliderValue},                    
            ]
        }, {
            onSuccess: () => {
                props.updatePageData?.();
                props.setUnsavedChanges?.(false);
                props.successAlertRef?.current?.show("Changes Saved!");
                setTimeout(() => {
                    props.successAlertRef?.current?.close();
                }, 3000);                
            },
            onError: () => {
                props.errorAlertRef?.current?.show("Oops! Something went wrong :( Please try again later or contact us for further support.");
            }
        });
    }
    
    const handleToneChange = (selectedOption) => {
        const value = selectedOption?.value;
        setSelectedTone(value);

        if (value === "off") {
            setSliderValue(0); 
        }

        if (value !== "other") {
            setCustomTone("");
            handleToneSave(value, value === "off" ? 0 : sliderValue);
        }
    };
    const handleSliderChange = (value: number) => {
        if (selectedTone === "off") return;        
        setSliderValue(value);
        if (selectedTone === 'other'){
            handleToneSave(customTone, value);
        }else{
            handleToneSave(selectedTone, value);
        }
             
    };

    const handleCustomToneBlur = () => {
        if (selectedTone === "other" && customTone.trim()) {
            handleToneSave(customTone.trim()); 
        }
    };
    // =====================================================
    // --------------------- MAIN CODE ---------------------
    // =====================================================
    return (
        <div className="card settings-section" style={props.boxStyle}>
            <div className="card-content">                
                <div>
                    <h2 style={{ fontWeight: "bold", fontSize: "1.4rem", marginBottom: "0.5rem" }}>Tone of article</h2>
                    <h5 className="settings-section-title">What should be the tone used in the article?
                    <span className="circle-question"
                        data-tooltip-id="tone_button"
                        data-tooltip-content="The tone affects how your article sounds to readers. Choose a tone that matches your brand voice and audience expectations">?</span>
                    </h5>
                    <p className="text-gray-600">Select the tone that best fits your content goals and target audience</p>
                    {/* Dropdown */}
                    <div className=" mt-3" style={{width: '100%', maxWidth:'300px'}}>
                    <Select
                        className="w-full border rounded-md"
                        options={toneOptions}
                        value={selectedToneOption}
                        menuPlacement={props.boxStyle ? "top" : "auto"} 
                        onChange={handleToneChange}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                padding: 0, // Removes internal padding
                                minHeight: 'auto', // Removes extra height
                            }),
                        }}
                        formatOptionLabel={(option, { context }) =>
                            context === "value" 
                                ? option.value === "off" 
                                    ? `${option.label}` // Don't show intensity for "off"
                                    : `${option.label} Intensity: ${sliderValue}`
                                : option.label
                        }
                    />
                    </div>                    
                        {selectedTone === "other" && (
                            <input
                            type="text"
                            maxLength={20}
                            placeholder="Enter custom tone"
                            className="tone-dropDown custom-tone input mt-3"
                            value={customTone}
                            onChange={(e) => setCustomTone(e.target.value)}
                            onBlur={handleCustomToneBlur}
                            />
                        )}
                    {/* Slider */}
                    {selectedTone !== "off" && (
                        <div className="mt-3 flex items-center" style={{width: '100%', maxWidth:'454px'}}>
                            <span style={{fontWeight: "500"}}>Tone Intensity</span>
                            <div className="is-flex is-flex-direction-row is-align-items-center is-justify-content-start"  style={{marginTop:"-2px", gap:'18px'}}>
                                <span className="mb-1">{intensityLabels[1]}</span>
                                <Slider
                                    style={{ width: "50%", marginTop: "2px" }}
                                    value={sliderValue}
                                    min={1}
                                    max={5}
                                    step={1}
                                    onChange={(_, newValue) => handleSliderChange(newValue as number)}
                                />
                                <span className="mb-1">{intensityLabels[sliderValue]}</span>
                            </div>
                        </div>
                    )}
                    </div>                
                                         
                <Tooltip 
                        id="tone_button" 
                        place="bottom-start" 
                        offset={10} 
                        style={{ maxWidth: "500px", whiteSpace: "normal" }} // Prevents overflow
                    />
                </div>


            {/* -=-=-=-=-=-=-=-=-=-=-= Save All Settings Button -=-=-=-=-=-=-=-=-=-=-= */}
            {/* <div className="save-changes-section mt-4">
                <button
                    className={`button is-primary ${saveSettings.isLoading ? 'is-loading' : ''}`}
                    onClick={saveBacklinkSettings}
                >
                    Save Changes
                </button>
            </div> */}
        </div>
    );
}
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import {
    generateAICalculatorMutation,
    getAICalculatorDataQuery,
    modifyAICalculatorMutation
} from "../../utils/api";
import "./AICalculatorGenerator.scss";


interface ChatMessage {
    role: "user" | "assistant";
    content: string;
}

interface LocationState {
    calculatorId?: string;
    calculatorType?: string;
    userModifications?: string[];
}

export default function AICalculatorGenerator() {
    // --------------------------- HOOKS ---------------------------
    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state as LocationState;

    // --------------------------- STATES ---------------------------
    const [calculatorId, setCalculatorId] = useState<string | null>(state?.calculatorId || null);
    const [calculatorType, setCalculatorType] = useState<string>(state?.calculatorType || "");
    const [calculatorHTML, setCalculatorHTML] = useState<string>("");
    const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
    const [userInput, setUserInput] = useState<string>("");
    const [activeTab, setActiveTab] = useState("update");
    const [selectedVersion, setSelectedVersion] = useState("smallversionname");
    const [isChecked, setIsChecked] = useState(true);

    // --------------------------- REFS ---------------------------
    const errorAlertRef = useRef<any>(null);
    const successAlertRef = useRef<any>(null);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const previewContainerRef = useRef<HTMLDivElement>(null);

    // --------------------------- QUERIES & MUTATIONS ---------------------------
    // Query for fetching calculator data
    const {
        isLoading: isLoadingCalculator
    } = useQuery({
        ...getAICalculatorDataQuery(state?.calculatorId || ''),
        enabled: !!state?.calculatorId,
        onSuccess: (response: any) => {
            if (response.data) {
                const { calculator_id, calc_type, user_modifications, code } = response.data.calculator_data;

                // Set calculator details
                setCalculatorId(calculator_id);
                setCalculatorType(calc_type);
                setCalculatorHTML(code);

                // Initialize chat messages
                const initialMessages: ChatMessage[] = [
                    {
                        role: "user",
                        content: `Generate a ${calc_type} calculator`,
                    },
                    {
                        role: "assistant",
                        content: "I've created your calculator. You can preview it or make modifications by sending me a message.",
                    }
                ];

                // Add modification messages if they exist
                if (user_modifications && user_modifications.length > 0) {
                    user_modifications.forEach(modification => {
                        initialMessages.push({
                            role: "user",
                            content: modification,
                        });
                        initialMessages.push({
                            role: "assistant",
                            content: "I've updated your calculator with the requested changes.",
                        });
                    });
                }

                setChatMessages(initialMessages);
            }
        },
        onError: () => {
            errorAlertRef.current?.show("Failed to load calculator data");
        }
    });

    // Mutation for generating a new calculator
    const generateAICalculatorMut = useMutation(generateAICalculatorMutation);

    // Mutation for modifying an existing calculator
    const modifyAICalculatorMut = useMutation(modifyAICalculatorMutation);

    // --------------------------- EFFECTS ---------------------------
    // Scroll to bottom of chat when messages change
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatMessages]);

    // Update preview when HTML changes and execute JavaScript
    useEffect(() => {
        if (previewContainerRef.current && calculatorHTML) {
            // Create an iframe
            const iframe = document.createElement('iframe');
            iframe.style.width = '100%';
            iframe.style.height = '100%';
            iframe.style.border = 'none';

            // Set the HTML content of the iframe
            iframe.srcdoc = calculatorHTML;

            // Clear previous content and append the iframe
            previewContainerRef.current.innerHTML = "";
            previewContainerRef.current.appendChild(iframe);
        }
    }, [calculatorHTML]);

    // --------------------------- HANDLERS ---------------------------
    const generateCalculator = () => {
        if (!calculatorType.trim()) {
            errorAlertRef.current?.show("Please enter a calculator type");
            return;
        }

        generateAICalculatorMut.mutate(
            { calc_instruction: calculatorType },
            {
                onSuccess: (response) => {
                    const data = response.data;
                    if (data && data.status === "success") {
                        setCalculatorId(data.calculator_id);
                        setCalculatorHTML(data.html_content);
                        setCalculatorType(data.calc_type);
                        setChatMessages([
                            {
                                role: "user",
                                content: `Generate a ${calculatorType} calculator`,
                            },
                            {
                                role: "assistant",
                                content: "I've created your calculator. You can preview it or make modifications by sending me a message.",
                            },
                        ]);
                        successAlertRef.current?.show("Calculator generated successfully!");
                        setTimeout(() => {
                            successAlertRef.current?.close();
                        }, 3000);
                    }
                },
                onError: () => {
                    errorAlertRef.current?.show(
                        "Failed to generate calculator. Please try again."
                    );
                }
            }
        );
    };

    const requestModification = () => {
        if (!userInput.trim() || !calculatorId) {
            return;
        }

        const newMessage: ChatMessage = {
            role: "user",
            content: userInput,
        };

        setChatMessages([...chatMessages, newMessage]);
        setUserInput("");

        modifyAICalculatorMut.mutate(
            { calculator_id: calculatorId, modifications: userInput },
            {
                onSuccess: (response) => {
                    const data = response.data;
                    if (data && data.status === "success") {
                        setCalculatorHTML(data.html_content);
                        setChatMessages([
                            ...chatMessages,
                            {
                                role: "user",
                                content: userInput,
                            },
                            {
                                role: "assistant",
                                content: "I've updated your calculator with the requested changes.",
                            },
                        ]);
                    }
                },
                onError: () => {
                    setChatMessages([
                        ...chatMessages,
                        {
                            role: "user",
                            content: userInput,
                        },
                        {
                            role: "assistant",
                            content: "Sorry, I couldn't apply those modifications. Please try a different request.",
                        },
                    ]);
                }
            }
        );
    };

    const copyToClipboard = () => {
        try {
            navigator.clipboard.writeText(calculatorHTML)
                .then(() => {
                    successAlertRef.current?.show("HTML copied to clipboard!");
                    setTimeout(() => {
                        successAlertRef.current?.close();
                    }, 3000);
                })
                .catch(err => {
                    console.error('Failed to copy: ', err);
                    errorAlertRef.current?.show("Failed to copy to clipboard");
                });
        } catch (error) {
            console.error('Copy to clipboard error:', error);
            errorAlertRef.current?.show("Failed to copy to clipboard");
        }
    };

    const resetGenerator = () => {
        setCalculatorId(null);
        setCalculatorType("");
        setCalculatorHTML("");
        setChatMessages([]);
        navigate("/ai-calculator-generator", { replace: true });
    };

    const backToList = () => {
        navigate("/ai-calculators");
    };

    const versions = [
        { id: "smallversionname", name: "smallversionname generatedFromGpt", time: "few seconds ago" },
        { id: "oldversionname", name: "oldversionname generatedFromGpt", time: "1 hour ago" },
        { id: "oldversionnameFromGpt", name: "oldversionnameFromGpt", time: "2:03pm 17th March 25" },
      ];

    // Determine if any mutation is loading
    const isLoading = (state?.calculatorId && isLoadingCalculator) ||
        generateAICalculatorMut.isLoading ||
        modifyAICalculatorMut.isLoading;

    // --------------------------- RENDER ---------------------------
    return (
        <div className="ai-calculator-generator-container">
            <div className="ai-calculator-generator-header">
                <button className="ai-calculator-back-button" onClick={backToList}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 12H5" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12 19L5 12L12 5" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>

            <div className="ai-calculator-generator-content">
                <h1 className="ai-calculator-generator-title">AI Calculator Generator</h1>
                <p className="ai-calculator-generator-description">
                    Create Calculators for your Audience & Grow your Organic Traffic 
                </p>
                <hr className="horizontal-line"/>

                {!calculatorHTML ? (
                    <div className="ai-calculator-input-card">
                        <div className="ai-calculator-input-group">
                            <label htmlFor="calculator-type"> <span>Create a</span> 
                            <input
                                id="calculator-type"
                                type="text"
                                value={calculatorType}
                                onChange={(e) => setCalculatorType(e.target.value)}
                                // placeholder="e.g., BMI, mortgage, tip, currency converter"
                                className="ai-calculator-type-input"
                                disabled={isLoading}
                            /> <span>Calculator</span>
                            </label>
                        </div>
                            <p className="ai-calculator-text" >that does the following:</p>
                            <textarea
                                // value={description}
                                // onChange={(e) => setDescription(e.target.value)}
                                className="textarea-box"
                                placeholder="(Optional)"
                            ></textarea>
                        <button
                            className="ai-generate-calculator-button"
                            onClick={generateCalculator}
                            disabled={isLoading}
                        >
                            Generate ➜
                        </button>
                    </div>
                ) : (
                    <div className="ai-calculator-result-section">
                        <div className="ai-left-container">
                            <div className="tabs is-centered">
                                <ul>
                                  <li className={activeTab === "update" ? "is-active" : ""}>
                                    <a onClick={() => setActiveTab("update")}>Update Calculator</a>
                                  </li>
                                  <li className={activeTab === "versions" ? "is-active" : ""}>
                                    <a onClick={() => setActiveTab("versions")}>Versions</a>
                                  </li>
                                  <li className={activeTab === "embed" ? "is-active" : ""}>
                                    <a onClick={() => setActiveTab("embed")}>Get Embed Code</a>
                                  </li>
                                </ul>
                            </div>
                            <div className="box" style={{marginTop:"40px"}}>

                                {activeTab === "update" && 
                                    
                                    <div className="ai-calculator-chat-container">
                                        <div className="ai-content">
                                            <h2 className="title-text">What changes do you want in the calculator?</h2>
                                            <textarea
                                              className="textarea custom-textarea"
                                              placeholder="In the list include Draftss.com on the top..."
                                              value={userInput}
                                              onChange={(e) => setUserInput(e.target.value)}
                                            />
                                            <button
                                              className="button is-primary custom-button"
                                              onClick={requestModification}
                                              disabled={isLoading || !userInput.trim()}
                                            >
                                              {isLoading ? "Updating..." : "Update Calculator"}
                                            </button>
                                            <div className="field mt-6">
                                              <label className="checkbox">
                                                <input
                                                  type="checkbox"
                                                  checked={isChecked}
                                                  onChange={() => setIsChecked(!isChecked)}
                                                  className="mr-2"
                                                />
                                                Make the Calculator Name as H1 Tag
                                              </label>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {activeTab === "versions" && 
                                  <div className="ai-version-container">
                                  {versions.map((version) => (
                                    <label key={version.id} className="is-flex is-align-items-center mb-3 ml-0" style={{cursor:"pointer"}}>
                                      <input
                                        type="radio"
                                        name="version"
                                        value={version.id}
                                        checked={selectedVersion === version.id}
                                        onChange={() => setSelectedVersion(version.id)}
                                        className="mr-2 custom-radio"
                                      />
                                      <div>
                                        <p style={{fontWeight: '500'}}>{version.name}</p>
                                        <p className="is-size-7 has-text-grey">{version.time}</p>
                                      </div>
                                    </label>
                                  ))}
                                </div>
                                }
                                {activeTab === "embed" && (
                                  <div className="ai-embed-container">
                                    <div className="ai-section">
                                        <h3 className="subtitle">Add this to the &lt;head&gt; tag</h3>
                                        <textarea
                                          className="textarea"
                                          value={`<script>console.log("Embed Code Here");</script>`}
                                          readOnly
                                        />
                                        <button className=" copy-btn button is-primary">Copy</button>
                                    </div>
                                    <div className="ai-section">
                                        <h3 className="subtitle mt-4">Add this to the tag wherever on the page you want to display the calculator</h3>
                                        <textarea
                                          className="textarea"
                                          value={`<div id="calculator"></div>`}
                                          readOnly
                                        />
                                        <button className="copy-btn button is-primary">Copy</button>
                                    </div>
                                  </div>
                                )}
                            </div>
                        </div>
                        
                        <hr className="ai-horizontal-line"/>

                        <div className="ai-calculator-preview-section">
                            {/* <div className="ai-preview-header">
                                <h3>Calculator Preview</h3>
                                <div className="ai-preview-actions">
                                    <button
                                        className="ai-action-button ai-text-button"
                                        onClick={copyToClipboard}
                                        title="Copy HTML"
                                    >
                                        Copy
                                    </button>
                                    <button
                                        className="ai-action-button ai-text-button"
                                        onClick={resetGenerator}
                                        title="Reset Calculator"
                                    >
                                        Reset
                                    </button>
                                </div>
                            </div> */}
                            <div className="ai-preview-container">
                                <div className="ai-preview-content" ref={previewContainerRef}></div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <ErrorAlert ref={errorAlertRef} />
            <SuccessAlert ref={successAlertRef} />
        </div>
    );
};

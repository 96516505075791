/* eslint-disable react-hooks/exhaustive-deps */
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useMutation, useQuery } from "@tanstack/react-query";
import { ColumnDef, createColumnHelper, RowData, RowModel } from "@tanstack/react-table";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import EarthFlag from "../../assets/images/earth-flag.png";
import AbunButton from "../../components/AbunButton/AbunButton";
import AbunLoader from '../../components/AbunLoader/AbunLoader';
import AbunModal from "../../components/AbunModal/AbunModal";
import AbunTable from "../../components/AbunTable/AbunTable";
import Card from "../../components/Card/Card";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import SuccessAlert from '../../components/SuccessAlert/SuccessAlert';
import Icon from "../../components/Icon/Icon";
import LoadingData from "../../components/LoadingData/LoadingData";
import LoadingError from "../../components/LoadingError/LoadingError";
import Survey from "../../components/Survey/Survey";
import TextArea from "../../components/TextArea/TextArea";
import { SurveyContext } from "../../pages/Login/SurveyContext";
import { addCompetitors, generateCompetitorKeywords, getCompResearchData, removeCompetitors, retryFn } from "../../utils/api";
import countries from "../../utils/constants/CountriesforSerp";
import { getDefaultCompetitorLogo } from "../../utils/misc";
import { PageData } from "../KeywordsResearchV2/KeywordResearch";
import { pageURL } from "../routes";
import "./CompetitorResearch.scss";

interface CompetitorData {
	domain: string
	keywords_generated: boolean
	keyword_project_id?: string
	organic_traffic?: string | null
	organic_keywords?: string | null
	domain_authority?: string | null
	total_backlinks?: string | null
	follow?: string | null
	no_follow?: string | null
	referring_domains?: string | null
}

interface TableRow {
	logo: string
	domain: string
}

interface CountryType {
	location_code: number;
	location_name: string;
	country_iso_code: string;
	suggested?: boolean;
}


export default function CompetitorResearch() {
	// ---------------------- NON-STATE CONSTANTS ----------------------
	const pageSizes = [30, 100, 500]

	// ---------------------- STATES ----------------------
	const pageData = useLoaderData() as PageData

	const [
		competitorData,
		setCompetitorData
	] = useState<CompetitorData[]>([]);

	const [
		generatingKeywordsModalActive,
		setGeneratingKeywordsModalActive
	] = useState(false);

	const [
		selectedRows,
		setSelectedRows
	] = useState<RowModel<RowData>>();

	const [
		showAddCompetitorsModal,
		setShowAddCompetitorsModal
	] = useState<boolean>(false);

	const [
		domainTextArea,
		setDomainTextArea
	] = useState<string>("");

	const [
		bulkDeleteConfirmationModalActive,
		setBulkDeleteConfirmationModalActive
	] = useState<boolean>(false);

	const [
		deleteCompetitorModel,
		setdeleteCompetitorModel
	] = useState<boolean>(false);

	const [
		kwProjectId,
		setKwProjectId
	] = useState("")

	const [
		disableAddCompetitorsButton,
		setDisableAddCompetitorsButton
	] = useState<boolean>(false);

	const [
		showAddLocationsModal,
		setShowAddLocationsModal
	] = useState<boolean>(false);

	const [
		selectedCompetitor,
		setSelectedCompetitor
	] = useState<string>("");

	const [
		selectedLocation,
		setSelectedLocation
	] = useState<CountryType>({
		"location_code": 1,
		"location_name": "Global",
		"country_iso_code": "ZZ"
	});

	const [domain, setDomain] = useState<string>();

	// -------------------------- SURVEY CONTEXT --------------------------
	const context = useContext(SurveyContext);

	// ----------------------- REFS -----------------------
	const failAlertRef = useRef<any>(null);
	const successAlertRef = useRef<any>(null);
	const navigate = useNavigate();

	// ---------------------- QUERIES ----------------------
	const competitorsFetch = useQuery({
		queryKey: ['getCompResearchData'],
		queryFn: getCompResearchData,
		refetchOnWindowFocus: false,
		retry: retryFn
	});

	// ---------------------- MUTATIONS ----------------------
	const generateKeywords = useMutation({
		mutationKey: ['generateCompetitorKeywords'],
		mutationFn: generateCompetitorKeywords,
		cacheTime: 0,
		onSuccess: () => {
			failAlertRef.current?.close();
			setGeneratingKeywordsModalActive(false);
			competitorsFetch.refetch().then();
		},
		onError: (error: Error) => {
			setGeneratingKeywordsModalActive(false);
			failAlertRef.current?.show(error.message);
			setTimeout(() => {
				failAlertRef.current?.close();
			}, 5000);
		}
	});

	const removeCompetitorsMut = useMutation({
		mutationKey: ['removeCompetitors'],
		mutationFn: removeCompetitors,
		cacheTime: 0,
		retry: retryFn,
		onError: (error) => {
			console.error(error);
			successAlertRef.current?.close();
			failAlertRef.current?.show("Server Error. Please try again in some time.");
		}
	});

	const addCompetitorsMut = useMutation({
		mutationKey: ['addCompetitors'],
		mutationFn: addCompetitors,
		cacheTime: 0,
		retry: retryFn,
		onError: (error) => {
			console.error(error);
			successAlertRef.current?.close();
			failAlertRef.current?.show("Server Error. Please try again in some time.");
		}
	})

	// ---------------------- EFFECTS ----------------------
	useEffect(() => {
		if (competitorsFetch.data) {
			const backBtn = document.querySelector(".back-btn.steal-comp-keywords");
			if (backBtn && (backBtn.parentNode?.firstChild as HTMLElement)?.tagName === "ARTICLE") {
				backBtn.classList.add("warning-is-present");
			}
			if (competitorsFetch.data.data === "No website found") {
				setDisableAddCompetitorsButton(true);
			} else {
				setCompetitorData(competitorsFetch.data['data']);
				if (competitorsFetch.data['data'].length > 0 && competitorsFetch.data['data'][0]["content_plan_generation_status"] === "done") {
					setDisableAddCompetitorsButton(false);
				}
			}
		}
	}, [competitorsFetch.data]);

	useEffect(() => {
        // Find the country that matches the `country_iso_code` with `pageData.country_code`
		if (pageData.country_code !== "ZZ"){
			const matchedCountry = countries.find(
				(country) => country.country_iso_code === pageData.country_code.toUpperCase()
			);
			// If a match is found, update the selected location
			if (matchedCountry) {
				setSelectedLocation(matchedCountry);
			}
		}
    }, [pageData.country_code]);

	const { showSurvey } = context ? context : { showSurvey: false }

	// ------------------------FUNCTIONS -----------------------------

	function selectedRowsSetter(rowModel: RowModel<RowData>) {
		setSelectedRows(rowModel);
	}

	function addCompetitorsHandler() {
		let domains: Array<string> = domainTextArea.split("\n");
		if (domains.length > 0) {
			domains = domains.map(value => value.toLowerCase().trim());
			addCompetitorsMut.mutate({ domains: domains }, {
				onSuccess: () => {
					// Close modal
					setShowAddCompetitorsModal(false);
					setDomainTextArea("");

					// Modify (table) data
					competitorsFetch.refetch().then();

					failAlertRef.current?.close();
					successAlertRef.current?.show("Competitor(s) have been added successfully!");
				}
			})
		}
	}

	function removeCompetitorsHandler(domain: string, projectId: string) {		
		removeCompetitorsMut.mutate({ domain: domain, projectId: projectId }, {
			onSuccess: (response) => {
				// Uncheck checkboxes
				// selectedRows?.rows.forEach(row => {
				// 	row.toggleSelected()
				// });				
				// update table data
				const successMessage = response?.data?.message || "Competitor removed successfully!";

				setdeleteCompetitorModel(false);
				setKwProjectId("")
				setDomain("")
				failAlertRef.current?.close();
				if (response.data.message.includes("Article or Auto Blog"))				{
					failAlertRef.current?.show(response.data.message);
				}else{				
					const updatedCompetitors = competitorData.filter((competitor) => !domain.includes(competitor.domain));
					setCompetitorData(updatedCompetitors);
					successAlertRef.current?.show(response.data.message);
				}
			},
			onError: (error) => {
				const errorMessage = error?.response?.data?.message || "Failed to remove competitor. Please try again.";
				failAlertRef.current?.show(errorMessage);			

			}
		});
	}

	function handleCompetitorDelete(domain: string, projectId: string) {
		setdeleteCompetitorModel(true)		
		setDomain(domain);
		setKwProjectId(projectId)
	  }
	  

	// ---------------------- TABLE DATA ----------------------
	const columnHelper = createColumnHelper<CompetitorData>();

	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: CompetitorData) => row.domain, {
			id: 'domain',
			header: "Competitor",
			cell: useMemo(() => info => {
				if (info.getValue() === null) {
					return "---";
				} else {
					let cellValue = info.getValue();
					if (cellValue.length > 25) {
						cellValue = cellValue.substring(0, 24) + "...";
					}
					return <div className={"is-flex is-align-items-center"}>
						{/* <div className={"comp-research-logo-container is-inline-block mr-3"}>
							<img
								src={`${process.env['REACT_APP_LOGO_URL']}/${info.row.original.domain}`}
								onError={({ currentTarget }) => {
									currentTarget.onerror = null;
									currentTarget.src = getDefaultCompetitorLogo();
								}}
								className={"comp-research-table-logo"}
								alt={"logo"}
							/>
						</div> */}
						<div className={"is-inline-block"}>
							{cellValue}
						</div>
					</div>;
				}
			}, []),
			enableGlobalFilter: true,
		}),
		columnHelper.display({
			id: 'generate',
			header: "Generate Competitor Keywords Rankings",
			cell: props => {
				if (props.row.original.keywords_generated && props.row.original.keyword_project_id) {
					return (<div className="ml-6">
						<Link to={
							pageURL['keywordResearch'] + "?keywordProjectId=" + props.row.original.keyword_project_id
						}
							className={"button ml-6 is-primary is-small comp-research-table-button"}>
							View Keywords
						</Link>
					</div>
					)

				} else {
					return (<div className="ml-6">
						<AbunButton type={"success"}
							className={"is-outlined ml-6 is-small comp-research-table-button"}
							disabled={generateKeywords.isLoading}
							clickHandler={() => {
								setShowAddLocationsModal(true);
								setSelectedCompetitor(props.row.original.domain);
							}}>
							Generate Keywords
						</AbunButton>
					</div>
					)
				}
			},			
			meta: {
				align: "left"
			}		
		}),
		columnHelper.display({
			id: 'delete',
			header: "Action",
			cell: (props) => (
				<div style={{ textAlign: 'center' }}>
					<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
						onClick={() => {
							const keywordProjectId: string = props.row.original.keyword_project_id ?? "";
							handleCompetitorDelete(props.row.original.domain, keywordProjectId);
						}}>
						<g clip-path="url(#clip0_48_5565)">
							<g clip-path="url(#clip1_48_5565)">
								<path d="M3.15356 6.32313C3.44461 10.8562 3.72319 13.2144 3.88856 14.3369C3.97256 14.9046 4.34531 15.3672 4.90346 15.5011C5.66306 15.6839 6.9713 15.8906 9.00075 15.8906C11.0302 15.8906 12.3381 15.6839 13.098 15.5014C13.6559 15.3676 14.0286 14.9049 14.1126 14.3373C14.2783 13.2144 14.5566 10.8562 14.8476 6.32214" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M11.3087 3.47962C12.4769 3.50128 13.3871 3.53672 14.0394 3.56986C14.8236 3.60923 15.552 4.02694 15.7712 4.78097C15.804 4.89417 15.8349 5.01394 15.8618 5.14092C15.9911 5.74467 15.5392 6.26344 14.924 6.31561C13.9331 6.39928 12.1195 6.49444 8.99249 6.49444C5.86579 6.49444 4.05191 6.39928 3.0613 6.31561C2.44574 6.26377 1.99129 5.74139 2.15043 5.14486C2.20785 4.92994 2.2784 4.73372 2.35255 4.55948C2.61932 3.93506 3.26146 3.61284 3.93937 3.57544C4.56543 3.54131 5.47663 3.50259 6.69135 3.47962C6.87108 3.07198 7.16548 2.7254 7.53869 2.48211C7.9119 2.23882 8.34781 2.10932 8.79332 2.10938H9.20741C9.65286 2.10938 10.0887 2.23891 10.4618 2.4822C10.835 2.72549 11.129 3.07203 11.3087 3.47962Z" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M7.03125 9.32812L7.35937 12.6094" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
								<path d="M10.9687 9.32812L10.6406 12.6094" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
							</g>
						</g>
						<defs>
							<clipPath id="clip0_48_5565">
								<rect width="16.8" height="16.8" fill="white" transform="translate(0.600098 0.599976)" />
							</clipPath>
							<clipPath id="clip1_48_5565">
								<rect width="16.8" height="16.8" fill="white" transform="translate(0.600098 0.599976)" />
							</clipPath>
						</defs>
					</svg>
				</div>
			),
			meta: {
				align: "center"
			}	
		}),
		
	]

	// ==============================================================
	// ---------------------- MAIN RENDER CODE ----------------------
	// ==============================================================

	if (competitorsFetch.isFetching) return <LoadingData />

	if (competitorsFetch.isError) return <LoadingError />

	return (
		<div className={"w-100"}>
			{showSurvey && <Survey />}
			<svg className={"back-btn steal-comp-keywords"} onClick={() => navigate(pageURL['keywordResearch'])} stroke="#bcbcbc" fill="#bcbcbc" width="28" height="24" viewBox="0 0 28 24">
				<path d="M27.5 12H2M2 12L13 1M2 12L13 23" stroke="black" strokeOpacity="0.5" strokeWidth="2" />
			</svg>
			<AbunModal active={generatingKeywordsModalActive}
				headerText={""}
				closeable={false} hideModal={() => setGeneratingKeywordsModalActive(false)}>
				<div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
					<AbunLoader show={generatingKeywordsModalActive} height="20vh" />
				</div>
				<p className={"is-size-4 has-text-centered mb-4"}>
					Generating keywords. Please wait until it's done.
				</p>
			</AbunModal>
			<AbunModal active={showAddCompetitorsModal}
				headerText={""}
				closeable={true}
				closeableKey={true}
				hideModal={() => setShowAddCompetitorsModal(false)}>
				<h4 className={"is-size-4 mt-2 has-text-centered font-secondary has-text-primary has-text-weight-bold"}>
					Enter Competitors
				</h4>
				<p className={"has-text-centered"}>
					You can add both full URLs (ex: <b>https://example.com/blog/</b>) or only registered
					domain (ex: <b>example.com</b>). Invalid values will be ignored.
				</p>
				<TextArea value={domainTextArea}
					className={"mt-4"}
					placeholder={"Enter each competitor on a new line..."}
					rows={15}
					onChange={setDomainTextArea} />
				<AbunButton type={"success"}
					className={"mt-5 is-block ml-auto mr-auto"}
					disabled={!domainTextArea.length || addCompetitorsMut.isLoading}
					clickHandler={addCompetitorsHandler}>
					<Icon iconName={"floppy-disk"} additionalClasses={["icon-white"]} />
					&nbsp;&nbsp;{addCompetitorsMut.isLoading ? "Saving..." : "Save Competitors"}
				</AbunButton>
			</AbunModal>
			<Card className="w-100 card-container">
				<AbunTable tableContentName={"Competitors"}
					tableData={competitorData}
					tableName="Steal Competitor Keywords"
					columnDefs={columnDefs}
					pageSizes={pageSizes}
					initialPageSize={pageSizes[2]}
					noDataText={"No Competitors Found"}
					searchboxPlaceholderText={"Search Competitors..."}
					id="competitor-research-table"
					buttons={[
						{
							text: "+ Add Competitors",
							type: "success",
							isDisabled: disableAddCompetitorsButton,
							clickHandler: () => setShowAddCompetitorsModal(true),
							extraClassName: "is-small is-justify-content-space-between",
							// iconName: "plus",
							// iconClasses: ["icon-white"]
						}
					]}
				/>
			</Card>
			{/* ------------------------- Delete Competitor Model ------------------------- */}
			<AbunModal active={deleteCompetitorModel}
				headerText={"Delete Project"}
				closeable={false}
				hideModal={() => {
					setdeleteCompetitorModel(false);
				}}>
				<div>
					<p className={"is-size-4 has-text-centered"}>Are You Sure? want to delete this competitor {domain} </p>									
					<div className={"is-flex is-flex-direction-row is-justify-content-center mt-6 has-text-centered"}>
						<AbunButton type={"danger"} clickHandler={() => {
							if (domain) {
								console.log("com domains",domain)
								removeCompetitorsHandler(domain as string, kwProjectId as string);								
							}
						}}>
							Yes, Delete
						</AbunButton>
						<AbunButton type={"primary"} className={"ml-4"} clickHandler={() => {
							setdeleteCompetitorModel(false);
						}}>
							Cancel
						</AbunButton>
					</div>
				</div>
			</AbunModal>
			<div className={"blur-background " + (bulkDeleteConfirmationModalActive ? "" : "hidden")}>
				<div className={"confirmation-card w-100 mt-4"}>
					<button className={"delete is-pulled-right"}
						onClick={() => {
							setBulkDeleteConfirmationModalActive(false);
							selectedRows?.rows.forEach(row => {
								row.toggleSelected()
							});
						}} />
					<div className={"confirmation-card-content w-100"}>
						<h3 className={"is-size-4 has-text-centered"}>
							Are you sure you want to remove the selected competitors?
						</h3>
						<div className={"is-flex is-justify-content-center"}>
							<AbunButton type={"primary"}
								className={"mt-5 is-block ml-auto mr-auto go-back-button"}
								clickHandler={() => {
									setBulkDeleteConfirmationModalActive(false);
								}}>
								&nbsp;&nbsp;Go Back
							</AbunButton>
							<AbunButton type={"danger"}
								className={"mt-5 is-block ml-auto mr-auto"}
								clickHandler={() => {
									// Fetch the row's domain values and call delete function.
									let domains = selectedRows?.rows.map(row => {
										return (row.original as TableRow).domain;
									});
									if (domains) {
										// removeCompetitorsHandler(domains as Array<string>);
										setSelectedRows(undefined);
										setBulkDeleteConfirmationModalActive(false);
									}
								}}>
								<Icon iconName={"trash"} additionalClasses={["icon-white"]} />
								&nbsp;&nbsp;Yes, Remove
							</AbunButton>
						</div>
					</div>
				</div>
			</div>
			<div className={"blur-background " + (showAddLocationsModal ? "" : "hidden")}>
				<div className={"confirmation-card w-100 mt-4"}>
					<button className={"delete is-pulled-right"}
						onClick={() => {
							setShowAddLocationsModal(false);
							selectedRows?.rows.forEach(row => {
								row.toggleSelected()
							});
						}} />
					<div className={"confirmation-card-content w-100"}>
						<h3 className={"is-size-5 has-text-centered"}>
							Select the location you want to get keywords volume for:
						</h3>
						<div className={"comp-research-location-select"}>
							<div className={"comp-info"}>
								<img
									src={`${process.env['REACT_APP_LOGO_URL']}/${selectedCompetitor}`}
									onError={({ currentTarget }) => {
										currentTarget.onerror = null;
										currentTarget.src = getDefaultCompetitorLogo();
									}}
									className={"comp-logo"}
									alt={"logo"}
								/>
								<h4 className={"is-size-4 has-text-centered font-secondary"}>
									{selectedCompetitor}
								</h4>
							</div>
							<div className={"form-group location-select"}>
								<Autocomplete
									id="country-select-demo"
									sx={{ width: 300 }}
									options={countries}
									value={selectedLocation}
									autoHighlight
									getOptionLabel={(option) => option.country_iso_code !== "ZZ" ? `${option.location_name} (${option.country_iso_code})` : option.location_name}
									isOptionEqualToValue={(option, value) => option.location_code === value.location_code}
									renderOption={(props, option) => (
										<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
											<img
												loading="lazy"
												width="20"
												srcSet={option.country_iso_code !== "ZZ" ? `https://flagcdn.com/w40/${option.country_iso_code.toLowerCase()}.png 2x` : EarthFlag}
												src={option.country_iso_code !== "ZZ" ? `https://flagcdn.com/w20/${option.country_iso_code.toLowerCase()}.png` : EarthFlag}
												alt=""
											/>
											{option.location_name} ({option.country_iso_code})
										</Box>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Location"
											inputProps={{
												...params.inputProps,
												// disable autocomplete and autofill and suggestion
												autoComplete: 'off',
											}}
										/>
									)}
									onChange={(event, option) => {
										if (option) {
											setSelectedLocation(option);
										}
									}}
								/>
							</div>
							<AbunButton type={"primary"}
								className={"mt-5 is-block ml-auto mr-auto"}
								clickHandler={() => {
									if (selectedCompetitor) {
										generateKeywords.mutate({
											domain: selectedCompetitor,
											selectedLocation: selectedLocation,
										});
										setGeneratingKeywordsModalActive(true);
										setShowAddLocationsModal(false);
									}
								}}>Proceed
							</AbunButton>							
						</div>
					</div>
				</div>
			</div>
			<SuccessAlert ref={successAlertRef} />			
			<ErrorAlert ref={failAlertRef} />
		</div>
	)
}

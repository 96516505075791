import { useMutation, useQuery } from "@tanstack/react-query";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useEffect, useRef, useState } from "react";
import { NavLink, useLoaderData, useNavigate, useRouteLoaderData } from "react-router-dom";
import AbunButton from "../../components/AbunButton/AbunButton";
import AbunModal from "../../components/AbunModal/AbunModal";
import AbunTable from "../../components/AbunTable/AbunTable";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import Icon from "../../components/Icon/Icon";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import {
    addInternalLink,
    addSitemap,
    checkJobStatus,
    findWebsiteSitemaps,
    getScrapedWebPages,
    getWebsiteAnalysisStatsQueryFn,
    removeWebpageUrlMutation,
    reScrapeWebsitePages,
    saveSummaryMutation,
    scrapeMoreWebsitePages,
} from "../../utils/api";
import { BasePageData } from "../Base/Base";
import { ConnectWebsite } from "../KeywordsResearchV2/KeywordResearch";
import { pageURL } from "../routes";
import "./InternalLinks.scss";

interface InternalLink {
    url: string;
    title: string;
    summary: string;
    lastScanned: string;
}

interface ServerData {
    website_connected: boolean
    sitemap_urls: Array<string>
    domain: string
    is_crawling: boolean
    finding_sitemaps: boolean
    has_more_pages: boolean
}

interface WebsiteAanalysisStats {
    total_pages: number,
    pages_scanned: number,
    progress: number,
    estimated_time_left: number,
    steps: {
        crawling: string,
        analyzing: string,
        generating: string
    },
    is_analysis_complete: boolean,
    time_display: string
}

export default function InternalLinks() {
    // ---------------------- NON STATE CONSTANTS ----------------------
    const pageSizes = [15, 25, 50, 100];
    const isProduction = process.env.REACT_APP_DRF_DOMAIN === "https://api.abun.com";

    // ----------------------------- LOADER -----------------------------
    const pageData: ServerData = useLoaderData() as ServerData;
    const basePageData: BasePageData = useRouteLoaderData("base") as BasePageData;

    // ----------------------- REFS -----------------------
    const successAlertRef = useRef<any>(null);
    const failAlertRef = useRef<any>(null);

    // -------------------------- NAVIGATOR --------------------------
    const navigate = useNavigate();

    // -------------------------- STATES --------------------------
    const [tableData, setTableData] = useState<Array<InternalLink>>([]);
    const [isCrawling, setIsCrawling] = useState(pageData.is_crawling);
    const [sitemapUrl, setSitemapUrl] = useState(pageData.sitemap_urls[0] || "");
    const [internalLink, setInternalLink] = useState("");
    const [showAddInternalLinkModal, setShowAddInternalLinkModal] = useState(false);
    const [showConnectWebsiteModal, setShowConnectWebsiteModal] = useState(!pageData.website_connected);
    const [showSitemapUrlModal, setShowSitemapUrlModal] = useState(pageData.sitemap_urls.length === 0 && !pageData.finding_sitemaps && basePageData.user_verified);
    const [isLoading, setIsLoading] = useState(pageData.finding_sitemaps);
    const [loadingDataText, setLoadingDataText] = useState(pageData.finding_sitemaps ? "Finding Sitemaps..." : "Loading Data...");
    const [websiteAnalysisStats, setWebsiteAnalysisStats] = useState<WebsiteAanalysisStats>();
    const [showSchemaModal, setShowSchemaModal] = useState(false)
    const [rescanLink, setRescanLink] = useState("")
    const [editedSummary, setEditedSummary] = useState("")
    const [disabledButtons, setDisabledButtons] = useState<{ [key: string]: boolean }>({});
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const tableRef = useRef<{ refetchData: () => Promise<void> }>(null);
    const [jobId, setJobId] = useState("");
    // -------------------------- QUERIES --------------------------
    const { isFetching, isError, data, refetch } = useQuery(getScrapedWebPages());
    const {
        data: websiteAnalysisStatsData,
    } = useQuery({
        queryKey: ['websiteAnalysisStats'],
        queryFn: () => getWebsiteAnalysisStatsQueryFn(),
        refetchInterval: 5000,
        refetchIntervalInBackground: false,
        enabled: isCrawling,
    });

    const rescanQuery = checkJobStatus(jobId);

    const { data: jobStatus } = useQuery({
        ...rescanQuery,
        refetchInterval: 5000,
        enabled: !!jobId,
        onSuccess: (result: any) => {
            if (result.status === "completed") {
                setDisabledButtons(prev => ({ ...prev, [rescanLink]: false }));
                refetch();
                setJobId("");
            }
        },
        onError: (error) => {
            setDisabledButtons(prev => ({ ...prev, [rescanLink]: false }));
            setJobId("");
        },
    });

    // -------------------------- MUTATIONS --------------------------
    const addSiteMapMutation = useMutation(addSitemap);
    const addInternalLinkMutation = useMutation(addInternalLink);
    const reScrapeWebsitePagesMutation = useMutation(reScrapeWebsitePages);
    const scrapeMoreWebsitePagesMutation = useMutation(scrapeMoreWebsitePages);
    const removeWebpageUrl = useMutation(removeWebpageUrlMutation);
    const saveSummary = useMutation(saveSummaryMutation);

    // ----------------------- EFFECTS -----------------------
    useEffect(() => {
        document.title = "Internal Links | Abun";
    }, []);

    useEffect(() => {
        if (showSitemapUrlModal) {
            setIsLoading(true);
            setLoadingDataText("Finding Sitemaps...");

            // Try to find the sitemap before showing the modal
            findWebsiteSitemaps().then((response) => {
                const responseData: any = response.data;
                if (responseData.success) {
                    setShowSitemapUrlModal(false);
                    setIsCrawling(true);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }, []);

    useEffect(() => {
        if (showSchemaModal) {
            setTimeout(adjustTextareaHeight, 0);
        }
    }, [showSchemaModal, editedSummary]);


    useEffect(() => {
        if (data) {
            setTableData((data as any)['data']['web_pages']);
        }
    }, [data]);

    useEffect(() => {
        if (websiteAnalysisStatsData && (websiteAnalysisStatsData as any)['data']) {
            setWebsiteAnalysisStats((websiteAnalysisStatsData as any)['data']['stats']);
        }
    }, [websiteAnalysisStatsData]);

    useEffect(() => {
        if (websiteAnalysisStats && websiteAnalysisStats.is_analysis_complete) {
            // Refresh the page when analysis is complete
            window.location.reload();
        }
    }, [websiteAnalysisStats]);

    // ---------------------- TABLE COLUMN DEFS ----------------------
    const columnHelper = createColumnHelper<InternalLink>();
    const columnDefs: ColumnDef<any, any>[] = [
        columnHelper.accessor((row: InternalLink) => row.url, {
            id: 'url',
            header: "URL",
            cell: props => <NavLink to={props.getValue()} target="_blank">{props.getValue()}</NavLink>,
            enableGlobalFilter: true,
            meta: { align: 'left' }
        }),
        // columnHelper.accessor((row: InternalLink) => row.title, {
        //     id: 'title',
        //     header: "Page Title",
        //     cell: info => info.getValue(),
        //     enableGlobalFilter: true,
        //     meta: { align: 'left' }
        // }),
        columnHelper.accessor((row: InternalLink) => row.summary, {
            id: "summary",
            header: "Summary",
            cell: (props) => {
                const summaryValue = props.getValue();

                return (
                    <div className="internal-link-summary-cell">
                        <div className="summary-content">{summaryValue}</div>
                        <button
                            onClick={() => handleEditeSummary(summaryValue, props.row.original.url)}
                            className="internal-link-edit-button"
                        >
                            ✏️
                        </button>
                    </div>
                );
            },
            enableGlobalFilter: true,
            meta: { align: "left" },
        }),
        columnHelper.display({
            id: 'rescan',
            header: "Rescan Page",
            cell: ({ row }) => (
                <button
                    style={{
                        width: "100px",
                        cursor: disabledButtons[row.original.url] ? "not-allowed" : "pointer"
                    }}
                    className="button is-success is-outlined is-small more-rounded-borders"
                    onClick={() => handleRescan(row.original.url)}
                    disabled={disabledButtons[row.original.url]}
                >
                    {disabledButtons[row.original.url] ? "Rescanning..." : "Rescan"}
                </button>
            ),
            meta: { align: 'center' }
        }),
        columnHelper.accessor((row: InternalLink) => row.lastScanned, {
            id: 'lastScanned',
            header: "Last Scanned",
            cell: info => info.getValue(),
            enableGlobalFilter: true,
            meta: { align: 'center' }
        }),
        columnHelper.display({
            id: 'delete',
            header: "Action",
            cell: (cellProps) => (
                <div style={{ textAlign: 'center', marginRight: "1rem" }}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                        onClick={() => handleDelete(cellProps.row.original.url)}>
                        <g clip-path="url(#clip0_48_5565)">
                            <g clip-path="url(#clip1_48_5565)">
                                <path d="M3.15356 6.32313C3.44461 10.8562 3.72319 13.2144 3.88856 14.3369C3.97256 14.9046 4.34531 15.3672 4.90346 15.5011C5.66306 15.6839 6.9713 15.8906 9.00075 15.8906C11.0302 15.8906 12.3381 15.6839 13.098 15.5014C13.6559 15.3676 14.0286 14.9049 14.1126 14.3373C14.2783 13.2144 14.5566 10.8562 14.8476 6.32214" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M11.3087 3.47962C12.4769 3.50128 13.3871 3.53672 14.0394 3.56986C14.8236 3.60923 15.552 4.02694 15.7712 4.78097C15.804 4.89417 15.8349 5.01394 15.8618 5.14092C15.9911 5.74467 15.5392 6.26344 14.924 6.31561C13.9331 6.39928 12.1195 6.49444 8.99249 6.49444C5.86579 6.49444 4.05191 6.39928 3.0613 6.31561C2.44574 6.26377 1.99129 5.74139 2.15043 5.14486C2.20785 4.92994 2.2784 4.73372 2.35255 4.55948C2.61932 3.93506 3.26146 3.61284 3.93937 3.57544C4.56543 3.54131 5.47663 3.50259 6.69135 3.47962C6.87108 3.07198 7.16548 2.7254 7.53869 2.48211C7.9119 2.23882 8.34781 2.10932 8.79332 2.10938H9.20741C9.65286 2.10938 10.0887 2.23891 10.4618 2.4822C10.835 2.72549 11.129 3.07203 11.3087 3.47962Z" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.03125 9.32812L7.35937 12.6094" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M10.9687 9.32812L10.6406 12.6094" stroke="black" stroke-width="1.05" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                        </g>
                        <defs>
                            <clipPath id="clip0_48_5565">
                                <rect width="16.8" height="16.8" fill="white" transform="translate(0.600098 0.599976)" />
                            </clipPath>
                            <clipPath id="clip1_48_5565">
                                <rect width="16.8" height="16.8" fill="white" transform="translate(0.600098 0.599976)" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            ),
        }),
        // columnHelper.accessor((row: InternalLink) => row.isIndexed, {
        //     id: 'isIndexed',
        //     header: "Indexed by Google",
        //     cell: props => (
        //         <span className={`tag ${props.getValue() ? 'is-success' : 'is-warning'}`}>
        //             {props.getValue() ? 'Yes' : 'No'}
        //         </span>
        //     ),
        //     enableGlobalFilter: true,
        //     meta: { align: 'center' }
        // }),
    ];

    // ---------------------- HANDLERS ----------------------
    const handleAddInternalLink = () => {
        if (!internalLink.trim()) return;

        let url: URL | null;

        try {
            url = new URL(internalLink);
        } catch {
            failAlertRef.current?.show("Please enter a valid URL.");
            return;
        }

        if (url.hostname !== pageData.domain) {
            failAlertRef.current?.show("The internal link must match the website's domain.");
            return;
        }

        const linkExists = tableData.some(link => link.url === internalLink);
        if (linkExists) {
            failAlertRef.current?.show("This internal link already exists.");
            return;
        }

        addInternalLinkMutation.mutate({ internalLink }, {
            onSuccess: () => {
                setShowAddInternalLinkModal(false);
                setInternalLink(""); // Clear input
                successAlertRef.current?.show("Internal link added successfully!");
                refetch(); // Refresh the table data
                setTimeout(() => {
                    successAlertRef.current?.close();
                }, 5000);
            },
            onError: () => {
                failAlertRef.current?.show("Failed to add internal link. Please try again after some time.");
                setTimeout(() => {
                    failAlertRef.current?.close();
                }, 5000);
            }
        });
    };

    const handleEditeSummary = (summary: string, url: string) => {
        setEditedSummary(summary);
        setRescanLink(url)
        setShowSchemaModal(true)
    }

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto"; // Reset height
            textareaRef.current.style.height = textareaRef.current.scrollHeight + "px"; // Adjust height
        }
    };

    function handleDelete(url: string) {
        removeWebpageUrl.mutate({
            url: url
        }, {
            onSuccess: () => {
                refetch();
                successAlertRef.current?.show("Internal link removed successfully!");
                setTimeout(() => {
                    successAlertRef.current?.close();
                }, 5000);
            },
            onError: () => {
                failAlertRef.current?.show("Failed to remove Internal link. Please try again after some time.");
                setTimeout(() => {
                    failAlertRef.current?.close();
                }, 5000);
            }
        })

    }

    const handleRescan = (internalLink) => {
        setRescanLink(internalLink)
        setDisabledButtons(prev => ({ ...prev, [internalLink]: true }));

        addInternalLinkMutation.mutate(
            { internalLink, rescan: true },
            {
                onSuccess: (data) => {
                    const jobId = data['data']['job_id']
                    if (!jobId) {
                        setDisabledButtons(prev => ({ ...prev, [internalLink]: false }));
                        return;
                    }
                    setJobId(jobId);
                    successAlertRef.current?.show("Your rescan request is being processed. Please wait.");

                    setTimeout(() => {
                        successAlertRef.current?.close();
                    }, 5000);
                },
                onError: () => {
                    failAlertRef.current?.show("Failed to rescan internal link. Please try again after some time.");
                    setDisabledButtons(prev => ({ ...prev, [internalLink]: false }));
                    setTimeout(() => {
                        failAlertRef.current?.close();
                    }, 5000);
                },
            }
        );
    };


    const handleSaveSummary = (summary: string, url: string) => {
        saveSummary.mutate({
            summary: summary,
            url: url
        }, {
            onSuccess: () => {
                setShowSchemaModal(false)
                refetch()
                successAlertRef.current?.show("Summary Saved!");
                setTimeout(() => {
                    successAlertRef.current?.close();
                }, 5000);
            },
            onError: () => {
                setShowSchemaModal(false)
                failAlertRef.current?.show("Failed to save Summary. Please try again after some time.");
                setTimeout(() => {
                    failAlertRef.current?.close();
                }, 5000);
            }
        })
    }

    const handleAddSitemapUrl = () => {
        let url: URL | null;
        try {
            url = new URL(sitemapUrl);
        } catch {
            url = null;
        }

        if (!url) {
            failAlertRef.current?.show("Please enter a valid URL.");
        } else if (!sitemapUrl.trim()) {
            failAlertRef.current?.show("Please enter the sitemap URL.");
        } else if (!isValidSitemapUrl(url)) {
            failAlertRef.current?.show("The provided URL is not a valid sitemap URL. It should end with .xml or .txt");
        } else if (url.hostname !== pageData.domain) {
            failAlertRef.current?.show("The sitemap URL must match the website's domain.");
        } else {
            addSiteMapMutation.mutate(sitemapUrl, {
                onSuccess: (response: any) => {
                    const responseData: any = response.data;

                    if (responseData.success) {
                        setShowSitemapUrlModal(false);
                        setIsCrawling(true);
                        successAlertRef.current?.show("Sitemap URL added successfully!");
                        setTimeout(() => {
                            successAlertRef.current?.close();
                        }, 5000);
                    } else {
                        failAlertRef.current?.show(responseData.message);
                        setTimeout(() => {
                            failAlertRef.current?.close();
                        }, 5000);
                    }

                },
                onError: (response) => {
                    console.log(response)
                    failAlertRef.current?.show("Failed to add sitemap url. Please try again after some time.");
                    setTimeout(() => {
                        failAlertRef.current?.close();
                    }, 5000);
                }
            });
        }
    };

    // ---------------------- FUNCTIONS ----------------------
    function isValidSitemapUrl(parsedUrl: URL): boolean {
        try {
            return parsedUrl.pathname.endsWith('.xml') || parsedUrl.pathname.endsWith('.txt');
        } catch (error) {
            return false; // Return false if URL is not valid
        }
    }

    // ============================================================
    // --------------------- MAIN RENDER CODE ---------------------
    // ============================================================
    if (isCrawling) {
        return (
            <div className="analysis-progress-container">
                <div className="analysis-card">
                    <h1 className="analysis-title">Website Analysis in Progress</h1>
                    <p className="analysis-subtitle">Our AI is analyzing your website & finding all the internal pages based on your sitemap.</p>

                    {
                        websiteAnalysisStats?.total_pages ?
                            <>
                                <div className="progress-bar">
                                    <div className="progress-fill" style={{ width: `${websiteAnalysisStats.progress}%` }}></div>
                                </div>

                                <div className="analysis-stats">
                                    <div className="stat-item">
                                        <h2>{websiteAnalysisStats.progress}%</h2>
                                        <p>Progress</p>
                                    </div>
                                    <div className="stat-item">
                                        <h2>{websiteAnalysisStats.time_display}</h2>
                                        <p>Minutes Left</p>
                                    </div>
                                    <div className="stat-item">
                                        <h2>{websiteAnalysisStats.pages_scanned || 0}</h2>
                                        <p>Pages Scanned</p>
                                    </div>
                                </div>
                            </>
                            :
                            <h4 className={"is-size-5 has-text-centered"}>
                                {loadingDataText}&nbsp;&nbsp;<Icon iconName="spinner" />
                            </h4>
                    }
                </div>

                <div className="analysis-steps-card">
                    <h3>Analysis Steps</h3>
                    <ul>
                        <li className={websiteAnalysisStats?.steps.crawling === 'completed' ? 'completed' : ''}>
                            Crawling website pages
                        </li>
                        <li className={websiteAnalysisStats?.steps.analyzing === 'completed' ? 'completed' : ''}>
                            Analyzing content structure
                        </li>
                        <li className={websiteAnalysisStats?.steps.generating === 'completed' ? 'completed' : ''}>
                            Generating optimization suggestions
                        </li>
                    </ul>
                </div>

                <div className="next-steps-card">
                    <h3>Next:</h3>
                    <p>Once we have scanned all of your websites internal pages, we will automatically link them in the articles based on how relevant they are.</p>
                    <br />
                    <p>We will also be showing up all the links. You can manually add more pages or rescan.</p>
                    <br />
                    <p>The amount of pages scanned is dependent on the plan you are on.</p>
                </div>
            </div>
        );
    } else if (isFetching || isLoading) {
        return (
            <p style={{ textAlign: "center", fontSize: "1.3rem" }} className="mt-5">
                {loadingDataText}
            </p>
        );
    } else if (isError) {
        return (
            <section className="section">
                <div className="container">
                    <div className="box">
                        <h1 className="title has-text-centered">Internal Links</h1>
                        <p className="has-text-centered is-size-5">
                            Failed to load data. Please try again later.
                        </p>
                    </div>
                </div>
            </section>
        );
    } else if (!basePageData.user_verified) {
        return (
            <section className="section">
                <div className="container">
                    <div className="box">
                        <h1 className="title has-text-centered">Internal Links</h1>
                        <p className="has-text-centered is-size-5">
                            You will need to verify your email to use this feature.
                        </p>
                    </div>
                </div>
            </section>
        );
    }

    return (
        <>
            <div className="tab-content w-100">
                <div className="card">
                    <div className="card-content">
                    <div className={"is-flex is-justify-content-center is-align-items-center is-flex-direction-column has-text-centered"}>
                            <h1 style={{ fontWeight: "800", fontSize: "2rem", fontFamily: "Bricolage Grotesque"}}>Internal Links Manager</h1>
                            <p className={"is-size-6 mt-2 mb-4"}>Manage and optimize your website's internal linking structure</p>
                        </div>                              

                        <div className="is-flex is-flex-direction-row">
                            <AbunButton
                                className={"is-primary is-small mr-4"}
                                disabled={addInternalLinkMutation.isLoading}
                                type={"primary"} clickHandler={() => {
                                    setShowAddInternalLinkModal(true);
                                }}>
                                Add Internal Link
                            </AbunButton>

                            {
                                !pageData.is_crawling && !isProduction && basePageData.user_verified &&
                                <AbunButton
                                    className={"is-primary is-small mr-4"}
                                    disabled={reScrapeWebsitePagesMutation.isLoading}
                                    type={"primary"} clickHandler={() => {
                                        reScrapeWebsitePagesMutation.mutate(undefined, {
                                            onSuccess: () => {
                                                setIsCrawling(true);
                                                setLoadingDataText("Finding Sitemaps...")
                                            },
                                            onError: () => {
                                                failAlertRef.current?.show("Failed to send re-scrape website pages request. Please try again after some time.");
                                                setTimeout(() => {
                                                    failAlertRef.current?.close();
                                                }, 5000);
                                            }
                                        })
                                    }}>
                                    Re-Scrape
                                </AbunButton>
                            }

                            {
                                !pageData.is_crawling && pageData.has_more_pages && basePageData.currentPlanName !== 'Trial' &&
                                <AbunButton
                                    className={"is-primary is-small"}
                                    disabled={scrapeMoreWebsitePagesMutation.isLoading}
                                    type={"primary"} clickHandler={() => {
                                        scrapeMoreWebsitePagesMutation.mutate(undefined, {
                                            onSuccess: () => {
                                                setIsCrawling(true);
                                                setLoadingDataText("Finding Pages...")
                                            },
                                            onError: () => {
                                                failAlertRef.current?.show("Failed to send scrape more pages request. Please try again after some time.");
                                                setTimeout(() => {
                                                    failAlertRef.current?.close();
                                                }, 5000);
                                            }
                                        })
                                    }}>
                                    Scrape More
                                </AbunButton>
                            }
                        </div>

                        <AbunTable
                            ref={tableRef}
                            serverSide={true}
                            tableContentName={"Internal Links"}
                            apiUrl="/api/frontend/get-scraped-web-pages/"
                            tableData={tableData}
                            columnDefs={columnDefs}
                            pageSizes={pageSizes}
                            initialPageSize={pageSizes[1]}
                            transformResponse={(rawData) => ({
                                data: rawData.web_pages.map((page: any) => ({
                                    url: page.url,
                                    lastScanned: page.lastScanned,
                                    summary: page.summary,
                                    title: page.title,
                                })),
                                total: rawData.total_count
                            })}
                            noDataText={"No internal links data available."}
                            searchboxPlaceholderText={"Search internal links..."}
                        />
                    </div>
                </div>
            </div>

            {/* Add Internal Link Modal */}
            <AbunModal
                active={showAddInternalLinkModal}
                headerText={"Add Your Internal Link"}
                closeable={true}
                closeableKey={true}
                hideModal={() => setShowAddInternalLinkModal(false)}
            >
                <div className="has-text-centered">
                    <div className="field">
                        <div className="control">
                            <input
                                type="url"
                                className="input"
                                style={{borderRadius:'10px'}}
                                placeholder="Enter your internal link here..."
                                value={internalLink}
                                onChange={(e) => setInternalLink(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleAddInternalLink();
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <AbunButton
                        type="success"
                        className="mt-4"
                        disabled={!internalLink.trim() || addInternalLinkMutation.isLoading} // Disable if input is empty
                        clickHandler={handleAddInternalLink}
                    >
                        {addInternalLinkMutation.isLoading ? "Adding..." : "Add Internal Link"}
                    </AbunButton>
                </div>
            </AbunModal>

            {/* Add Sitemap Url Modal */}
            <AbunModal
                active={showSitemapUrlModal}
                headerText={"Add Your Website Sitemap"}
                closeable={true}
                hideModal={() => {
                    navigate(pageURL["createArticle"]);
                }}
            >
                <div className="has-text-centered">
                    <div className="field">
                        <div className="control">
                            <input
                                type="url"
                                className="input"
                                placeholder="Enter your website sitemap url here..."
                                value={sitemapUrl}
                                onChange={(e) => setSitemapUrl(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleAddSitemapUrl();
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <AbunButton
                        type="success"
                        className="mt-4"
                        disabled={!sitemapUrl.trim() || addSiteMapMutation.isLoading || !basePageData.user_verified} // Disable if input is empty
                        clickHandler={handleAddSitemapUrl}
                    >
                        {addSiteMapMutation.isLoading ? "Adding..." : "Add"}
                    </AbunButton>
                </div>
            </AbunModal>

            <AbunModal active={showSchemaModal}
                headerText=""
                closeable={true}
                closeableKey={true}
                modelWidth="800px"
                hideModal={() => {
                    setShowSchemaModal(false)
                }}>
                <div className={"has-text-centered"}>
                    <textarea
                        className="abun-script mt-2"
                        value={editedSummary}
                        onChange={(e) => {
                            setEditedSummary(e.target.value);
                            adjustTextareaHeight();
                        }}
                        ref={textareaRef}
                        style={{
                            width: "100%",
                            resize: "none",
                            border: "1px solid #ccc",
                            padding: "10px",
                            fontFamily: "'Inter', sans-serif",
                            overflow: "hidden", // Hide scrollbar
                            minHeight: "3rem", // Initial height
                        }}
                    />
                    <AbunButton type={"success"}
                        className={"mt-4"}
                        clickHandler={() => { handleSaveSummary(editedSummary, rescanLink) }}
                    >
                        Save
                    </AbunButton>
                </div>
            </AbunModal>


            <SuccessAlert ref={successAlertRef} />
            <ErrorAlert ref={failAlertRef} />

            {showConnectWebsiteModal && (
                <ConnectWebsite
                    setShowConnectWebsiteModal={setShowConnectWebsiteModal}
                    failAlertRef={failAlertRef}
                    successAlertRef={successAlertRef}
                    navigateOrReload="reload"
                />
            )}
        </>
    );
}
import { useMutation } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { NavLink, useLoaderData, useNavigate, useRouteLoaderData } from "react-router-dom";
import TimezoneSelect from "react-timezone-select";
import AbunButton from "../../components/AbunButton/AbunButton";
import AbunModal from "../../components/AbunModal/AbunModal";
import Card from "../../components/Card/Card";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import GenericButton from "../../components/GenericButton/GenericButton";
import Icon from "../../components/Icon/Icon";
import defaultWebIcon from "../../assets/images/icons/defaultCompetitorLogos/default-competitor-logo1.png";
import Input from "../../components/Input/Input";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import { BasePageData } from "../../pages/Base/Base";
import {
	deleteCurrentWebsite,
	emailPreferenceMutation,
	resendVerificationEmailMutation,
	saveUserDetailsMutation,
	uploadDomainLogoMutation
} from "../../utils/api";
import { pageURL } from "../routes";
import "./Profile.scss";

interface ProfilePageData {
	verified: boolean
	username: string
	website: string | null
	email: string
	tz: string
	send_notif_email: boolean
	// content_plan_generation_status: string
	plan_name: string
	domain_logo_url: string
}

export default function Profile() {
	// ---------------------------- PAGE DATA ----------------------------
	const { pageData } = useLoaderData() as {
		pageData: ProfilePageData;
	};
	const basePageData: BasePageData = useRouteLoaderData("base") as BasePageData;
	// ---------------------------- REACT ROUTER ----------------------------
	const navigate = useNavigate();

	// ---------------------------- STATES ----------------------------
	const [username, setUsername] = useState(pageData.username);
	const [sendNotifEmail, setSendNotifEmail] = useState(pageData.send_notif_email);
	const [domainLogoURL, setDomainLogoURL] = useState(pageData.domain_logo_url);
	const [
		selectedTimezone,
		setSelectedTimezone
	] = useState(pageData.tz === "UTC" ? "Etc/GMT" : pageData.tz);
	const [discWebsiteInput, setDiscWebsiteInput] = useState("");
	const [disconnectWebsiteModelActive, setDisconnectWebsiteModelActive] = useState(false);
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [selectedFileURL, setSelectedFileURL] = useState<string | null>(null);
	const [uploading, setUploading] = useState<boolean>(false);

	// ---------------------------- MUTATIONS ----------------------------
	const resendVerificationEmail = useMutation(resendVerificationEmailMutation);

	// -------------------------- REFS --------------------------
	const successAlertRef = useRef<any>(null);
	const errorAlertRef = useRef<any>(null);

	// ---------------------------- MUTATIONS ----------------------------
	const saveUserDetails = useMutation(saveUserDetailsMutation);
	const saveEmailPref = useMutation(emailPreferenceMutation);
	const uploadDomainLogoImage = useMutation(uploadDomainLogoMutation);
	const deleteWebsite = useMutation({
		mutationKey: ['deleteCurrentWebsite'],
		mutationFn: deleteCurrentWebsite,
		cacheTime: 0,
		onSuccess: () => {
			window.location.reload();
			navigate(pageURL['createArticle']);
		},
		onError: (error) => {
			console.error(error);
			errorAlertRef.current?.show("Oops! Something went wrong. Please try again in some time.")
		}
	})

	// ---------------------------- EFFECTS ----------------------------
	useEffect(() => {
		document.title = "Profile | Abun"
	}, []);

	// ----------------------- NON STATE CONSTANTS -----------------------
	const websiteDomain = pageData.website?.startsWith("default") && pageData.website.endsWith(".xyz") ? "Default" : pageData.website

	
	// --------------------- FUNCTIONS ------------------------
	function handleFileSelection(event: React.ChangeEvent<HTMLInputElement>) {
		if (event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0];

			// Check if file size is greater than 2 MB
			const maxSize = 1024 * 1024 * 2; 

			if (file.size > maxSize) {
				errorAlertRef.current?.show("File size should be less than 2mb.");
				return;
			}

			setSelectedFile(file);
			setSelectedFileURL(URL.createObjectURL(file));
		}
	}

	async function uploadImage() {
		if (selectedFile) {
			setUploading(true);

			// upload the image
			uploadDomainLogoImage.mutate({
				image: selectedFile,
			}, {
				onSuccess: (data) => {
					setDomainLogoURL(data.data.domain_logo_url);
					successAlertRef.current?.show("Domain logo uploaded successfully..")
					setSelectedFile(null);
					setSelectedFileURL(null);
					setUploading(false);
				},
				onError: () => {
					errorAlertRef.current?.show("Oops! Failed to upload :( Please try again later or contact us for further support.");
					setUploading(false);
				}
			});
		}
	}

	const convertToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	};
	
	// ===================================================================
	// ---------------------------- MAIN CODE ----------------------------
	// ===================================================================
	return (
		<>
			<h2 className={"is-size-2 w-100"}>Manage Your Account Settings</h2>
			<div className={"card w-100 mt-5"}>
				<div className={"card-header"}>
					<h2 className={"card-header-title"}>Personal Settings</h2>
				</div>
				<div className={"card-content settings-card"}>
					<div className={"content"}>
						<div className={"profile-container"}>
							<label className={"label"}>
								Full Name:
								<Input value={username}
									type={"text"}
									className={"mt-2"}
									placeholder={"Your full name (ex. John Doe)"}
									onChange={setUsername} />
							</label>
							<label className={"label mt-5"}>
								Email Address:
								<div>
									<div className={"profile-email-field mt-2"}>
										<input type="text"
											defaultValue={pageData.email}
											disabled={true}
											className={"input disabled-color"} />
										{pageData.verified && <Icon iconName={"green-checkmark-circle"} />}
										{!pageData.verified &&
											<button className={"button is-small is-primary mt-3"}
												disabled={resendVerificationEmail.isLoading}
												onClick={() => {
													successAlertRef.current?.close();
													errorAlertRef.current?.close();
													resendVerificationEmail.mutate(undefined, {
														onSuccess: () => {
															successAlertRef.current?.show(
																"Verification email has been sent to your registered email id successfully!"
															);
														},
														onError: (error) => {
															console.error(error);
															errorAlertRef.current?.show("Oops! Something went wrong. Please try again later");
														}
													})
												}}>
												Resend Verification Email
											</button>
										}
									</div>
								</div>
							</label>
							<div className="select-wrapper mt-5">
								<label className={"label"}>
									Your Timezone:
									<TimezoneSelect
										value={selectedTimezone}
										className={"mt-2"}
										onChange={(tz) => {
											setSelectedTimezone(tz.value)
										}}
									/>
								</label>
							</div>
							
							<div className="select-wrapper mt-5">
								<label className={"label"}>
										Upload Logo for {websiteDomain} (MAX SIZE: 2mb):
									<div className={"domain-image-upload"}>
										<div className={"domain-image-container"}>
												{domainLogoURL ?
													<img src={domainLogoURL} alt={"featured"} /> : <img src={defaultWebIcon} alt={"featured"} />
												}
										</div>
										<div className={"domain-image-upload-container"}>
											
											<div className={"featured-image-upload-actions"}>
												<input type={"file"} accept={"image/*"} onChange={handleFileSelection} />
												<AbunButton className="mt-4" type={"success"} clickHandler={uploadImage} disabled={uploading}>
													{uploading ? "Uploading..." : "Upload"}
												</AbunButton>
											</div>
										</div>
									</div>
								</label>
							</div>

							<GenericButton text={"Save Details"}
								type={"success"}
								additionalClassList={["mt-6", "profile-save-button"]}
								clickHandler={() => {
									successAlertRef.current?.close();
									errorAlertRef.current?.close();
									saveUserDetails.mutate({ username: username, tz: selectedTimezone }, {
										onSuccess: () => {
											successAlertRef.current?.show("All user details saved successfully!");
										},
										onError: () => {
											errorAlertRef.current?.show(
												"Oops! Something went wrong :( Please try " +
												"again later or contact us for further support."
											);
										}
									})
								}} />
						</div>
					</div>
				</div>
			</div>

			{/* ------------------------- EMAIL SETTINGS ------------------------- */}
			<div className={"card w-100 mt-5"}>
				<div className={"card-header"}>
					<h2 className={"card-header-title"}>Email Settings</h2>
				</div>
				<div className={"card-content settings-card"}>
					<div className={"content"}>
						<div className="field">
							<input id="sendNotificationEmails"
								type="checkbox"
								className="switch is-rounded"
								checked={sendNotifEmail}
								onChange={() => {
									saveEmailPref.mutate({ send_notif_email: !sendNotifEmail }, {
										onSuccess: () => {
											setSendNotifEmail(!sendNotifEmail);
										}
									});
								}} />
							<label htmlFor="sendNotificationEmails">Send Notification Emails</label>
						</div>
					</div>
				</div>
			</div>

			{/* ------------------------- LOGOUT ------------------------- */}
			<div className={"card w-100 mt-5"}>
				<div className={"card-content settings-card"}>
					<div className={"content is-flex is-flex-direction-row is-justify-content-center is-align-items-center"}>
						<NavLink to={`/logout`} className="align-image-text-vertically is-primary button">
							<Icon iconName={"logout"} />&nbsp;&nbsp;&nbsp;&nbsp;Logout from Abun
						</NavLink>
					</div>
				</div>
			</div>

			{/* ------------------------- DISCONNECT WEBSITE ALERT ------------------------- */}
			<AbunModal active={disconnectWebsiteModelActive}
				headerText={"Delete Project"}
				closeable={false}
				hideModal={() => {
					setDisconnectWebsiteModelActive(false);
				}}>
				<div>
					<h4 className={"is-size-4 has-text-centered"}>Are You Sure? Deleting <b>{websiteDomain}</b> is permanent!</h4>
					<p className={"has-text-centered mt-4"}>
					You'll lose access to all generated Articles, competitor insights, and keyword data. Published articles on your website will stay.
					</p>
					<p className={"has-text-centered mt-3"}>
						Not Sure? <a href="/contact-us">Contact Support Now</a>
					</p>
					<div className={"is-flex is-flex-direction-row is-justify-content-center mt-6 has-text-centered"}>
						<AbunButton type={"danger"} clickHandler={() => {
							deleteWebsite.mutate();
						}}>
							Yes, Delete
						</AbunButton>
						<AbunButton type={"primary"} className={"ml-4"} clickHandler={() => {
							setDisconnectWebsiteModelActive(false);
						}}>
							Cancel
						</AbunButton>
					</div>
				</div>
			</AbunModal>

			{/* ------------------------- DISCONNECT WEBSITE ------------------------- */}
			{websiteDomain && <Card cardHeaderTitle={"Delete Website"} className={"mt-5 w-100"}>
				<div className={"is-flex is-flex-direction-column is-justify-content-center is-align-items-center"}>
					<h3 className={"is-size-3 font-secondary has-text-centered"}>
						Delete <b className={"font-secondary"}>{websiteDomain}</b> from Abun
					</h3>
					<p className={"is-size-5 has-text-centered mt-2"} style={{ maxWidth: "700px" }}>
						Once Deleted you will lose access to all your generated Articles, Competitors and Keywords data.
						Posted articles will still be available on your site.
						This action can not be undone.
					</p>
					<p className={"is-size-5 has-text-centered mt-4"}>
						If you are sure you want to proceed, type <b className={"has-text-danger"}>{websiteDomain}</b> below
						to unlock the action.
					</p>
					<Input value={discWebsiteInput} type={"text"} className={"mt-4"} onChange={setDiscWebsiteInput} />
					<AbunButton type={"danger"}
						className={"mt-6"}
						disabled={(discWebsiteInput.toLowerCase() !== websiteDomain.toLowerCase()) || deleteWebsite.isLoading }
						clickHandler={() => {
							setDisconnectWebsiteModelActive(true);
						}}>
						Delete&nbsp;<b>{websiteDomain}</b>&nbsp;from Abun
					</AbunButton>
				</div>
			</Card>}

			<ErrorAlert ref={errorAlertRef} />
			<SuccessAlert ref={successAlertRef} />
		</>
	)
}


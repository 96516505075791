import { ReactNode, useEffect, useState } from "react";
import Icon from "../Icon/Icon";

import "./AbunModal.scss";

interface AbunModalProps {
	active: boolean
	headerText: string
	children: ReactNode
	closeable: boolean
	wideModal?: boolean
	closeableKey?: boolean
	modelWidth?: string
	hideModal: () => void;
	backgroundClass?: string;
	modalCardClass?: string;
	closeOnOutsideClick?: boolean;
}

export default function AbunModal(props: AbunModalProps) {
	// ------------------- STATES -------------------
	const [modalActive, setModalActive] = useState(props.active);

	useEffect(() => {
		setModalActive(props.active);

		if (modalActive) {
            const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === "Escape" && props.closeableKey) {
                props.hideModal();
			}
        };

		window.addEventListener("keydown", handleKeyDown);

		  // Cleanup listener on component unmount or modal deactivation
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
		}
	}, [modalActive, props.active, props.hideModal, props.closeableKey]);

	return (
		<div className={modalActive ? "modal is-active" : "modal"}>
			<div className={`modal-background ${props.backgroundClass || ""}`} onClick={props.closeOnOutsideClick ? props.hideModal : undefined}></div>
			<div className={`modal-content ${props?.wideModal ? "wide-modal" : ""}`} style={{ width: props?.modelWidth }}>
				<div className={`card abun-modal-card ${props.modalCardClass || ""}`}>
					{props.closeable ?
						<button className={"abun-modal-close"} onClick={() => {
							props.hideModal();
						}}>
							<Icon additionalClasses={[`${props?.wideModal ? "icon-white" : ""}`]} iconName={"delete-circle"} />
						</button> :
						<></>}
					<div className={props.headerText !== "" ? "card-header" : "card-header is-hidden"}>
						<div className="card-header-title is-justify-content-center">
							<p>{props.headerText}</p>
						</div>
					</div>
					<div className="card-content">
						<div className="content">
							{props.children}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

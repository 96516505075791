import { useQuery } from "@tanstack/react-query";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AbunTable from "../../components/AbunTable/AbunTable";
import { getRedditPostFinderQueries } from "../../utils/api";
import LinkButton from "../../components/LinkButton/LinkButton";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";

interface RedditQuery {
    id: string,
    query: string,
    limit: number,
    is_processing: boolean,
    created_at: Date
}

export default function RedditPostFinderTable() {
    // ---------------------- NON STATE CONSTANTS ----------------------
    const pageSizes = [15, 25, 50, 100];

    // -------------------------- STATES --------------------------
    const [queries, setQueries] = useState<Array<RedditQuery>>([]);
    const navigate = useNavigate();

    // -------------------------- QUERIES --------------------------
    const {
        isFetching,
        isError,
        data,
        refetch
    } = useQuery({
        queryKey: ['getGuestPostFinderQueries'],
        queryFn: getRedditPostFinderQueries,
        refetchOnWindowFocus: false
    });


    // -------------------------- REFS --------------------------
    const errorAlertRef = useRef<any>(null);
    const successAlertRef = useRef<any>(null);

    // ---------------------- EFFECTS ----------------------
    useEffect(() => {
        if (data) {
            setQueries(data['data']['queries']);
        }
    }, [data]);

    useEffect(() => {
        document.title = "Reddit Post Finder | Abun"
    }, []);

    // ---------------------- TABLE COLUMN DEFS ----------------------
    const columnHelper = createColumnHelper<RedditQuery>();
    const columnDefs: ColumnDef<any, any>[] = [

        columnHelper.accessor((row: RedditQuery) => row.query, {
            id: 'topic',
            header: "Topic",
            cell: (props) => {
                const handleClick = () => {
                    navigate(`/reddit-post-finder/view/${props.row.original.id}/`);
                };

                return (
                    <span onClick={handleClick} style={{ cursor: 'pointer' }}>
                        {props.row.original.query}
                    </span>
                );
            },
            enableGlobalFilter: true
        }),
        columnHelper.accessor((row: RedditQuery) => row.limit, {
            id: 'limit',
            header: "Limit",
            cell: info => info.getValue(),
            enableGlobalFilter: true,
            meta: {
                align: 'center'
            }
        }),
        columnHelper.accessor((row: RedditQuery) => row.created_at, {
            id: 'date',
            header: "Created On",
            cell: cellProps => {
                let selectedDate: any = cellProps.row.original.created_at;

                // defining date showing context
                const getRelativeTime = (dateString: string) => {
                    const createdDateObj = new Date(dateString);
                    const now = new Date();
                    const timeDiff = now.getTime() - createdDateObj.getTime();

                    // Handle future dates
                    if (timeDiff < 0) {
                        return "just now";
                    }

                    const seconds = Math.floor(timeDiff / 1000);
                    const minutes = Math.floor(seconds / 60);
                    const hours = Math.floor(minutes / 60);
                    const days = Math.floor(hours / 24);

                    // Check conditions in ascending order of time units
                    if (seconds < 60) {
                        return "just now";
                    }

                    if (minutes < 60) {
                        return minutes === 1 ? "a minute ago" : `${minutes} minutes ago`;
                    }

                    if (hours < 24) {
                        return hours === 1 ? "an hour ago" : `${hours} hours ago`;
                    }

                    if (days > 30) {
                        const day = createdDateObj.getDate();
                        const month = createdDateObj.toLocaleString('default', { month: 'short' });
                        const year = createdDateObj.getFullYear().toString().slice(-2);
                        return `${day} ${month}, ${year}`;
                    }

                    return days === 1 ? "a day ago" : `${days} days ago`;
                };

                return getRelativeTime(selectedDate);
            },
            meta: {
                align: 'center'
            }
        }),
        columnHelper.display({
            id: 'view',
            header: () => "View",
            cell: cellProps => {
                let processing: boolean = cellProps.row.original.is_processing;

                if (processing) {

                    return (
                        <LinkButton
                            linkTo={`/reddit-post-finder/view/${cellProps.row.original.id}/`}
                            text={"Generating..."}
                            type={"primary"}
                            width={"100px"}
                            outlined={true}
                            additionalClassList={["is-small", "more-rounded-borders"]}
                        />
                    );
                }
                else {
                    return (
                        <LinkButton
                            linkTo={`/reddit-post-finder/view/${cellProps.row.original.id}/`}
                            text={"View"}
                            type={"success"}
                            width={"100px"}
                            additionalClassList={["is-small", "more-rounded-borders"]}
                        />
                    );
                }
            },
            enableGlobalFilter: false,
            meta: {
                align: 'center'
            }
        }),
    ]
    function goBack() {
        navigate(-1);
    }
    return <>
        <div className="card w-100" style={{ minHeight: '50vh' }}>
            <div className={"card-content"}>
                <span className={"back-btn"} style={{ cursor: "pointer" }} onClick={goBack}>
                    <svg className="back-btn" width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M26.0435 12.0003H2.82031M2.82031 12.0003L12.8382 1.98242M2.82031 12.0003L12.8382 22.0181" stroke="black" stroke-opacity="0.5" stroke-width="3" />
                    </svg>
                </span>
                <div className={"is-flex is-justify-content-center is-align-items-center is-flex-direction-column"}>
                    <h1 style={{ fontWeight: "800", fontSize: "2rem", fontFamily: "Bricolage Grotesque" }}>Reddit Post Finder Projects</h1>
                </div>
                <AbunTable tableContentName={"Reddit Post Queries"}
                    tableData={queries}
                    columnDefs={columnDefs}
                    pageSizes={pageSizes}
                    initialPageSize={pageSizes[0]}
                    noDataText={"No Queries data available."}
                    searchboxPlaceholderText={"Search query..."}
                />
                <SuccessAlert ref={successAlertRef} />
                <ErrorAlert ref={errorAlertRef} />

            </div>
        </div>
    </>
}
import React, { useState, useRef, useEffect } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import "./GSCAnalytics.scss";
import { PageData } from "../KeywordsResearchV2/KeywordResearch";
import { useLoaderData, useNavigate } from "react-router-dom";
import { GSCDomainSelectionProps } from "../KeywordsResearchV2/KeywordResearch";
import { gscfetchConnectedDomains } from "../../utils/api";
import AbunModal from "../../components/AbunModal/AbunModal";
import AbunLoader from "../../components/AbunLoader/AbunLoader";
import GenericButton from "../../components/GenericButton/GenericButton";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import AbunButton from "../../components/AbunButton/AbunButton";
import { googleIntegrationMutation } from "../../utils/api";
import GSCData from "./GSCData";
import { postSelectedGSCDomain } from "../../utils/api";

function LoadGSCDomainsList(props: GSCDomainSelectionProps) {
    // ----------------------- QUERIES -----------------------
    const getDomainListData = useQuery(gscfetchConnectedDomains());

    // ----------------------- STATES -----------------------
    const [mainModalActive, setMainModalActive] = useState(true);

    if (getDomainListData.isLoading) {
        return (
            <AbunModal active={true}
                headerText={"Loading Google Search Console Data"}
                closeable={false}
                hideModal={() => { }}>
                <div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
                    <AbunLoader show={getDomainListData.isLoading} height="20vh" />
                </div>
            </AbunModal>
        )
    } else if (getDomainListData.isError) {
        let error = JSON.parse((getDomainListData.error as Error).message) || null;
        if (!error) error = { message: "Error fetching data from Google Search Console!" }
        return (
            <AbunModal active={mainModalActive}
                headerText={"Error fetching data"}
                closeable={true}
                closeableKey={true}
                hideModal={() => {
                    setMainModalActive(false)
                    props.handleBackBtnClick();
                }}>
                <div className="error-div has-text-centered">
                    <p className="my-2">{error.message}</p>
                    <GenericButton text={"Retry"}
                        type={"success"}
                        clickHandler={() => getDomainListData.refetch()} />
                </div>
            </AbunModal>
        )
    } else {
        const sites = (getDomainListData.data as any[]).map((site) => {
            return {
                domain: site.siteUrl.split(":")[1],
                permissionLevel: site.permissionLevel === "siteUnverifiedUser" ? "Unverified" : "Verified"
            }
        });
        if ((getDomainListData.data as any[]).length === 0) {
            return (
                <AbunModal active={mainModalActive}
                    headerText={"No Domains Found"}
                    closeable={true}
                    closeableKey={true}
                    hideModal={() => {
                        setMainModalActive(false)
                        props.handleBackBtnClick();
                    }}>
                    <div className="error-div has-text-centered">
                        <p className="my-2">No domains found in Google Search Console!</p>
                    </div>
                </AbunModal>
            )
        } else {
            return (
                <AbunModal active={mainModalActive}
                    headerText={"Select Domain to Import Data from"}
                    closeable={true}
                    closeableKey={true}
                    hideModal={() => {
                        setMainModalActive(false)
                        props.handleBackBtnClick();
                    }}>
                    <div className={"gsc-domains-list"}>
                        {sites.map((domain, index) => {
                            return (
                                <div className={"gsc-domain-item"} key={index}>
                                    <div className={"gsc-domain-item-content"}>
                                        <h1>{domain.domain.replaceAll("https://", "").replaceAll("http://", "").replaceAll("/", "")}</h1>
                                        <p className={"gsc-domain-item-verified"}>{domain.permissionLevel}</p>
                                    </div>
                                    <AbunButton className={"gsc-domain-item-button"} type={"primary"}
                                        clickHandler={() => {
                                            if (domain.permissionLevel === "Verified") {
                                                const formattedDomain = domain.domain.replaceAll("https://", "").replaceAll("http://", "").replaceAll("/", "");
                                                props.setSelectedDomain(formattedDomain);
                                                setMainModalActive(false);
                                                postSelectedGSCDomain({ selected_domain: formattedDomain });
                                            }
                                            else{
                                                props.failAlertRef.current?.show(
                                                    "Failed to import data from Unverified domain."
                                                );
                                            }
                                        }}>
                                        Import Data
                                    </AbunButton>
                                </div>
                            )
                        })}
                    </div>
                </AbunModal>
            );
        }
    }
}

interface GoogleIntegrationResponse {
    success: boolean
    authorization_endpoint: string
}
const GSCAnalytics = () => {
    const { pageData } = useLoaderData() as {
        pageData: PageData;
    };
    // const [selectedDomain, setSelectedDomain] = useState("");
    const [selectedDomain, setSelectedDomain] = useState<string>(pageData.selected_gsc_domain || pageData.current_active_website || "");
    const [GSCIntegrationProcessing, setGSCIntegrationProcessing] = useState(false);

    // --------------------------- REFS ---------------------------
    const failAlertRef = useRef<any>(null);
    const successAlertRef = useRef<any>(null);
    const googleIntegrationMut = useMutation(googleIntegrationMutation);

    const navigate = useNavigate();

    function handleBackBtnClick() {
        navigate('/create-article');
    }

    function googleIntegration(integrationType: "google-search-console" | "google-analytics" | "google-drive") {
        failAlertRef.current?.close();
        setGSCIntegrationProcessing(true);
        googleIntegrationMut.mutate(integrationType, {
            onSuccess: (data) => {
                const response: GoogleIntegrationResponse = data['data'];
                if (response.success) {
                    successAlertRef.current?.show("New GSC Account Integrated successfully.");
                    window.location.href = response.authorization_endpoint;
                } else {
                    setGSCIntegrationProcessing(false);
                    failAlertRef.current?.show(
                        "Oops! Something went wrong :( Please try " +
                        "again later or contact us for further support."
                    );
                }
            },
            onError: () => {
                setGSCIntegrationProcessing(false);
                failAlertRef.current?.show(
                    "Oops! Something went wrong :( Please try " +
                    "again later or contact us for further support."
                );
            }
        })
    }

    return (
        <div className="gsc-container">
            <h2>Google Search Console Analytics</h2>
            <p>Connect to your Google Search Console account to analyze search performance</p>
            <br />
            {
                pageData.has_gsc_integration ?
                    (!selectedDomain ?
                        <LoadGSCDomainsList failAlertRef={failAlertRef} setSelectedDomain={setSelectedDomain} handleBackBtnClick={handleBackBtnClick} />
                        :
                        <GSCData selectedDomain={selectedDomain} handleBackBtnClick={() => {
                            setSelectedDomain("");
                        }} />
                    )
                    :
                    <div className="gsc-box">
                        <h2>Connect Google Search Console</h2>
                        <p>Connect your GSC account to visualize your search performance data</p>
                        <div className="gsc-benefits">
                            <p className="benefits-title">Benefits of connecting GSC:</p>
                            <ul>
                                <li>Visualize your search performance metrics</li>
                                <li>Identify top-performing keywords and pages</li>
                                <li>Track your position in search results over time</li>
                                <li>Discover optimization opportunities</li>
                            </ul>
                        </div>
                        <div className="gsc-privacy">
                            <span className="privacy-icon">🔒</span>
                            <div className="privacy-text">
                                <span className="privacy-title">Your data stays private</span>
                                <p>We only access the data you authorize. You can revoke access at any time.</p>
                            </div>
                        </div>
                        <button className="gsc-connect-button" onClick={() => googleIntegration("google-search-console")}
                            disabled={GSCIntegrationProcessing}>{GSCIntegrationProcessing ? "Connecting..." : "Connect Google Search Console"}</button>
                    </div>
            }

            <ErrorAlert ref={failAlertRef} />
            <SuccessAlert ref={successAlertRef} />
        </div>
    );
};

export default GSCAnalytics;
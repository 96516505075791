import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from '@fullcalendar/timegrid';
import { Autocomplete, FormControl, MenuItem, Select, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useMutation, useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { Calendar, Clock } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createRoot } from "react-dom/client";
import { Tooltip } from "react-tooltip";
import { NavLink } from "react-router-dom";
import ghostIconPrimary from "../../assets/images/ghost-primary-icon.png";
import shopifyIconPrimary from "../../assets/images/Shopify-logo-primary.png";
import webflowIconPrimary from "../../assets/images/webflow-logo-primary.png";
import wixIconPrimary from "../../assets/images/wix-logo-primary.png";
import wordpressIconPrimary from "../../assets/images/wordpress-logo-primary.png";
import DelayedInput from "../../components/DelayedInput/DelayedInput";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import { contentSchedulingCalenderArticles, deleteScheduledCalendarArticle, scheduleArticleMutation } from "../../utils/api";
import { AllIntegrations } from "../Articles/ShowV2Articles";
import "./ContentSchedule.scss";

interface ArticleTitle {
    id: string;
    title: string;
    status: string;
    date: string;
    scheduled_for_posting: boolean;
    postLink: string;
}

const integrationLogoPrimary = {
    "wordpress": wordpressIconPrimary,
    "webflow": webflowIconPrimary,
    "wix": wixIconPrimary,
    "shopify": shopifyIconPrimary,
    "ghost": ghostIconPrimary,
}

const DraggableArticle = ({ article, onRemove, setDraggedArticle }) => {
    const articleRef = useRef(null);
    useEffect(() => {
        if (articleRef.current) {
            const draggable = new Draggable(articleRef.current, {
                itemSelector: ".article",
                eventData: function (eventEl) {
                    return {
                        title: eventEl.getAttribute("data-title"),
                        id: eventEl.getAttribute("data-id"),
                    };
                },
            });
            return () => {
                draggable.destroy();
            };
        }
    }, [article]);

    return (
        <div ref={articleRef} className={`article`}
            onDragStart={() => setDraggedArticle(article.id)}
            data-id={article.id}
            data-title={article.title}
        >
            <div className="article-header">
                <span style={{ fontWeight: '500' }}>{article.title}</span>
            </div>
        </div>
    );
};

const ContentSchedule = () => {
    const [articles, setArticles] = useState<ArticleTitle[]>([]);
    const [draggedArticle, setDraggedArticle] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [integrationWithUniqueID, setIntegrationWithUniqueID] = useState<Array<AllIntegrations>>([]);
    const [selectedIntegration, setSelectedIntegration] = useState("");
    const [selectedIntegrationUniqueID, setSelectedIntegrationUniqueID] = useState("");
    const [selectedTime, setSelectedTime] = useState<string>("morning");
    const { data, isLoading, isFetching, isError } = useQuery(contentSchedulingCalenderArticles());
    const [visible, setVisible] = useState(() => {
        return localStorage.getItem("bannerDismissed") !== "true";
    });

    // --------------------------- Refs ---------------------------
    const successAlertRef = useRef<any>(null);
    const errorAlertRef = useRef<any>(null);
    const selectedTimeRef = useRef("");
    const scheduleArticle = useMutation(scheduleArticleMutation);

    const timeOptions = [
        { value: "anyTime", text: "Any Time" },
        { value: "morning", text: "Morning (06:00 AM to 12:00 PM)" },
        { value: "afternoon", text: "Afternoon (12:00 PM to 06:00 PM)" },
        { value: "evening", text: "Evening (06:00 PM to 12:00 AM)" },
        { value: "night", text: "Night (12:00 AM to 06:00 AM)" },
    ];
    const handleClose = () => {
        setVisible(false);
        localStorage.setItem("bannerDismissed", "true");
    };

    useEffect(() => {
        if (!isLoading && !isError && data) {
            processData(data);
        }
    }, [data, isLoading, isError]);

    const processData = (data: any) => {
        if (data?.data?.title_data) {
            const filteredArticles = data.data.title_data
                .map((article: any) => ({
                    id: article.articleUID || null,
                    title: article.articleTitle || null,
                    status: article.scheduledOn || article.isPosted ? "scheduled" : "draft",
                    date: article.scheduledDateTime ? article.scheduledDateTime : article.isPosted ? article.postedOn : null,
                    scheduled_for_posting: article.scheduledOn ? true : false,
                    postLink: article.isPosted ? article.postLink : null,
                })
                );
            setArticles(filteredArticles);
        }
        const integrationWithUniqueID = data?.data?.all_integrations_with_unique_id || [];
        setIntegrationWithUniqueID(integrationWithUniqueID);

        const matchingIntegration = integrationWithUniqueID.find((integration) =>
            integration.integrationName.toLowerCase().includes("wordpress")
        );

        // Set the selected integration and unique ID
        setSelectedIntegration(
            matchingIntegration ? matchingIntegration.integrationName : ""
        );

        setSelectedIntegrationUniqueID(
            matchingIntegration ? matchingIntegration.integrationUniqueID : ""
        );
    };

    useEffect(() => {
        const dropdownContainer = document.querySelector(".fc-timeDropdown-button");

        if (dropdownContainer) {
            dropdownContainer.innerHTML = "";

            const getNextAvailableTime = () => {
                const now = new Date();
                const minutes = now.getMinutes();
                const roundedMinutes = Math.ceil(minutes / 30) * 30;

                now.setMinutes(roundedMinutes);
                now.setSeconds(0);
                now.setMilliseconds(0);

                return `${now.getHours().toString().padStart(2, "0")}:${now.getMinutes().toString().padStart(2, "0")}`;
            };

            const getSelectedTime = (selectedOption) => {
                switch (selectedOption) {
                    case "morning":
                        return "06:00";
                    case "afternoon":
                        return "12:00";
                    case "evening":
                        return "18:00";
                    case "night":
                        return "00:00";
                    default:
                        return getNextAvailableTime();
                }
            };

            selectedTimeRef.current = "anyTime";

            // Set default time
            setSelectedTime(getNextAvailableTime());

            const root = createRoot(dropdownContainer);
            root.render(
                <FormControl sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "4px" }}>
                    <h2 style={{ marginBottom: '5px', fontSize: '20px', justifyContent: 'center', display: 'flex', fontWeight: '500' }}>Schedule for</h2> &nbsp;
                    <Select
                        size="small"
                        defaultValue={selectedTimeRef.current}
                        onChange={(e) => {
                            selectedTimeRef.current = e.target.value;
                            setSelectedTime(getSelectedTime(e.target.value))
                        }}
                        sx={{
                            height: "35px"
                        }}
                    >
                        {timeOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.text}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        }
    }, []);

    const events = articles.filter((a) => a.status === "scheduled").map((a) => ({
        id: a.id.toString(),
        title: a.title,
        start: a.date,
        editable: !a.postLink,
    }));

    const renderEventContent = (eventInfo) => {
        const publishTime = format(new Date(eventInfo.event.start), "p");
        const isPublished = articles.find(article => article.title === eventInfo.event.title)?.postLink;
        const handleTitleClick = () => {
            if (isPublished) {
                window.open(isPublished, '_blank');
            }
        };

        return (
            <div className="custom-event">
                <div className={`event-title-box ${isPublished ? 'published' : ''}`} onClick={handleTitleClick}>
                    <span className="event-title">{eventInfo.event.title}</span>
                    {!isPublished && (
                        <button className="delete-event-btn" onClick={() => { handleDeleteArticle(eventInfo.event.id) }}>
                            ✖
                        </button>
                    )}
                    <span className={`event-time ${isPublished ? 'published' : ''}`}>
                        {isPublished ? (
                            <>
                                <Calendar size={14} className="icon scheduled" /> Published
                            </>
                        ) : (
                            <>
                                <Clock size={14} className="icon draft" /> <strong>{publishTime} </strong> Publish
                            </>
                        )}
                    </span>
                </div>
            </div>
        );
    };

    function getIntegrationName(integrate: string | undefined) {
        if (!integrate) {
            return "wordpress";
        } else {
            if (integrate.includes("wordpress")) {
                return "wordpress";
            } else if (integrate.includes("webflow")) {
                return "webflow";
            } else if (integrate.includes("shopify")) {
                return "shopify";
            } else if (integrate.includes("ghost")) {
                return "ghost";
            } else {
                return "wix";
            }
        }
    }

    const handleDrop = (info) => {
        const articleId = info.draggedEl.getAttribute('data-id');
        const scheduleDate = new Date(info.dateStr);
        const currentDate = new Date();

        const [hours, minutes] = selectedTime.split(":").map(Number);
        scheduleDate.setHours(hours, minutes, 0);

        try {
            if (!selectedIntegration) {
                successAlertRef.current?.close();
                errorAlertRef.current?.close();

                errorAlertRef.current?.show("Please choose an integration site for your article.");
                setTimeout(() => errorAlertRef.current?.close(), 5000);
                setArticles((prev) =>
                    prev.map((article) =>
                        article.id === articleId
                            ? { ...article, status: "draft", date: "" }
                            : article
                    )
                );
                return;
            }
            if (scheduleDate <= currentDate) {
                successAlertRef.current?.close();
                errorAlertRef.current?.close();

                errorAlertRef.current?.show("Please select a future date and time for scheduling.");
                setTimeout(() => {
                    errorAlertRef.current?.close();
                }, 5000);
                setArticles((prev) =>
                    prev.map((article) =>
                        article.id === articleId
                            ? { ...article, status: "draft", date: "" }
                            : article
                    )
                );
                return;
            }
        } catch (error) {
            successAlertRef.current?.close();
            errorAlertRef.current?.close();

            console.error(error);
            errorAlertRef.current?.show("Date is not in valid ISO format. Please try again.");
            setTimeout(() => {
                errorAlertRef.current?.close();
            }, 5000);
            setArticles((prev) =>
                prev.map((article) =>
                    article.id === articleId
                        ? { ...article, status: "draft", date: "" }
                        : article
                )
            );
            return;
        }

        const scheduleDateString = scheduleDate.toISOString();

        // Call the mutation to update the article's scheduled date in the backend
        scheduleArticle.mutate({
            articleUID: articleId,
            articleScheduleDate: scheduleDateString,
            integrationName: selectedIntegration,
            integrationUniqueID: selectedIntegrationUniqueID,
            postStatus: 'publish',
        }, {
            onSuccess: (data) => {
                let responseData = data?.data;

                if (responseData?.success) {
                    if (articles.find(article => article.id === articleId)?.scheduled_for_posting) {
                        successAlertRef.current?.show("Article rescheduled successfully!");
                    } else {
                        successAlertRef.current?.show("Article scheduled successfully!");
                    }
                    setTimeout(() => successAlertRef.current?.close(), 5000);
                    setArticles((prev) =>
                        prev.map((article) =>
                            article.id === articleId
                                ? { ...article, scheduled_for_posting: true }
                                : article
                        )
                    );
                } else {
                    errorAlertRef.current?.show(responseData?.message);
                    setTimeout(() => errorAlertRef.current?.close(), 5000);
                }
            },
            onError: (error) => {
                console.error(error);
                errorAlertRef.current?.show("Error scheduling article. Please try again.");
                setTimeout(() => errorAlertRef.current?.close(), 5000);
                setArticles((prev) =>
                    prev.map((article) =>
                        article.id === articleId
                            ? { ...article, status: "draft", date: "" }
                            : article
                    )
                );
            },
        });

        // Update the local state for the article that has been dragged and dropped
        setArticles((prev) =>
            prev.map((article) =>
                article.id === articleId
                    ? { ...article, status: "scheduled", date: scheduleDateString }
                    : article
            )
        );
    };

    const handleEventDrop = (info) => {
        const articleId = info.event.id;
        const newDate = info.event.startStr;
        const scheduleDate = new Date(info.event.startStr);
        const currentDate = new Date();

        const [hours, minutes] = selectedTime.split(":").map(Number);
        scheduleDate.setHours(hours, minutes, 0);
        const previousArticleState = articles.find((article) => article.id === articleId);

        try {
            if (scheduleDate <= currentDate) {
                successAlertRef.current?.close();
                errorAlertRef.current?.close();

                errorAlertRef.current?.show("Please select a future date and time for scheduling.");
                setTimeout(() => {
                    errorAlertRef.current?.close();
                }, 5000);
                if (previousArticleState) {
                    setArticles((prev) =>
                        prev.map((article) =>
                            article.id === articleId ? { ...previousArticleState } : article
                        )
                    );
                }
                return;
            }
        } catch (error) {
            successAlertRef.current?.close();
            errorAlertRef.current?.close();

            console.error(error);
            errorAlertRef.current?.show("Date is not in valid ISO format. Please try again.");
            setTimeout(() => {
                errorAlertRef.current?.close();
            }, 5000);
            if (previousArticleState) {
                setArticles((prev) =>
                    prev.map((article) =>
                        article.id === articleId ? { ...previousArticleState } : article
                    )
                );
            }
            return;
        }

        const scheduleDateString = scheduleDate.toISOString();

        // Call the mutation to update the article's scheduled date in the backend
        scheduleArticle.mutate({
            articleUID: articleId,
            articleScheduleDate: scheduleDateString,
            integrationName: selectedIntegration,
            integrationUniqueID: selectedIntegrationUniqueID,
            postStatus: 'publish',
        }, {
            onSuccess: (data) => {
                let responseData = data?.data;

                if (responseData?.success) {
                    if (articles.find(article => article.id === articleId)?.scheduled_for_posting) {
                        successAlertRef.current?.show("Article rescheduled successfully!");
                    } else {
                        successAlertRef.current?.show("Article scheduled successfully!");
                    }
                    setTimeout(() => successAlertRef.current?.close(), 5000);
                } else {
                    errorAlertRef.current?.show(responseData?.message);
                    setTimeout(() => errorAlertRef.current?.close(), 5000);
                }
            },
            onError: (error) => {
                console.error(error);
                errorAlertRef.current?.show("Error rescheduling article. Please try again.");
                setTimeout(() => errorAlertRef.current?.close(), 5000);
                if (previousArticleState) {
                    setArticles((prev) =>
                        prev.map((article) =>
                            article.id === articleId ? { ...previousArticleState } : article
                        )
                    );
                }
            },
        });

        // Update the local state for the article that has been dragged and dropped
        setArticles((prev) =>
            prev.map((article) =>
                article.id === articleId
                    ? { ...article, status: "scheduled", date: scheduleDateString }
                    : article
            )
        );
    };

    const handleDeleteArticle = (id) => {
        deleteScheduledCalendarArticle(id).then((data) => {
            if (data.status === 200) {
                successAlertRef.current?.show(
                    `Scheduled Article deleted successfully!`
                );
                setTimeout(() => {
                    successAlertRef.current?.close();
                }, 5000);
                setArticles((prev) =>
                    prev.map((article) =>
                        article.id === id
                            ? { ...article, status: "draft", scheduled_for_posting: false } // Update status
                            : article
                    )
                );
            }
        }).catch((error) => {
            errorAlertRef.current?.show(
                `Error deleting Scheduled Article. Please try again. ${error.message}`
            );
            setTimeout(() => {
                errorAlertRef.current?.close();
            }, 5000);
        });
    };

    const handleEventDragStop = (info) => {
        const calendarEl = document.querySelector('.fc');
        if (!calendarEl) {
            return;
        }

        const calendarBounds = calendarEl.getBoundingClientRect();
        const dropX = info.jsEvent.clientX;
        const dropY = info.jsEvent.clientY;

        // Check if the drop is inside the calendar
        const isInside =
            dropX >= calendarBounds.left &&
            dropX <= calendarBounds.right &&
            dropY >= calendarBounds.top &&
            dropY <= calendarBounds.bottom;

        if (isInside) {
            return;
        } else {
            setArticles((prev) =>
                prev.map((article) =>
                    article.id === info.event.id
                        ? { ...article, status: "draft", scheduled_for_posting: false }
                        : article
                )
            );
            handleDeleteArticle(info.event.id);
        }
    }

    const getDayClassNames = (date: any) => {
        const today = new Date();
        const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        const dayDate = date.date;
        if (dayDate < todayDate) {
            return ['past']; // Add 'past' class for past dates
        }
        return [];
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="content-schedule">
                <div className="content-header">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <h1>Content Scheduling Calendar</h1>
                    </div>
                    <div className="publish-container is-flex is-align-items-center is-justify-content-center" style={{ marginTop: '-20px', marginBottom: '10px' }}>
                        <div className={"confirmation-card-content w-100"}>
                            {integrationWithUniqueID.length > 0 ? (
                                <div className={"comp-research-location-select"}>
                                    <div className={"form-group location-select"}>
                                        <Autocomplete
                                            id="country-select-demo"
                                            sx={{ width: 300 }}
                                            options={integrationWithUniqueID
                                                .map(integration => integration.integrationName)
                                                || []}
                                            value={selectedIntegration.charAt(0).toUpperCase() + selectedIntegration.slice(1)}
                                            autoHighlight
                                            renderInput={(params) => {
                                                const selectedIntegrationData = integrationWithUniqueID.find(
                                                    integration => integration.integrationName === selectedIntegration
                                                );

                                                return (
                                                    <TextField
                                                        {...params}
                                                        label="Integration"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: 'off',
                                                        }}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            startAdornment: selectedIntegrationData ? (
                                                                <InputAdornment position="start">
                                                                    {integrationLogoPrimary[getIntegrationName(selectedIntegration)] && (
                                                                        <img
                                                                            src={integrationLogoPrimary[getIntegrationName(selectedIntegration)]}
                                                                            alt={selectedIntegration}
                                                                            style={{ width: 20, height: 20, marginRight: 0 }}
                                                                        />
                                                                    )}
                                                                </InputAdornment>
                                                            ) : null,
                                                        }}
                                                    />
                                                );
                                            }}
                                            onChange={(event, option) => {
                                                if (option) {
                                                    setSelectedIntegration(option);
                                                    const selectedIntegrationData = integrationWithUniqueID.find(
                                                        integration => integration.integrationName === option
                                                    );
                                                    if (selectedIntegrationData) {
                                                        setSelectedIntegrationUniqueID(selectedIntegrationData.integrationUniqueID);
                                                    }
                                                }
                                            }}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
                                                        {integrationLogoPrimary[getIntegrationName(option)] && (
                                                            <img
                                                                src={integrationLogoPrimary[getIntegrationName(option)]}
                                                                alt={option}
                                                                style={{ width: 20, height: 20, marginRight: 8 }}
                                                            />
                                                        )}
                                                        {option.charAt(0).toUpperCase() + option.slice(1)}
                                                    </li>
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="card-content space-y-4">
                                    <p className="text-sm text-muted-foreground">
                                        Please integrate your blog to publish your article.
                                    </p>
                                    <div className="mb-2">
                                        <NavLink to="/integrations" className="text-blue-600 hover:text-blue-800 underline">
                                            Go to Integrations
                                        </NavLink>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <Tooltip id="publish-to-blog" />
                </div>
                {visible && <p
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        flexWrap: 'wrap-reverse',
                        background: 'rgb(239, 246, 255)',
                        padding: '8px',
                        borderRadius: '6px',
                        width: '100%',
                        color: 'rgba(30, 64, 175, 1)',
                        marginBottom: '10px',
                        fontWeight: '500',
                        border: '1px solid #d1d5db',
                    }}
                >
                    <div>
                        <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{ flexShrink: 0 }}
                        >
                            <circle cx="12" cy="12" r="10"></circle>
                            <path d="M12 16v-4"></path>
                            <path d="M12 8h.01"></path>
                        </svg>

                        <span style={{ marginLeft: '8px' }}>
                            <strong style={{ color: 'rgba(30, 64, 175, 1)' }}>Tip:</strong> Drag articles to schedule them. Click
                            <button style={{
                                background: '#f87171',
                                color: 'white',
                                border: 'none',
                                width: '14px',
                                height: '14px',
                                fontSize: '12px',
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                margin: '0 6px',
                            }}
                            >
                                ✖
                            </button>
                            to remove.
                        </span>
                    </div>
                    <button
                        onClick={handleClose}
                        style={{
                            background: "none",
                            border: "none",
                            color: "#555",
                            fontSize: "16px",
                            fontWeight: "bold",
                            cursor: "pointer",
                            marginLeft: "auto",
                        }}
                    >
                        ✖
                    </button>
                </p>
                }
                <div className="content-container">
                    <aside className="sidebar">
                        <h2 style={{ marginBottom: '5px', fontSize: '20px', justifyContent: 'center', display: 'flex', fontWeight: '500' }}>Generated Articles</h2>
                        <h3 style={{ justifyContent: 'center', display: 'flex', color: 'grey', fontWeight: '500' }}>Drag to Calendar to Schedule</h3>
                        <div style={{ marginBottom: '10px', marginLeft: '-20px' }}>
                            <DelayedInput initialValue={''}
                                delayInMilliseconds={500}
                                placeholder={"Search for articles"}
                                additionalClasses={['abun-table-search-input']}
                                resetInput={undefined}
                                onChange={value => {
                                    setSearchTerm(value.toLowerCase());
                                }}
                            />
                        </div>
                        {articles.filter(article => !article.scheduled_for_posting && !article.postLink).length === 0 ? (
                            <p className="no-articles-message">No articles appear here</p>
                        ) : (
                            articles
                                .filter(article => article.title.toLowerCase().includes(searchTerm)
                                    && !article.scheduled_for_posting && !article.postLink)
                                .map((article) => (
                                    <DraggableArticle
                                        key={article.id}
                                        article={article}
                                        onRemove={handleDeleteArticle}
                                        setDraggedArticle={setDraggedArticle}
                                    />
                                ))
                        )}
                    </aside>
                    <div className="calendar-container">
                        <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                            events={events}
                            editable={true}
                            droppable={true}
                            drop={handleDrop}
                            eventDrop={handleEventDrop}
                            headerToolbar={{
                                left: "timeDropdown today",
                                center: "",
                                right: "prev next title",
                            }}
                            customButtons={{
                                timeDropdown: {
                                    text: "",
                                    click: () => { },
                                },
                            }}
                            eventContent={renderEventContent}
                            fixedWeekCount={false}
                            eventDragStop={handleEventDragStop}
                            dayCellClassNames={getDayClassNames}
                        />
                    </div>
                </div>
                <SuccessAlert ref={successAlertRef} />
                <ErrorAlert ref={errorAlertRef} />
            </div>
        </DndProvider>
    );
};



export default ContentSchedule;

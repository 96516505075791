import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLoaderData, useNavigate, useRouteLoaderData } from 'react-router-dom';
import Select from 'react-select';
import { Tooltip } from 'react-tooltip';
import optionIcon from "../../assets/images/icons/icons8-option-50.png";
import saveIcon from "../../assets/images/icons/icons8-save-50.png";
import AbunLoader from '../../components/AbunLoader/AbunLoader';
import AbunModal from '../../components/AbunModal/AbunModal';
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert';
import GenericButton from '../../components/GenericButton/GenericButton';
import SuccessAlert from '../../components/SuccessAlert/SuccessAlert';
import Survey from "../../components/Survey/Survey";
import { BasePageData } from "../../pages/Base/Base";
import { ConnectWebsite } from "../../pages/KeywordsResearchV2/KeywordResearch";
import { SurveyContext } from "../../pages/Login/SurveyContext";
import { APIError, generateV2ArticleMutation, getLatestContext, makeApiRequest, saveContextMutation, saveSettingsMutation } from '../../utils/api';
import allSupportedLanguagesOptions from "../../utils/constants/allSupportedLanguagesOptions";
import { Language } from '../Settings/ArticleDetails';
import { FeatureImageTemplates } from '../Settings/ImageSettings';
import ToneOfArticle from "../Settings/ToneOfArticle";
import './CreateArticle.scss';

export interface CreateArticlePageData {
  verified: boolean;
  article_context: string | null;
  feature_image_templates: FeatureImageTemplates[];
  selected_template: string | null;
  current_plan_name: string;
  max_internal_backlinks: string;
  max_external_backlinks: string;
  image_source: string;
  images_file_format: string;
  feature_image_required: boolean;
  article_context_from_settings: string
  tone_of_article: string;
  scale_of_tone: number;
}

const CreateArticle = () => {
  // ---------------------------- PAGE DATA ----------------------------
  const { pageData } = useLoaderData() as {
    pageData: CreateArticlePageData;
  };

  // ---------------------------- BASE PAGE DATA ----------------------------
  const basePageData: BasePageData = useRouteLoaderData("base") as BasePageData;

  // -------------------------- STATES --------------------------
  const [keyword, setKeyword] = useState('');
  const [location, setLocation] = useState('us');
  const [maxTitles, setMaxTitles] = useState(8);
  const [serpTitles, setSerpTitles] = useState([]);
  const [creativeTitles, setCreativetitles] = useState([]);
  const [articleTitle, setArticleTitle] = useState('');
  const [articleContext, setArticleContext] = useState(
    pageData.article_context_from_settings
      ? pageData.article_context_from_settings
      : pageData.article_context
        ? pageData.article_context
        : ''
  );
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [IsLoading, setIsLoading] = useState(false);
  const [serpActive, setSerpActive] = useState(false);
  const [modalText, setModalText] = useState("");
  const [requestModalActive, setRequestModalActive] = useState(false);
  const [requestSettingModalActive, setRequestSettingModalActive] = useState(false);
  const [isCreativeActive, setIsCreativeActive] = useState(true);
  const [isVerified, _] = useState(pageData.verified || basePageData.user_has_ltd_plans);
  const [currentKeyword, setCurrentKeyword] = useState('');
  const [appSumoCode, setAppSumoCode] = useState('');
  const [showConnectWebsiteModal, setShowConnectWebsiteModal] = useState(
    basePageData.website_list.length === 0 ||
    (
      basePageData.website_list[0].domain.startsWith("default-") &&
      basePageData.website_list[0].domain.endsWith(".xyz")
    )
  );
  const [languagePreference, setLanguagePreference] = useState("");
  const [featuredImageTemplates, setFeaturedImageTemplates] = useState<FeatureImageTemplates[]>(pageData.feature_image_templates || []);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(pageData.selected_template || null);
  const [currentPlan, setCurrentPlan] = useState(pageData.current_plan_name || "");
  const [internalBacklinks, setInternalBacklinks] = useState(pageData.max_internal_backlinks || "");
  const [externalBacklinks, setExternalBacklinks] = useState(pageData.max_external_backlinks || "");
  const [imageSource, setImageSource] = useState(pageData.image_source || "");
  const [imageFilesFormat, setImagesFileFormat] = useState(pageData.images_file_format || "");
  const [featuredImageIsEnable, setIsFeaturedImageEnable] = useState(pageData.feature_image_required || false);
  const [oldContext, setOldContext] = useState(pageData.article_context_from_settings
    ? pageData.article_context_from_settings
    : pageData.article_context
      ? pageData.article_context
      : '')
  const [showDropdown, setShowDropdown] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false)

  const saveSettings = useMutation(saveSettingsMutation);

  const saveInstructionContext = useMutation(saveContextMutation)
  const { data, isFetching, error, refetch } = useQuery({
    queryKey: ['getLatestContext'],
    queryFn: () => getLatestContext(),
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  // -------------------------- SURVEY CONTEXT --------------------------
  const context = useContext(SurveyContext);
  const { showSurvey } = context ? context : { showSurvey: false };

  // -------------------------- REFS --------------------------
  const failAlertRef = useRef<any>(null);
  const successAlertRef = useRef<any>(null);

  // -------------------------- NAVIGATE --------------------------
  const navigate = useNavigate();

  // -------------------------- EFFECTS --------------------------
  useEffect(() => {
    const activateAppSumoLicense = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const appSumoCode = urlParams.get('code');

      if (!appSumoCode) return;

      setAppSumoCode(appSumoCode);
      setRequestModalActive(true);
      setModalText("Acitvating License...");

      try {
        await makeApiRequest(
          '/api/frontend/add-appsumo-license-to-user-account/',
          'post',
          { appsumo_code: appSumoCode }
        );

        // Remove query param and show success message
        navigate(window.location.pathname);
        successAlertRef.current?.show('License successfully activated!');

      } catch (err) {
        console.error('Failed to activate AppSumo license:', err);
        failAlertRef.current?.show('Failed to activate the license!');

      } finally {
        setRequestModalActive(false);
        setModalText("");
      }
    };

    activateAppSumoLicense();
  }, [navigate]);


  useEffect(() => {
    const fetchArticleLanguagePreference = async () => {
      try {
        const response = await makeApiRequest(
          '/api/frontend/get-article-language-preference/',
          'get'
        );

        const data = response.data;
        if (data.article_language_preference) {
          setLanguagePreference(data.article_language_preference);
        } else {
          setLanguagePreference("American English (en-us)");
        }
      } catch (err) {
        if (err instanceof APIError) {
          if (err.statusCode === 401) {
            failAlertRef.current?.show('Unauthorized. Please Refresh the Page.');
          } else {
            failAlertRef.current?.show('An error occurred while fetching language preference.');
          }
        }
      }
    };

    fetchArticleLanguagePreference();
  }, []);

  // Creative Logic Implemented on keyword Click
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    failAlertRef.current?.close();
    // Check if the keyword is empty
    if (!keyword) {
      return;
    }
    // Prevent searching if the keyword hasn't changed
    if (keyword === currentKeyword) {
      return; // No new search
    }

    setIsLoading(true);
    setCreativetitles([]);
    setSerpTitles([]);

    try {
      const response = await makeApiRequest(
        `/api/frontend/fetch-creative-title-data/`, // Update with the correct API path
        'post',
        {
          keyword,
          max_titles: maxTitles,
        }
      );

      const data = response.data;
      if (data.success) {
        setCreativetitles(data.titles);
        setCurrentKeyword(keyword); // Update the current keyword
        setIsCreativeActive(true); // Activate Creative Ideas button
        setSerpActive(false); // Deactivate SERP Ideas
      } else {
        failAlertRef.current?.show('Looks like the Title factory ran out of ink. Try again or reach out to support!');
      }
    } catch (err) {
      failAlertRef.current?.show('Oops! Something went wrong :( Please try again later or contact us for further support.');
    } finally {
      setIsLoading(false);
    }
  }

  // Function to handle suggestion click
  const handleSuggestionClick = (title) => {
    setArticleTitle(title);
  };

  const options = data?.data?.contexts?.map((context) => ({
    label: context.split(" ").slice(0, 3).join(" "),
    value: context,
  })) || [];


  function saveContext(context: string, oldContext?: string) {
    saveInstructionContext.mutate({ context, oldContext: oldContext || "" }, {
      onSuccess: () => {
        setOldContext("")
        setChangeStatus(false)
        successAlertRef.current.show("Context Saved!");
        refetch()
        setTimeout(() => {
          try {
            if (successAlertRef.current) {
              successAlertRef.current.close();
            }
          } catch (e) { }
        }, 3000);
      },
      onError: () => {
        failAlertRef.current?.show("Oops! Something went wrong :( Please try again later or contact us for further support.");
      }
    });
  }

  function handleContextChange(Context: Language) {
    const selectedContext = Context?.value || "";
    if (selectedContext == articleContext) {
      return;
    }
    setArticleContext(selectedContext);
    setOldContext(selectedContext)


    // Trigger save settings immediately
    saveSettings.mutate({
      settingsToSave: [
        { settingName: 'article_context', settingValue: selectedContext },
      ]
    }, {
      onSuccess: () => {
        setShowDropdown(false)
        successAlertRef.current.show("Changes Saved!");
        setTimeout(() => {
          try {
            if (successAlertRef.current) {
              successAlertRef.current.close();
            }
          } catch (e) { }
        }, 3000);
      },
      onError: () => {
        failAlertRef.current?.show("Oops! Something went wrong :( Please try again later or contact us for further support.");
      }
    });
  }


  //Serp Button Handle Logic
  const handleSerpButtonClick = async () => {
    if (!keyword) {
      return;
    }
    // Only fetch titles if they haven't been generated yet
    if (serpTitles.length > 0) {
      setIsCreativeActive(false);
      setSerpActive(true);
      return;
    }
    setSerpActive(true); // Toggle the active state
    setIsCreativeActive(false); // Deactivate Creative Ideas
    setIsLoading(true);

    // Serper Logic Implemented here
    try {
      // Use makeApiRequest to call your backend API
      const response = await makeApiRequest(
        `/api/frontend/fetch-serper-title-data/`,
        'post',
        {
          keyword: keyword,
          location: location,
          max_titles: maxTitles,
        }
      );

      // Process the API response
      const data = response.data;
      if (data.success) {
        setSerpTitles(data.titles);
      } else {
        failAlertRef.current?.show(data.error || 'Something went wrong');
      }
    } catch (err) {
      if (err instanceof APIError) {
        if (err.statusCode === 401) {
          failAlertRef.current?.show('Unauthorized. Please log in again.');
        } else {
          failAlertRef.current?.show('An error occurred while fetching titles.');
        }
      } else {
        failAlertRef.current?.show('An error occurred while fetching titles.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateArticle = async () => {
    if (!articleTitle || !keyword) {
      return;
    }

    if (keyword.length > 300) {
      failAlertRef.current?.show('Keyword is too long. Please enter a shorter keyword.');
      return;
    }

    // Remove any existing error message
    failAlertRef.current?.close();

    try {
      const response = await makeApiRequest(
        '/api/frontend/new-article-data/',
        'post',
        {
          keyword: keyword,
          title: articleTitle,
          serp_titles: serpTitles,
          creative_titles: creativeTitles,
          article_context: articleContext
        }
      );

      // Assuming the response structure contains the necessary fields
      const data = response.data;
      if (data.success) {
        generateArticleHandler(data.article_uid);
      } else {
        failAlertRef.current?.show(data.error || 'Failed to generate article.');
      }
    } catch (err) {
      if (err instanceof APIError) {
        if (err.statusCode === 401) {
          failAlertRef.current?.show('Unauthorized. Please log in again.');
        } else {
          failAlertRef.current?.show('An error occurred while generating the article: ' + (err.responseData.message || 'Unknown error'));
        }
      } else {
        failAlertRef.current?.show('An unexpected error occurred: ' + ('Unknown error'));
      }
    }
  };

  // Logic to Generate Article
  const generateArticle = useMutation(generateV2ArticleMutation);

  function generateArticleHandler(articleUID: string, articleContext?: string) {
    setModalText("Processing request. Please wait...");
    setRequestModalActive(true);
    generateArticle.mutate({ articleUID, context: articleContext ?? "" }, {
      onSuccess: (data) => {
        setRequestModalActive(false);
        let responseData = (data as any)["data"];

        if (responseData["status"] === "sent_for_processing") {
          navigate(`/articles/edit/${articleUID}/`);
        } else if (responseData["status"] === "rejected") {
          if (responseData["reason"] === "max_limit_reached") {
            failAlertRef.current?.show("Article generation request failed. " +
              "You have reached your max article generation limit for the month.");
          } else {
            failAlertRef.current?.show(`Article generation request failed. Error ID: ${responseData["reason"]}`);
          }
        } else {
          failAlertRef.current?.show(
            `Article generation request returned unknown status ${responseData["status"]}. Please contact us if there's any issue.`
          );
        }
      },
      onError: (error: Error) => {
        console.error(error);
        setRequestModalActive(false);
        failAlertRef.current?.show(`Article generation request failed. Please try again later`)
        setTimeout(() => {
          failAlertRef.current?.close();
        }, 5000);
      }
    });
  }

  const handleCreativeIdeas = async () => {
    if (!keyword) {
      return;
    }
    // Only fetch titles if they haven't been generated yet
    if (creativeTitles.length > 0) {
      setIsCreativeActive(true);
      setSerpActive(false);
      return;
    }
  };

  function handleLanguageChange(selectedOption: Language | null) {
    const newLanguage = selectedOption?.value || "";
    setLanguagePreference(newLanguage);
    saveSettings.mutate(
      {
        settingsToSave: [{ settingName: "article_language_preference", settingValue: newLanguage }],
      },
      {
        onSuccess: () => {
          successAlertRef.current?.show("Article Language Changes Saved!");
          setTimeout(() => successAlertRef.current?.close(), 3000);
        },
        onError: () => {
          failAlertRef.current?.show("Oops! Something went wrong. Please try again later.");
        },
      }
    );
  }

  const handleSelectTemplate = (templateId) => {
    if (templateId === selectedTemplate) return;
    failAlertRef.current?.close();
    setSelectedTemplate(templateId);

    if (templateId === "") {
      failAlertRef.current?.show("Please select a feature image template.");
      setTimeout(() => {
        try {
          failAlertRef.current?.close();
        } catch (e) { }
      }, 5000);
      return;
    }

    saveSettings.mutate(
      {
        settingsToSave: [
          { settingName: "feature_image", settingValue: templateId },
          { settingName: 'feature_image_required', settingValue: featuredImageIsEnable },
        ],
      },
      {
        onSuccess: () => {
          try {
            successAlertRef.current?.show("Featured Image Template Saved!");
            setTimeout(() => {
              try {
                successAlertRef.current?.close();
              } catch (e) { }
            }, 3000);
          } catch (e) { }
        },
        onError: () => {
          failAlertRef.current?.show("Failed to update featured image template. Please try again.");
          setTimeout(() => {
            try {
              failAlertRef.current?.close();
            } catch (e) { }
          }, 5000);
        },
      }
    );
  };

  function isTemplateDisabled(template: FeatureImageTemplates, currentPlan: string) {
    // Allow user to use premium template if he/she has already selected a premium template
    const selectedTemplateData = featuredImageTemplates.filter((template) => (template.template_id === selectedTemplate))[0];
    if (selectedTemplateData.label === 'Premium') {
      return false;
    } else if (currentPlan === 'Basic' || currentPlan === 'LTD' || currentPlan === 'Trial') {
      return template.label === 'Premium';
    }
    return false;
  }

  const handleInternalLinks = (newValue) => {
    if (newValue === internalBacklinks) return;
    setInternalBacklinks(newValue);

    saveSettings.mutate(
      {
        settingsToSave: [{ settingName: "max_internal_backlinks", settingValue: newValue }],
      },
      {
        onSuccess: () => {
          successAlertRef.current?.show("Internal links updated!");
          setTimeout(() => {
            try {
              successAlertRef.current?.close();
            } catch (e) { }
          }, 3000);
        },
        onError: () => {
          failAlertRef.current?.show("Failed to update internal links. Please try again.");
          setTimeout(() => {
            try {
              failAlertRef.current?.close();
            } catch (e) { }
          }, 5000);
        },
      }
    );
  };

  const handleExternalLinks = (newValue) => {
    if (newValue === externalBacklinks) return;
    setExternalBacklinks(newValue);

    saveSettings.mutate(
      {
        settingsToSave: [{ settingName: "max_external_backlinks", settingValue: newValue }],
      },
      {
        onSuccess: () => {
          successAlertRef.current?.show("External links updated!");
          setTimeout(() => {
            try {
              successAlertRef.current?.close();
            } catch (e) { }
          }, 3000);
        },
        onError: () => {
          failAlertRef.current?.show("Failed to update external links. Please try again.");
          setTimeout(() => {
            try {
              failAlertRef.current?.close();
            } catch (e) { }
          }, 5000);
        },
      }
    );
  };

  function changeImageSource(newValue) {
    failAlertRef.current?.close();
    if (newValue === imageSource) return;
    setImageSource(newValue);

    saveSettings.mutate({
      settingsToSave: [
        { settingName: 'image_source', settingValue: newValue },
      ]
    }, {
      onSuccess: () => {
        try {
          successAlertRef.current.show("Image Source Changes Saved!");
          setTimeout(() => {
            try {
              if (successAlertRef.current) {
                successAlertRef.current.close();
              }
            } catch (e) { }
          }, 3000);
        } catch (e) { }
      },
      onError: () => {
        failAlertRef.current?.show("Failed to update image source. Please try again.");
        setTimeout(() => {
          try {
            if (failAlertRef.current) {
              failAlertRef.current.close();
            }
          } catch (e) { }
        }, 5000);
      }
    });
  }


  function handleTextareaChange(e) {
    const newValue = e.target.value;
    setArticleContext(newValue)
    if (newValue.trim() !== oldContext.trim()) {
      setChangeStatus(true)
    } else {
      setChangeStatus(false)
    }
  }

  function changeImageFileFormat(newValue: string) {
    failAlertRef.current?.close();
    setImagesFileFormat(newValue);

    saveSettings.mutate({
      settingsToSave: [
        { settingName: 'images_file_format', settingValue: newValue },
      ]
    }, {
      onSuccess: () => {
        try {
          successAlertRef.current.show("Image File Format Changes Saved!");
          setTimeout(() => {
            try {
              if (successAlertRef.current) {
                successAlertRef.current.close();
              }
            } catch (e) { }
          }, 3000);
        } catch (e) { }
      },
      onError: () => {
        failAlertRef.current?.show("Failed to update image file format. Please try again.");
        setTimeout(() => {
          try {
            if (failAlertRef.current) {
              failAlertRef.current.close();
            }
          } catch (e) { }
        }, 5000);
      }
    });
  }

  function CustomizedSwitch() {
    const IOSSwitch = styled((props: SwitchProps) => (
      <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
      width: 42,
      height: 26,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: '#65C466',
            opacity: 1,
            border: 0,
            ...theme.applyStyles('dark', {
              backgroundColor: '#2ECA45',
            }),
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: theme.palette.grey[100],
          ...theme.applyStyles('dark', {
            color: theme.palette.grey[600],
          }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.7,
          ...theme.applyStyles('dark', {
            opacity: 0.3,
          }),
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
      },
      '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 500,
        }),
        ...theme.applyStyles('dark', {
          backgroundColor: '#39393D',
        }),
      },
    }));

    return (
      <FormGroup>
        <FormControlLabel
          control={<IOSSwitch sx={{ m: 1 }}
            defaultChecked={featuredImageIsEnable}
            onChange={() => UpdateFeaturedReq()}
          />}
          label=""
        />
      </FormGroup>
    );
  }

  function UpdateFeaturedReq() {
    const newValue = !featuredImageIsEnable;
    setIsFeaturedImageEnable(newValue);
    saveSettings.mutate(
      {
        settingsToSave: [
          { settingName: 'feature_image_required', settingValue: newValue },
        ],
      },
      {
        onSuccess: () => {
          successAlertRef.current?.show("Featured Images Changes Saved!");
          setTimeout(() => {
            successAlertRef.current?.close();
          }, 3000);
        },
        onError: () => {
          failAlertRef.current?.show("Failed to Fetch Featured images. Please try again.");
          setTimeout(() => {
            failAlertRef.current?.close();
          }, 5000);
        },
      }
    );
  }

  return (
    <>
      {showSurvey && <Survey />}
      <div className="ca-container">
        <div className="ca-header">
          <h2 className={"is-size-4"} style={{ fontWeight: "800" }}>Create Article</h2>
        </div>

        {/* Center-aligned checkboxes in two rows and three columns */}
        <div className="ca-checkbox-row">
          <div className="ca-checkbox-group">
            <div className="ca-checkbox-item">
              <span className="elementor-icon-list-icon" id="fct">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path opacity="0.5" d="M21.8385 10.9993C21.8385 16.9824 16.9882 21.8327 11.0052 21.8327C5.02212 21.8327 0.171875 16.9824 0.171875 10.9993C0.171875 5.01626 5.02212 0.166016 11.0052 0.166016C16.9882 0.166016 21.8385 5.01626 21.8385 10.9993Z" fill="#1E9156"></path>
                  <path d="M15.3688 7.71649C15.6861 8.03379 15.6861 8.54824 15.3688 8.8655L9.95209 14.2822C9.63479 14.5995 9.12042 14.5995 8.80308 14.2822L6.63641 12.1155C6.31911 11.7982 6.31911 11.2838 6.63641 10.9665C6.95371 10.6492 7.46816 10.6492 7.78546 10.9665L9.3776 12.5586L11.7986 10.1376L14.2198 7.71649C14.5371 7.39919 15.0515 7.39919 15.3688 7.71649Z" fill="#0B7944"></path>
                </svg>
              </span>
              <span className="ca-checkbox-text">Google Compliant</span>
            </div>
            <div className="ca-checkbox-item">
              <span className="elementor-icon-list-icon" id="fct">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path opacity="0.5" d="M21.8385 10.9993C21.8385 16.9824 16.9882 21.8327 11.0052 21.8327C5.02212 21.8327 0.171875 16.9824 0.171875 10.9993C0.171875 5.01626 5.02212 0.166016 11.0052 0.166016C16.9882 0.166016 21.8385 5.01626 21.8385 10.9993Z" fill="#1E9156"></path>
                  <path d="M15.3688 7.71649C15.6861 8.03379 15.6861 8.54824 15.3688 8.8655L9.95209 14.2822C9.63479 14.5995 9.12042 14.5995 8.80308 14.2822L6.63641 12.1155C6.31911 11.7982 6.31911 11.2838 6.63641 10.9665C6.95371 10.6492 7.46816 10.6492 7.78546 10.9665L9.3776 12.5586L11.7986 10.1376L14.2198 7.71649C14.5371 7.39919 15.0515 7.39919 15.3688 7.71649Z" fill="#0B7944"></path>
                </svg>
              </span>
              <span className="ca-checkbox-text">No Plagiarism</span>
            </div>
          </div>
          <div className="ca-checkbox-group">
            <div className="ca-checkbox-item">
              <span className="elementor-icon-list-icon" id="fct">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path opacity="0.5" d="M21.8385 10.9993C21.8385 16.9824 16.9882 21.8327 11.0052 21.8327C5.02212 21.8327 0.171875 16.9824 0.171875 10.9993C0.171875 5.01626 5.02212 0.166016 11.0052 0.166016C16.9882 0.166016 21.8385 5.01626 21.8385 10.9993Z" fill="#1E9156"></path>
                  <path d="M15.3688 7.71649C15.6861 8.03379 15.6861 8.54824 15.3688 8.8655L9.95209 14.2822C9.63479 14.5995 9.12042 14.5995 8.80308 14.2822L6.63641 12.1155C6.31911 11.7982 6.31911 11.2838 6.63641 10.9665C6.95371 10.6492 7.46816 10.6492 7.78546 10.9665L9.3776 12.5586L11.7986 10.1376L14.2198 7.71649C14.5371 7.39919 15.0515 7.39919 15.3688 7.71649Z" fill="#0B7944"></path>
                </svg>
              </span>
              <span className="ca-checkbox-text">Optimized for Ranking</span>
            </div>
            <div className="ca-checkbox-item">
              <span className="elementor-icon-list-icon" id="fct">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path opacity="0.5" d="M21.8385 10.9993C21.8385 16.9824 16.9882 21.8327 11.0052 21.8327C5.02212 21.8327 0.171875 16.9824 0.171875 10.9993C0.171875 5.01626 5.02212 0.166016 11.0052 0.166016C16.9882 0.166016 21.8385 5.01626 21.8385 10.9993Z" fill="#1E9156"></path>
                  <path d="M15.3688 7.71649C15.6861 8.03379 15.6861 8.54824 15.3688 8.8655L9.95209 14.2822C9.63479 14.5995 9.12042 14.5995 8.80308 14.2822L6.63641 12.1155C6.31911 11.7982 6.31911 11.2838 6.63641 10.9665C6.95371 10.6492 7.46816 10.6492 7.78546 10.9665L9.3776 12.5586L11.7986 10.1376L14.2198 7.71649C14.5371 7.39919 15.0515 7.39919 15.3688 7.71649Z" fill="#0B7944"></path>
                </svg>
              </span>
              <span className="ca-checkbox-text">Auto-Publish Blogs</span>
            </div>
          </div>
          <div className="ca-checkbox-group">
            <div className="ca-checkbox-item">
              <span className="elementor-icon-list-icon" id="fct">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path opacity="0.5" d="M21.8385 10.9993C21.8385 16.9824 16.9882 21.8327 11.0052 21.8327C5.02212 21.8327 0.171875 16.9824 0.171875 10.9993C0.171875 5.01626 5.02212 0.166016 11.0052 0.166016C16.9882 0.166016 21.8385 5.01626 21.8385 10.9993Z" fill="#1E9156"></path>
                  <path d="M15.3688 7.71649C15.6861 8.03379 15.6861 8.54824 15.3688 8.8655L9.95209 14.2822C9.63479 14.5995 9.12042 14.5995 8.80308 14.2822L6.63641 12.1155C6.31911 11.7982 6.31911 11.2838 6.63641 10.9665C6.95371 10.6492 7.46816 10.6492 7.78546 10.9665L9.3776 12.5586L11.7986 10.1376L14.2198 7.71649C14.5371 7.39919 15.0515 7.39919 15.3688 7.71649Z" fill="#0B7944"></path>
                </svg>
              </span>
              <span className="ca-checkbox-text">Human-Like Content</span>
            </div>
            <div className="ca-checkbox-item">
              <span className="elementor-icon-list-icon" id="fct">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path opacity="0.5" d="M21.8385 10.9993C21.8385 16.9824 16.9882 21.8327 11.0052 21.8327C5.02212 21.8327 0.171875 16.9824 0.171875 10.9993C0.171875 5.01626 5.02212 0.166016 11.0052 0.166016C16.9882 0.166016 21.8385 5.01626 21.8385 10.9993Z" fill="#1E9156"></path>
                  <path d="M15.3688 7.71649C15.6861 8.03379 15.6861 8.54824 15.3688 8.8655L9.95209 14.2822C9.63479 14.5995 9.12042 14.5995 8.80308 14.2822L6.63641 12.1155C6.31911 11.7982 6.31911 11.2838 6.63641 10.9665C6.95371 10.6492 7.46816 10.6492 7.78546 10.9665L9.3776 12.5586L11.7986 10.1376L14.2198 7.71649C14.5371 7.39919 15.0515 7.39919 15.3688 7.71649Z" fill="#0B7944"></path>
                </svg>
              </span>
              <span className="ca-checkbox-text">Factual Content</span>
            </div>
          </div>
        </div>

        {/* Flexbox for input fields and suggestions */}
        <div className="ca-content-row">
          <div className="ca-form-column">
            <form onSubmit={handleSubmit} style={{ marginTop: '-20px' }}>
              <label className="ca-label">Keyword</label>
              <input
                className="ca-input"
                type="text"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="Enter keyword"
                onBlur={handleSubmit} // Automatically fetch titles when focus is lost
                style={{ marginTop: '3px', textAlign: 'start' }}
                required
              />
            </form>

            <label className="ca-label">Article Title</label>
            <input
              className="ca-input"
              type="text"
              value={articleTitle}
              placeholder="Enter Article Title or choose from the right widget"
              onChange={(e) => setArticleTitle(e.target.value)}
              style={{ textAlign: 'start' }}
              required
            />
            <label className="ca-label">What should be the language of the generated articles?</label>
            <Select
              options={allSupportedLanguagesOptions}
              value={allSupportedLanguagesOptions.find(option => option.value === languagePreference) || null}
              onChange={handleLanguageChange}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '10px'
                }),
              }}
            />
            <label className="mt-3 ca-label is-flex is-align-items-center">
              Instructions & Context (Optional)
              <span className="tooltip-question"
                data-tooltip-id="style_button"
                data-tooltip-content="Specify the tone, style, or key points, and we’ll cook up the perfect article. The more details, the better!">?
              </span>
            </label>
            <div className="textarea-container">
              <textarea
                rows={10}
                className="ca-input"
                value={articleContext}
                placeholder="Include MyCompany.com at the top of the list. Also talk more about how MyCompany.com helps with This, This & That"
                onChange={(e) => handleTextareaChange(e)}
                style={{ textAlign: "start", height: "180px", minHeight: "86px" }}
                required
              />
              {/* Three Dots Button */}
              <button
                className={`svg-btn ${!changeStatus ? "disabled" : ""}`}
                disabled={!changeStatus}
                onClick={() => {
                  if (changeStatus) {
                    saveContext(articleContext);
                  }
                }}
                style={{
                  cursor: changeStatus ? "pointer" : "not-allowed",
                }}
              >
                <img src={saveIcon} alt="save-icon" style={{ height: "15px" }} />
              </button>
              <div
                className="btn"
                onClick={(event) => {
                  event.stopPropagation();
                  setShowDropdown((prev) => !prev)
                }}
              >
                <img src={optionIcon} 
                     alt="save-icon"
                     className={`icon ${showDropdown || options.some(option => articleContext?.trim() === option.value.trim()) ? 'active' : ''}`}
                 />
              </div>

              {/* Dropdown Menu */}
              {showDropdown && (
                <div ref={dropdownRef} className="dropdown-container">
                  <div className="sub-dropdown-container"
                  >
                    {options.map((option) => (
                      <div
                        key={option.value}
                        onClick={() => handleContextChange(option)}
                        style={{
                          padding: "8px",
                          cursor: "pointer",
                          backgroundColor: articleContext?.trim() === option.value.trim() ? "#ddd" : "white",
                          borderBottom: "1px solid #eee",
                        }}
                      >
                        {option.label}
                      </div>
                    ))}
                  </div>



                </div>
              )}
            </div>

            <div style={{ display: "flex" }}>
              <button
                onClick={() => setRequestSettingModalActive(true)}
                className="Advanced_Button_Large">
                Advanced
              </button>
              <div className="Generate_button_large" style={{ position: 'relative', display: 'inline-block' }}>
                <div data-tooltip-id="buttonTip" data-tooltip-content="Verify email to create article">
                  <GenericButton
                    text={"Generate Article ➜"}
                    type={"primary"}
                    width={"219px"}
                    height={"40px"}
                    left={"7px"}
                    outlined={true}
                    disable={!isVerified}
                    additionalClassList={["is-small", "more-rounded-borders"]}
                    clickHandler={handleGenerateArticle}
                    style={{ fontSize: "1rem" }}
                  />
                </div>
                {!isVerified && (
                  <Tooltip id="buttonTip" place="bottom" />
                )}
              </div>
            </div>
          </div >

          <div className="ca-suggestion-box">
            <h3 className="ca-suggestion-title">Article Title Suggestions based on keyword</h3>
            <div className="ca-suggestions">
              <button className={`ca-suggestion-button ${isCreativeActive ? 'active' : ''}`} onClick={handleCreativeIdeas}>
                Creative Ideas
                <span className="circle-question"
                  data-tooltip-id="style_button"
                  data-tooltip-content="AI brainstormed, Creative Titles designed to help you stand out!">?</span>
              </button>
              <button className={`ca-suggestion-button ${serpActive ? 'active' : ''}`} onClick={handleSerpButtonClick}>
                SERP Based Ideas
                <span className="circle-question" data-tooltip-id="style_button"
                  data-tooltip-content="Top-ranking Titles inspired by SERP results!">?</span></button>
            </div>
            <ul className="ca-suggestion-list">
              {IsLoading ? (
                <li className="ca-suggestion-item">Generating Titles, please wait...</li>
              ) : isCreativeActive && creativeTitles.length > 0 ? (
                // Render Creative Ideas when active
                creativeTitles.map((title, index) => (
                  <li key={index} className="ca-suggestion-item"
                    data-tooltip-id="tooltip"
                    data-tooltip-content={keyword ? "Click to use title" : "Please enter a keyword"}
                    onClick={() => {
                      if (keyword) {
                        handleSuggestionClick(title);
                      }
                    }}>
                    {title}
                  </li>
                ))
              ) : serpActive && serpTitles.length > 0 ? (
                // Render SERP-based Ideas when active
                serpTitles.map((title, index) => (
                  <li key={index} className="ca-suggestion-item"
                    data-tooltip-id="tooltip"
                    data-tooltip-content={keyword ? "Click to use title" : "Please enter a keyword"}
                    onClick={() => {
                      if (keyword) {
                        handleSuggestionClick(title);
                      }
                    }}>
                    {title}
                  </li>
                ))
              ) : (
                <h3 className="ca-suggestion-title"
                  style={{ fontWeight: '500', marginTop: '50px', fontFamily: 'Inter', fontStyle: 'italic', fontSize: '18px' }}
                >
                  Enter Keyword to get Title Suggestions</h3>
              )}
            </ul>
            {/* React Tooltip Component */}
            <Tooltip id="tooltip" place="bottom" />
            <Tooltip id="style_button" place="bottom" />
          </div>
        </div >
        <button
          onClick={() => setRequestSettingModalActive(true)}
          className="Advanced_Button_Small">
          Advanced
        </button>
        <div className="Generate_button">
          <div data-tooltip-id="buttonTip" data-tooltip-content="Verify email to create article">
            <GenericButton
              text={"Generate Article ➜"}
              type={"primary"}
              width={"219px"}
              height={"40px"}
              left={"7px"}
              outlined={true}
              disable={!isVerified}
              additionalClassList={["is-small", "more-rounded-borders"]}
              clickHandler={handleGenerateArticle}
              style={{ fontSize: "1rem" }}
            />
          </div>
          {!isVerified && (
            <Tooltip id="buttonTip" place="bottom" />
          )}
        </div>

        {/* ------------------------------ ONGOING REQUEST MODAL ------------------------------ */}
        <AbunModal active={requestModalActive}
          headerText={""}
          closeable={false}
          modalCardClass={"loading-article-generation"}
          hideModal={() => {
            setRequestModalActive(false)
          }}>
          <div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
            {
              appSumoCode ?
                <l-quantum size="120" speed="1.75" color="#3F77F8"></l-quantum> :
                <AbunLoader show={requestModalActive} height="20vh" />
            }
          </div>
          <p className={`is-size-4 has-text-centered mb-4 ${appSumoCode ? "mt-5" : ""}`}>{modalText}</p>
        </AbunModal>

        <AbunModal
          active={requestSettingModalActive}
          headerText={"Advanced Settings"}
          closeable={true}
          closeableKey={true}
          modalCardClass="advanced-settings-modal"
          closeOnOutsideClick={true}
          hideModal={() => setRequestSettingModalActive(false)}
        >
          {/* Container for internal & external link dropdowns */}
          <div className={"internal-external-link-container"}>
            <div>
              <label style={{ fontWeight: "600", fontSize: "14px" }}>Number of Internal Links</label>
              <input
                type="number"
                value={internalBacklinks}
                onChange={(e) => {
                  const rawValue = e.target.value;
                  if (rawValue === "") {
                    setInternalBacklinks("");
                    return;
                  }
                  const value = Math.max(0, Math.min(25, parseInt(rawValue, 10) || 0));
                  handleInternalLinks(value);
                }}
                className="ca-link-input"
              />
            </div>

            <div>
              <label style={{ fontWeight: "600", fontSize: "14px" }}>Number of External Links</label>
              <input
                type="number"
                value={externalBacklinks}
                onChange={(e) => {
                  const rawValue = e.target.value;
                  if (rawValue === "") {
                    setExternalBacklinks("");
                    return;
                  }
                  const value = Math.max(0, Math.min(25, parseInt(rawValue, 10) || 0));
                  handleExternalLinks(value);
                }}
                className="ca-link-input"
              />
            </div>
          </div>

          {/* Featured Image Template Section */}
          <div style={{ display: 'flex' }}>
            <CustomizedSwitch
              aria-label="Enable featured images"
            />
            <h3 style={{ fontSize: "14px", fontWeight: "600", marginTop: "10px", marginLeft: "-15px" }}>Featured Image Template</h3>
          </div>
          <div className="ca-featured-section">
            {featuredImageIsEnable &&
              featuredImageTemplates?.sort((a, b) => {
                return a.order - b.order;
              })?.map((item, index) => {
                const isDisabled = isTemplateDisabled(item, currentPlan);
                const tooltipId = `disabled-${item.template_id}`;

                return (
                  <div key={index}
                    data-tooltip-id={tooltipId}
                    data-tooltip-content="This template is only available for Pro and Pro Max users. Please upgrade your plan."
                    onClick={() => !isDisabled && handleSelectTemplate(item.template_id)}
                    style={{
                      border: selectedTemplate === item.template_id ? "2px solid blue" : "1px solid #ccc",
                      borderRadius: "8px",
                      padding: "15px",
                      textAlign: "center",
                      cursor: isDisabled ? "not-allowed" : "pointer",
                      background: "#f9f9f9",
                      width:"100%",
                      height: "100%"
                    }}>
                    {isDisabled && <Tooltip style={{ position: "absolute", zIndex: "1000", opacity: 1 }} id={tooltipId} />}
                    <div style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "10px",
                      opacity: isDisabled ? 0.6 : 1,
                    }}>
                      <img
                        src={item.sample_image_url}
                        alt={item.template_name}
                        className="ca-featured-image"
                      />
                    </div>
                    <div className="title-label-container" style={{ opacity: isDisabled ? 0.6 : 1 }}>
                      <h4 style={{ fontSize: "14px", fontWeight: "600", marginBottom: "4px" }}>{item.template_name}</h4>
                      <div className="label-container">
                        <span
                          className="uncollapsed-tag tag is-info is-light is-rounded"
                          style={{ padding: "6px 13px", fontSize: "14px" }}
                        >
                          {item.label === "Premium" && <span>👑 </span>}
                          {item.label === "Basic" && <span>🌱 </span>}
                          {item.label === "Pro" && <span>⚡ </span>}
                        </span>
                      </div>
                    </div>
                    {/* <p style={{ fontSize: "12px", color: "#666", opacity: isDisabled ? 0.6 : 1 }}>{item.tool_tip}</p> */}
                  </div>
                );
              })}
          </div>
          {/* -=-=-=-=-=-=-=-=-=-=-= Image Source -=-=-=-=-=-=-=-=-=-=-= */}
          <div className={"settings-section"} style={featuredImageIsEnable ? { marginTop: "2rem" } : { marginTop: "0.5rem" }}>
            <h5 className={"settings-section-title"}>
              Which image source do you want to use in your articles?
            </h5>
            <div className="control mt-5">
              <label className="radio">
                <input type="radio"
                  className={"mr-4"}
                  name={"image-source"}
                  checked={imageSource === 'ai_image_generation'}
                  onChange={() => changeImageSource('ai_image_generation')}
                />
                <span className={"settings-radio-item-title"}>AI Generated</span>
              </label>
              <p className={"settings-radio-item-description"}>
                AI Generated images will be used in the Article.
              </p>
            </div>
            <div className="control mt-5">
              <label className="radio">
                <input type="radio"
                  className={"mr-4"}
                  name={"image-source"}
                  checked={imageSource === 'google'}
                  onChange={() => changeImageSource('google')}
                />
                <span className={"settings-radio-item-title"}>Google Images</span>
              </label>
              <p className={"settings-radio-item-description"}>
                Get images from Google. Using this option might require you to get the necessary permission(s)
                from the photo owner before using them in your article.
              </p>
            </div>
            <div className="control mt-5">
              <label className="radio">
                <input type="radio"
                  className={"mr-4"}
                  name={"image-source"}
                  checked={imageSource === 'no_image'}
                  onChange={() => changeImageSource('no_image')}
                />
                <span className={"settings-radio-item-title"}>No Image</span>
              </label>
              <p className={"settings-radio-item-description"}>
                No images will be used in the Article.
              </p>
            </div>
          </div>

          {/* -=-=-=-=-=-=-=-=-=-=-= Image File Format -=-=-=-=-=-=-=-=-=-=-= */}
          <div className={"settings-section mt-6"}>
            <h5 className={"settings-section-title"}>
              Images File Format:
            </h5>
            <div className="is-flex control mt-4">
              <div className="mr-4">
                <label className="radio is-flex is-align-items-center">
                  <input type="radio"
                    className={"mr-4"}
                    name={"image-format"}
                    checked={imageFilesFormat === "webp"}
                    onChange={() => changeImageFileFormat("webp")}
                  />
                  <span className={"settings-radio-item-title"}>WebP</span>
                </label>
              </div>
              <div className="ml-4">
                <label className="radio is-flex is-align-items-center">
                  <input type="radio"
                    className={"mr-4"}
                    name={"image-format"}
                    checked={imageFilesFormat === "png"}
                    onChange={() => changeImageFileFormat("png")}
                  />
                  <span className={"settings-radio-item-title"}>PNG</span>
                </label>
              </div>
              <div className="ml-4">
                <label className="radio is-flex is-align-items-center">
                  <input type="radio"
                    className={"mr-4"}
                    name={"image-format"}
                    checked={imageFilesFormat === "jpeg"}
                    onChange={() => changeImageFileFormat("jpeg")}
                  />
                  <span className={"settings-radio-item-title"}>JPG</span>
                </label>
              </div>
            </div>
          </div>

          {/* -=-=-=-=-=-=-=-=-=-=-= Tone of article -=-=-=-=-=-=-=-=-=-=-= */}
          <ToneOfArticle errorAlertRef={failAlertRef}
            successAlertRef={successAlertRef}
            toneOfArticle={pageData.tone_of_article}
            scaleOfTone={pageData.scale_of_tone}
            boxStyle={{ boxShadow: "none", marginLeft: "-1.5rem" }}
          />

        </AbunModal>


        <ErrorAlert ref={failAlertRef} />
        <SuccessAlert ref={successAlertRef} />

        {
          showConnectWebsiteModal && (
            <ConnectWebsite
              setShowConnectWebsiteModal={setShowConnectWebsiteModal}
              failAlertRef={failAlertRef}
              successAlertRef={successAlertRef}
              navigateOrReload="reload"
            />
          )
        }
      </div >
    </>
  );
};

export default CreateArticle;
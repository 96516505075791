import { useQuery } from "@tanstack/react-query";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AbunTable from "../../components/AbunTable/AbunTable";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import { getRedditPostFinderQueriesView } from "../../utils/api";
import { getTaskProgress } from "../../utils/api";
import AbunLoader from "../../components/AbunLoader/AbunLoader";

interface RedditQueryResult {
    post_title: string,
    post_link: string,
    query: string,
    position: number,
}


export default function RedditPostFinderView() {
    // ---------------------- NON STATE CONSTANTS ----------------------
    const pageSizes = [15, 25, 50, 100];

    // -------------------------- STATES --------------------------
    const [queries, setQueries] = useState<Array<RedditQueryResult>>([]);
    const { queryID } = useParams() as { queryID: string };
    const [query, setQuery] = useState("");
    const [processing, setIsProcessing] = useState(false);
    const navigate = useNavigate();

    // -------------------------- REFS --------------------------
    const errorAlertRef = useRef<any>(null);
    const successAlertRef = useRef<any>(null);

    // -------------------------- QUERIES --------------------------
    const {
        isFetching,
        isError,
        data,
        refetch
    } = useQuery({
        queryKey: ['getRedditPostFinderQueriesView', queryID],
        queryFn: () => getRedditPostFinderQueriesView(queryID),
        refetchOnWindowFocus: false
    });

    // ---------------------- EFFECTS ----------------------
    useEffect(() => {
        const storedTaskId = localStorage.getItem("task_id");
        if (storedTaskId) {
            pollTaskProgress(storedTaskId);
        }
    }, []);

    const pollTaskProgress = (taskId) => {
        setIsProcessing(true);
        const interval = setInterval(() => {
            getTaskProgress(taskId)
                .then((res) => {
                    const status = res.data.status;
                    if (status === "success") {
                        console.log("progress succeed")
                        clearInterval(interval);
                        setIsProcessing(false);
                        setTimeout(() => successAlertRef.current?.close(), 5000);
                        localStorage.removeItem("task_id");
                        refetch();
                    } else if (status === "failure") {
                        clearInterval(interval);
                        setIsProcessing(false);
                        errorAlertRef.current?.show("Task failed. Please try again.");
                        setTimeout(() => errorAlertRef.current?.close(), 5000);
                    }
                })
                .catch((err) => {
                    console.error("Error fetching task progress:", err);
                    clearInterval(interval);
                    setIsProcessing(false);
                    errorAlertRef.current?.show("Error fetching task progress.");
                    setTimeout(() => errorAlertRef.current?.close(), 5000);
                });
        }, 2000);
    };

    useEffect(() => {
        if (data) {
            // Function to replace null/undefined with "N/A"
            const setDefaults = (item: RedditQueryResult): RedditQueryResult => ({
                post_title: item.post_title || "N/A",
                post_link: item.post_link || "N/A",
                query: item.query || "N/A",
                position: item.position ?? "N/A",
            });

            // Transform data
            const transformedQueries = data['data']['queries'].map(setDefaults);

            setQueries(transformedQueries);

            if (transformedQueries.length > 0) {
                setQuery(transformedQueries[0].query);
            }
        }
    }, [data]);

    // ---------------------- EFFECTS ----------------------
    useEffect(() => {
        document.title = "Reddit Post Finder | Abun"
    }, []);

    // ---------------------- TABLE COLUMN DEFS ----------------------
    const columnHelper = createColumnHelper<RedditQueryResult>();
    const columnDefs: ColumnDef<any, any>[] = [
        columnHelper.accessor((row: RedditQueryResult) => row.post_title, {
            id: 'URL',
            header: "Top Reddit Post URL",
            cell: (props) => {
                const handleClick = () => {
                    window.open(props.row.original.post_link, '_blank');
                };

                return (
                    <span onClick={handleClick} style={{ cursor: 'pointer' }}>
                        {props.row.original.post_title}
                    </span>
                );
            },
            enableGlobalFilter: true,
        }),
        columnHelper.accessor((row: RedditQueryResult) => row.position, {
            id: 'SERP',
            header: "SERP Rank",
            cell: info => info.getValue() || "N/A",
            enableGlobalFilter: true,
            meta: {
                align: 'center'
            }
        }),
    ]
    function goBack() {
        navigate(-1);
    }
    function downloadCSV() {
        if (queries.length === 0) {
            errorAlertRef.current?.show("No data available to download.");
            return;
        }

        // Extract relevant columns (Reddit Post URL and SERP Rank)
        const csvData = [
            ["Reddit Post URL", "Reddit Post Title", "SERP Rank"], // CSV headers
            ...queries.map((row) => [
                row.post_link,
                row.post_title,
                row.position
            ])
        ];

        // Convert array to CSV format
        const csvContent = csvData.map(e => e.join(",")).join("\n");

        // Create a Blob and trigger download
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `Abun.com - ${query}_REDDIT_POST_FINDER.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Show success alert after the file is downloaded
        setTimeout(() => {
            successAlertRef.current?.show("CSV file has been downloaded successfully.");
        }, 500);
    }


    // ============================================================
    // --------------------- MAIN RENDER CODE ---------------------
    // ============================================================
    if (processing || isFetching) {
        return (
            <>
                <div className="card w-100">
                    <div className={"card-content"}>
                        <AbunLoader show={processing || isFetching} height="50vh" />
                    </div>
                </div>
            </>
        );
    }

    else if (isError) {
        return (
            <section className="section">
                <div className="container">
                    <div className="box">
                        <h1 className="title has-text-centered">Find Blogs with Reddit Post
                            Opportunities for your Topic/Niche</h1>
                        <p className="has-text-centered is-size-5">
                            Failed to load data. Please try again later.
                        </p>
                    </div>
                </div>
            </section>
        );
    } else {
        return (
            <>
                <div className="card w-100">
                    <div className={"card-content"}>
                        <span className={"back-btn"} style={{ cursor: "pointer" }} onClick={goBack}>
                            <svg className="back-btn" width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26.0435 12.0003H2.82031M2.82031 12.0003L12.8382 1.98242M2.82031 12.0003L12.8382 22.0181" stroke="black" stroke-opacity="0.5" stroke-width="3" />
                            </svg>
                        </span>
                        <div className={"w-100 is-flex is-align-items-center is-flex-direction-column mt-3"}>
                            <h1 style={{ fontSize: "2em", fontWeight: "normal", textAlign: "center"}}>
                                Topic: {query}
                            </h1>
                        </div>
                        <div className={"abun-table-button-container"}>
                            <button
                                className={`button is-primary is-small is-justify-content-space-between is-flex is-align-items-center`}
                                onClick={downloadCSV}
                            >
                                <p className={"btn-txt"}>Download as CSV</p>
                            </button>
                        </div>

                        <AbunTable tableContentName={"Reddit Post Queries"}
                            tableData={queries}
                            columnDefs={columnDefs}
                            pageSizes={pageSizes}
                            initialPageSize={pageSizes[0]}
                            noDataText={"No Queries data available."}
                            searchboxPlaceholderText={"Search query..."}
                        />

                        <SuccessAlert ref={successAlertRef} />
                        <ErrorAlert ref={errorAlertRef} />
                    </div>
                </div>
            </>
        );
    }
}
